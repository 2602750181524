import { ALERT_ERROR, FULFILLED, REJECTED } from 'actionTypes';
import history from 'appHistory';
import { delay, put } from 'redux-saga/effects';
import { apiRequest, apiRequestDirectURL } from 'utils/request';

const SEARCH_INPUT_DELAY = 300;

export const handlerError = function*(TYPE, error) {
  if (error.response) {
    if (error.response.status === 401) {
      yield put({ type: ALERT_ERROR, payload: error.response.data.detail });
      yield put({ type: 'LOGOUT' });
    } else {
      yield put({ type: ALERT_ERROR, payload: error.response.statusText });
      yield put({ type: TYPE + REJECTED, payload: error.response.statusText });
    }
  } else {
    yield put( { type: ALERT_ERROR, payload: 'Unknown error' });
    yield put({ type: TYPE + REJECTED, payload: 'Unknown error' });
  }
};

export const create = (TYPE, url) => function* ({ payload: data, meta: redirect }) {
  try {
    const response = yield apiRequest(`${url}`, 'POST', data);
    yield put({ type: TYPE + FULFILLED, payload: response.data });
    if (redirect) {
      history.push(redirect);
    }
  } catch (error) {
    yield handlerError(TYPE, error);
  }
};

export const fetchPaginated = (TYPE, url) => function* ({ payload: id }) {
  try {
    const buffer = [];
    let response = yield apiRequest(url);
    if ( Array.isArray(response.data)) {
      buffer.push(response.data);
      while (response.data.next) {
        response = yield apiRequestDirectURL(response.data.next);
        buffer.push(response.data);
      }

      // TODO to solve Nuno modification oon API to remove later when he corrects ENDPOINT


      const reducer = (acc, cv) => [...acc, ...cv.results ? cv.results : cv];
      const data = buffer.reduce(reducer, []);
      yield put({ type: TYPE + FULFILLED, payload: data });
    } else {
      yield put({ type: TYPE + FULFILLED, payload: response.data });
    }
  } catch (error) {
    yield handlerError(TYPE, error);
  }
};

export const fetch = (TYPE, url) => function* ({ payload: id }) {
  try {
    const response = yield apiRequest(id ? url + id : url);
    yield put({ type: TYPE + FULFILLED, payload: response.data, meta: { refresh: !id } });
  } catch (error) {
    yield handlerError(TYPE, error);
  }
};

export const fetchWithParams = (TYPE, url) => function* ({ payload: params, meta }) {
  try {
    let queryParams = '';
    Object.keys(params || {}).forEach(key => {
      if (key && params[key]) {
        // don't add & the first time
        if (queryParams) {
          queryParams += '&';
        }
        queryParams += `${key}=${params[key]}`;
      }
    });

    const response = yield apiRequest(queryParams ? url + '?' + queryParams : url);
    yield put({
      type: TYPE + FULFILLED,
      payload: response.data,
      meta: { refresh: (queryParams === '') || (meta && meta.forcedRefresh) }
    });
  } catch (error) {
    yield handlerError(TYPE, error);
  }
};

export const remove = (TYPE, url) => function* ({ payload: id, meta: redirect }) {
  try {
    yield apiRequest(`${url}/${id}`, 'DELETE');
    yield put({ type: TYPE + FULFILLED, payload: id });
    if (redirect) {
      history.push(redirect);
    }
  } catch (error) {
    yield handlerError(TYPE, error);
  }
};

export const update = (TYPE, url) => function* ({ payload, meta: redirect }) {
  try {
    const response = yield apiRequest(`${url}/${payload.id}`, 'PUT', payload.data);
    yield put({ type: TYPE + FULFILLED, payload: response.data });
    if (redirect) {
      history.push(redirect);
    }
  } catch (error) {
    yield handlerError(TYPE, error);
  }
};

export const searchDebounce = TYPE => function* () {
  try {
    yield delay(SEARCH_INPUT_DELAY);
    yield put({ type: TYPE });
  } catch (error) {
    console.log(error);
  }
};

export const poolApi = (TYPE, initialPath, finalPath) => function* ({ payload: id }) {
  try {
    const response = yield apiRequest(initialPath + id + finalPath);
    yield put({ type: TYPE + FULFILLED, payload: response.data });
  } catch (error) {
    yield handlerError(TYPE, error);
  }
};
