import accountsReducer from 'ducks/accounts';
import alertsReducer from 'ducks/alerts';
import authenticationReducer from 'ducks/auth';
import bootstrapReducer from 'ducks/bootstrap';
import rawMaterialConsumptionsReducer from 'ducks/consumptions';
import conditionsReducer from 'ducks/conditions';
import descriptorsReducer from 'ducks/descriptors';
import descriptorTypesReducer from 'ducks/descriptorTypes';
import echaReducer from 'ducks/echa';
import flowsReducer from 'ducks/flows';
import messagesReducer from 'ducks/messages';
import mixturesReducer from 'ducks/mixtures';
import profileReducer from 'ducks/profile';
import productsReducer from 'ducks/products';
import productFlowReducer from 'ducks/productFlows';
import productStepsReducer from 'ducks/productSteps';
import productsRMReducer from 'ducks/productsRM';
import rawMaterialsReducer from 'ducks/rawMaterials';
import rawMaterialSuppliersReducer from 'ducks/rawMaterialSuppliers';
import reportReducer from 'ducks/report';
import stepsReducer from 'ducks/steps';
import stepRawMaterialsReducer from 'ducks/stepRawMaterials';
import supplierRegisterReducer from 'ducks/supplierRegister';
import suppliersReducer from 'ducks/suppliers';
import uiReducer from 'ducks/ui';
import unitsReducer from 'ducks/units';
import userAccountReducer from 'ducks/userAccount';
import usersReducer from 'ducks/users';
import scenariosReducer from 'ducks/scenarios';
import scenariosDescriptorsReducer from 'ducks/scenariosDescriptors';
import scenariosDescriptorsConditionsReducer from 'ducks/scenariosDescriptorsConditions';
import { combineReducers } from 'redux';

const appReducer = combineReducers({
  accounts: accountsReducer,
  alert: alertsReducer,
  auth: authenticationReducer,
  bootstrap: bootstrapReducer,
  conditions: conditionsReducer,
  descriptors: descriptorsReducer,
  descriptorTypes: descriptorTypesReducer,
  echa: echaReducer,
  flows: flowsReducer,
  messages: messagesReducer,
  mixtures: mixturesReducer,
  products: productsReducer,
  productFlows: productFlowReducer,
  productSteps: productStepsReducer,
  productsRM: productsRMReducer,
  profile: profileReducer,
  rawMaterials: rawMaterialsReducer,
  rawMaterialSuppliers: rawMaterialSuppliersReducer,
  rawMaterialConsumptions: rawMaterialConsumptionsReducer,
  report: reportReducer,
  steps: stepsReducer,
  stepRawMaterials: stepRawMaterialsReducer,
  suppliers: suppliersReducer,
  supplierRegister: supplierRegisterReducer,
  ui: uiReducer,
  users: usersReducer,
  units: unitsReducer,
  userAccount: userAccountReducer,
  scenarios: scenariosReducer,
  scenarioDescriptors: scenariosDescriptorsReducer,
  scenarioDescriptorConditions: scenariosDescriptorsConditionsReducer
});

// https://stackoverflow.com/a/35641992
export default (state, action) => {
  if (action.type === 'LOGOUT') {
    state = {alert: state.alert};
  }

  return appReducer(state, action);
};
