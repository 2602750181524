export const HEADER_HEIGHT = 64;

// Building device tree view
export const TREE_HEADER_HEIGHT = 36;
export const SPACER = 16;
export const SPACER_MINI = 8;

// Component Tabs
export const TABS_HEIGHT = 32; //48

export const EXPANDED_EXPANSION_PANEL_HEADER_NEW = 40;

// shortcut constants
export const FOOTER = SPACER;

// Buildings panel constants
export const PANEL_HEADER = 32;

const PAGING_FOOTER = 53

export const TABLE_HEIGHT = (paging = true, offset = 0) => paging ?
  (window.innerHeight - 64 - 64 - 52 - 1) - offset :
  (window.innerHeight - 64 - 64 - 52 - 1 + PAGING_FOOTER) - offset;

export const TABLE_HEIGHT_SPLIT = (paging, offset) => paging ?
  (((window.innerHeight - 64) / 2) - 64 - 52 - 1) - offset / 2 :
  (((window.innerHeight - 64) / 2 + PAGING_FOOTER) - 64 - 52 - 1) - offset / 2;

export const TABLE_HEIGHT_SPLIT_4VIEW = offset =>
  (window.innerHeight - 64 - 84 - 48 - 64) / 2 - offset

export const TABLE_HEIGHT_SPLIT_2VIEW = offset =>
  (window.innerHeight - 64 - 84 - 48 - 64) - offset

