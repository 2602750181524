import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable'
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/scenarios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import docDefinitionScenarioAnalysis from 'reports/evaluation/productsScenarioAnalysis';
import docDefinitionScenario from 'reports/evaluation/productsScenarioFinal';
import docDefinitionScenarios from 'reports/evaluation/productsScenarioFinalMultiple';
import { filterByPropFieldSelector, getImmutableStoreById } from 'selectors/generic';
import { SCENARIO_P } from 'utils/constants/coreConstants';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/scenarios';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const {
    conditions, descriptors, lcsById, productId, products, productsById, scenarios,
    scenarioDescriptors, scenarioDescriptorConditions, unitsById, user
  } = props;
  const {
    backToProductsTable, createScenario, fetchScenario, openScenarioManagement,
    removeScenario, updateScenario
  } = props;
  const tableRef = React.useRef(null);

  const [loading, setLoading] = useState(false);

  const exportPDFScenario = scenario => {
    const product = productsById[productId];
    const lcs = lcsById[scenario.id_lcs];

    const filteredScenarioDescriptors = scenarioDescriptors.filter(sd => sd.id_cenario === scenario.id);

    pdfMake.createPdf(
      docDefinitionScenario(lcs, product, descriptors, conditions, scenario,
        filteredScenarioDescriptors, scenarioDescriptorConditions, unitsById))
      .open();
  }

  const exportPDFScenarioAnalysis = scenario => {
    const product = productsById[productId];
    const lcs = lcsById[scenario.id_lcs];

    pdfMake.createPdf(
      docDefinitionScenarioAnalysis(lcs, product, scenario))
      .open();
  }

  const exportPDFScenarios = scenarios => {
    const product = productsById[productId];
    pdfMake.createPdf(
      docDefinitionScenarios(product, descriptors, conditions, scenarios,
        scenarioDescriptors, scenarioDescriptorConditions, unitsById, lcsById))
      .open();
  }

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        titleBack={true}
        titleAction={backToProductsTable}
        title={`CENÁRIOS - ${products[productId].codigo_produto} - ${products[productId].produto_nome}`}
        columns={columns(user)}
        data={scenarios}
        onRowClick={() => null}
        onRowDoubleClick={rowData => openScenarioManagement(productId, rowData.id_lcs, rowData.id)}
        isLoading={loading}
        options={{
          actionsCellStyle: {
            width: 250,
            paddingLeft: 20
          }
        }}
        actions={[
          {
            isFreeAction: true,
            icon: tableIcons.Pdf,
            tooltip: 'Exportar Cenários',
            onClick: () => exportPDFScenarios(tableRef.current.state.data)
          },
          {
            icon: tableIcons.Pdf,
            tooltip: 'Exportar Cenário Final',
            onClick: (event, rowData) => exportPDFScenario(rowData)
          },
          {
            icon: tableIcons.ExportPDF3(),
            tooltip: 'Exportar Cenário Análise',
            onClick: (event, rowData) => exportPDFScenarioAnalysis(rowData)
          },
          {
            icon: tableIcons.ManageEntity,
            tooltip: 'Gerir Cenário',
            onClick: (event, rowData) => openScenarioManagement(productId, rowData.id_lcs, rowData.id)
          }
        ]}
        editable={{
          onRowAdd: (newData, oldData) => {
            setLoading(true);
            return createScenario({
              id_produto: productId,
              codigo: newData.codigo,
              designacao: newData.designacao,
              id_lcs: newData.id_lcs,
              notas: newData.notas,
              tipo: SCENARIO_P
            })
              // TODO NOT NECESSARY THE FETCH REDUCER TAKES CARE OF THIS ON THE BACKGROUND!!
              .then(response => fetchScenario(response.id))
              .catch(e => alert(e))
              .finally(() => setLoading(false))
          },
          onRowUpdate: (newData, oldData) => {
            setLoading(true);
            return updateScenario(newData.id, newData)
              .catch(e => alert(e))
              .finally(() => setLoading(false))
          },
          onRowDelete: oldData => {
            setLoading(true);
            return removeScenario(oldData.id)
              .catch(e => alert(e))
              .finally(() => setLoading(false))
          }
        }}
      />
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const products = getImmutableStoreById('products');
  const scenarios = filterByPropFieldSelector('scenarios', 'productId', 'id_produto');
  const mapStateToProps = (state, props) => ({
    products: products(state),
    productsById: state.products.dataById,
    scenarios: scenarios(state, props),
    user: state.profile.entity,
    lcsById: state.descriptors.dataById,
    conditions: state.conditions,
    descriptors: state.descriptors,
    scenarioDescriptorConditions: state.scenarioDescriptorConditions.data,
    scenarioDescriptors: state.scenarioDescriptors.data,
    unitsById: state.units.dataById
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  createScenario: data => dispatch(actions.createPromise(data)),
  fetchScenario: id => dispatch(actions.fetchOne(id)),
  updateScenario: (id, data) => dispatch(actions.updatePromise(id, data)),
  removeScenario: id => dispatch(actions.removePromise(id))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
