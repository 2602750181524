import { PRODUCT } from 'actionTypes';
import { DISTRIBUIDOR, FABRICANTE, IMPORTADOR, UJ } from 'utils/constants/papel_reach';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';
import thunkFactory from 'utils/redux/baseEntitiesFactory/thunk';

// ACTIONS
const rawActions = actionsFactory(PRODUCT);
const actions = {
  ...rawActions,
  ...thunkFactory(PRODUCT, '/produto/'),
};
export { actions };

// REDUCER
const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};

const options = {
  orderBy: {
    what: ['produto_nome'],
    how: ['asc']
  },
  sanitizers: {
    papel_reach: value => {
      switch (value) {
        case 'UJ':
        case 'uj':
          return UJ;
        case 'fabricante':
        case 'Fabricante':
          return FABRICANTE;
        case 'importador':
        case 'Importador':
          return IMPORTADOR;
        case 'distribuidor':
        case 'Distribuidor':
          return DISTRIBUIDOR;
        default:
          return '';
      }
    },
    perigoso: value => {
      switch (value) {
        case 'sim':
        case 'Sim':
        case true:
          return true;
        case 'Não':
        case 'não':
        case 'nao':
        case 'Nao':
        case  null:
        case  false:
          return false;
        case 'true':
        case 'false':
          return JSON.parse(value)
        default:
          return false;
      }
    }
  }
};

export default reducerFactory(PRODUCT, initialState, options);

// SAGAS
const sagas = sagasFactory(PRODUCT, '/produto/');
export { sagas };
