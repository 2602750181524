const Joi = require('@hapi/joi');

const baseObject = { codigo_fluxo: '', nome: ''};

export const schema = Joi.object({
  codigo_fluxo: Joi.string()
    .min(2)
    .max(250)
    .pattern(/^[a-zA-Z0-9\-_\s]+$/)
    .required()
    .messages({
      "any.required": `"Código Fluxograma" campo de preenchimento obrigatório`,
      "string.empty": `"Código Fluxograma" campo vazio`,
      "string.min": `"Código Fluxograma" tem de ter pelo menos 2 caracteres`,
      "string.max": `"Código Fluxograma" só pode 250 caracteres máximo`,
      "string.pattern.base": `"Código Fluxograma" só pode usar caracteres alfanuméricos e "-_ "`
    }),
  nome: Joi.string()
    .min(3)
    .max(250)
    .required()
    .messages({ // .pattern(/^[a-zA-Z0-9\-_\s]+$/)
      "any.required": `"Nome" campo de preenchimento obrigatório`,
      "string.empty": `"Nome" campo vazio`,
      "string.min": `"Nome" tem de ter pelo menos 2 caracteres`,
      "string.max": `"Nome" só pode 250 caracteres máximo`,
      "string.pattern.base": `"Nome" só pode usar caracteres alfanuméricos e "-_ "`
    }),
});

const validator = (newEntity, Entities) => {
  let error = undefined;
  let iEntity = { ...baseObject, ...newEntity };

  const baseObjectKeys = Object.keys(baseObject);
  Object.keys(iEntity).forEach(k => {
    if (!baseObjectKeys.includes(k)) {
      delete iEntity[k];
    }
  })

  // Validate data correctness
  const validateEntity = schema.validate(iEntity, { abortEarly: false });
  if (validateEntity.error) {
    return { error: validateEntity.error.details.map(obj => obj.message) };
  }

  Entities.some(sup => {

    if (!newEntity.id || (newEntity.id && newEntity.id !== sup.id)) { // Validation of a create or an update operation!
      if (sup.codigo_fluxo === iEntity.codigo_fluxo) {
        error = { error: 'Código de Fluxograma repetido' }
        return true;
      } else {
        return false;
      }
    }
    return false
  })
  return error ? error : iEntity;
};

export default validator;
