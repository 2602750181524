import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  progress: {
    marginLeft: theme.spacing(1)
  }
});

const Component = props => {
  const {
    classes, color = 'primary', disabled, label, submitting,
    size = 'small', type, variant='contained'
  } = props;
  const { onClick } = props;

  return (
    <Button
      variant={variant}
      color={color}
      disabled={submitting || disabled}
      onClick={onClick}
      size={size}
      type={type}
    >
      {submitting && 'Aguarde '}
      {
        submitting &&
        <CircularProgress
          className={classes.progress}
          size={24}
        />
      }
      {!submitting && label}
    </Button>
  );
}

export default withStyles(styles)(Component);

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  submitting: PropTypes.bool,
  type: PropTypes.string
};
