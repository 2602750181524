import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable'
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/scenarios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/scenariosExposure/scenarioExposureSubstanceShipment';
import { SCENARIO_EP } from 'utils/constants/coreConstants';
import { lcsExternalExposure } from 'utils/constants/lcsTypes';
import { DISTRIBUIDOR } from 'utils/constants/papel_reach';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/scenarioExposure/scenariosExposure';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const {
    rawMaterialsById, scenarios, shipmentsById, substanceId, suppliersById, user,
    scenarioDescriptorsData, descriptorsData, descriptorsById, conditionsData,
    scenarioDescriptorConditionsData, unitsById, lcsById,
  } = props;
  const {
    alertError, closeParentContainerConfirm, setScenariosManagementOpen, setScenarioInitializer,
    setScenariosOpen, setShipmentInitializer, createScenario, fetchScenarios,
    removeScenario, updateScenario,
  } = props;

  const tableRef = React.useRef(null);

  const [loading, setLoading] = useState(false);


  // TODO VERIFICAR FILTERED SCENARIOS EM  FLOW CEDEMP SCENARIOS TABLE
  const filteredScenarios = scenarios.filter(s =>
    s.tipo === SCENARIO_EP &&
    s.id_substancia === substanceId &&
    s.id_substancia_fornecedor &&
    lcsExternalExposure.includes(parseInt(s.id_lcs)) &&
    (
      substanceId === shipmentsById[s.id_substancia_fornecedor].id_mp ||
      rawMaterialsById[substanceId].inter_misturas
        .map(mix => mix.id_mistura)
        .includes(shipmentsById[s.id_substancia_fornecedor].id_mp)
    )
  );

  /**
   * ---------------------
   Shipments information for table dropdown selection.
   Shipments of substances or substances that are in Mixs that have Shipments.
   Of The shipments mentioned above only the ones chosen are:
   shipment.papel_reach !== DISTRIBUIDOR && shipment.registos_directos[0].ce !== 'nao' && shipment.registos_directos[0].ce !== 'na'
   */
  const shipmentsLookup = {};
  rawMaterialsById[substanceId].inter_fornecedores.forEach(shipment => {
      if (shipment.papel_reach !== DISTRIBUIDOR && shipment.registos_directos[0].ce !== 'nao' && shipment.registos_directos[0].ce !== 'na') {
        shipmentsLookup[shipment.id] =
          `[ ${shipment.codigo} ] - [ ${rawMaterialsById[substanceId].cod_mp} ] ${rawMaterialsById[substanceId].mp_nome} - 
       [ ${shipment.id_fornecedor.cod_fornecedor} ] ${shipment.id_fornecedor.fornecedor} - 
       ${shipment.designacao_comercial}`
      }
    }
  )
  rawMaterialsById[substanceId].inter_misturas.forEach(mix =>
    rawMaterialsById[mix.id_mistura].inter_fornecedores.forEach(shipment => {
        if (shipment.papel_reach !== DISTRIBUIDOR && shipment.registos_directos[0].ce !== 'nao' && shipment.registos_directos[0].ce !== 'na') {
          shipmentsLookup[shipment.id] =
            `[ ${shipment.codigo} ] - [ ${rawMaterialsById[mix.id_mistura].cod_mp} ] ${rawMaterialsById[mix.id_mistura].mp_nome} - 
       [ ${shipment.id_fornecedor.cod_fornecedor} ] ${shipment.id_fornecedor.fornecedor} - 
       ${shipment.designacao_comercial}`
        }
      }
    )
  );
  /** --------------------- */

  const exportPDF = data => {
    const filteredTableData = data;
    pdfMake.createPdf(
      docDefinition(
        rawMaterialsById[substanceId],
        filteredTableData,
        {
          conditionsData: conditionsData,
          descriptorsData: descriptorsData,
          descriptorsById: descriptorsById,
          lcsById: lcsById,
          scenarioDescriptorConditionsData: scenarioDescriptorConditionsData,
          scenarioDescriptorsData: scenarioDescriptorsData,
          shipmentsById: shipmentsById,
          suppliersById: suppliersById,
          unitsById: unitsById
        },
        'R - Cenário Substância do Fornecedor, CE DE MP - No Produto'
      ))
      .open();
  };

  const selectScenario = rowData => {
    setScenarioInitializer(rowData);

    let augmentedShipment = {};

    if (
      rawMaterialsById[substanceId].inter_fornecedores
        .find(shipment => rowData.id_substancia_fornecedor === shipment.id)
    ) {
      augmentedShipment = {
        ...rawMaterialsById[substanceId].inter_fornecedores
          .find(shipment =>  rowData.id_substancia_fornecedor === shipment.id),
        shipment_substance_id: substanceId,
        shipment_id_mp: substanceId,
        shipment_mp_nome: rawMaterialsById[substanceId].mp_nome,
        shipment_cod_mp: rawMaterialsById[substanceId].cod_mp
      };
    } else {
      const mixIds = rawMaterialsById[substanceId].inter_misturas.map(mix => mix.id_mistura);
      mixIds.forEach(id => {
        const mixShipment = rawMaterialsById[id].inter_fornecedores.find(shipment => rowData.id_substancia_fornecedor === shipment.id)
        if (mixShipment) {
          augmentedShipment = {
            ...mixShipment,
            shipment_mixture_id: id,
            shipment_id_mp: id,
            shipment_mp_nome: rawMaterialsById[id].mp_nome,
            shipment_cod_mp: rawMaterialsById[id].cod_mp
          };
        }
      })
    }

    setShipmentInitializer(augmentedShipment);
    setScenariosOpen(false);
    setScenariosManagementOpen(true);
  };

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        titleBack={true}
        titleAction={closeParentContainerConfirm}
        title={`CE DE MP - ${rawMaterialsById[substanceId].mp_nome} - [CAS] ${rawMaterialsById[substanceId].cas} - [EC] ${rawMaterialsById[substanceId].ec}`}
        columns={columns(user, shipmentsLookup)}
        data={filteredScenarios}
        onRowClick={() => null}
        onRowDoubleClick={selectScenario}
        options={{
          actionsCellStyle: {
            minWidth: 150,
            paddingLeft: 20
          }
        }}
        isLoading={loading}
        actions={[
          {
            isFreeAction: true,
            icon: tableIcons.Pdf,
            tooltip: 'Exportar PDF',
            onClick: () => exportPDF(tableRef.current.state.data)
          },
          {
            icon: tableIcons.Pdf,
            tooltip: 'Exportar PDF',
            onClick: (event, rowData) => exportPDF([rowData])
          },
          {
            icon: tableIcons.ManageEntity,
            tooltip: 'Gerir Cenário',
            onClick: (event, rowData) => selectScenario(rowData)
          }
        ]}
        editable={{
          onRowUpdate: (newData, oldData) => {
            setLoading(true);
            return updateScenario(newData.id, newData)
              .then(() => setLoading(false))
              .catch(e => {
                setLoading(false);
                alert(e);
              })
          },
          onRowAdd: newData => {
            setLoading(true);

            // There cannot be CE DE MP - scenarios with code repetitions
            if (scenarios.some(sep => sep.codigo === newData.codigo && sep.tipo === SCENARIO_EP)) {
              return Promise.reject()
                .catch(e => alertError("Não pode adicionar cenários com Códigos iguais"))
                .finally(() => setLoading(false))
            }

            return createScenario({
              id_substancia: substanceId,
              id_substancia_fornecedor: newData.id_substancia_fornecedor,
              codigo: newData.codigo,
              designacao: newData.designacao,
              notas: newData.notas,
              id_lcs: newData.id_lcs,
              tipo: SCENARIO_EP
            })
              .then(() => fetchScenarios())
              .catch(e => alertError(e))
              .finally(() => setLoading(false))
          },
          onRowDelete: oldData => {
            setLoading(true);
            return removeScenario(oldData.id)
              .catch(e => alertError(e))
              .finally(() => setLoading(false))
          }
        }}
      />
    </Grid>
  )
};

const mapStateToProps = (state, props) => ({
  rawMaterialsById: state.rawMaterials.dataById,
  scenarios: state.scenarios.data,
  shipmentsById: state.rawMaterialSuppliers.dataById,
  suppliersById: state.suppliers.dataById,
  user: state.profile.entity,
  scenarioDescriptorsData: state.scenarioDescriptors.data,
  descriptorsData: state.descriptors.data,
  descriptorsById: state.descriptors.dataById,
  conditionsData: state.conditions.data,
  scenarioDescriptorConditionsData: state.scenarioDescriptorConditions.data,
  unitsById: state.units.dataById,
  lcsById: state.descriptors.dataById

})

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  createScenario: data => dispatch(actions.createPromise(data)),
  fetchScenarios: () => dispatch(actions.fetchAll()),
  updateScenario: (id, data) => dispatch(actions.updatePromise(id, data)),
  removeScenario: id => dispatch(actions.removePromise(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
