import Grid from '@material-ui/core/Grid';
import ConfirmationDialog from 'components/AlertToAction'
import RMProductsDetailPanel from 'components/DetailPanels/RMProductsDetailPanel';
import ShipmentsDetailPanel from 'components/DetailPanels/ShipmentsDetailPanel';
import SubstancesDetailPanel from 'components/DetailPanels/SubstanceDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions as rawMaterialsActions } from 'ducks/rawMaterials';
import { confirmBoxClose, confirmBoxOpen, setContextSearchRM } from 'ducks/ui';
import ConsumptionsDetailPanel from 'pages/Inventory/Consumptions/ConsumptionsDetailPanel';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/inventory/rawMaterials';
import { filterByFunctionSelector } from 'selectors/generic';
import theme from 'source/theme';
import { MIXTURE } from 'utils/constants/coreConstants';
import exportXLSX from 'utils/export/rawMaterials';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/rawMaterials';
import noIcon from 'utils/tables/noIcon';
import rawMaterialValidator from 'utils/tables/validators/rawMaterial';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const {
    contextSearch, consumptionsById, filteredRawMaterials, products, productsById,
    rawMaterials, rawMaterialsById, suppliersById, ui, user
  } = props;
  const {
    alertError, confirmBoxOpen, confirmBoxClose, create, fetch, remove,
    setSearch, update
  } = props;

  const tableRef = React.useRef(null);

  const onUpdate = (newData, oldData) => {
    const validation = rawMaterialValidator(newData, rawMaterials)
    validation.cas = JSON.stringify(validation.cas);
    validation.ec = JSON.stringify(validation.ec);
    // validation.perigosa = JSON.stringify(validation.perigosa);
    if (validation.error) {
      alertError(validation.error)
      return Promise.reject()
    } else {
      // TODO SEND DIFERENCES ONLY
      const submitableFields = columns(user).map(prop => prop.field);
      const updatedData = {};
      submitableFields.forEach(field => {
        if (Array.isArray(oldData[field])) {
          if (JSON.stringify(newData[field]) !== JSON.stringify(oldData[field])) {
            updatedData[field] = validation[field];
          }
        } else if (newData[field] !== oldData[field]) {
          updatedData[field] = validation[field];
        }
      });
      update(newData.id, updatedData);
      return Promise.resolve();
    }
  };

  const onAdd = newData => {
    const validation = rawMaterialValidator(newData, rawMaterials)
    validation.cas = JSON.stringify(validation.cas);
    validation.ec = JSON.stringify(validation.ec);
    if (validation.confirm) {
      confirmBoxOpen({
        confirm: validation.confirm,
        onConfirmYes: () => {
          update(
            validation.oldData.id,
            { mp_nome: validation.newData.mp_nome, cod_mp: validation.newData.cod_mp }
          );
          confirmBoxClose();
        },
        onConfirmNo: () => {
          update(validation.oldData.id, { cod_mp: validation.newData.cod_mp })
          confirmBoxClose();
        }
      });
      return Promise.resolve();
    } else if (validation.error) {
      alertError(validation.error);
      return Promise.reject();
    } else {
      create(validation);
      return Promise.resolve();
    }
  };

  const onDelete = oldData => {
    const mixIds = oldData.inter_misturas.map( inter_mistura => inter_mistura.id_mistura);
    const productList = oldData.produtos.filter( productId => {
      const rmInUse = productsById[productId].Produto_Mps.find(prm =>
        prm.id_mp &&
        ( prm.id_mp.id === oldData.id || mixIds.includes(prm.id_mp.id) ) &&
        !prm.id_mistura_caminho_mp &&
        prm.mp_formulacao &&
        prm.id_mp.cod_mp !== '' &&
        prm.id_mp.cod_mp !== null
      );
      return !!rmInUse;
    });

    if (oldData.inter_fornecedores.length > 0 || productList.length > 0) {
      let actionsPending = ['Por favor apague a seguinte informação associada a esta MP:'];
      if (oldData.inter_fornecedores.length > 0) {
        actionsPending.push('- Fornecimentos com registos associados.');
      }
      if (productList.length > 0) {
        const pList = productList.map(id => productsById[id].produto_nome);
        actionsPending.push('- Produtos com registos associados: ' + pList.join(', '));
      }
      alertError(actionsPending);
      return Promise.resolve();
    } else {
      if (oldData.tipo === MIXTURE) {
        // TODO UPDATE PRODUCTS / SHIPMENTS / RAWMATERIALS RELATED
        remove(oldData.id)
        return Promise.resolve();
      } else {
        update(oldData.id, { cod_mp: null });
        return Promise.resolve();
      }
    }
  };

  const exportPDF = () => {
    const filteredTableData = tableRef.current.state.data;
    pdfMake.createPdf(
      docDefinition(filteredTableData))
      .open();
  };

  // TODO: Test cellEditable. Should be reviewed bcause of redux SAGA modifications
  const onUpdateCell = (newValue, oldValue, rowData, columnDef) => {
    const oldData = rowData;
    const newData = { ...rowData, [columnDef.field]: newValue };
    return onUpdate(newData, oldData)
      .catch(e => alert(e))
      .finally(() => setLoading(false));
  };


  return (
    <React.Fragment>
      <Grid item xs={12}>
        <BaseTable
          ref={tableRef}
          columns={columns(user)}
          data={filteredRawMaterials}
          onRowClick={() => null}
          isLoading={rawMaterials.loading}
          // TODO: Test cellEditable
          // cellEditable={{
          //   onCellEditApproved: onUpdateCell
          // }}
          onSearchChange={ text => setSearch(text)}
          options={{
            searchText: contextSearch,
          }}
          actions={[
            {
              isFreeAction: true,
              icon: tableIcons.Pdf,
              tooltip: 'Exportar PDF',
              onClick: exportPDF
            },
            {
              isFreeAction: true,
              icon: tableIcons.Xlsx,
              tooltip: 'Exportar XLSX',
              onClick: exportXLSX(tableRef, 'MATERIAS_PRIMAS.xlsx')
            }
          ]}
          editable={{
            onRowAdd: onAdd,
            onRowUpdate: onUpdate,
            onRowDelete: onDelete
          }}
          detailPanel={[
            rowData => rowData.tipo !== MIXTURE ?
              noIcon :
              {
                icon: tableIcons.Substance,
                disabled: rowData.inter_substancias.length === 0,
                tooltip: 'Composição',
                render: () => <SubstancesDetailPanel rowData={rowData.inter_substancias} data={rawMaterialsById} title={'Substâncias'}/>
              },
            rowData =>
              ({
                icon: rowData.inter_fornecedores.length > 0 ?
                  tableIcons.Shipments :
                  tableIcons.ShipmentsStyled({htmlColor: theme.palette.error.main}),
                disabled: rowData.inter_fornecedores.length === 0,
                tooltip: 'Fornecimentos',
                render: () => <ShipmentsDetailPanel rowData={rowData} suppliersById={suppliersById} rawMaterialsById={rawMaterialsById} title={'Fornecimentos'}/>
              }),
            rowData =>
              ({
                icon: rowData.produtos.length > 0 ?
                  tableIcons.Products :
                  tableIcons.ProductsStyled({htmlColor: theme.palette.error.main}),
                disabled: rowData.produtos.length === 0,
                tooltip: 'Produtos',
                render: () => <RMProductsDetailPanel
                  rowData={rowData}
                  productsById={productsById}
                  rawMaterialsById={rawMaterialsById}
                  title={'Produtos'}
                />
              }),
            rowData => rowData.consumos.length === 0 ?
              noIcon :
              {
                icon: tableIcons.Consumptions,
                tooltip: 'Consumos',
                render: () => <ConsumptionsDetailPanel rowData={rowData.consumos} data={consumptionsById} title={'Consumos'}/>
              }
          ]}
        />
      </Grid>
      {
        ui.confirmBox &&
        <ConfirmationDialog
          open={true}
          alert={ui.confirmBox.confirm}
          onConfirmYes={ui.confirmBox.onConfirmYes}
          onConfirmNo={ui.confirmBox.onConfirmNo}
          //onClose={() => setConfirmBox(null)}
          onClose={confirmBoxClose}
          backText={'Cancelar'}
          yesText={'Sobrepor'}
          noText={'Manter'}
        />
      }
    </React.Fragment>
  )
};


const makeMapStateToProps = () => {
  const filteredRawMaterials = filterByFunctionSelector('rawMaterials', rm => rm.cod_mp !== null && rm.cod_mp !== '');
  const mapStateToProps = (state, props) => ({
    filteredRawMaterials: filteredRawMaterials(state),
    products: state.products,
    rawMaterials: state.rawMaterials.data,
    rawMaterialsById: state.rawMaterials.dataById,
    suppliersById: state.suppliers.dataById,
    productsById: state.products.dataById,
    consumptionsById: state.rawMaterialConsumptions.dataById,
    ui: state.ui.pageRM,
    contextSearch: state.ui.context.searchRM,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  fetch: id => dispatch(rawMaterialsActions.fetchOne(id)),
  create: data => dispatch(rawMaterialsActions.createPromise(data)),
  confirmBoxOpen: data => dispatch(confirmBoxOpen(data)),
  confirmBoxClose: () => dispatch(confirmBoxClose()),
  remove: id => dispatch(rawMaterialsActions.removePromise(id)),
  update: (id, data) => dispatch(rawMaterialsActions.updatePromise(id, data)),
  setSearch: search => dispatch(setContextSearchRM(search))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
