import Grid from '@material-ui/core/Grid';
import BigDialog from 'components/BigDialog';
import Drawer from 'components/Drawer';
import RawMaterialInserter from 'connectedComponents/SubstancePolimerInserter';
import InRawMaterialSelector from 'pages/Products/ProductRM/InRawMaterialSelectorTable';
import OutRawMaterialSelector from 'pages/Products/ProductRM/OutRawMaterialSelectorTable';
import ProductsTable from 'pages/Products/ProductRM/ProductsTable';
import ProductSummary from 'pages/Products/ProductRM/ProductSummaryTable';
import React, { useState } from 'react';

const Component = props => {

  const [productId, setProductId] = useState(null);
  const [inOutFinalRM, setInOutFinalRM] = useState(null); // ['in', 'out','final']
  const [rmInserter, setRMInserter] = useState(null); // The RM inserter is here in the cases you
  const [highlightSubstances, setHighlightSubstances] = useState([]);
  const [highlightMP, setHighlightMP] = useState([]);

  const handleCloseDrawer = () => {
    setProductId(null);
    setInOutFinalRM(null);
  };
  const handleCloseRmInserter = () => setRMInserter(false);
  const handleOpenRmInserter = () => setRMInserter(true);



  return (
    <Grid item xs={12}>
      <ProductsTable
        setProductId={setProductId}
        setInOutFinalRM={setInOutFinalRM}
      />
      {
        productId && inOutFinalRM === 'in' &&
        <Drawer open={true} coverage={100} onClose={handleCloseDrawer}>
          <Grid container>
            <Grid container>
              <Grid item xs={5}>
                <InRawMaterialSelector
                  productId={productId}
                  handleCloseDrawer={handleCloseDrawer}
                  setHighlightSubstances={setHighlightSubstances}
                  highlightMP={highlightMP}
                  setHighlightMP={setHighlightMP}
                />
              </Grid>
              <Grid item xs={7}>
                <ProductSummary
                  productId={productId}
                  handleCloseDrawer={handleCloseDrawer}
                  inOutFinalRM={inOutFinalRM}
                  setInOutFinalRM={setInOutFinalRM}
                  highlightSubstances={highlightSubstances}
                  setHighlightSubstances={setHighlightSubstances}
                  setHighlightMP={setHighlightMP}
                />
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      }

      {
        productId && ( inOutFinalRM === 'out' || inOutFinalRM === 'distr') &&
        <Drawer open={true} coverage={100} onClose={handleCloseDrawer}>
          <Grid container>
            <Grid container>
              <Grid item xs={5}>
                <OutRawMaterialSelector
                  title={ inOutFinalRM === 'out' ? 'REACÇÃO' : 'SUBSTÂNCIAS DA FDS'}
                  productId={productId}
                  handleCloseDrawer={handleCloseDrawer}
                  handleOpenRmInserter={handleOpenRmInserter}
                  inOutFinalRM={inOutFinalRM}
                  highlightSubstances={highlightSubstances}
                  setHighlightSubstances={setHighlightSubstances}
                />
              </Grid>
              <Grid item xs={7}>
                <ProductSummary
                  productId={productId}
                  handleCloseDrawer={handleCloseDrawer}
                  inOutFinalRM={inOutFinalRM}
                  setInOutFinalRM={setInOutFinalRM}
                  setHighlightMP={setHighlightMP}
                  highlightSubstances={highlightSubstances}
                  setHighlightSubstances={setHighlightSubstances}
                />
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      }
      {
        productId && inOutFinalRM === 'final' &&
        <Drawer open={true} onClose={handleCloseDrawer}>
          <ProductSummary
            productId={productId}
            handleCloseDrawer={handleCloseDrawer}
            inOutFinalRM={inOutFinalRM}
            setInOutFinalRM={setInOutFinalRM}
            setHighlightMP={setHighlightMP}
            highlightSubstances={highlightSubstances}
            setHighlightSubstances={setHighlightSubstances}
          />
        </Drawer>
      }

      {
        rmInserter &&
        <BigDialog open={true} handleClose={handleCloseRmInserter}>
          <RawMaterialInserter/>
        </BigDialog>
      }
    </Grid>
  )
};

export default Component;
