import ButtonDialog from 'components/Buttons/ButtonTableDialog'
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { filterSubstanceWithDirectAndIndirectShipment } from 'selectors/generic';


const BUTTONS_TEXT = ['Substância: '];

const COLUMNS = [
  { title: 'Substância', field: 'mp_nome' },
  { title: 'CAS', field: 'cas' },
  { title: 'EC', field: 'ec' }
];

const OPTIONS_KEY = [
  { tag: 'mp_nome' },
  { tag: 'cas' },
  { tag: 'ec' }
];

const OPTIONS_KEYS = [OPTIONS_KEY];

const Component = props => {
  const { currentOption, rawMaterialsData } = props;
  const { onSelection } = props;

  const onSelectionID = obj => onSelection(obj.id)

  return (
    <ButtonDialog
      buttonsText={BUTTONS_TEXT}
      currentOption={currentOption}
      header='Seleccione uma substância'
      optionKeys={OPTIONS_KEYS}
      options={rawMaterialsData}
      onSelection={onSelectionID}
      columns={COLUMNS}
      maxWidth='lg'
    />
  )
};

const makeMapStateToProps = () => {
  const filteredRawMaterials = filterSubstanceWithDirectAndIndirectShipment();
  const mapStateToProps = (state, props) => ({
    rawMaterialsData: filteredRawMaterials(state)
  })
  return mapStateToProps;
}

export default connect(makeMapStateToProps)(Component);

Component.propTypes = {
  currentOption: PropTypes.object,
  onSelection: PropTypes.func.isRequired
};
