import { MTableEditField } from '@material-table/core'
import React from 'react';
import { DISTRIBUIDOR, FABRICANTE, IMPORTADOR, UJ } from 'utils/constants/papel_reach';

export default user => {
  const columns = [
    {
      title: 'Código',
      field: 'codigo_produto',
      minWidth: 100,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Nome',
      field: 'produto_nome',
      minWidth: 300,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
    },
    {
      title: 'Papel Reach',
      field: 'papel_reach',
      sorting: false,
      minWidth: 50,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      initialEditValue: UJ,
      lookup: {
        //'-': '-',
        [UJ]: 'UJ',
        [FABRICANTE]: 'Fabricante',
        [IMPORTADOR]: 'Importador',
        [DISTRIBUIDOR]: 'Distribuidor'
      },
    },
    {
      title: 'Perigoso',
      field: 'perigoso',
      type: 'boolean',
      minWidth: 50,
      initialEditValue: 'true',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      },
      lookup: {
        true: 'Sim',
        false: 'Não'
      },
      render: rowData =>
        <span style={{fontWeight: rowData.perigoso ? 'bold' : null}}>
        {rowData.perigoso ? 'Sim' : 'Não' }
      </span>
    },
    {
      title: 'Utilização',
      field: 'utilizacao',
      sorting: false,
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
      minWidth: 300
    },
    {
      title: 'Emprego',
      field: 'modo_emprego',
      sorting: false,
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
      minWidth: 300
    },
    {
      title: 'Notas',
      field: 'notas',
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
      minWidth: 300
    },
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};
