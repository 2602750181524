import {
  ConditionsIcon,
  ConsumptionIcon,
  DescriptorsIcon,
  EchaListIcon,
  FinalProductIcon,
  FlowsIcon,
  ImportIcon,
  ImportMultpileIcon,
  MixtureIcon,
  RawMaterialIcon,
  ScenariosIcon,
  ShipmentIcon,
  StepsIcon,
  SubstanceIcon,
  SupplierIcon,
  TypesIcon,
  UnitsIcon
} from 'utils/icons/materialTableIcons';

export default [
  {
    name: 'INVENTÁRIO',
    onOpen: true,
    subsections: [
      { text: 'Matérias Primas', icon: RawMaterialIcon, url: '/raw-materials' },
      { text: 'Substâncias/Polímeros', icon: SubstanceIcon, url: '/substance-polimers' },
      { text: 'Misturas', icon: MixtureIcon, url: '/mixtures' },
      { text: 'Fornecedores', icon: SupplierIcon, url: '/suppliers' },
      { text: 'Fornecimentos', icon: ShipmentIcon, url: '/raw-materials-suppliers' },
      { text: 'Consumos', icon: ConsumptionIcon, url: '/raw-material-consumptions' }
    ]
  },
  {
    name: 'PRODUÇÃO',
    onOpen: true,
    subsections: [
      { text: 'Fluxogramas', icon: FlowsIcon, url: '/flow-steps' },
      { text: 'Etapas-MP', icon: StepsIcon, url: '/flow-steps-rm' },
      { text: 'Cenários', icon: ScenariosIcon, url: '/flow-scenarios' }
    ]
  },
  {
    name: 'PRODUTO',
    onOpen: true,
    subsections: [
      { text: 'Produtos', icon: FinalProductIcon, url: '/products' },
      { text: 'Fluxogramas', icon: FlowsIcon, url: '/product-flows' },
      { text: 'Composição', icon: MixtureIcon, url: '/product-rms' },
      { text: 'Cenários', icon: ScenariosIcon, url: '/product-scenarios' }
    ]
  },
  {
    name: 'CE DE MP',
    onOpen: true,
    subsections: [
      { text: 'Na Produção', icon: FlowsIcon, url: '/exposure-internal' },
      { text: 'No Produto', icon: FinalProductIcon, url: '/exposure-external' }
    ]
  },
  {
    name: 'AVALIAÇÃO',
    onOpen: true,
    subsections: [
      // { text: 'Produção', icon: FlowsIcon, url: '/evaluation-product' },
      { text: 'Produto', icon: FinalProductIcon, url: '/evaluation-product' }
    ]
  },
  {
    name: 'CONSULTA',
    subsections: [
      { text: 'Situação ECHA', icon: EchaListIcon, url: '/echa-status' }
    ]
  },
  {
    name: 'ADMINISTRAÇÃO',
    subsections: [
      { text: 'Unidades', icon: UnitsIcon, url: '/units' },
      { text: 'Tipo Descritores', icon: TypesIcon, url: '/type-descriptors' },
      { text: 'Descritores', icon: DescriptorsIcon, url: '/descriptors' },
      { text: 'Condições', icon: ConditionsIcon, url: '/conditions' },
      { text: 'Lista ECHA', icon: EchaListIcon, url: '/echas' },
      { text: 'Cenários Lista Total', icon: ScenariosIcon, url: '/scenarios' }
    ]
  },
  {
    name: 'IMPORTAÇÕES',
    subsections: [
      { text: 'Importar ECHAs', icon: ImportMultpileIcon, url: '/import-echas' },
      { text: 'Importar Dados', icon: ImportIcon, url: '/import-data' }
    ]
  }
];
