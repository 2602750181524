import Grid from '@material-ui/core/Grid';
import MixturesDetailPanel from 'components/DetailPanels/MixturesDetailPanel';
import RMProductsDetailPanel from 'components/DetailPanels/RMProductsDetailPanel';
import ShipmentsDetailPanel from 'components/DetailPanels/ShipmentsDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/rawMaterials';
import { setContextSearchRM } from 'ducks/ui';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { filterByFieldSelector } from 'selectors/generic';
import theme from 'source/theme';
import { POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/substancePolimer';
import substancesPolimersValidator from 'utils/tables/validators/substancesPolimers';

const Component = props => {
  const {
    contextSearch, productsById, rawMaterials, rawMaterialsById, suppliersById,
    user
  } = props;
  const { alertError, create, remove, setSearch, update } = props;

  const tableRef = React.useRef(null);

  const [loading, setLoading] = useState(false);

  return (
    <Grid item xs={12}>
      <BaseTable
        tableRef={tableRef}
        columns={columns(user)}
        data={rawMaterials}
        isLoading={loading}
        onSearchChange={text => setSearch(text)}
        onRowClick={() => null}
        options={{
          searchText: contextSearch
        }}
        editable={{
          onRowAdd: newData => {
            const validation = substancesPolimersValidator(newData, rawMaterials)
            validation.cas = JSON.stringify(validation.cas);
            validation.ec = JSON.stringify(validation.ec);
            if (validation.error) {
              alertError(validation.error)
              return Promise.reject()
            } else {
              setLoading(false);
              return create(validation)
                .catch(e => alert(e))
                .finally(() => setLoading(false));
            }
          },
          onRowUpdate: newData => {
            const validation = substancesPolimersValidator(newData, rawMaterials)
            validation.cas = JSON.stringify(validation.cas);
            validation.ec = JSON.stringify(validation.ec);
            //validation.perigosa = JSON.stringify(validation.perigosa);
            if (validation.error) {
              alertError(validation.error)
              return Promise.reject()
            } else {
              setLoading(true)
              return update(newData.id, validation)
                .catch(e => alert(e))
                .finally(() => setLoading(false));
            }
          },
          onRowDelete: oldData => {
            if ((oldData.cod_mp !== null && oldData.cod_mp !== '') || oldData.inter_misturas.length > 0) {
              let actionsPending = ['Por favor apague a seguinte informação associada a esta Substância/Polímero:'];
              if (oldData.cod_mp !== null && oldData.cod_mp !== '') {
                actionsPending.push('- Substância/Polímero em uso como Matéria Prima.')
              }
              if (oldData.inter_misturas.length > 0) {
                actionsPending.push('- Substância/Polímero presente em Misturas.')
              }
              alertError(actionsPending);
              return Promise.resolve();
            } else {
              setLoading(true);
              return remove(oldData.id)
                .catch(e => alert(e))
                .finally(() => setLoading(false));
            }
          }
        }}
        detailPanel={[
          rowData =>
            ({
              icon: tableIcons.Mixtures,
              disabled: rowData.inter_misturas.length === 0,
              tooltip: 'Misturas',
              render: () => <MixturesDetailPanel rowData={rowData.inter_misturas} data={rawMaterialsById} title={'Misturas'}/>
            }),
          rowData => {
            const hasDirectShipment = rowData.inter_fornecedores.length > 0;
            const hasIndirectShipment =
              rowData.inter_misturas.map( interMixture =>
                rawMaterialsById[interMixture.id_mistura].inter_fornecedores.length > 0
              ).some(t => t)

            return ({
              icon: hasDirectShipment || hasIndirectShipment ?
                tableIcons.Shipments :
                tableIcons.ShipmentsStyled({htmlColor: theme.palette.error.main}),
              disabled: !hasDirectShipment && !hasIndirectShipment,
              tooltip: 'Fornecimentos',
              render: () => <ShipmentsDetailPanel rowData={rowData} suppliersById={suppliersById} rawMaterialsById={rawMaterialsById} title={'Fornecimentos'}/>
            })
          },
          rowData =>
            ({
              icon: rowData.produtos.length > 0 ?
                tableIcons.Products :
                tableIcons.ProductsStyled({htmlColor: theme.palette.error.main}),
              disabled: rowData.produtos.length === 0,
              tooltip: 'Produtos',
              render: () => <RMProductsDetailPanel
                rowData={rowData}
                productsById={productsById}
                rawMaterialsById={rawMaterialsById}
                title={'Produtos'}
              />
            }),
        ]}
      />
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const filteredRawMaterials = filterByFieldSelector('rawMaterials', 'tipo', [SUBSTANCE, POLIMER]);
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchRM,
    productsById: state.products.dataById,
    rawMaterials: filteredRawMaterials(state),
    rawMaterialsById: state.rawMaterials.dataById,
    suppliersById: state.suppliers.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  create: data => dispatch(actions.createPromise(data)),
  remove: id => dispatch(actions.removePromise(id)),
  update: (id, data) => dispatch(actions.updatePromise(id, data)),
  setSearch: search => dispatch(setContextSearchRM(search))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
