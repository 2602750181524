import cloneDeep from 'lodash/cloneDeep';
import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';

const dataMapping = [
  { id: 'cod_mp', name: 'Código MP' },
  { id: 'mp_nome', name: 'Nome' },
  { id: 'tipo', name: 'Tipo' },
  { id: 'cas', name: 'CAS' },
  { id: 'ec', name: 'EC' },
  { id: 'observacoes', name: 'Observações' },
  { id: 'perigosa', name: 'Perigosa', transform: value => value ? 'x' : '' }
];

const rowGenerator = (dataMapping, data) => {
  const clonedData = cloneDeep(data);
  return clonedData.map(item => {
    return dataMapping.map(field => {
      if (field.transform) {
        return item[field.id] === null ? '' : field.transform(item[field.id])
      } else {
        return item[field.id] === null ? '' : item[field.id]
      }
    })
  })
};

const dd = (data) => ({
  pageSize: 'A4',
  pageOrientation: 'landscape',
  pageMargins: [40, 60, 40, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: 'R - Listagem de Matérias Primas' //title,
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: [50, '*', 80, 80, 80, 100, 40],
        body: [
          dataMapping.map(i => i.name),
          ...rowGenerator(dataMapping, data)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 8
    }
  }

});

export default dd;
