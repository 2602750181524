import { MTableEditField } from '@material-table/core';
import React from 'react';

export default descriptorTypes => [
  {
    title: 'ID',
    field: 'id',
    editable: 'never',
    width: 100,
  },
  {
    title: 'Id Tipo',
    field: 'id_tipo',
    lookup: descriptorTypes,
    initialEditValue: Object.keys(descriptorTypes)[0],
    width: 100,
  },
  {
    title: 'Ordem',
    field: 'ordem',
    width: 100,
  },
  {
    title: 'Codigo',
    field: 'codigo',
    width: 100,
  },
  {
    title: 'Filtro LCS',
    field: 'filter_lcs',
    width: 100,
  },
  {
    title: 'Descrição',
    field: 'descricao',
    width: 400,
    editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
  }
];
