import { MTableToolbar } from '@material-table/core';
import BaseTable from 'components/Tables/BaseTable';
import TabsBar from 'components/TabsBars/TabsBarsDescriptors';
import { alertError } from 'ducks/alerts';
import { actions as scenariosDescriptorsActions } from 'ducks/scenariosDescriptors'
import { sortBy } from 'lodash';
import ConditionsDetailPanel from 'pages/Flows/FlowScenarios/ConditionsDetailPanel';
import HeaderWSelectors from 'pages/Flows/HeaderWSelectors';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/flows/flowScenarios';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/scenarioDescriptors';
import noIcon from 'utils/tables/noIcon';
import { TABLE_HEIGHT } from 'utils/ui/constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const {
    conditions, descriptorTab, descriptors, descriptorFilter,
    flow, lcs, scenario, scenarioDescriptors, scenarioDescriptorConditions,
    stepId, steps, takeOutTabsHeader, units, user
  } = props;
  const {
    alertError, backToScenarios, createScenarioDescriptor, closeDescriptors,
    removeScenarioDescriptor, setDescriptor, setDescriptorTab,
  } = props;
  const tableRef = React.useRef(null);


  const filteredDescriptors = descriptors.data.filter(v => v.id_tipo === descriptorFilter);

  const filteredFSD = scenarioDescriptors.filter(fsd =>
    stepId ?
      descriptors.dataById[fsd.id_descritor].id_tipo === descriptorFilter && fsd.id_etapa === stepId :
      descriptors.dataById[fsd.id_descritor].id_tipo === descriptorFilter
  );

  const [selectedIds, setSelectedIds] = useState(filteredFSD.map(fsd => fsd.id_descritor));
  const [loading, setLoading] = useState(false);

  useEffect(
    () => setSelectedIds(filteredFSD.map(fsd => fsd.id_descritor)),
    [loading, descriptorTab]
  );

  // TODO THIS IS NOT CORRECT OR NEEDED WITH HEADER SELECTOR LIKE IT IS CURRENTLY
  if (!scenario) {
    alertError(
      "Cenário não criado para combinação Fluxograma e Lcs selecionada. " +
      "Por favor crie Cenário de fluxograma primeiro"
    );
    backToScenarios();
  }

  // TODO Adding Checked info to table, there should be a better way
  const rows = filteredDescriptors.map(descriptor =>
    selectedIds.includes(descriptor.id) ?
      { ...descriptor, tableData: { checked: true } } :
      descriptor.tableData && descriptor.tableData.checked ?
        { ...descriptor, tableData: { checked: false } } : descriptor
  );

  const reOrderedRows = sortBy(rows, [o => !selectedIds.includes(o.id), 'ordem' ]);

  const manageConditions = rowData => {
    if (rowData.tableData.checked) {
      setDescriptor(rowData)
    } else {
      setLoading(true);
      createScenarioDescriptor(
        stepId ?
          { id_descritor: rowData.id, id_cenario: scenario.id, id_etapa: stepId } :
          { id_descritor: rowData.id, id_cenario: scenario.id }
      )
        .then(() => {
          setDescriptor(rowData);
          setLoading(false);
        })
        .catch(e => alert(e));
    }
  };

  const exportPDF = [
    {
      position: 'toolbarOnSelect',
      icon: tableIcons.Pdf,
      tooltip: 'Exportar PDF',
      onClick: () => {
        pdfMake.createPdf(
          docDefinition(lcs, flow, descriptors, conditions, scenario,
            scenarioDescriptors, scenarioDescriptorConditions, units))
          .open();
      }
    },
    {
      position: 'toolbar',
      icon: tableIcons.Pdf,
      tooltip: 'Exportar PDF',
      onClick: () => {
        pdfMake.createPdf(
          docDefinition(lcs, flow, descriptors, conditions, scenario,
            scenarioDescriptors, scenarioDescriptorConditions, units))
          .open();
      }
    }
  ];

  return (
    <React.Fragment>
      <BaseTable
        ref={tableRef}
        titleBack={(closeDescriptors || backToScenarios) && true}
        titleAction={closeDescriptors ? closeDescriptors : backToScenarios}
        title={
          closeDescriptors ?
            `DESCRITORES - ${steps[stepId].nome}` :
            <HeaderWSelectors
              disabled={true}
              currentOptionFlow={flow}
              currentOptionLcs={lcs}
              currentOptionScenario={scenario}
            />
        }
        columns={columns(user)}
        data={reOrderedRows}
        onRowClick={() => null}
        onRowDoubleClick={setDescriptor ? manageConditions : undefined}
        paging={false}
        options={{
          actionsCellStyle: {
            paddingLeft: 20
          },
          maxBodyHeight: takeOutTabsHeader ? TABLE_HEIGHT(false, 0) : TABLE_HEIGHT(false, 48),
          minBodyHeight: takeOutTabsHeader ? TABLE_HEIGHT(false, 0) : TABLE_HEIGHT(false, 48),
          selection: true,
          showTextRowsSelected: false,
          showSelectAllCheckbox: false,
          selectionProps: rowData => ({
            //checked: prevInterSubstanceIds.includes(rowData.id)
            //onClick: (event , rowData) => this.handleCheckboxClick(event,rowData),
          })
        }}
        isLoading={loading}
        onSelectionChange={(rows, difRow) => {
          console.log('rows.length', rows.length, selectedIds.length);
          setLoading(true);
          if (rows.length > selectedIds.length) {
            createScenarioDescriptor(stepId ?
              { id_descritor: difRow.id, id_cenario: scenario.id, id_etapa: stepId } :
              { id_descritor: difRow.id, id_cenario: scenario.id }
            )
              .then(() => setLoading(false))
              .catch(e => alert('ERROR', e));
          } else {
            const id_sd = filteredFSD.find(fsd => fsd.id_descritor === difRow.id).id;
            removeScenarioDescriptor(id_sd)
              .then(() => setLoading(false))
              .catch(e => alert('ERROR', e));
          }
        }}
        actions={setDescriptor ?
          [
            {
              icon: tableIcons.Conditions,
              tooltip: 'Gerir condições',
              onClick: (event, rowData) => manageConditions(rowData),
              position: 'row'
            },
            ...exportPDF
          ] :
          exportPDF
        }
        detailPanel={setDescriptor && [
          rowData => {
            const rowScenarioDescriptor = filteredFSD.find(fsd => fsd.id_descritor === rowData.id);
            return rowScenarioDescriptor ?
              {
                icon: tableIcons.Conditions,
                tooltip: 'Condições',
                render: () => <ConditionsDetailPanel scenarioDescriptor={rowScenarioDescriptor}/>
              } :
              noIcon
          }]
        }
        components={{
          Toolbar: props => (
            <div style={{ backgroundColor: theme.palette.background.veryLight }}>
              <MTableToolbar {...props} />
              {!closeDescriptors && <TabsBar value={descriptorTab} setValue={setDescriptorTab}/>}
            </div>
          )
        }}
      />
    </React.Fragment>
  )
};

const mapStateToProps = state => ({
  conditions: state.conditions,
  descriptors: state.descriptors,
  scenarioDescriptorConditions: state.scenarioDescriptorConditions.data,
  steps: state.steps.dataById,
  units: state.units.dataById,
  user: state.profile.entity
})


const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  createScenarioDescriptor: data => dispatch(scenariosDescriptorsActions.createPromise(data)),
  removeScenarioDescriptor: id => dispatch(scenariosDescriptorsActions.removePromise(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
