import { MIXTURE, SCENARIO_EP, SUBSTANCE } from 'utils/constants/coreConstants';
import { PC } from 'utils/constants/descriptorTypes';

export default (
  conditionsById, descriptorsById, descriptorFilter, scenario, lcs, productId, productsById, rawMaterialsById,
  scenarioDescriptors, scenarioDescriptorConditions, scenarios, shipmentsById, unitsById
) => {

  /**
   * Start By filtering the PC scenarios descriptors for PPRODUCT SCENARIOS.
   * This will later be used to filter out the CE DE MP - SCENARIOS which substances
   * enter in the product but don't share the intital PC, and because of that
   * should be filtered out
   */
  const filteredScenarioDescriptors = scenarioDescriptors.filter(sd =>
    sd.id_cenario === scenario.id &&
    descriptorsById[sd.id_descritor].id_tipo === PC
  );
  const descriptorsPCidsToFilterCEDEMP = filteredScenarioDescriptors.map(fsd => fsd.id_descritor);

  /**
   * Algorithm to filter:
   *  - Subtances from mixtures in CARGA
   *  - Substances in CARGA
   *  - Substances in MP FINAL
   *  ----------------------------------------------
   */

  const summaryRawMaterials = [];
  /**
   * First adding all substances related with mixtures, assuming that the substance
   * has not been modified in exclusions AND all substances that have cod_mp (CARGA)
   **/
  productsById[productId].Produto_Mps.forEach(prm => {

    if (prm.mp_formulacao) {

      if (rawMaterialsById[prm.id_mp.id].tipo === MIXTURE) {
        rawMaterialsById[prm.id_mp.id].inter_substancias.forEach(interSubstance => {
          const substanceId = interSubstance.id_substancia
          summaryRawMaterials.push({
            ...rawMaterialsById[substanceId],
            mp_final: false,
            mp_formulacao: true,
            mp_excluido_final: false,
            id_produto_mp: null, // Ainda não tem id_produto_mp porque não se sabe se existe
            contribui_perigo: false,
            cod_mp: rawMaterialsById[prm.id_mp.id].cod_mp,
            mp_nome_mistura: rawMaterialsById[prm.id_mp.id].mp_nome,
            id_mistura_caminho_mp: prm.id_mp.id,
            id_mistura_caminho: interSubstance.id
            // id_mistura_caminho: prm.id_mp.id,
            // id_produto_mp_mistura_caminho: prm.id
          });
        })
      } else if (!prm.id_mistura_caminho_mp) {
        summaryRawMaterials.push({
          ...rawMaterialsById[prm.id_mp.id],
          mp_final: prm.mp_final,
          mp_formulacao: prm.mp_formulacao,
          mp_excluido_final: prm.mp_excluido_final,
          id_produto_mp: prm.id,
          contribui_perigo: prm.contribui_perigo,
          mp_nome_mistura: '',
          id_mistura_caminho_mp: null,
          id_mistura_caminho: null
        });
      }
    }
  })


  /**
   * Finding Substances that overlap with the default/lazy ones created above
   * and putting these ones on top
   **/
  productsById[productId].Produto_Mps.forEach(prm => {
    // TODO removed prm.mp_formulacao from IF because is unclear how values come tagged from the API
    //if (prm.id_mistura_caminho_mp && prm.mp_formulacao) {
    if (prm.id_mistura_caminho_mp) {

      const index = summaryRawMaterials.findIndex(s =>
        s.id === prm.id_mp.id &&
        s.id_mistura_caminho_mp === prm.id_mistura_caminho_mp.id
      );
      if (index >= 0) {
        summaryRawMaterials[index] =
          {
            ...summaryRawMaterials[index],
            id_produto_mp: prm.id,
            mp_excluido_final: prm.mp_excluido_final,
            contribui_perigo: prm.contribui_perigo
          }
      }
    }
  })
  /**
   * Finding Substances with mp_final True (FINAL)
   * The above is incorrect, mp_final doesn't seem to make sense
   */
  productsById[productId].Produto_Mps.forEach(prm => {
    if (!prm.mp_formulacao && !prm.id_mistura_caminho_mp && rawMaterialsById[prm.id_mp.id].tipo === SUBSTANCE) {
      summaryRawMaterials.push({
        ...rawMaterialsById[prm.id_mp.id],
        mp_final: prm.mp_final,
        mp_formulacao: prm.mp_formulacao,
        mp_excluido_final: prm.mp_excluido_final,
        id_produto_mp: prm.id,
        contribui_perigo: prm.contribui_perigo,
        cod_mp: null,
        mp_nome_mistura: null
      });
    }
  })
  /**
   * END - * Algorithm to filter
   *  ----------------------------------------------
   */

  /**
   * Filter summaryRawMaterials to get summarySubstances unrepeated
   */
  const summarySubstances = [];
  summaryRawMaterials
    .filter(rm =>
      rm.contribui_perigo || rm.mp_final
    )
    .forEach(rm => {
      if (summarySubstances.findIndex(substance => substance.id === rm.id) === -1) {
        summarySubstances.push(rm);
      }
    });


  /**
   * -----------------------------------------------------
   * Finding the EP scenarios for the substances above
   */
  let summarySubstanceScenarios = [];

  summarySubstanceScenarios = summarySubstances.map(substance => {
    return scenarios.filter(s =>
      s.tipo === SCENARIO_EP &&
      s.id_substancia_fornecedor &&
      parseInt(s.id_lcs) === lcs.id &&
      (
        substance.id === shipmentsById[s.id_substancia_fornecedor].id_mp ||
        rawMaterialsById[substance.id].inter_misturas
          .map(mix => mix.id_mistura)
          .includes(shipmentsById[s.id_substancia_fornecedor].id_mp)
      )
    );
  });

  /**
   * Finding the descriptors PC (To Compare Later and filter CEDEMP) that match
   * this scenarios and adding to summarySubstanceScenarios -> descriptorsPCfilterCEDEMP
   */
  summarySubstances.forEach((substance, index) => {
    summarySubstanceScenarios[index].forEach(substanceScenario => {
      substanceScenario.descriptorsPCfilterCEDEMP = scenarioDescriptors
        .filter(scenarioDescriptor =>
          scenarioDescriptor.id_cenario === substanceScenario.id &&
          descriptorsById[scenarioDescriptor.id_descritor].id_tipo === PC
        )
        .map(scenarioDescriptor => scenarioDescriptor.id_descritor)
    });
  });

  /**
   * Finding the descriptors that match this scenarios and adding to
   * summarySubstanceScenarios
   */
  summarySubstances.forEach((substance, index) => {
    summarySubstanceScenarios[index].forEach(substanceScenario => {
      substanceScenario.descriptors = scenarioDescriptors
        .filter(scenarioDescriptor =>
          scenarioDescriptor.id_cenario === substanceScenario.id &&
          descriptorsById[scenarioDescriptor.id_descritor].id_tipo === descriptorFilter
        )
        .map(scenarioDescriptor => scenarioDescriptor.id_descritor)
    });
  });

  /**
   * Finding the conditions that match this scenarios descriptors and adding to
   * summarySubstanceScenarios
   */
  summarySubstances.forEach((substance, index) => {
    summarySubstanceScenarios[index].forEach(substanceScenario => {
      substanceScenario.scenarioConditions = {};
      scenarioDescriptors
        .filter(scenarioDescriptor =>
          scenarioDescriptor.id_cenario === substanceScenario.id &&
          descriptorsById[scenarioDescriptor.id_descritor].id_tipo === descriptorFilter
        )
        .forEach(scenarioDescriptor => {
          substanceScenario.scenarioConditions[scenarioDescriptor.id_descritor] =
            scenarioDescriptorConditions
              .filter(sdc => scenarioDescriptor.id === sdc.id_cenariodescritor)
        })
    });
  });

  /**
   * Now should add another layer to confront if CEDEMP Scenario contains the same
   * PC descriptors present in the PRODUCT Scenario
   */

  const summarySubstanceScenariosFinal = [];
  summarySubstanceScenarios.forEach( (substanceScenarios, index ) => {
    summarySubstanceScenariosFinal.push([]);
    substanceScenarios.forEach( scenario => {
      if (
        descriptorsPCidsToFilterCEDEMP.some(dId => scenario.descriptorsPCfilterCEDEMP.includes(dId))
      ) {
        summarySubstanceScenariosFinal[index].push(scenario);
      }
    });
  });

  summarySubstanceScenarios = summarySubstanceScenariosFinal;

  /**
   * -----------------------------------------------------
   */



  /**
   * Using summarySubstances and summarySubstanceScenarios (with the descriptor ids inside)
   * And
   * inverting it into an object index by descriptorIds with the paired information
   * of substances and scenarios
   */
  const productDescriptors = {}
  summarySubstanceScenarios.forEach((scenarios, substanceIndex) =>
    scenarios.forEach(scenario =>
      scenario.descriptors.forEach(descriptor => {
        const descriptorInfo = {
          substance: summarySubstances[substanceIndex],
          scenario: scenario
        };

        if (productDescriptors[descriptor]) {
          productDescriptors[descriptor].push(descriptorInfo);
        } else {
          productDescriptors[descriptor] = [descriptorInfo];
        }
      })
    )
  );

  /**
   * Using summarySubstances and summarySubstanceScenarios
   * (with the descriptor and condition ids inside)
   * And
   * inverting it into an object index by descriptorIds and by nested
   * conditioIds with the paired information of substances and scenarios
   */
  const productConditionsByDescriptorId = {};
  Object.keys(productDescriptors).forEach(descriptorId => {

    summarySubstanceScenarios.forEach((scenarios, substanceIndex) => {
      scenarios.forEach(scenario => {
        if (scenario.descriptors.includes(parseInt(descriptorId))) {
          scenario.scenarioConditions[descriptorId].forEach(scenarioCondition => {
            const conditionInfo = {
              substance: summarySubstances[substanceIndex],
              scenario: scenario
            };

            if (!productConditionsByDescriptorId[descriptorId]) {
              productConditionsByDescriptorId[descriptorId] = {};
            }

            if (productConditionsByDescriptorId[descriptorId][scenarioCondition.id_condicao]) {
              productConditionsByDescriptorId[descriptorId][scenarioCondition.id_condicao].push(conditionInfo);
            } else {
              productConditionsByDescriptorId[descriptorId][scenarioCondition.id_condicao] = [conditionInfo];
            }
          })
        }
      })
    })
  });

  /**
   * DESCRIPTORS
   * Merging descriptors with substance and shipment information, important! not
   * scenario information SHIPMENT
   *
   */
  const augmentedDescriptors = [];
  Object.keys(productDescriptors).forEach(descriptorId => {

    const augmentedDescriptor = {
      substanceIds: [],
      substances: [],
      shipmentIds: [],
      shipmentsBySubstanceId: {}
    };
    /**
     * Merge scenarios from the same substance, important different scenarios of the same substance might have the
     * same shipment ID, That is what is relevant here, the SUBSTANCE -- SHIPMENT so multiple scenarios with the same
     * shipment are irrelevant
     */
    productDescriptors[descriptorId].forEach(descriptorInfo => {
      if (augmentedDescriptor.substanceIds.includes(descriptorInfo.substance.id)) {
        if (!augmentedDescriptor.shipmentIds.includes(descriptorInfo.scenario.id_substancia_fornecedor)) {
          augmentedDescriptor.shipmentsBySubstanceId[descriptorInfo.substance.id].push(
            shipmentsById[descriptorInfo.scenario.id_substancia_fornecedor]
          );
        }
      } else {
        augmentedDescriptor.substanceIds.push(descriptorInfo.substance.id);
        augmentedDescriptor.substances.push(descriptorInfo.substance);
        augmentedDescriptor.shipmentIds.push(descriptorInfo.scenario.id_substancia_fornecedor);
        augmentedDescriptor.shipmentsBySubstanceId[descriptorInfo.substance.id] = [
          shipmentsById[descriptorInfo.scenario.id_substancia_fornecedor]
        ];
      }
    });

    /**
     * linking descriptors with respective SUBSTANCE -- SHIPMENT information
     */
    augmentedDescriptors.push({
      ...descriptorsById[descriptorId],
      ...augmentedDescriptor
    });
  });


  /**
   * CONDITIONS
   * Merging conditions with substance and shipment information, important! not
   * scenario information SHIPMENT
   *
   */

  const augmentedConditionsByDescriptorId = {};
  Object.keys(productConditionsByDescriptorId).forEach( descriptorId => {
    augmentedConditionsByDescriptorId[descriptorId] = {}

    Object.keys(productConditionsByDescriptorId[descriptorId]).forEach(conditionIdStr => {

      const conditionId = parseInt(conditionIdStr);
      const augmentedCondition = {
        substanceIds: [],
        substances: [],
        shipmentIds: [],
        shipmentsBySubstanceId: {}
      };
      /**
       * Merge scenarios from the same substance, important different scenarios of the same substance might have the
       * same shipment ID, That is what is relevant here, the SUBSTANCE -- SHIPMENT so multiple scenarios with the same
       * shipment are irrelevant
       */
      productConditionsByDescriptorId[descriptorId][conditionId].forEach( conditionInfo => {
        if (augmentedCondition.substanceIds.includes(conditionInfo.substance.id)) {
          if (!augmentedCondition.shipmentIds.includes(conditionInfo.scenario.id_substancia_fornecedor)) {

            const conditionData = conditionInfo.scenario.scenarioConditions[descriptorId]
              .find(sceCondition => sceCondition.id_condicao === conditionId);
            augmentedCondition.shipmentsBySubstanceId[conditionInfo.substance.id].push(
              {
                ...shipmentsById[conditionInfo.scenario.id_substancia_fornecedor],
                notas: conditionData.notas,
                valor: conditionData.valor,
                unidade: conditionData.id_unidade ?
                  unitsById[conditionData.id_unidade].nome : ''
              }
            );
          }
        } else {
          augmentedCondition.substanceIds.push(conditionInfo.substance.id);
          augmentedCondition.substances.push(conditionInfo.substance);
          augmentedCondition.shipmentIds.push(conditionInfo.scenario.id_substancia_fornecedor);

          const conditionData = conditionInfo.scenario.scenarioConditions[descriptorId]
            .find(sceCondition => sceCondition.id_condicao === conditionId);
          augmentedCondition.shipmentsBySubstanceId[conditionInfo.substance.id] = [
            {
              ...shipmentsById[conditionInfo.scenario.id_substancia_fornecedor],
              notas: conditionData.notas,
              valor: conditionData.valor,
              unidade: conditionData.id_unidade ?
                unitsById[conditionData.id_unidade].nome : ''
            }
          ];
        }
      });

      /**
       * linking conditions with respective SUBSTANCE -- SHIPMENT information
       */
      if (augmentedConditionsByDescriptorId[descriptorId][conditionId]) {
        augmentedConditionsByDescriptorId[descriptorId][conditionId].push({
          ...conditionsById[conditionId],
          ...augmentedCondition
        });
      } else {
        augmentedConditionsByDescriptorId[descriptorId][conditionId] = [{
          ...conditionsById[conditionId],
          ...augmentedCondition
        }];
      }
    });
  });

  return {
    augmentedDescriptors,
    augmentedConditionsByDescriptorId
  };

}
