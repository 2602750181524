import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import SpinButton from 'components/Buttons/SpinButton';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { passwordUpdate } from 'ducks/auth';
import { actions } from 'ducks/profile';
import NewPasswordDialog from 'pages/AppBarMenu/UserData/NewPasswordDialog';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import theme from 'source/theme';
import columns from 'utils/tables/columns/userData';

const styles = theme => ({
  text: {
    marginBottom: theme.spacing(1)
  }
});

const Component = props => {
  const { account, classes, user } = props;
  const { updatePassword, update } = props;

  const [submitting, setSubmitting] = useState(false);

  const [userPassword, setUserPassword] = React.useState(false);

  const handleClose = () => {
    setUserPassword(false)
  };

  const handleSubmit = (oldPassword, newPassword) => {
    updatePassword(oldPassword, newPassword);
  };

  const userData = [{ ...user, account_name: account.account_name }]

  return (
    <Grid item xs={12}>
      <NewPasswordDialog
        open={userPassword}
        name={user.username}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
      <BaseTable
        columns={columns(user)}
        data={userData}
        options={{
          search: false,
          toolbar: false,
          pageSize: 5,
          maxBodyHeight: null,
          minBodyHeight: null
        }}
        editable={{
          onRowUpdate: (newData, oldData) => update(newData)
        }}
      />
      <Grid item xs={12} style={{ padding: theme.spacing(3) }}>
        <div className={classes.text}>
          Para modificar a sua password:
        </div>
        <SpinButton
          color='primary'
          label='Renovar Password'
          onClick={() => setUserPassword(true)}
          submitting={submitting}
        />
      </Grid>
    </Grid>
  )
};


const mapStateToProps = state => ({
  user: state.profile.entity,
  account: state.userAccount.entity
});

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  updatePassword: (oldPassword, newPassword) => dispatch(passwordUpdate(oldPassword, newPassword)),
  update: (data) => dispatch(actions.ownUpdate(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, theme)(Component));
