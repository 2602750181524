import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountSelector from 'connectedComponents/AccountSelector';
import { toggleSidebar } from 'ducks/ui';
import reachOKLogo from 'images/brand/LOGO_FINAL_REACHOK_Texto alinhado.png';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import theme from 'source/theme';
import { MenuIcon } from 'utils/icons/materialTableIcons';
import Menu from './Menu';
import Sidebar from './Sidebar';

const styles = theme => ({
  appBar: {
    paddingBottom: 8,
    paddingTop: 8,
    zIndex: theme.zIndex.drawer + 1,
    //backgroundColor: theme.palette.primary.light,
    backgroundColor: 'white'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    color: theme.palette.secondary.main,
    marginLeft: -12,
    marginRight: 20
  },
  image: {
    marginRight: 40
  }
});

const Component = props => {
  const { appBarTitle, classes, noLoggedUser = false, sections } = props;
  const { toggleSidebar } = props;

  return (
    <React.Fragment>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar variant='dense'>
          {
            noLoggedUser ?
              null :
              <IconButton className={classes.menuButton} onClick={toggleSidebar}>
                <MenuIcon/>
              </IconButton>
          }

          {
            noLoggedUser ?
              null :
              <AccountSelector/>
          }

          <Typography variant="h6" style={{color: theme.palette.secondary.dark, paddingLeft: 20}}>
            {appBarTitle}
          </Typography>

          <div className={classes.grow}/>

          <img className={classes.image} src={reachOKLogo} height="40"/>
          {
            noLoggedUser ?
              null :
              <Menu/>
          }

        </Toolbar>
      </AppBar>
      {
        noLoggedUser ?
          null :
          <Sidebar sections={sections}/>
      }
    </React.Fragment>
  );
};

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  sections: PropTypes.array,
  toggleSidebar: PropTypes.func,
  noLoggedUser: PropTypes.bool
};

const mapStateToProps = state => ({
  appBarTitle: state.ui.appBarTitle
});

const mapDispatchToProps = ({
  toggleSidebar
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, theme)(Component));
