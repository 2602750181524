import { MTableEditField } from '@material-table/core';
import React from 'react';
import { POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';

const emptyCellFiller = field => field ? field : '-';

const dateFormater = date => {
  if (typeof date === 'string') {
    const d = new Date(date);
    return isNaN(d.getDate()) ?
      undefined :
      (d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear())
  }
  return (date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
};

export default [
  {
    title: 'Percentagem (%)',
    field: 'percentagem',
    editable: 'never',
    sorting: false,
    minWidth: 125,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'left',
    },
    render: rowData => rowData.percentagem ?
      parseFloat(rowData.percentagem) : rowData.percentagem
  },
  {
    title: 'Tipo',
    field: 'tipo',
    sorting: false,
    minWidth: 125,
    editable: 'never',
    lookup: {
      [SUBSTANCE]: 'Substância',
      [POLIMER]: 'Polímero'
    }
  },
  {
    title: 'Nome',
    field: 'mp_nome',
    editable: 'never',
    minWidth: 250,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'EC',
    field: 'ec',
    sorting: false,
    editable: 'never',
    minWidth: 150,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'CAS',
    field: 'cas',
    sorting: false,
    editable: 'never',
    minWidth: 150,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Nº Registo',
    field: 'n_registo',
    minWidth: 200,
    initialEditValue: '',
    sorting: false,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    editComponent: (props) => <MTableEditField fullminWidth {...props} multiline/>
  },
  {
    title: 'CE',
    field: 'ce',
    sorting: false,
    initialEditValue: 'sim',
    minWidth: 50,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    lookup: {
      //'-': '-',
      'sim': 'Sim',
      'nao': 'Não',
      'na': 'N/A'
    }
  },
  {
    title: 'Data Recepção CE',
    field: 'data_recepcao',
    type: 'date',
    sorting: false,
    minWidth: 100,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'center',
    },
    initialEditValue: null,
    render: ({ data_recepcao }) => data_recepcao && emptyCellFiller(dateFormater(data_recepcao))
  },
  {
    title: 'Observações',
    field: 'observacoes',
    sorting: false,
    minWidth: 250,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'left',
    },
    editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>
  }
];
