import Grid from '@material-ui/core/Grid';
import Drawer from 'components/Drawer';
import ProductsTable from 'pages/Products/FlowProducts/ProductsTable';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import StepProductSelector from './StepProductSelector'

const Component = props => {
  const { flows } = props;

  const [productId, setProductId] = useState(null);
  const [flowId, setFlowId] = useState(flows.data[0].id);
  const handleCloseDrawer = () => setProductId(null);

  const flowSteps = flows.dataById[flowId].etapas
  // Generates flowSteps with aditional data for Table in StepProductSelector
  const flowStepsEnhanced = flowSteps.map( fs => {
    if (!fs.id_master_etapa) {
      return {...fs, nome_etapa: fs.nome, descricao_etapa: fs.descricao};
    } else {
      return {
        ...fs,
        nome_etapa: fs.id_master_etapa.nome,
        descricao_etapa: fs.id_master_etapa.descricao,
        nome_subetapa: fs.nome,
        descricao_subetapa: fs.descricao
      }
    }
  });

  return (
    <Grid item xs={12}>
      <ProductsTable
        flowId={flowId}
        setFlowId={setFlowId}
        setProductId={setProductId}
      />
      {
        productId &&
        <Drawer open={true} onClose={handleCloseDrawer}>
          <StepProductSelector
            productId={productId}
            flowId={flowId}
            flowSteps={flowStepsEnhanced}
            handleCloseDrawer={handleCloseDrawer}
          />
        </Drawer>
      }
    </Grid>
  )
};

const mapStateToProps = (state, props) => ({
  flows: state.flows,
});

export default connect(mapStateToProps)(Component);
