import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';
import deepClone from 'lodash/cloneDeep';

const themeLight = theme.palette.primary.light;

const mixtureHeader = [
  { text: 'Código MP', fillColor: themeLight},
  { text: 'Nome Mistura', fillColor: themeLight, colSpan: 2},
  {},
  { text: '', fillColor: themeLight },
  { text: '', fillColor: themeLight },
  { text: 'Observações', fillColor: themeLight },
  { text: 'Perigosa', transform: value => value ? 'Sim' : 'Não', fillColor: themeLight }
];

const substanceHeader = [
  { text: '' },
  { text: '%' },
  { text: 'Nome Substância' },
  { text: 'CAS' },
  { text: 'EC' },
  { text: '' },
  { text: 'Perigosa', transform: value => value ? 'Sim' : 'Não' }
];


const rowGenerator = (data, rawMaterialsById) => {
  const clonedData = deepClone(data);
  const clonedRawMaterialsById = deepClone(rawMaterialsById);

  const rows = [];
  clonedData.forEach( mixture => {
    rows.push([
      { text: mixture.cod_mp, fillColor: themeLight},
      { text: mixture.mp_nome, fillColor: themeLight, colSpan: 4},
      { text: '', fillColor: themeLight },
      { text: '', fillColor: themeLight },
      { text: '', fillColor: themeLight },
      { text: mixture.observacoes, fillColor: themeLight },
      { text: mixture.perigosa ? 'Sim' : 'Não', fillColor: themeLight }
    ]);
    mixture.inter_substancias.forEach( inter_substancia => {

      const substanceId = inter_substancia.id_substancia;
      rows.push([
        { text: '', border: [ false, false, false, false,]},
        {
          text: inter_substancia.percentagem ?
            parseFloat(inter_substancia.percentagem) : inter_substancia.percentagem
        },
        { text: clonedRawMaterialsById[substanceId].mp_nome},
        { text: clonedRawMaterialsById[substanceId].cas},
        { text: clonedRawMaterialsById[substanceId].ec},
        {},
        { text: clonedRawMaterialsById[substanceId].perigosa ? 'Sim' : 'Não'}
      ]);
    })
  })

  return rows;
};

const dd = (data, rawMaterialsById) => ({
  pageSize: 'A4',
  pageOrientation: 'landscape',
  pageMargins: [40, 60, 40, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: 'R - Listagem de Misturas' //title,
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 2,
        widths: [60, 40, '*', 60, 60, '*', 60],
        body: [
          mixtureHeader,
          substanceHeader,
          ...rowGenerator(data, rawMaterialsById)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 8
    }
  }

});

export default dd;
