import { MTableEditField } from '@material-table/core';
import React from 'react';
import { DISTRIBUIDOR, FABRICANTE, IMPORTADOR, UJ } from 'utils/constants/papel_reach';

const emptyCellFiller = field => field ? field : '-';

const dateFormater = date => {
  if (typeof date === 'string') {
    const d = new Date(date);
    return isNaN(d.getDate()) ?
      undefined :
      (d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear())
  }
  return (date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
};

export default (user, userAccount, rawMaterial, suppliers, shipmentType) => {

  let papelReach = {};
  let supplier = {}

  switch (shipmentType) {
    case IMPORTADOR:
      papelReach.initialEditValue = IMPORTADOR;
      papelReach.editable = 'never';
      break;
    case DISTRIBUIDOR:
      papelReach.initialEditValue = DISTRIBUIDOR;
      papelReach.editable = 'never';
      break;
    case UJ:
      papelReach.initialEditValue = UJ;
      papelReach.editable = 'never';
      break
    case FABRICANTE:
      papelReach.initialEditValue = FABRICANTE;
      papelReach.editable = 'never';
      supplier.editable = 'never';
      supplier.initialEditValue = userAccount.account_id_fornecedor;
  }

  let columns = [
    {
      title: 'Código Fornecimento',
      field: 'codigo',
      minWidth: 100,
      render: ({ codigo }) => emptyCellFiller(codigo)
    },
    {
      title: 'Desig. Comercial',
      field: 'designacao_comercial',
      minWidth: 100,
      render: ({ designacao_comercial }) => emptyCellFiller(designacao_comercial)
    },

    {
      title: 'Fornecedor',
      field: 'id_fornecedor',
      lookup: suppliers,
      initialEditValue: Object.keys(suppliers)[0],
      minWidth: 200,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
      editComponent: (props) => <MTableEditField fullWidth {...props}/>,
      ...supplier
    },
    {
      title: 'Papel Reach',
      field: 'papel_reach',
      width: 100,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      initialEditValue: UJ,
      lookup: {
        //'-': '-',
        [UJ]: 'UJ',
        [FABRICANTE]: 'Fabricante',
        [IMPORTADOR]: 'Importador',
        [DISTRIBUIDOR]: 'Distribuidor'
      },
      ...papelReach
    },
    {
      title: 'Data FDS',
      field: 'data_fds',
      width: 100,
      type: 'date',
      initialEditValue: null,
      render: ({ data_fds }) => data_fds && emptyCellFiller(dateFormater(data_fds))
    },
    {
      title: 'CE',
      field: 'ce',
      width: 70,
      initialEditValue: 'sim',
      lookup: {
        '-': '-',
        'sim': 'Sim',
        'nao': 'Não',
        'na': 'N/A'
      }
    },
    {
      title: 'Nº Registo (xx-xxxxxxxxxx-xx-xxxx)',
      field: 'n_registo',
      //initialEditValue: 'xx-xxxxxxxxxx-xx-xxxx',
      minWidth: 300,
      render: ({ n_registo }) => emptyCellFiller(n_registo)
    },
    {
      title: 'Observações',
      field: 'observacoes',
      render: ({ observacoes }) => emptyCellFiller(observacoes)
    }
  ];

  /**
   * When Adding a SHIPMENT for a SUBSTANCE that is still not a RAW MATERIAL
   * (meaning doesn't still have a cod_mp) cod_mp of SUBSTANCE should be updated
   */
  if (!rawMaterial.cod_mp || rawMaterial.cod_mp === '') {
    columns = [
      {
        title: 'Código MP',
        field: 'cod_mp',
        render: ({ cod_mp }) => emptyCellFiller(cod_mp)
      },
      ...columns
    ]
  }

  return columns;
}
