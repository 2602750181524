import cloneDeep from 'lodash/cloneDeep';
import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';
import { SCENARIO_EF } from 'utils/constants/coreConstants';
import { lcsInternalExposure } from 'utils/constants/lcsTypes';

const themeLight = theme.palette.primary.light;
const themeMain = theme.palette.primary.main;
const lightGrey = '#EEEEEE';

const dataMapping = [
  { id: 'cod_mp', name: 'Código MP' },
  { id: 'mp_nome', name: 'Nome' },
  { id: 'tipo', name: 'Tipo' },
  { id: 'cas', name: 'CAS' },
  { id: 'ec', name: 'EC' },
  { id: 'observacoes', name: 'Observações' },
  { id: 'perigosa', name: 'Perigosa', transform: value => value ? 'Sim' : 'Não' }
];

const rowGenerator = (dataMapping, lcsById, substances, scenarios, rawMaterialsById, shipmentsById) => {
  const clonedSubstances = cloneDeep(substances);
  const rows = [];
  clonedSubstances.forEach(substance => {
    rows.push(dataMapping.map(field => {
      if (field.transform) {
        return substance[field.id] ?
          { text: field.transform(substance[field.id]), fillColor: themeLight } :
          { text: '', fillColor: themeLight };
      } else {
        return substance[field.id] ?
          { text: substance[field.id], fillColor: themeLight } :
          { text: '', fillColor: themeLight };
      }
    }));

    scenarios.forEach(scenario => {
      if (
        scenario.tipo === SCENARIO_EF &&
        scenario.id_substancia_fornecedor &&
        lcsInternalExposure.includes(parseInt(scenario.id_lcs)) &&
        (
          substance.id === shipmentsById[scenario.id_substancia_fornecedor].id_mp ||
          substance.inter_misturas
            .map(mix => mix.id_mistura)
            .includes(shipmentsById[scenario.id_substancia_fornecedor].id_mp)
        )
      ) {

        const shipment = shipmentsById[scenario.id_substancia_fornecedor];
        const shipmentRM = rawMaterialsById[shipment.id_mp]

        rows.push([
          { text: lcsById[scenario.id_lcs].descricao },
          { text: scenario.codigo },
          { text: scenario.designacao },
          {
            text: '[ ' + shipment.codigo + ' ]\r\n' + shipment.designacao_comercial,
            alignment: 'center'
          },
          { text: '[ ' + shipmentRM.cod_mp + ' ]\r\n' + shipmentRM.mp_nome,
            alignment: 'center'
          },
          { text: scenario.notas, colSpan: 2 },
          {},
        ]);
      }
    });
  })

  return rows;
};

const dd = (substances, lcsById, scenarios, rawMaterialsById, shipmentsById, title) => ({
  pageSize: 'A4',
  pageOrientation: 'landscape',
  pageMargins: [40, 60, 40, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: title //title,
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 2,
        widths: [80, 110, 180, 70, 70, 110, 40],
        body: [
          [
            { text: 'Código MP', fillColor: themeLight },
            { text: 'Nome', fillColor: themeLight },
            { text: 'Tipo', fillColor: themeLight },
            { text: 'CAS', fillColor: themeLight },
            { text: 'EC', fillColor: themeLight },
            { text: 'Observações', fillColor: themeLight },
            { text: 'Perigosa', fillColor: themeLight }
          ],
          [
            { text: 'LCS'},
            { text: 'Código'},
            { text: 'Designação'},
            { text: 'Codigo - MP'},
            { text: ''},
            { text: 'Codigo - Fornecedor'},
            { text: ''},
          ],
          ...rowGenerator(dataMapping, lcsById, substances, scenarios, rawMaterialsById, shipmentsById)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 8
    }
  }

});

export default dd;
