import Typography from '@material-ui/core/Typography';
import FluxogramSelector from 'components/Buttons/ButtonRadioDialog';
import RmFlowsDetailPanel from 'components/DetailPanels/RmFlowsDetailPanel';
import RMProductsDetailPanel from 'components/DetailPanels/RMProductsDetailPanel';
import ShipmentsDetailPanel from 'components/DetailPanels/ShipmentsDetailPanel';
import SubstancesDetailPanel from 'components/DetailPanels/SubstanceDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { actions as productFlowActions } from 'ducks/productFlows'
import { actions as productActions } from 'ducks/products'
import { setContextSearchProduct } from 'ducks/ui';
import { sortBy } from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/products/productsInFlows';
import { getImmutableStoreData, filterByFieldSelector } from 'selectors/generic';
import theme from 'source/theme';
import { FABRICANTE, UJ } from 'utils/constants/papel_reach';
import tableIcons, {StepsIcon} from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/product/productFlows';
import noIcon from 'utils/tables/noIcon';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const { contextSearch, products, flows, flowId, user } = props;
  const {
    createProductFlow, fetchProduct, removeProductFlow, setFlowId,
    setProductId, setSearch
  } = props;
  const tableRef = React.useRef(null);

  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      const flowProductsIds = products
        .filter(p => p.fluxos_produto.map(k => k.id_flux).includes(flowId))
        .map(k => k.id);
      setSelectedIds(flowProductsIds);
    }, [products, flowId]
  );

  const manageSteps = rowData => {
    if (rowData.tableData.checked) {
      setProductId(rowData.id)
    } else {
      setLoading(true);
      createProductFlow({ id_flux: flowId, id_produto: rowData.id })
        .then(() => fetchProduct(rowData.id))
        .then(() => {
          setLoading(false);
          setProductId(rowData.id);
        })
        .catch(e => alert('ERROR', e));
    }
  };

  const exportPDF = () => {
    const filteredTableData = tableRef.current.state.data;
    pdfMake.createPdf(docDefinition(filteredTableData, flows.data)).open();
  };

  // TODO Adding Checked info to table, there should be a better way
  const rows = products.map(product =>
    selectedIds.includes(product.id) ?
      { ...product, tableData: { checked: true } } :
      product.tableData && product.tableData.checked ?
        { ...product, tableData: { checked: false } } : product
  );
  const reOrderedRows = sortBy(rows, [o => !selectedIds.includes(o.id)]);

  return (
    <BaseTable
      ref={tableRef}
      title={
        <React.Fragment>
          <Typography variant="h6">
            <FluxogramSelector
              buttonText='Fluxograma: '
              currentOption={flows.dataById[flowId]}
              header='Seleccione um fluxograma'
              optionKey='nome'
              options={flows.data}
              optionsById={flows.dataById}
              onSelection={id => setFlowId(id)}
            />
          </Typography>
        </React.Fragment>
      }
      columns={columns(user)}
      data={reOrderedRows}
      onRowClick={() => null}
      onRowDoubleClick={manageSteps}
      onSearchChange={ text => setSearch(text)}
      options={{
        selection: true,
        showTextRowsSelected: false,
        showSelectAllCheckbox: false,
        selectionProps: rowData => ({
          //checked: prevInterSubstanceIds.includes(rowData.id)
          //onClick: (event , rowData) => this.handleCheckboxClick(event,rowData),
        }),
        searchText: contextSearch,
      }}
      isLoading={loading}
      onSelectionChange={(rows, difRow) => {
        setLoading(true);
        if (rows.length > selectedIds.length) {
          createProductFlow({ id_flux: flowId, id_produto: difRow.id })
            .then(() => fetchProduct(difRow.id))
            .then(() => setLoading(false))
            .catch(e => alert('ERROR', e));
        } else {
          const fluxprod = difRow.fluxos_produto.filter(fp => fp.id_flux === flowId)
          removeProductFlow(fluxprod[0].id)
            .then(() => fetchProduct(difRow.id))
            .then(() => setLoading(false))
            .catch(e => alert('ERROR', e));
        }
      }}
      actions={[
        {
          icon: StepsIcon,
          tooltip: 'Gerir etapas',
          position: 'row',
          onClick: (events, rowData) => manageSteps(rowData)
        },
        {
          position: 'toolbarOnSelect',
          icon: tableIcons.Pdf,
          tooltip: 'Exportar PDF',
          onClick: exportPDF
        },
        {
          position: 'toolbar',
          icon: tableIcons.Pdf,
          tooltip: 'Exportar PDF',
          onClick: exportPDF
        }
      ]}
      detailPanel={[
        rowData => {
          if (rowData.fluxos_produto.length === 0) {
            return {
              disabled: true,
              icon: tableIcons.FlowsBase(theme.palette.error.main),
              tooltip: 'Fluxogramas',
              render: () => {
              }
            };
          }


          const productFlowIds = rowData.fluxos_produto.map(f => f.id_flux);
          const productFlows = productFlowIds.map(flowId => flows.dataById[flowId]);

          return {
            icon: tableIcons.FlowsBase(),
            tooltip: 'Fluxogramas',
            render: () => <RmFlowsDetailPanel rowData={productFlows} title={'Fluxogramas'}/>
          };
        },
      ]}
    />
  )
};

const makeMapStateToProps = () => {
  const getProducts = filterByFieldSelector('products', 'papel_reach', [FABRICANTE, UJ, ]);
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchProduct,
    products: getProducts(state),
    flows: state.flows,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  createProductFlow: data => dispatch(productFlowActions.createPromise(data)),
  fetchProduct: id => dispatch(productActions.fetchOne(id)),
  removeProductFlow: id => dispatch(productFlowActions.removePromise(id)),
  setSearch: search => dispatch(setContextSearchProduct(search))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
