// import { renewAuthData } from 'ducks/authentication';
// import { actions as messagesActions } from 'ducks/messages';
import { apiRequest } from 'utils/request';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import history from 'source/history';
import { confirmToken } from 'utils/startApp';
import { BOOT_ADMIN, BOOT_MANAGER, BOOT_STAFF, FETCH_PROFILE, FETCH_USER_ACCOUNT, FULFILLED, LOGIN } from './actionTypes';
import App from './App';
import store from './store';

// https://nelo.is/writing/styling-better-focus-states/
require('focus-visible');
require('./focus.css');


confirmToken()
  .then(token => {
    store.dispatch({ type: LOGIN + FULFILLED, payload: token });
    return apiRequest('/user_account/');
  })
  .then((response) => {
    store.dispatch({ type: FETCH_USER_ACCOUNT + FULFILLED, payload: response.data })
    return apiRequest('/profile/');
  })
  .then((response) => {
    const profile = response.data;
    store.dispatch({ type: FETCH_PROFILE + FULFILLED, payload: profile })
    if (profile.is_staff) {
      store.dispatch({ type: BOOT_ADMIN });
    } else if (profile.manager) {
      store.dispatch({ type: BOOT_MANAGER });
    } else {
      store.dispatch({ type: BOOT_STAFF });
    }

  })
  .catch(token => {
    history.push('/login');
    console.log('Redirected to Login, user not authenticated');
  })
  .finally(() => {
    //store.dispatch(messagesActions.fetchMessages());
    ReactDOM.render(
      <Provider store={store}>
        <App/>
      </Provider>,
      document.getElementById('root')
    );
  });
