import { MTableToolbar } from '@material-table/core';
import BaseTable from 'components/Tables/BaseTable';
import { sortBy } from 'lodash';
import HeaderWSelectors from 'pages/Flows/HeaderWSelectors'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/flows/flowScenarios';
import theme from 'source/theme';
import { PROC_TAB } from 'utils/constants/descriptorTypes';
import { lcsTranslator } from 'utils/constants/lcsTypes';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/flowSteps';
import { TABLE_HEIGHT } from 'utils/ui/constants';
import TabsBar from '../TabsBar';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const {
    conditions, descriptors, flows, flow, lcsById, lcs, scenario,
    scenarioDescriptors, scenarioDescriptorConditions, stepRawMaterials, units,
    user
  } = props;
  const { backToScenarios, setDescriptorTab, setFlow, setLcs, setStepId } = props;
  const tableRef = React.useRef(null);

  /**
   * Error alert computation
   */
  const stepsToTest = flow.etapas.map(step => step.id);
  const matchingSteps = stepRawMaterials
    .filter(srm => srm.descriptors && srm.descriptors.includes(lcsTranslator[lcs.id]) && stepsToTest.includes(srm.id_etapa))
    .map(srm => srm.id_etapa);

  const matchingStepsSet = new Set(matchingSteps);
  /**
   * Error alert computation --- END
   */

    // Taking out Master steps with sub steps
  const flowSteps = [];
  flow.etapas.forEach(step => {
    if (step.subetapas.length === 0) {
      if (step.id_master_etapa) {
        flowSteps.push({
          ...step,
          nome_etapa: step.id_master_etapa.nome,
          descricao_etapa: step.id_master_etapa.descricao,
          nome_subetapa: step.nome,
          descricao_subetapa: step.descricao
        });
      } else {
        flowSteps.push({
          ...step,
          nome_etapa: step.nome,
          descricao_etapa: step.descricao,
        });
      }
    }
  });

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    const preSelectedSteps = new Set();
    scenarioDescriptors
      .filter(fsd => flowSteps.map(fs => fs.id).includes(fsd.id_etapa))
      .forEach(fsd => preSelectedSteps.add(fsd.id_etapa));
    setSelectedIds(Array.from(preSelectedSteps));
  }, [scenarioDescriptors]);


  // TODO Adding Checked info to table, there should be a better way
  const checkedSteps = flowSteps.map(fs =>
    selectedIds.includes(fs.id) ?
      { ...fs, tableData: { checked: true } } :
      fs.tableData && fs.tableData.checked ?
        { ...fs, tableData: { checked: false } } : fs
  );
  const orderedCheckedSteps = sortBy(checkedSteps, [o => !selectedIds.includes(o.id)]);

  const exportPDF = [
    {
      position: 'toolbarOnSelect',
      icon: tableIcons.Pdf,
      tooltip: 'Exportar PDF',
      onClick: () => {
        pdfMake.createPdf(
          docDefinition(lcs, flow, descriptors, conditions, scenario,
            scenarioDescriptors, scenarioDescriptorConditions, units))
          .open();
      }
    },
    {
      position: 'toolbar',
      icon: tableIcons.Pdf,
      tooltip: 'Exportar PDF',
      onClick: () => {
        pdfMake.createPdf(
          docDefinition(lcs, flow, descriptors, conditions, scenario,
            scenarioDescriptors, scenarioDescriptorConditions, units))
          .open();
      }
    }
  ];

  return (
    <React.Fragment>
      <BaseTable
        ref={tableRef}
        titleBack={true}
        titleAction={backToScenarios}
        title={
          <HeaderWSelectors
          disabled={true}
          currentOptionFlow={flow}
          currentOptionLcs={lcs}
          currentOptionScenario={scenario}
          />
        }
        columns={columns(user)}
        data={orderedCheckedSteps}
        onRowClick={() => null}
        onRowDoubleClick={rowData => setStepId(rowData.id)}
        paging={false}
        options={{
          maxBodyHeight: TABLE_HEIGHT(false, 48),
          minBodyHeight: TABLE_HEIGHT(false, 48),
          selection: true,
          showTextRowsSelected: false,
          showSelectAllCheckbox: false,
          selectionProps: {
            disabled: true
          },
          rowStyle: rowData => ({
            backgroundColor: !rowData.tableData.checked && matchingStepsSet.has(rowData.id) ?
              theme.palette.rowErrorBackground.main : ''
          })
        }}
        actions={
          [
            {
              icon: tableIcons.Descriptors,
              tooltip: 'Gerir descritores',
              onClick: (event, rowData) => setStepId(rowData.id),
              position: 'row'
            },
            ...exportPDF
          ]
        }
        components={{
          Toolbar: props => (
            <div style={{ backgroundColor: theme.palette.background.veryLight }}>
              <MTableToolbar {...props} />
              <TabsBar value={PROC_TAB} setValue={setDescriptorTab}/>
            </div>
          )
        }}
      />
    </React.Fragment>
  )
};

const mapStateToProps = state => ({
  conditions: state.conditions,
  descriptors: state.descriptors,
  lcsById: state.descriptors.dataById,
  flows: state.flows,
  units: state.units.dataById,
  scenarioDescriptorConditions: state.scenarioDescriptorConditions.data,
  stepRawMaterials: state.stepRawMaterials.data,
  user: state.profile.entity
});

export default connect(mapStateToProps)(Component);
