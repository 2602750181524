import ProductsDetailPanel from 'components/DetailPanels/ProductsDetailPanel';
import ShipmentSupplierSubstMixDetailPanel from 'components/DetailPanels/ScenarioEPShipmentSupplierSubstMixDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { setContextSearchRM } from 'ducks/ui';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/scenariosExposure/scenarioExposureSubstancesScenarios';
import { filterDangerousSubstanceWithDirectAndIndirectShipment, filterSubstanceWithDirectAndIndirectShipment } from 'selectors/generic';
import { SCENARIO_EP } from 'utils/constants/coreConstants';
import { lcsExternalExposure } from 'utils/constants/lcsTypes';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/substancePolimer';
import noIcon from 'utils/tables/noIcon';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const {
    contextSearch, lcsById, productsById, rawMaterials, rawMaterialsById,
    shipmentsById, scenarios, user
  } = props;
  const { setSearch, setSubstanceId, setScenariosOpen } = props;
  const tableRef = React.useRef(null);

  const [filterCenario, setFilterCenarios] = useState(false);

  const openSubstanceScenarios = id => {
    setSubstanceId(id);
    setScenariosOpen(true);
  }

  const exportPDF = () => {
    const filteredTableData = tableRef.current.state.data;
    pdfMake.createPdf(
      docDefinition(
        filteredTableData,
        lcsById,
        scenarios,
        rawMaterialsById,
        shipmentsById,
        'R - Listagem de Cenários No Produto por Substância'
      ))
      .open();
  };

  let filteredRawMaterials = rawMaterials.filter(rawMaterial => rawMaterial.perigosa);
  if (filterCenario) {
    filteredRawMaterials = rawMaterials
      .filter(rawMaterial => rawMaterial.perigosa)
      .filter(rawMaterial =>
        scenarios.find(s =>
          s.tipo === SCENARIO_EP &&
          s.id_substancia === rawMaterial.id &&
          s.id_substancia_fornecedor &&
          lcsExternalExposure.includes(parseInt(s.id_lcs)) &&
          (
            rawMaterial.id === shipmentsById[s.id_substancia_fornecedor].id_mp ||
            rawMaterialsById[rawMaterial.id].inter_misturas
              .map(mix => mix.id_mistura)
              .includes(shipmentsById[s.id_substancia_fornecedor].id_mp)
        )
      )
    );
  }

  return (
    <BaseTable
      ref={tableRef}
      columns={columns(user)}
      data={filteredRawMaterials}
      onRowClick={() => null}
      onRowDoubleClick={rowData => openSubstanceScenarios(rowData.id)}
      onSearchChange={text => setSearch(text)}
      options={{
        searchText: contextSearch
      }}
      actions={[
        {
          isFreeAction: true,
          icon: tableIcons.Pdf,
          tooltip: 'Exportar PDF',
          onClick: exportPDF
        },
        rowData => {
          const substanceId = rowData.id;
          const filteredScenarios = scenarios.find(s =>
            s.tipo === SCENARIO_EP &&
            s.id_substancia === substanceId &&
            s.id_substancia_fornecedor &&
            lcsExternalExposure.includes(parseInt(s.id_lcs)) &&
            (
              substanceId === shipmentsById[s.id_substancia_fornecedor].id_mp ||
              rawMaterialsById[substanceId].inter_misturas
                .map(mix => mix.id_mistura)
                .includes(shipmentsById[s.id_substancia_fornecedor].id_mp)
            )
          );
          return {
            icon: filteredScenarios ?
              tableIcons.Scenarios() :
              tableIcons.ScenariosEmpty(),
            tooltip: 'Gerir cenários',
            onClick: (event, rowData) => openSubstanceScenarios(rowData.id)
          }
        },
        {
          isFreeAction: true,
          icon: filterCenario ? tableIcons.FilterWrongStyled : tableIcons.FilterWrong,
          tooltip: 'Filtrar cenários',
          onClick: () => setFilterCenarios(!filterCenario)
        }
      ]}
      detailPanel={[
        rowData => rowData.produtos.length === 0 ?
          noIcon :
          {
            icon: tableIcons.Products,
            tooltip: 'Produtos',
            render: () => {
              const dangerousProducts = rowData.produtos.filter( id => {
                const substanceInProduct = productsById[id].Produto_Mps.find( mp => mp.id_mp.id === rowData.id);
                return substanceInProduct && substanceInProduct.contribui_perigo;
              });

              return <ProductsDetailPanel rowData={dangerousProducts} data={productsById} title={'Produtos'}/>
            }
          },
        rowData =>
          ({
            icon: tableIcons.Shipments,
            tooltip: 'Fornecimentos',
            render: () => <ShipmentSupplierSubstMixDetailPanel
              rowData={rowData}
              dataById={rawMaterialsById}
              title={'Fornecimentos da Substância e das Misturas em que participa'}
              extra={{ scenarios, rawMaterialsById, shipmentsById }}
            />
          })
      ]}
    />
  )
};

const makeMapStateToProps = () => {
  const filteredRawMaterials = filterDangerousSubstanceWithDirectAndIndirectShipment();
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchRM,
    lcsById: state.descriptors.dataById,
    products: state.products,
    rawMaterials: filteredRawMaterials(state),
    rawMaterialsById: state.rawMaterials.dataById,
    productsById: state.products.dataById,
    suppliersById: state.suppliers.dataById,
    user: state.profile.entity,
    shipmentsById: state.rawMaterialSuppliers.dataById,
    scenarios: state.scenarios.data
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  setSearch: search => dispatch(setContextSearchRM(search))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
