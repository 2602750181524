import axios from 'axios/index';
import history from 'source/history';
import { apiRequest } from 'utils/request';

const setToken = token => axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export const confirmToken = () => {
  const token = localStorage.getItem('token_access');
  const refresh = localStorage.getItem('token_refresh');

  if (token) {
    return apiRequest('/token/verify/', 'POST', { token })
      .then(() => {
        return apiRequest('/token/refresh/', 'POST', { refresh })
      })
      .then((response) => {
        const newToken = response.data.access;
        localStorage.setItem('token_access', response.data.access);
        setToken(newToken);
        return Promise.resolve(newToken);
      })
  } else {
    history.push('/login');
    return Promise.reject(null);
  }
}

