const Joi = require('@hapi/joi');
import cloneDeep from 'lodash/cloneDeep';
import { MIXTURE, SUBSTANCE } from 'utils/constants/coreConstants';

const schema = {
  cod_mp: Joi.string().min(3).max(60).pattern(/^[a-zA-Z0-9\-_\s]+$/).empty('').required(),
  mp_nome: Joi.string().min(3).max(250).required(),
  tipo: Joi.string().valid(SUBSTANCE, MIXTURE).required(),
  ec: Joi.array().items(Joi.string().pattern(/^([0-9]{3}\-){2}[0-9]{1}$/).allow('')).required(),
  cas: Joi.array().items(Joi.string().pattern(/^([0-9]+\-){2}[0-9]+$/).allow('')).required(),
  observacoes:Joi.string().allow('').max(500),
  perigosa: Joi.string().required()
};

const
  rootSchema = Joi.array().min(1)
  .items(Joi.object(schema))
  .required();

export const validate = (data, rawMaterials) => {
  const validation = rootSchema.validate(data, { abortEarly: false });

  const cod_mp = [];
  const mp_nome = [];
  const cas = [];
  const ec = [];
  data.forEach(v => {
    cod_mp.push(v.cod_mp);
    mp_nome.push(v.mp_nome);
    cas.push(v.cas);
    ec.push(v.ec);
  });

  const error = (entity, index, value, msg) => ({
    context: {
      key: entity,
      label: `[${index}].${entity} repetido`,
      value: value
    },
    message: `"${entity}" ${msg ? msg : ' valor repetido!'}`
  });

  const logRepetitions = (data, entity) => {
    const auxData = [...data];
    for (let i = 0; i < data.length; i += 1) {
      auxData.shift();
      const index = auxData.indexOf(data[i])
      if (index !== -1) {
        const iError = error(entity, i + index + 1, data[i]);
        if (validation.error) {
          validation.error.details.push(iError);
        } else {
          validation.error = { details: [iError] };
        }
      }
    }
  }

  logRepetitions(cod_mp, 'cod_mp');
  logRepetitions(mp_nome, 'mp_nome');
  logRepetitions(cas, 'cas');
  logRepetitions(ec, 'ec');

  // Verify repetitions against existing rawMaterials
  rawMaterials.forEach(sup => {
    data.forEach((newRM, index) => {
      if (sup.cod_mp === newRM.cod_mp) {
        const iError = error('codigo mp', index, newRM.cod_mp, ' existe na BD de Matérias Primas');
        if (validation.error) {
          validation.error.details.push(iError);
        } else {
          validation.error = { details: [iError] };
        }
      }
      if (sup.mp_nome === newRM.mp_nome) {
        const iError = error('nome mp', index, newRM.mp_nome, ' existe na BD de Matérias Primas');
        if (validation.error) {
          validation.error.details.push(iError);
        } else {
          validation.error = { details: [iError] };
        }
      }
    })
  })

  console.log('validation', validation);

  data.forEach((newRM, index) => {
    // Compare if rest of restrictions are respected
    if (newRM.tipo === MIXTURE && (newRM.cas.length > 0 || newRM.ec.length > 0)) {
      const iError = error('misturas', index, `CAS: ${newRM.cas} ** EC:${newRM.ec}`, ' não têm CAS nem EC');
      if (validation.error) {
        validation.error.details.push(iError);
      } else {
        validation.error = { details: [iError] };
      }
    }

    if (newRM.tipo === SUBSTANCE && newRM.cas.length === 0 && newRM.ec.length === 0) {
      const iError = error('substâncias', index, `CAS: ${newRM.cas} ** EC:${newRM.ec}`, ' têm de possuir pelo menos um CAS ou EC');
      if (validation.error) {
        validation.error.details.push(iError);
      } else {
        validation.error = { details: [iError] };
      }
    }
  })


  data.forEach((newRM, index) => {
    rawMaterials.some(rm => {
      if (
        newRM.tipo === SUBSTANCE && (
          (
            (rm.ec.length === 1 && newRM.ec.length === 1 && rm.ec[0] === newRM.ec[0]) &&
            (newRM.cas.length !== 0 && rm.cas.length === newRM.cas.length && newRM.cas.map(newCas => rm.cas.includes(newCas)).every(a => a))
          ) ||
          (
            (rm.ec.length === 1 && newRM.ec.length === 1 && rm.ec[0] === newRM.ec[0]) &&
            (rm.cas.length === 0 && newRM.cas.length === 0)
          ) ||
          (
            (rm.ec.length === 0 && newRM.ec.length === 0) &&
            (newRM.cas.length !== 0 && rm.cas.length === newRM.cas.length && newRM.cas.map(newCas => rm.cas.includes(newCas)).every(a => a))
          )
        )
      ) {
        const iError = error('cas e ec', index, `CAS: ${newRM.cas} ** EC:${newRM.ec}`, ' existe na BD de Matérias Primas');
        if (validation.error) {
          validation.error.details.push(iError);
        } else {
          validation.error = { details: [iError] };
        }
      }
    })
  });



  return validation
};

export const sanitize = (data) => {
  let sanitizedData = cloneDeep(data);
  let sanitizeOptions = {
    eliminateEmptyRows: true,
    undefinedsRemoval: true,
    trimProps: ['cas', 'ec'],
    trimIfens: true,
    EcCasToArray: true,
    perigosaToBool: true
  };
  /**
   * ATTENTION: sanitization order is relevant because of operations performed
   * do not change without care. To improve should split sanitization in modules
   * and call in the correct order at a higher level
   */
  if (sanitizeOptions.eliminateEmptyRows) {
    sanitizedData = sanitizedData.filter(row => {
      const props = Object.keys(row);
      const isNotRow = props.map(prop => row[prop] === undefined).every(v => v)
      return isNotRow ? undefined : row;
    })
  }

  if (sanitizeOptions.undefinedsRemoval) {
    sanitizedData = sanitizedData.map(row => {
      const props = Object.keys(row);
      props.forEach(prop => {
        if (row[prop] === undefined) {
          row[prop] = '';
        }
      })
      return row;
    })
  }

  if (sanitizeOptions.trimProps) {
    sanitizedData.forEach(row => {
      sanitizeOptions.trimProps.forEach(prop => {
        row[prop] = row[prop].replace(/ /g, '')
      })
    })
  }
  // TrimIfens
  if (sanitizeOptions.trimIfens) {
    sanitizedData.forEach(row => {
      Object.keys(row).forEach(field => {
        if (Array.isArray(row[field])) {
          row[field] = row[field].map(value => value.replace(/^-$/g, ''))
        } else if (typeof row[field] === 'string') {
          row[field] = row[field].replace(/^-$/g, '')
        }
      })
    })
  }
  // Transform CAS and EC to array
  if (sanitizeOptions.EcCasToArray) {

    sanitizedData.forEach(rows => {
      if (rows['cas'] === '') {
        rows['cas'] = [];
      } else {
        rows['cas'] = rows['cas'].split(',');
      }
      if (rows['ec'] === '') {
        rows['ec'] = [];
      } else {
        rows['ec'] = rows['ec'].split(',');
      }
    })
  }
  // Transform CAS and EC to array
  if (sanitizeOptions.perigosaToBool) {
    sanitizedData.forEach(row => {
      switch (row.perigosa) {
        case 'True':
        case 'TRUE':
        case 'true':
        case true:
          row.perigosa = 'true';
          break;
        case 'False':
        case 'false':
        case 'FALSE':
        case false:
          row.perigosa = 'false';
          break;
      }
    })
  }
  return sanitizedData;
};

export default validate;
