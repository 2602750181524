export default (array, id, field) => {
  if (field && id && Array.isArray(field)) {
    return array.reduce((object, model) => {
      const obj = {};
      field.forEach(i => obj[i] = model[i]);
      object[model[id]] = obj;
      return object;
    }, {});
  } else if (field && id) {
    return array.reduce((object, model) => {
      object[model[id]] = model[field];
      return object;
    }, {});
  }  else if (id) {
    return array.reduce((object, model) => {
      object[model[id]] = model;
      return object;
    }, {});
  } else if (field && Array.isArray(field)) {
    return array.reduce((object, model) => {
      const obj = {};
      field.forEach(i => obj[i] = model[i]);
      object[model.id] = obj;
      return object;
    }, {});
  } else if (field) {
    return array.reduce((object, model) => {
      object[model.id] = model[field];
      return object;
    }, {});
  }else {
    return array.reduce((object, model) => {
      object[model.id] = model;
      return object;
    }, {});
  }
};
