const Joi = require('@hapi/joi');

const baseObject = { nome: '', descricao: ''};

export const schema = Joi.object({
  nome: Joi.string()
    .min(1)
    .max(250)
    .required()
    .messages({ // .pattern(/^[a-zA-Z0-9\-_\s]+$/)
      "any.required": `"Nome etapa" campo de preenchimento obrigatório`,
      "string.empty": `"Nome etapa" campo vazio`,
      "string.min": `"Nome etapa" tem de ter pelo menos 1 caracteres`,
      "string.max": `"Nome etapa" só pode 250 caracteres máximo`,
      "string.pattern.base": `"Nome etapa" só pode usar caracteres alfanuméricos e "-_ "`
    }),
  descricao: Joi.string()
    .min(3)
    .max(250)
    .required()
    .messages({ // .pattern(/^[a-zA-Z0-9\-_\s]+$/)
      "any.required": `"Descrição" campo de preenchimento obrigatório`,
      "string.empty": `"Descrição" campo vazio`,
      "string.min": `"Descrição" tem de ter pelo menos 2 caracteres`,
      "string.max": `"Descrição" só pode 250 caracteres máximo`,
      "string.pattern.base": `"Descrição" só pode usar caracteres alfanuméricos e "-_ "`
    }),
});

const validator = (newEntity, Entities) => {
  let error = undefined;
  let iEntity = { ...baseObject, ...newEntity };

  const baseObjectKeys = Object.keys(baseObject);
  Object.keys(iEntity).forEach(k => {
    if (!baseObjectKeys.includes(k)) {
      delete iEntity[k];
    }
  })

  // Validate data correctness
  const validateEntity = schema.validate(iEntity, { abortEarly: false });
  if (validateEntity.error) {
    return { error: validateEntity.error.details.map(obj => obj.message) };
  }

  Entities.some(sup => {

    if (!newEntity.id || (newEntity.id && newEntity.id !== sup.id)) { // Validation of a create or an update operation!
      if (sup.nome === iEntity.nome) {
        error = { error: 'Código de Etapa repetido' }
        return true;
      } else {
        return false;
      }
    }
    return false
  })
  return error ? error : iEntity;
};

export default validator;
