import Grid from '@material-ui/core/Grid';
import MixturesDetailPanel from 'components/DetailPanels/MixturesDetailPanel';
import ProductsDetailPanel from 'components/DetailPanels/ProductsDetailPanel';
import ShipmentsDetailPanel from 'components/DetailPanels/ShipmentsDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import cloneDeep from 'lodash/cloneDeep'
import orderBy from 'lodash/orderBy';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import docDefEchaLinked from 'reports/inventory/echaRawMaterialsLinked';
import docDefEchaNotLinked from 'reports/inventory/echaRawMaterialsNotLinked';
import { filterByFunctionSelector, getImmutableStore } from 'selectors/generic';
import theme from 'source/theme';
import arrayToObject from 'utils/arrayToObject';
import { SUBSTANCE } from 'utils/constants/coreConstants';
import { matchEchaRawMaterial } from 'utils/echa/match';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/echaStatus';
import EchaLinkedTable from './EchaLinkedDetailPanel';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const { echas, productsById, rawMaterialsById, substances, suppliersById } = props;
  const tableRef = React.useRef(null);

  const [matches, setMatches] = useState({});
  const [filterEcha, setFilterEcha] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tempMatches = matchEchaRawMaterial(substances, echas.data);
    setMatches(tempMatches);
    setLoading(false);
  }, []);

  const matchsArray = Object.keys(matches);

  const rawMaterialShipments = rawMaterial => {
    const rawMaterialShipments = rawMaterial.inter_fornecedores.map(i => i);

    const mixtureShipments = [];
    rawMaterial.inter_misturas.forEach(inter_mix =>
      mixtureShipments.push(...rawMaterialsById[inter_mix.id_mistura].inter_fornecedores)
    );

    return [...rawMaterialShipments, ...mixtureShipments];
  }

  const reportDataEchaLinked = () => {
    let filteredTableData = cloneDeep(tableRef.current.state.data);
    //let filteredTableData = cloneDeep(substances);
    const filteredTableDataObj = arrayToObject(filteredTableData);
    matchsArray.forEach(substanceId =>
      matches[substanceId].forEach(echaId => {
        if (filteredTableDataObj[substanceId]) {
          if (filteredTableDataObj[substanceId].echas) {
            filteredTableDataObj[substanceId]['echas'].push(echas.dataById[echaId]);
          } else {
            filteredTableDataObj[substanceId]['echas'] = [echas.dataById[echaId]];
          }
        }
      })
    );
    return Object.values(filteredTableDataObj);
  };


  const reportDataEchaNotLinked = echas.data.filter(item =>
    (item.ec.length === 0 || (item.ec.length === 1 && item.ec[0].length === 0)) &&
    (item.cas.length === 0 || (item.cas.length === 1 && item.cas[0].length === 0))
  );

  const substancesToRender = () => {
    if (filterEcha) {
      const substancesObj = arrayToObject(substances);
      return orderBy(
        matchsArray.map(substanceId => ({ ...substancesObj[substanceId], link_echa: matches[substanceId].length })),
        ['mp_nome'],
        ['asc']
      )
    } else {
      return substances.map(substance => matches[substance.id] ?
        { ...substance, link_echa: matches[substance.id].length } :
        substance
      );
    }
  };

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={columns}
        onRowClick={() => null}
        data={substancesToRender()}
        isLoading={loading}
        actions={[
          {
            isFreeAction: true,
            icon: filterEcha ? tableIcons.FilterWrongStyled : tableIcons.FilterWrong,
            tooltip: 'Filtrar ECHAs ligadas',
            onClick: (event, rowData) => {
              setFilterEcha(!filterEcha);
            }
          },
          {
            isFreeAction: true,
            icon: tableIcons.Pdf,
            tooltip: 'Relatório PDF',
            onClick: (event, rowData) => {
              pdfMake.createPdf(docDefEchaLinked(reportDataEchaLinked(), rawMaterialsById)).open();
            }
          },
          {
            isFreeAction: true,
            icon: tableIcons.Pdf2,
            tooltip: 'Relatório ECHAs NÃO ligadas',
            onClick: (event, rowData) => {
              pdfMake.createPdf(docDefEchaNotLinked(reportDataEchaNotLinked)).open();
            }
          }
        ]}
        detailPanel={[
          rowData => {
            const linkedEcha = matchsArray.includes(rowData.id.toString());
            return linkedEcha ?
              {
                icon: tableIcons.EchaLinkStyledOk,
                tooltip: 'Ver ECHAs ligadas',
                disabled: false,
                render: () => <EchaLinkedTable rowData={matches[rowData.id]} data={echas.dataById} title={'ECHAs ligadas'}/>
              } :
              {
                icon: tableIcons.EchaLink,
                disabled: true,
                render: () => null
              }
          },
          rowData => ({
            disabled: rawMaterialShipments(rowData).length === 0,
            icon: tableIcons.Shipments,
            tooltip: rawMaterialShipments(rowData).length === 0 ? '' : 'Fornecimentos',
            render: () => <ShipmentsDetailPanel rowData={rowData} suppliersById={suppliersById} rawMaterialsById={rawMaterialsById} title={'Fornecimentos'}/>

          }),
          rowData => ({
            disabled: rowData.inter_misturas.length === 0,
            icon: tableIcons.Mixtures,
            tooltip: rowData.inter_misturas.length === 0 ? '' : 'Misturas',
            render: () => <MixturesDetailPanel rowData={rowData.inter_misturas} data={rawMaterialsById} title={'Misturas'}/>
          }),
          rowData => ({
            icon: rowData.produtos.length > 0 ?
              tableIcons.Products :
              tableIcons.ProductsStyled({ htmlColor: theme.palette.error.main }),
            tooltip: 'Produtos',
            render: () => <ProductsDetailPanel rowData={rowData.produtos} data={productsById} title={'Produtos'}/>
          })
        ]}
      />
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const filteredRawMaterials = filterByFunctionSelector(
    'rawMaterials',
    rm => rm.tipo === SUBSTANCE && ((rm.cod_mp !== null && rm.cod_mp !== '') || rm.inter_misturas.length > 0)
  );
  const immutableEchas = getImmutableStore('echa');
  const mapStateToProps = (state, props) => ({
    echas: immutableEchas(state),
    productsById: state.products.dataById,
    rawMaterialsById: state.rawMaterials.dataById,
    substances: filteredRawMaterials(state),
    suppliersById: state.suppliers.dataById,
  })
  return mapStateToProps;
}

export default connect(makeMapStateToProps)(Component);
