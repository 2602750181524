import Grid from '@material-ui/core/Grid';
import AnalysisConditionsTable from 'pages/ScenarioExposure/Product/ScenariosManagement/AnalysisConditionsTable';
import AnalysisDescriptorsTable from 'pages/ScenarioExposure/Product/ScenariosManagement/AnalysisDescriptorsTable';
import Conditions from 'pages/ScenarioExposure/Product/ScenariosManagement/ConditionsTable';
import Descriptors from 'pages/ScenarioExposure/Product/ScenariosManagement/DescriptorsTable';
import React, { useState } from 'react';
import { ERC } from 'utils/constants/descriptorTypes';
import { TABLE_HEIGHT_SPLIT_4VIEW } from 'utils/ui/constants';

const TABLE_HEIGHT = TABLE_HEIGHT_SPLIT_4VIEW(0);

const Component = props => {
  const { lcs, scenario, productScenarioDescriptors, substanceId } = props;

  // TODO Conditions at this level
  const [descriptorId, setDescriptorId] = useState(null);
  const [analysisDescriptorId, setAnalysisDescriptorId] = useState(null);

  return (
    <Grid container>
      <Grid item xs={7}>
        <Descriptors
          descriptorId={descriptorId}
          setDescriptorId={setDescriptorId}
          descriptorFilter={ERC}
          scenario={scenario}
          substanceId={substanceId}
          lcs={lcs}
          TABLE_HEIGHT={TABLE_HEIGHT}
        />
      </Grid>
      <Grid item xs={5}>
        <AnalysisDescriptorsTable
          descriptorId={analysisDescriptorId}
          setDescriptorId={setAnalysisDescriptorId}
          descriptorFilter={ERC}
          productScenarioDescriptors={productScenarioDescriptors}
          TABLE_HEIGHT={TABLE_HEIGHT}
        />
      </Grid>
      <Grid item xs={7}>
        <Conditions
          descriptorFilter={ERC}
          descriptorId={descriptorId}
          conditionsFilter={'Ambiente'}
          scenario={scenario}
        />
      </Grid>
      <Grid item xs={5}>
        <AnalysisConditionsTable
          descriptorId={analysisDescriptorId}
          scenarioDescriptors={productScenarioDescriptors}
          conditionsFilter={'Ambiente'}
        />
      </Grid>
    </Grid>
  )
};

export default Component;
