import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columns = [
  { title: 'Produto', field: 'produto_nome' },
  { title: 'Codigo', field: 'codigo_produto' },
  {
    title: 'Perigoso',
    field: 'perigoso',
    render: value =>
      <span style={{ fontWeight: value ? 'bold' : null }}>
        {value ? 'Sim' : 'Não'}
      </span>
  },
  { title: 'Notas', field: 'notas' }
];

export default props => {
  const { rowData, data, title } = props;

  const rowDataNoRepetitions = [... new Set(rowData)];
  const rows = rowDataNoRepetitions.map(id => data[id]);

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={rows} title={title}/>
    </div>
  )
};
