import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import SelectableTable from 'components/Tables/SelectableTable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import theme from 'source/theme';

const useStyles = makeStyles({
  select1st: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  select2nd: {
    color: theme.palette.secondary.main
  }
});

const Component = props => {
    const {
      buttonsText, columns, currentOption, header, maxWidth, options, optionKeys
    } = props;
    const { onSelection } = props;
    const classes = useStyles();


    const [open, setOpen] = useState(false);
    const [tempOption, setTempOption] = useState(currentOption)


    useEffect(() =>
        setTempOption(currentOption),
      [currentOption]
    );

    const handleClose = () => {
      setOpen(false);
      setTempOption(currentOption);
    };

    const handleOpen = () => setOpen(true);

    const handleConfirm = () => {
      onSelection(tempOption);
      setOpen(false);
    };


    // TODO remove nested, should me flatten outside by the parent component

    const finalButtonText = () => {
      if (currentOption) {
        let finalButtonText = [];
        optionKeys.forEach((optionKey, index) => {
          finalButtonText.push(
            <span key={buttonsText[index]} className={classes.select1st}>{buttonsText[index]} &nbsp;</span>
          )
          optionKey.forEach(option => {
            if (option.nested) {
              finalButtonText.push(
                <span key={option.tag} className={classes.select2nd}>
                      &nbsp;{currentOption && ` - ${currentOption[option.nested][option.tag]}`}
                    </span>
              )
            } else {
              finalButtonText.push(
                <span key={option.tag} className={classes.select2nd}>
                      &nbsp; {currentOption && ` - ${currentOption[option.tag]}`}
                    </span>
              )
            }
          })
          finalButtonText.push(<React.Fragment key={index}> &nbsp; &nbsp; </React.Fragment>);
        })
        return finalButtonText;
      }
      return <React.Fragment>
        <span className={classes.select1st}>{buttonsText[0]} &nbsp;</span> Não existe
      </React.Fragment>
    }

    return (
      <React.Fragment>
        <Button variant='outlined' size='small' color='secondary' onClick={handleOpen} disabled={!currentOption}>
          {finalButtonText()}
        </Button>
        {
          open &&
          <Dialog
            header={header}
            open={open}
            onClose={handleClose}
            onConfirm={handleConfirm}
            maxWidth={maxWidth}
            noContentPadding={true}
          >
            <SelectableTable
              currentRow={tempOption}
              columns={columns}
              rows={options}
              onSelection={setTempOption}
            />
          </Dialog>
        }
      </React.Fragment>
    )
  }
;

export default Component;

Component.propTypes = {
  buttonsText: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentOption: PropTypes.object,
  header: PropTypes.string.isRequired,
  maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs', false]),
  optionKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.exact({
          title: PropTypes.string,
          tag: PropTypes.string,
          nested: PropTypes.string
        })
      )
    ])
  ).isRequired,
  options: PropTypes.array.isRequired,
  onSelection: PropTypes.func.isRequired
};
