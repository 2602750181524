const Joi = require('@hapi/joi');

const baseObject = { cod_fornecedor: '', fornecedor: '', ativo: false };

const schema = Joi.object({
  cod_fornecedor: Joi.string()
    .required()
    .messages({
      "any.required": `"Código fornecedor" campo de preenchimento obrigatório`,
      "number.empty": `"Código fornecedor" campo vazio`,
      "number.base": `"Código fornecedor" tem de ser um número`
    }),
  fornecedor: Joi.string()
    .min(3)
    .max(250)
    .required()
    .messages({ // .pattern(/^[a-zA-Z0-9\-_\s]+$/)
      "any.required": `"Nome" campo de preenchimento obrigatório`,
      "string.empty": `"Nome" campo vazio`,
      "string.min": `"Nome" tem de ter pelo menos 3 caracteres`,
      "string.max": `"Nome" só pode 250 caracteres máximo`,
      "string.pattern.base": `"Nome" só pode usar caracteres alfanuméricos e "-_ "`
    }),
  ativo: Joi.boolean()
    .required()
    .messages({
      "any.required": `"Activo" campo de preenchimento obrigatório`,
      "boolean.empty": `"Activo" campo vazio`,
      "boolean.base": `"Activo" tem de ser boleano`
    })
});

const supplierValidator = (newSupplier, suppliers) => {
  let error = undefined;
  let iSupplier = { ...baseObject, ...newSupplier };

  const baseObjectKeys = Object.keys(baseObject);
  Object.keys(iSupplier).forEach(k => {
    if (!baseObjectKeys.includes(k)) {
      delete iSupplier[k];
    }
  })

  // Validate data correctness
  const validateSupplier = schema.validate(iSupplier, { abortEarly: false });
  if (validateSupplier.error) {
    return { error: validateSupplier.error.details.map(obj => obj.message) };
  }

  suppliers.some(sup => {

    if (!newSupplier.id || (newSupplier.id && newSupplier.id !== sup.id)) { // Validation of a create or an update operation!
      if (sup.cod_fornecedor === parseInt(iSupplier.cod_fornecedor) || sup.fornecedor === iSupplier.fornecedor) {
        console.log('supplier', sup.fornecedor, iSupplier.fornecedor);
        error = { error: 'Codigo fornecedor ou Nome de fornecedor repetido!' }
        return true;
      } else {
        return false;
      }
    }
    return false
  })
  return error ? error : iSupplier;
};

export default supplierValidator;
