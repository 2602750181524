import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import RadioGroup from 'components/RadioGroup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import theme from 'source/theme';

const useStyles = makeStyles({
  select1st: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  select2nd: {
    color: theme.palette.secondary.main
    // fontSize: 10,
  }
});

const Component = props => {
  const {
    buttonText, currentOption, disabled=false, header, maxWidth, optionKey,
    options, optionsById
  } = props;
  const { onSelection } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [tempOptionId, setTempOptionId] = useState(currentOption && currentOption.id)

  useEffect(() =>
      setTempOptionId(currentOption && currentOption.id),
    [currentOption]
  );

  const handleClose = () => {
    setOpen(false);
    setTempOptionId(currentOption && currentOption.id);
  };

  const handleOpen = () => setOpen(true);

  const handleConfirm = () => {
    onSelection(tempOptionId);
    setOpen(false);
  };

  const handleOptionChange = option => {
    const id = options[optionsList.indexOf(option)].id;
    setTempOptionId(id);
  };

  let optionsList = [];
  let currentValue = '';
  if (!disabled) {
    if ( Array.isArray(optionKey) ){
      optionsList = options.map(option =>
        optionKey.map(oKey => option[oKey]).join(' ___ ')
      );
      currentValue = optionKey.map(oKey => optionsById[tempOptionId][oKey]).join(' ___ ');
    } else {
      optionsList = options.map(v => v[optionKey]);
      currentValue = optionsById[tempOptionId][optionKey];
    }
  }

  return (
    <React.Fragment>
      <Button disabled={disabled} variant='outlined' size='small' color='secondary' onClick={handleOpen}>
        <span className={classes.select1st}>{buttonText} &nbsp;</span>
        {
          Array.isArray(optionKey) ?
            <span className={classes.select2nd}>{currentOption && optionKey.map(oKey => currentOption[oKey]).join(' - ')}</span> :
            <span className={classes.select2nd}>{currentOption && currentOption[optionKey]}</span>
        }
      </Button>
      {
        open &&
        <Dialog
          header={header}
          open={open}
          onClose={handleClose}
          onConfirm={handleConfirm}
          maxWidth={maxWidth}
        >
          {
            currentOption ?
              <RadioGroup
                onChange={handleOptionChange}
                options={optionsList}
                value={currentValue}
              /> :
              <span>
              Não existem opções ou nenhuma foi seleccionada.
            </span>
          }
        </Dialog>
      }

    </React.Fragment>
  )
};

export default Component;

Component.propTypes = {
  buttonText: PropTypes.string.isRequired,
  currentOption: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  maxWidth:  PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs', false]),
  optionKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  options: PropTypes.array.isRequired,
  optionsById: PropTypes.object.isRequired,
  onSelection: PropTypes.func.isRequired
};
