const Joi = require('@hapi/joi');

const baseProperties = ['ce', 'data_recepcao', 'n_registo', 'observacoes'];

export const schema = Joi.object({
  ce: Joi.string()
    .valid('sim', 'nao', 'na')
    .required()
    .allow(null) //TODO remove when DB is cleaned!!
    .messages({
      "any.required": `"CE" campo de preenchimento obrigatório`,
      "string.empty": `"CE" campo vazio`,
      "any.only": `"CE" só pode ter as seguintes opções: -, Sim, Não ou N/A`,
      "string.valid": `"CE" só pode ter as seguintes opções: -, Sim, Não ou N/A`
    }),
  data_recepcao: Joi.date()
    .allow(null) //TODO remove when DB is cleaned!!
    .messages({
      "any.required": `"Data Recepção" campo de preenchimento obrigatório`,
      "date.base": `"Data Recepção" tem de ser uma data válida"`
    }),
  n_registo: Joi.string()
    .pattern(/^([0-9]{2}\-)([0-9]{10}\-)([0-9]{2}\-)(([0-9]{4})|XXXX|xxxx)$/)
    .allow('XX-XXXXXXXXXX-XX-XXXX', 'xx-xxxxxxxxxx-xx-xxxx', '')
    .allow(null) //TODO remove when DB is cleaned!!
    .messages({
      "any.required": `"Numero Registo" campo de preenchimento obrigatório`,
      "string.pattern.base": `"Numero Registo" tem de usar o seguinte padrão "2digitos-10digitos-2digitos-4digitos" ou 
      "2digitos-10digitos-2digitos-xxxx". Também pode não ser preenchido. Exemplos: xx-xxxxxxxxxx-xx-xxxx, 01-1234567890-12-xxxx ou 01-1234567890-12-6123`
    }),
  observacoes: Joi.string().max(600).allow('')
    .allow(null) //TODO remove when DB is cleaned!!
    .messages({
      "string.max": `"Observacoes" não podem exceder 600 caracteres máximo`
    })
});

export const validator = (newEntity, Entity) => {
  let error = undefined;
  let iEntity = { ...newEntity };

  Object.keys(iEntity).forEach(k => {
    if (!baseProperties.includes(k)) {
      delete iEntity[k];
    }
  })
  // Validate data correctness
  const validateEntity = schema.validate(iEntity, { abortEarly: false });
  if (validateEntity.error) {
    return { error: validateEntity.error.details.map(obj => obj.message) };
  }
  return error ? error : iEntity;
};

