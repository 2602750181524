import { CREATE, DELETE, FETCH, UPDATE } from 'actionTypes';
import pluralize from 'utils/pluralize';

export default TYPE => {
  const PLURAL = pluralize(TYPE);

  return {
    create: (data, redirect) => ({
      type: CREATE + TYPE,
      payload: data,
      meta: redirect
    }),

    remove: (id, redirect) => ({
      type: DELETE + TYPE,
      payload: id,
      meta: redirect
    }),

    fetchAll: (params, meta) => ({
      type: FETCH + PLURAL,
      payload: params,
      meta: meta
    }),

    fetchOne: id => ({
      type: FETCH + TYPE,
      payload: id
    }),

    update: (id, data, redirect) => ({
      type: UPDATE + TYPE,
      payload: { id, data },
      meta: redirect
    })
  };
};
