import cloneDeep from 'lodash/cloneDeep';
import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';
import { SCENARIO_P } from 'utils/constants/coreConstants';

const themeLight = theme.palette.primary.light;

const dataMapping = [
  { id: 'codigo_produto', name: 'Código MP' },
  { id: 'produto_nome', name: 'Nome Produto', colSpan: 3 },
  {},
  {}
];

const rowGenerator = (dataMapping, lcsById, products, scenarios) => {
  const clonedProducts = cloneDeep(products);
  const rows = [];
  clonedProducts.forEach(product => {
    rows.push(dataMapping.map(field => {
      if (dataMapping.colSpan) {
        return product[field.id] ?
          { text: product[field.id], fillColor: themeLight, colSpan: dataMapping.colSpan } :
          { text: '', fillColor: themeLight, colSpan: dataMapping.colSpan };
      }
      return product[field.id] ?
        { text: product[field.id], fillColor: themeLight } :
        { text: '', fillColor: themeLight };
    }));

    scenarios.forEach(scenario => {
      if (scenario.tipo === SCENARIO_P && scenario.id_produto === product.id) {
        rows.push([
          { text: lcsById[scenario.id_lcs].descricao },
          { text: scenario.codigo },
          { text: scenario.designacao },
          { text: scenario.notas}
        ]);
      }
    });
  })

  return rows;
};

const dd = (substances, lcsById, scenarios, title) => ({
  pageSize: 'A4',
  pageOrientation: 'landscape',
  pageMargins: [40, 60, 40, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: title //title,
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 2,
        widths: [130, 170, 190, 190],
        body: [
          [
            { text: 'Código Produto', fillColor: themeLight },
            { text: 'Nome Produto', fillColor: themeLight },
            { text: '', fillColor: themeLight },
            { text: '', fillColor: themeLight }
          ],
          [
            { text: 'LCS' },
            { text: 'Código' },
            { text: 'Designação' },
            { text: 'Notas' }
          ],
          ...rowGenerator(dataMapping, lcsById, substances, scenarios)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 8
    }
  }

});

export default dd;
