import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import BaseTable from 'components/Tables/BaseTable';
import { actions as actionsAccounts } from 'ducks/accounts';
import { alertError } from 'ducks/alerts';
import React from 'react';
import { connect } from 'react-redux';
import theme from 'source/theme';

const styles = theme => ({
  text: {
    marginBottom: theme.spacing(1)
  }
});

const columns = [
  { title: 'Conta cliente', field: 'account_name' },
  { title: 'NIF', field: 'nif' },
  { title: 'Morada', field: 'address' }
];

const Component = props => {
  const { accounts, classes } = props;
  const { create, fetchAll, remove, update } = props;

  return (
    <Grid item xs={12}>
      <BaseTable
        title="GESTÃO DE CLIENTES ReachOK"
        columns={columns}
        data={accounts}
        editable={{
          onRowAdd: (newData, oldData) => create(newData).then(() => fetchAll()),
          //onRowUpdate: (newData, oldData) => update(newData.id, newData).then(() => fetchAll())
          onRowDelete: (newData) => remove(newData.id).then(() => fetchAll())
        }}
      />
    </Grid>
  )
};

const mapStateToProps = state => ({
  accounts: state.accounts.data
});

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  create: data => dispatch(actionsAccounts.createPromise(data)),
  update: (id, data) => dispatch(actionsAccounts.updatePromise(id, data)),
  remove: id => dispatch(actionsAccounts.removePromise(id)),
  fetchAll: (params, meta) => dispatch(actionsAccounts.fetchAll(params, meta))
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, theme)(Component));
