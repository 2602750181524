
import { RAW_MATERIAL_SUPPLIER } from 'actionTypes';
import thunkFactory from 'source/utils/redux/baseEntitiesFactory/thunk';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';

// ACTIONS
const rawActions = actionsFactory(RAW_MATERIAL_SUPPLIER);
const actions = { ...rawActions, ...thunkFactory(RAW_MATERIAL_SUPPLIER, '/mp_fornecedor/') };
export { actions };

// REDUCER
const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};

const options = {
  flattenFields: {
    id_mp: ['materia_prima'],
    id_fornecedor: ['fornecedor', 'id']
  }
};

export default reducerFactory(RAW_MATERIAL_SUPPLIER, initialState, options);

// SAGAS
const sagas = sagasFactory(RAW_MATERIAL_SUPPLIER, '/mp_fornecedor/');
export { sagas };
