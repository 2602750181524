import cloneDeep from 'lodash/cloneDeep';
import theme from 'source/theme';
import { shortDate } from 'utils/helperFunctions/dateFormat';
import { newLogoReachOk64 } from '../base64Images';

const mainHeader = [
  { text: 'cod_mp', rowSpan: 1, fillColor: '#EEEEEE' },
  { text: 'mp_nome', rowSpan: 1, fillColor: '#EEEEEE' },
  { text: 'tipo', fillColor: '#EEEEEE' },
  { text: 'ec', fillColor: '#EEEEEE' },
  { text: 'cas', fillColor: '#EEEEEE' },
  { text: 'perigosa', transform: { true: 'Sim', false: 'Não' }, fillColor: '#EEEEEE' },
  { text: 'empty', fillColor: '#EEEEEE' },
  { text: 'empty', fillColor: '#EEEEEE' },
  { text: 'empty', fillColor: '#EEEEEE' },
  { text: 'empty', fillColor: '#EEEEEE' },
];

const secondaryHeader = [
  { text: 'empty' },
  { text: 'empty' },
  { text: 'codigo' },
  { text: 'designacao_comercial' },
  { text: 'id_fornecedor', extraDataSource: 'suppliers', extraDataKey: 'fornecedor' },
  { text: 'data_fds', render: value => shortDate(value) }, // value.slice(0, 10) },
  { text: 'papel_reach' },
  { text: 'revisao_fds' },
  { text: 'ce', nested: 'registos_directos', extraDataKey: 'ce', translation: {'sim': 'Sim', 'na': 'N/A', 'nao': 'Não'} },
  { text: 'obervacoes', nested: 'registos_directos', extraDataKey: 'observacoes' },
];

const rowGenerator = (data, extraData = {}) => {
  let rows = [];

  // Cloning the data to avoid modification of redux store
  const clonedData = cloneDeep(data);

  let count = 0;
  clonedData.forEach(item => {

    rows.push(mainHeader.map(field => {
      if (item[field.text] === null || field.text === 'empty') {
        return { ...field, text: '' };
      } else if (Array.isArray(item[field.text])) {
        return { ...field, text: item[field.text].join(', ') };
      } else if (field.rowSpan) {
        count++;
        return {
          ...field,
          id: item[field.text] + count,
          text: item[field.text],
          border: [false, true, false, false]
          //rowSpan: item.inter_fornecedores.length > 0 ? item.inter_fornecedores.length + 1 : 1
        };
      } else if (field.transform) {
        return { ...field, text: field.transform[item[field.text]] };
      } else {
        return { ...field, text: item[field.text] };
      }
    }));

    if (item.inter_fornecedores.length > 0) {
      item.inter_fornecedores.forEach(shipment => {
        rows.push(secondaryHeader.map(field => {
          if (shipment[field.text] === null) {
            return { text: '' };
          } else if ( field.text === 'empty') {
            return { text: '', border: [false, false, false, false] };
          } else if (field.extraDataSource) {
            return { ...field, text: extraData[field.extraDataSource][shipment[field.text].id][field.extraDataKey] };
          } else if (field.nested) {
            if (field.translation) {
              return { ...field, text: shipment[field.nested].length > 0 ? field.translation[shipment[field.nested][0][field.extraDataKey]] : '' };
            }
            return { ...field, text: shipment[field.nested].length > 0 ? shipment[field.nested][0][field.extraDataKey] : '' };
          } else {
            return field.render ?
              { ...field, text: field.render(shipment[field.text]) } :
              { ...field, text: shipment[field.text] };
          }
        }));
      });
    }
  });
  return rows;
};


// playground requires you to assign document definition to a variable called dd
const dd = (data, extraData = {}) => {

  console.log('rowGenerator(data, extraData)', rowGenerator(data, extraData));

  return {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [40, 60, 40, 40],

    footer: function (currentPage, pageCount) {
      return {
        text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
        alignment: 'right',
        relativePosition: { x: -15, y: 20 },
        fontSize: 7
      }
    },
    header: function (currentPage, pageCount, pageSize) {
      return [
        {
          image: newLogoReachOk64,
          width: 50,
          relativePosition: { x: 20, y: 10 }
        }
      ]
    },
    content: [
      {
        style: 'header',
        text: 'R - Listagem de Fornecimentos de Matérias Primas' //title,
      },
      {
        style: 'tableExample',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 2,
          dontBreakRows: true,
          widths: [40, 70, 60, 90, 100, '*', '*', 20, 20, 140],
          body: [
            [
              { text: '', fillColor: '#EEEEEE', border: [false, false, false, false] },
              { text: 'Matéria Prima', fillColor: '#EEEEEE', border: [false, false, false, false] },
              { text: 'Tipo', fillColor: '#EEEEEE' },
              { text: 'EC', fillColor: '#EEEEEE' },
              { text: 'CAS', fillColor: '#EEEEEE' },
              { text: 'Perigosa', fillColor: '#EEEEEE' },
              { text: '', fillColor: '#EEEEEE' },
              { text: '', fillColor: '#EEEEEE' },
              { text: '', fillColor: '#EEEEEE' },
              { text: '', fillColor: '#EEEEEE' }
            ],
            [
              { text: '', border: [false, false, false, false]},
              { text: '', border: [false, false, false, false]},
              { text: 'Código'},
              { text: 'Designação Comercial'},
              { text: 'Fornecedor'},
              { text: 'Data FDS'},
              { text: 'Papel Reach'},
              { text: 'Rev. FDS'},
              { text: 'CE'},
              { text: 'Observações'}
            ],
            ...rowGenerator(data, extraData)
          ]
        }
      }
    ],
    // pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
    //   if(currentNode.id) {
    //     // console.log('***************');
    //     // console.log(currentNode);
    //     // console.log(followingNodesOnPage);
    //     // console.log(nodesOnNextPage);
    //     // console.log(previousNodesOnPage);
    //
    //     for (let i=1; i < followingNodesOnPage.length; i++) {
    //       if (followingNodesOnPage[i].id) {
    //         return false;
    //       }
    //     }
    //     return true;
    //   }
    // },
    styles: {
      header: {
        color: theme.palette.primary.main,
        fontSize: 16,
        bold: true,
        alignment: 'center'
      },
      tableExample: {
        margin: [0, 15, 0, 0],
        fontSize: 8
      }
    }
  }
};


export default dd;
