import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';
import { MIXTURE } from 'utils/constants/coreConstants';

const rowGenerator = (rawMaterials, rawMaterialsById, consumptionsById) => {

  let consumptionsAugmentedByRmId = {};

  // collect consumptions
  rawMaterials.forEach(rm => {
    const consumptionsAugmented = {};

    const rmConsumptions = rm.consumos.map(consumptionId => consumptionsById[consumptionId]);
    rmConsumptions.forEach(rmC => {
      const rmCmutated = {
        ...rmC,
        mp_nome: rm.mp_nome
      }

      return consumptionsAugmented[rmC.ano] ?
        consumptionsAugmented[rmC.ano].push(rmCmutated) :
        consumptionsAugmented[rmC.ano] = [rmCmutated]
    });


    if (rm.tipo !== MIXTURE && rm.inter_misturas.length > 0) {
      rm.inter_misturas.forEach(interMix => {
        const percent = interMix.percentagem;
        const mix = rawMaterialsById[interMix.id_mistura];

        const mixConsumptions = mix.consumos.map(consumptionId => consumptionsById[consumptionId]);
        mixConsumptions.forEach(rmC => {
          const rmCmutated = {
            ...rmC,
            compra_anual_kg: rmC.compra_anual_kg * percent / 100,
            consumo_anual_kg: rmC.consumo_anual_kg * percent / 100,
            mp_nome: mix.mp_nome
          }

          return consumptionsAugmented[rmC.ano] ?
            consumptionsAugmented[rmC.ano].push(rmCmutated) :
            consumptionsAugmented[rmC.ano] = [rmCmutated]
        });
      });
    }

    consumptionsAugmentedByRmId[rm.id] = consumptionsAugmented;

  });

  // transform consumptions to report
  let reportRows = [];
  rawMaterials.forEach( rm => {

    if(Object.keys(consumptionsAugmentedByRmId[rm.id]).length > 0) {
      const years = Object.keys(consumptionsAugmentedByRmId[rm.id]).sort();
      let rows = [];

      rows.push([
        { text: rm.mp_nome, fillColor: theme.palette.primary.light },
        { text: rm.cas, fillColor: theme.palette.primary.light },
        { text: rm.ec, fillColor: theme.palette.primary.light },
        { text: '', fillColor: theme.palette.primary.light },
        { text: '', fillColor: theme.palette.primary.light },
        { text: '', fillColor: theme.palette.primary.light },
      ]);

      years.forEach( year => {
        let totalBuy = 0;
        let totalConsumption = 0;

        consumptionsAugmentedByRmId[rm.id][year].forEach( consumption => {
          totalBuy = totalBuy + consumption.compra_anual_kg;
          totalConsumption = totalConsumption + consumption.consumo_anual_kg;
           rows.push([
             '',
             '',
             year,
             consumption['mp_nome'],
             consumption['compra_anual_kg'],
             consumption['consumo_anual_kg']]);
        });
        rows.push([
          '',
          '',
          { text: `Total`, bold: true, fillColor: '#EEEEEE' },
          { text: ``, bold: true, fillColor: '#EEEEEE' },
          { text: totalBuy, bold: true, fillColor: '#EEEEEE' },
          { text: totalConsumption, bold: true, fillColor: '#EEEEEE' }
        ]);
      });


      reportRows = [...reportRows, ...rows];
    }
  });

  return reportRows;
};

const dd = (rawMaterialsData, rawMaterialsById, consumptionsById) => ({
  pageSize: 'A4',
  pageOrientation: 'landscape',
  pageMargins: [40, 60, 40, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: 'R - Consumo Anual de Substâncias' //title,
    },
    {
      style: 'tableExample',
      layout: 'headerLineOnly',
      // layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['*', 100, 100, 100, 100, 100],
        body: [
          [
            '', 'CAS', 'EC', 'ANO', 'COMPRA (Kg)', 'CONSUMO (Kg)'
          ],
          ...rowGenerator(rawMaterialsData, rawMaterialsById, consumptionsById)

        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 8
    }
  }

});

export default dd;
