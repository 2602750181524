import {
  ADMIN,
  BOOT,
  BOOT_ADMIN,
  BOOT_MANAGER,
  BOOT_STAFF,
  FETCH_ACCOUNTS, FETCH_CONDITIONS,
  FETCH_CONSUMPTIONS,
  FETCH_DESCRIPTOR_TYPES,
  FETCH_DESCRIPTORS,
  FETCH_ECHAS,
  FETCH_FLOWS, FETCH_PRODUCTS,
  FETCH_PROFILE,
  FETCH_RAW_MATERIALS, FETCH_SCENARIO_DESCRIPTOR_CONDITIONS, FETCH_SCENARIO_DESCRIPTORS, FETCH_SCENARIOS, FETCH_STEP_RAW_MATERIALS,
  FETCH_RAW_MATERIAL_SUPPLIERS,
  FETCH_STEPS,
  FETCH_SUPPLIERS,
  FETCH_UNITS,
  FETCH_USER_ACCOUNT,
  FETCH_USERS,
  FULFILLED,
  MANAGER,
  RELOAD_ADMIN,
  STAFF
} from 'actionTypes';
import { all, fork, put, take, takeLatest } from 'redux-saga/effects';

// reducer
// Reducer moficactions are taken care in UI reducer
export default (state = {}, { type, payload }) => {
  switch (type) {
    case BOOT:
    case BOOT_ADMIN:
    case BOOT_MANAGER:
    case BOOT_STAFF:
    case RELOAD_ADMIN:
      return { ...state, booting: true };

    case BOOT + FULFILLED:
      return { ...state, booting: false };

    default:
      return state;
  }
};

// sagas
function* watchBoot() {
  yield takeLatest(BOOT, boot);
}

function* watchBootAdmin() {
  yield takeLatest(BOOT_ADMIN, boot(ADMIN));
}

function* watchReloadAdmin() {
  yield takeLatest(RELOAD_ADMIN, reloadAdmin);
}

function* watchBootManager() {
  yield takeLatest(BOOT_MANAGER, boot(MANAGER));
}

function* watchBootUser() {
  yield takeLatest(BOOT_STAFF, boot(STAFF));
}


const boot = userType => function* () {
  try {
    if (userType === ADMIN) {
      yield put({ type: FETCH_USERS });
      yield put({ type: FETCH_ACCOUNTS });
    } else if (userType === MANAGER) {
      yield put({ type: FETCH_USERS });
    }
    yield put({ type: FETCH_UNITS });
    yield put({ type: FETCH_CONDITIONS });
    yield put({ type: FETCH_DESCRIPTOR_TYPES });
    yield put({ type: FETCH_DESCRIPTORS });
    yield put({ type: FETCH_ECHAS });
    yield put({ type: FETCH_SUPPLIERS });
    yield put({ type: FETCH_RAW_MATERIALS });
    yield put({ type: FETCH_CONSUMPTIONS });
    yield put({ type: FETCH_FLOWS });
    yield put({ type: FETCH_STEPS });
    yield put({ type: FETCH_STEP_RAW_MATERIALS });
    yield put({ type: FETCH_RAW_MATERIAL_SUPPLIERS });
    yield put({ type: FETCH_SCENARIOS });
    yield put({ type: FETCH_SCENARIO_DESCRIPTORS });
    yield put({ type: FETCH_SCENARIO_DESCRIPTOR_CONDITIONS });
    yield put({ type: FETCH_PRODUCTS });
    yield all([
      take(FETCH_UNITS + FULFILLED),
      take(FETCH_CONDITIONS + FULFILLED),
      take(FETCH_DESCRIPTOR_TYPES + FULFILLED),
      take(FETCH_DESCRIPTORS + FULFILLED),
      take(FETCH_ECHAS + FULFILLED),
      take(FETCH_SUPPLIERS + FULFILLED),
      take(FETCH_RAW_MATERIALS + FULFILLED),
      take(FETCH_RAW_MATERIAL_SUPPLIERS + FULFILLED),
      take(FETCH_CONSUMPTIONS + FULFILLED),
      take(FETCH_FLOWS + FULFILLED),
      take(FETCH_STEPS + FULFILLED),
      take(FETCH_STEP_RAW_MATERIALS + FULFILLED),
      take(FETCH_SCENARIOS + FULFILLED),
      take(FETCH_SCENARIO_DESCRIPTORS + FULFILLED),
      take(FETCH_SCENARIO_DESCRIPTOR_CONDITIONS + FULFILLED),
      take(FETCH_PRODUCTS + FULFILLED),
    ]);
    yield put({ type: BOOT + FULFILLED });
  } catch (error) {
    console.log(error);
  }
}

const reloadAdmin = function* () {
  try {
    yield put({ type: FETCH_PROFILE });
    yield put({ type: FETCH_USER_ACCOUNT });
    yield put({ type: FETCH_USERS });
    yield put({ type: FETCH_ACCOUNTS });
    yield put({ type: FETCH_UNITS });
    yield put({ type: FETCH_CONDITIONS });
    yield put({ type: FETCH_DESCRIPTOR_TYPES });
    yield put({ type: FETCH_DESCRIPTORS });
    yield put({ type: FETCH_ECHAS });
    yield put({ type: FETCH_SUPPLIERS });
    yield put({ type: FETCH_RAW_MATERIALS });
    yield put({ type: FETCH_RAW_MATERIAL_SUPPLIERS });
    yield put({ type: FETCH_CONSUMPTIONS });
    yield put({ type: FETCH_FLOWS });
    yield put({ type: FETCH_STEPS });
    yield put({ type: FETCH_STEP_RAW_MATERIALS });
    yield put({ type: FETCH_SCENARIOS });
    yield put({ type: FETCH_SCENARIO_DESCRIPTORS });
    yield put({ type: FETCH_SCENARIO_DESCRIPTOR_CONDITIONS });
    yield put({ type: FETCH_PRODUCTS });
    yield all([
      take(FETCH_UNITS + FULFILLED),
      take(FETCH_CONDITIONS + FULFILLED),
      take(FETCH_DESCRIPTOR_TYPES + FULFILLED),
      take(FETCH_DESCRIPTORS + FULFILLED),
      take(FETCH_ECHAS + FULFILLED),
      take(FETCH_SUPPLIERS + FULFILLED),
      take(FETCH_RAW_MATERIALS + FULFILLED),
      take(FETCH_RAW_MATERIAL_SUPPLIERS + FULFILLED),
      take(FETCH_CONSUMPTIONS + FULFILLED),
      take(FETCH_FLOWS + FULFILLED),
      take(FETCH_STEPS + FULFILLED),
      take(FETCH_STEP_RAW_MATERIALS + FULFILLED),
      take(FETCH_SCENARIOS + FULFILLED),
      take(FETCH_SCENARIO_DESCRIPTORS + FULFILLED),
      take(FETCH_SCENARIO_DESCRIPTOR_CONDITIONS + FULFILLED),
      take(FETCH_PRODUCTS + FULFILLED),
    ]);
    yield put({ type: BOOT + FULFILLED });
  } catch (error) {
    console.log(error);
  }
}

const sagas = [
  fork(watchBoot),
  fork(watchBootAdmin),
  fork(watchBootManager),
  fork(watchBootUser),
  fork(watchReloadAdmin)

];

export { sagas };
