import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columns = [
  { title: 'Codigo Fornecimento', field: 'cod_fornecimento' },
  { title: 'Designação comercial', field: 'designacao_comercial' },
  {
    title: 'Ativo',
    field: 'ativo',
    render: value =>
      <span style={{ fontWeight: value ? 'bold' : null }}>
        {value ? 'Sim' : 'Não'}
      </span>
  }
];

export default props => {
  const { rowData, data, title } = props;

  const rows = rowData.map(fsup => ({
    ...data[fsup.id_fornecedor.id],
    designacao_comercial: fsup.designacao_comercial,
    cod_fornecimento: fsup.codigo
  }));

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={rows} title={title}/>
    </div>
  )
}
