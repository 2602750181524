import XLSX from 'xlsx';

/**
 * XLSX library returns an array of arrays with the xlsx file, the function
 * takes that out and converts to an array of objects, where keys are xlsx
 * columns
 *
 * @param reader
 * @returns {[]}
 */
export const xlsxToAoO = reader => {
  const data = new Uint8Array(reader.result);
  const workbook = XLSX.read(data, { type: 'array' });
  const jsonAoA = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
  const jsonAoO = [];
  for (let i = 1; i < jsonAoA.length; i += 1) {
    const obj = {};
    jsonAoA[0].forEach((header, index) => obj[header] = jsonAoA[i][index])
    jsonAoO.push(obj);
  }
  return jsonAoO
}
