import React from 'react';
import { MIXTURE, SUBSTANCE } from 'utils/constants/coreConstants';

export default [
  {
    title: 'Consumos',
    width: 75,
    render: rowData => rowData.consumos.length === 0 ?
      <span style={{ color: 'red' }}>0</span> :
      <span style={{ color: 'green' }}>{rowData.consumos.length}</span>,
    editable: 'never',
  },
  {
    title: 'Código_MP',
    field: 'cod_mp',
    width: 150,
    editable: 'never',
  },
  {
    title: 'Nome',
    field: 'mp_nome',
    minWidth: 400,
    editable: 'never',
  },
  {
    title: 'Tipo',
    field: 'tipo',
    minWidth: 100,
    lookup: {
      [SUBSTANCE]: 'Substância',
      [MIXTURE]: 'Mistura'
    },
    editable: 'never',
  },
  {
    title: 'Observações',
    field: 'observacoes',
    minWidth: 400
  },
  {
    title: 'Perigosa',
    field: 'perigosa',
    type: 'boolean',
    minWidth: 90,
    initialEditValue: 'true',
    headerStyle: {
      textAlign: 'center'
    },
    cellStyle: {
      textAlign: 'center'
    },
    lookup: {
      true: 'Sim',
      false: 'Não'
    },
    render: rowData =>
      <span style={{fontWeight: rowData.perigosa ? 'bold' : null}}>
        {rowData.perigosa ? 'Sim' : 'Não' }
      </span>,
    editable: 'never',
  }
];
