import Grid from '@material-ui/core/Grid';
import BigDialog from 'components/BigDialog';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions as flowActions } from 'ducks/flows';
import { actions as stepActions } from 'ducks/steps';
import cloneDeep from 'lodash/cloneDeep'
import SubStepsDetailPanel from 'pages/Flows/Flows/SubStepsDetailPanel';
import SubStepsTable from 'pages/Flows/Flows/SubStepsTable';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreById } from 'selectors/generic';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/steps';
import validator from 'utils/tables/validators/steps';

const Component = props => {
  const { flowId, flows, user } = props;
  const {
    alertError, closeParentContainerConfirm, createStep, fetchFlow,
    removeStep, updateStep
  } = props;
  const tableRef = React.useRef(null);

  const [masterStepId, setmasterStepId] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleCloseDialog = () => setmasterStepId(undefined);

  const allSteps = flows[flowId]['etapas'];
  const masterdSteps = cloneDeep(flows[flowId]['etapas'].filter(s => s.id_master_etapa === null));

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        titleBack={true}
        titleAction={closeParentContainerConfirm}
        title={`ETAPAS - ${flows[flowId].codigo_fluxo} - ${flows[flowId].nome}`}
        columns={columns(user)}
        data={masterdSteps}
        onRowClick={() => null}
        onRowDoubleClick={rowData => setmasterStepId(rowData.id)}
        options={{
          actionsCellStyle: {
            width: 200,
            paddingLeft: 20
          }
        }}
        isLoading={loading}
        actions={[
          {
            icon: tableIcons.Add,
            tooltip: 'Adicionar Sub-Etapas',
            onClick: (event, rowData) => setmasterStepId(rowData.id)
          }
        ]}
        detailPanel={[
          rowData => {
            const subSteps = rowData['subetapas'];
            return subSteps.length === 0 ?
              {
                disabled: true,
                icon: tableIcons.NoIcon,
                render: () => {
                }
              } :
              {
                icon: tableIcons.SubSteps,
                tooltip: 'Lista Sub Etapas',
                render: () => <SubStepsDetailPanel data={subSteps}/>
              }
          }
        ]}
        editable={{
          onRowAdd: newData => {
            setLoading(true);
            const validation = validator(newData, allSteps)
            if (validation.error) {
              alertError(validation.error)
              setLoading(false);
              return Promise.reject();
            } else {
              return createStep({ ...validation, id_fluxograma: flowId, id_master_etapa: null })
                .then(() => fetchFlow(flowId))
                .then(() => setLoading(false))
                .catch(e => {
                  setLoading(false);
                  alert(e);
                })
            }
          },
          onRowUpdate: (newData, oldData) => {
            setLoading(true);
            const validation = validator(newData, allSteps)
            if (validation.error) {
              alertError(validation.error)
              setLoading(false);
              return Promise.resolve();
              // TODO Instead of rejecting we are resolving, should be rejecting. Have to update library later github issue #2086
            } else {
              return updateStep(newData.id, newData)
                .then(() => fetchFlow(flowId))
                .then(() => setLoading(false))
                .catch(e => {
                  setLoading(false);
                  alert(e);
                })
            }
          },
          onRowDelete: oldData => {
            setLoading(true);
            return removeStep(oldData.id).then(() => fetchFlow(flowId))
              .then(() => setLoading(false))
              .catch(e => {
                setLoading(false);
                alert(e);
              })
          }
        }}
      />
      <BigDialog open={!!masterStepId} handleClose={handleCloseDialog}>
        {
          masterStepId &&
          <SubStepsTable
            masterStepId={masterStepId}
          />
        }
      </BigDialog>
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const flows = getImmutableStoreById('flows');
  const mapStateToProps = (state, props) => ({
    flows: flows(state),
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  createStep: data => dispatch(stepActions.createPromise(data)),
  removeStep: id => dispatch(stepActions.removePromise(id)),
  updateStep: (id, data) => dispatch(stepActions.updatePromise(id, data)),
  fetchFlow: id => dispatch(flowActions.fetchOne(id))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
