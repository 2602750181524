import Grid from '@material-ui/core/Grid';
import BigDialog from 'components/BigDialog';
import Drawer from 'components/Drawer';
import RawMaterialInserter from 'connectedComponents/SubstancePolimerInserter';
import React, { useState } from 'react';
import MixturesTable from './MixturesTable';
import SubstancesTable from './SubstancesTable';

const Component = props => {
  const [mixtureId, setMixtureId] = useState(null);
  const [rmInserter, setRmInserter] = useState(false);
  const handleCloseDrawer = () => setMixtureId(null);
  const handleOpenRmInserter = () => setRmInserter(true);
  const handleCloseRmInserter = () => setRmInserter(false);

  return (
    <Grid item xs={12}>
      <MixturesTable setMixtureId={setMixtureId} handleOpenRmInserter={handleOpenRmInserter}/>
      {
        mixtureId &&
        <Drawer open={true} onClose={handleCloseDrawer}>
          <SubstancesTable
            mixtureId={mixtureId}
            handleCloseDrawer={handleCloseDrawer}
            handleOpenRmInserter={handleOpenRmInserter}
          />
        </Drawer>
      }
      {
        rmInserter &&
        <BigDialog open={true} handleClose={handleCloseRmInserter}>
          <RawMaterialInserter/>
        </BigDialog>
      }
    </Grid>
  )
};

export default Component;
