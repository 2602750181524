import React from 'react';

export default user => {
  const columns = [
    {
      title: 'Username',
      field: 'username',
      editable: 'onAdd'
    },
    {
      title: 'Nome',
      field: 'first_name',
      editable: 'onAdd'
    },
    {
      title: 'Sobrenome',
      field: 'last_name',
      editable: 'onAdd',
    },
    {
      title: 'Email',
      field: 'email',
      editable: 'onAdd'
    },
    {
      title: 'Password',
      field: 'password',
      editable: 'onAdd',
    },
    {
      title: 'Permissões',
      field: 'manager',
      type: 'boolean',
      lookup: {
        'true': 'Manager',
        'false': 'Utilizador'
      },
      initialEditValue: 'false',
      render: rowData => {
        if (rowData.manager === 'true' || rowData.manager === true) {
          return <span style={{ color: 'blue' }}>Manager</span>;
        } else if (rowData.manager === 'false' || rowData.manager === false) {
          return <span style={{ color: 'grey' }}>Utilizador</span>;
        }
      },
    },
    {
      title: 'Acesso',
      field: 'access',
      type: 'boolean',
      //editable: 'never',
      lookup: {
        'normal': 'Normal',
        'restricted': 'Inventorio'
      },
      initialEditValue: 'restricted',
      render: rowData => {
        if (rowData.groups.length > 0 ) {
          if (rowData.groups[0].name === 'restricted') {
            return <span style={{ color: 'orange' }}>Inventório</span>;
          } else if (rowData.groups[0].name === 'normal') {
            return <span style={{ color: 'green' }}>Normal</span>;
          }
        } else {
          return <span style={{ color: 'red' }}>Não Definido</span>;
        }
      },
    },
    {
      title: 'Estado',
      field: 'is_active',
      type: 'boolean',
      lookup: {
        'true': 'Activo',
        'false': 'Inactivo'
      },

      initialEditValue: 'false',
      render: rowData => {
        if (rowData.is_active === 'true' || rowData.is_active === true) {
          return <span style={{ color: 'green' }}>Activo</span>;
        } else if (rowData.is_active === 'false' || rowData.is_active === false) {
          return <span style={{ color: 'red' }}>Inactivo</span>;
        }
      },
      editable: user.is_staff || user.manager ? 'onUpdate' : 'never'
    },
    {
      title: 'Ultimo login',
      field: 'last_login',
      type: 'date',
      editable: 'never'
    }
  ];

  const columnsAdmin = [
    {
      title: 'Identificador',
      field: 'id',
      editable: 'never',
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};
