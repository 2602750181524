import { DISTRIBUIDOR, FABRICANTE, IMPORTADOR, UJ } from 'utils/constants/papel_reach';

const Joi = require('@hapi/joi');

const baseProperties = ['codigo', 'designacao_comercial', 'id_fornecedor', 'data_fds', 'revisao_fds', 'papel_reach'];

export const schema = data => {
  const joiObj = {
    codigo: Joi.string()
      .min(3)
      .max(60)
      .required()
      .messages({
        "any.required": `"Código" campo de preenchimento obrigatório`,
        "string.empty": `"Código" campo vazio`,
        "string.min": `"Código" tem de ter pelo menos 3 caracteres`,
        "string.max": `"Código" só pode 60 caracteres máximo`
      }),
    designacao_comercial: Joi.string()
      .min(3)
      .max(200)
      .required()
      .messages({
        "any.required": `"Designação Comercial" campo de preenchimento obrigatório`,
        "string.empty": `"Designação Comercial" campo vazio`,
        "string.min": `"Designação Comercial" tem de ter pelo menos 3 caracteres`,
        "string.max": `"Designação Comercial" só pode 200 caracteres máximo`
      }),
    id_fornecedor: Joi.number()
      .required()
      .messages({
        "any.required": `"Fornecedor" campo de preenchimento obrigatório`,
        "number.base": `"Fornecedor tem de ser um identificador numérico"`
      }),

    data_fds: Joi.date()
      .allow(null)
      .messages({
        "any.required": `"Data FDS" campo de preenchimento obrigatório`,
        "date.base": `"Data FDS" tem de ser uma data válida"`
      }),
    revisao_fds: Joi.string()
      .max(25)
      .allow('')
      .messages({
        "any.required": `"Revisão FDS" campo de preenchimento obrigatório`,
        "string.empty": `"Revisão FDS" campo vazio`,
        "string.max": `"Revisão FDS" só pode 25 caracteres máximo`
      }),
    papel_reach: Joi.string()
      .valid(FABRICANTE, DISTRIBUIDOR, UJ, IMPORTADOR)
      .required()
      .messages({
        "any.required": `"Papel Reach" campo de preenchimento obrigatório`,
        "string.empty": `"Papel Reach" campo vazio`,
        "any.only": `"Papel Reach" só pode ter as seguintes opções:  Fabricante, Distribuidor, UJ, Importador`,
        "string.valid": `"Papel Reach" só pode ter as seguintes opções: Fabricante, Distribuidor, UJ, Importador`
      })
  };

  if (data.cod_mp && data.cod_mp !== '') {
    return Joi.object({
      cod_mp: Joi.string()
        .min(3)
        .max(60)
        .pattern(/^[a-zA-Z0-9\-_\s]+$/)
        .empty('')
        .required()
        .messages({
          "any.required": `"Código MP" campo de preenchimento obrigatório`,
          "string.empty": `"Código MP" campo vazio`,
          "string.min": `"Código MP" tem de ter pelo menos 3 caracteres`,
          "string.max": `"Código MP" só pode 60 caracteres máximo`,
          "string.pattern.base": `"Código MP" só pode usar caracteres alfanuméricos e "-_ "`
        }),
      ...joiObj
    });
  } else {
    return Joi.object(joiObj);
  }
}


export const validator = (newEntity, shipments) => {
  let error = undefined;
  let iEntity = { ...newEntity };

  if (newEntity.cod_mp && newEntity.cod_mp !== '') {
    baseProperties.push('cod_mp');
  }
  Object.keys(iEntity).forEach(k => {
    if (!baseProperties.includes(k)) {
      delete iEntity[k];
    }
  });
  // Validate data correctness
  const validateEntity = schema(newEntity).validate(iEntity, { abortEarly: false });
  if (validateEntity.error) {
    return { error: validateEntity.error.details.map(obj => obj.message) };
  }
  return error ? error : iEntity;
};

