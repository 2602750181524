export default theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '20% minmax(300px, 450px ) 20%',
    gridTemplateRows: '20% minmax(350px, 450px ) 20%',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    width: '100vw',
    height: '100vh',
    minWidth: '400px',
    minHeight: '450px',
    backgroundColor: theme.palette.secondary.light,
    //backgroundColor: 'white',
    alignItems: 'center'

  },
  loginArea: {
    //gridArea: 'space2',
    gridColumn: '2 ',
    gridRow: '2',
  },
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(1),
    //backgroundColor: theme.palette.primary.main,
    backgroundColor: 'white',
  },
  textTransformationNone: {
    textTransform: 'none',
    textDecoration: 'none'
  },
  iconColor: {
    color: theme.palette.secondary.main,
  },
  textInput: {
    marginTop: 15
  }
});

//Todo Solve inline styles on <Grid item style={{paddingBottom: 0}}> and others
