import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import UserManagement from './UserManagement'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.veryLight,
    padding: 10,
    width: '100%'
  },
  header: {
    backgroundColor: theme.palette.secondary.main
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    flexBasis: '33.33%',
    flexShrink: 0,
    //color: theme.palette.secondary.dark
    color: 'white'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    // color: theme.palette.secondary.dark
    color: 'white'
  }
}));

const Topic = ({ classes, expanded, handleChange }) => (
  <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
    <ExpansionPanelSummary
      className={classes.header}
      expandIcon={<ExpandMoreIcon/>}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
    >
      <Typography className={classes.heading}>GESTÃO DE UTILIZADORES</Typography>
      <Typography className={classes.secondaryHeading}>
        Gestão de utilizadores da aplicação, criação, remoção, etc ...
      </Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <UserManagement/>
    </ExpansionPanelDetails>
  </ExpansionPanel>
);

const Component = props => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Topic classes={classes} expanded={expanded} handleChange={handleChange}/>
    </div>
  );
}

export default Component;
