import { MTableEditField } from '@material-table/core';
import Done from '@material-ui/icons/Done';
import Remove from '@material-ui/icons/Remove';
import React from 'react';
import { MIXTURE, SUBSTANCE } from 'utils/constants/coreConstants';

export default user => {
  const columns = [
    {
      title: 'Código MP',
      field: 'cod_mp',
      minWidth: 150,
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'left'
      }
    },
    {
      title: 'Nome',
      field: 'mp_nome',
      minWidth: 300,
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'left'
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>
    },
    {
      title: 'Observações',
      field: 'observacoes',
      minWidth: 500,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
    },
    {
      title: 'Tipo',
      field: 'tipo',
      minwidth: 100,
      lookup: {
        [SUBSTANCE]: 'Substância',
        [MIXTURE]: 'Mistura'
      }
    },
    {
      title: 'CAS',
      field: 'cas',
      minWidth: 150,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      render: rowData => rowData.ec && rowData.cas.length !== 0 ? rowData.cas.join(', ') : '-'
    },
    {
      title: 'EC',
      field: 'ec',
      minWidth: 150,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      render: rowData => rowData.ec && rowData.ec.length !== 0 ? rowData.ec.join(', ') : '-'
    },
    {
      title: 'Perigosa',
      field: 'perigosa',
      type: 'boolean',
      minWidth: 90,
      initialEditValue: 'true',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      },
      lookup: {
        true: 'Sim',
        false: 'Não'
      },
      render: rowData =>
        <span style={{fontWeight: rowData.perigosa ? 'bold' : null}}>
        {rowData.perigosa ? 'Sim' : 'Não' }
      </span>
    }
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};


