import LCSSelector from 'connectedComponents/HeaderButtons/LcsRadioSelector';
import ProductSelector from 'connectedComponents/HeaderButtons/ProductRadioSelector';
import ScenarioRenderer from 'connectedComponents/HeaderButtons/ScenarioRadioSelector';
import React from 'react';

const Component = props => {
  const { currentOptionProduct, currentOptionLcs, currentOptionScenario } = props;
  const { onSelectionProduct, onSelectionLcs } = props;

  return (
    <React.Fragment>
      &nbsp;&nbsp;
      <ProductSelector disabled={true} currentOption={currentOptionProduct} onSelection={onSelectionProduct}/>
      &nbsp;&nbsp;
      <LCSSelector disabled={true} currentOption={currentOptionLcs} onSelection={onSelectionLcs}/>
      &nbsp;&nbsp;
      <ScenarioRenderer disabled={true} currentOption={currentOptionScenario}/>
    </React.Fragment>
  )
};

export default Component;
