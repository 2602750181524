import { DISTRIBUIDOR, FABRICANTE, IMPORTADOR, UJ } from 'utils/constants/papel_reach';

const Joi = require('@hapi/joi');

const baseObject = {
  codigo_produto: '',
  produto_nome: '',
  papel_reach: 'uj',
  perigoso: false,
  utilizacao: '',
  modo_emprego: '',
  notas: '',
};

export const schema = Joi.object({
  codigo_produto: Joi.string()
    .max(60)
    .required()
    .messages({
      "any.required": `"Código" campo de preenchimento obrigatório`,
      "string.max": `"Código" só pode 60 caracteres máximo`,
    }),
  produto_nome: Joi.string()
    .max(200)
    .required()
    .messages({
      "any.required": `"Nome" campo de preenchimento obrigatório`,
      "string.empty": `"Nome" campo vazio`,
      "string.max": `"Nome" só pode 200 caracteres máximo`,
    }),
  papel_reach: Joi.string()
    .valid(UJ, FABRICANTE, IMPORTADOR, DISTRIBUIDOR)
    .required()
    .messages({
      "any.required": `"Papel Reach" campo de preenchimento obrigatório`,
      "any.only": `"Papel Reach" só pode ter as seguintes opções: UJ, Fabricante, Importador ou Distribuidor`,
      "string.valid": `"Papel Reach" só pode ter as seguintes opções: UJ, Fabricante, Importador ou Distribuidor`
    }),
  perigoso: Joi.boolean().required()
    .messages({
      "any.required": `"Perigoso" campo de preenchimento obrigatório`,
      "boolean.empty": `"Perigoso" campo vazio`,
      "boolean.base": `"Perigoso" campo de preenchimento obrigatório`
    }),
  utilizacao: Joi.string()
    .allow(null, '')
    .messages({
      "string.max": `"Utilização" só pode 500 caracteres máximo`,
    }),
  modo_emprego: Joi.string()
    .allow(null, '')
    .messages({
      "string.max": `"Modo de Emprego" só pode 500 caracteres máximo`,
    }),
  notas: Joi.string()
    .allow(null, '')
    .messages({
      "string.max": `"Notas" só pode 1000 caracteres máximo`,
    }),
});

const validator = (newEntity, allEntities) => {
  let error = undefined;
  let intEntity = { ...baseObject, ...newEntity };

  const baseObjectKeys = Object.keys(baseObject);
  Object.keys(intEntity).forEach(k => {
    if (!baseObjectKeys.includes(k)) {
      delete intEntity[k];
    }
  })

  // Validate data correctness
  const validateEntity = schema.validate(intEntity, { abortEarly: false });
  if (validateEntity.error) {
    return { error: validateEntity.error.details.map(obj => obj.message) };
  }

  allEntities.some(sup => {
    if (!newEntity.id || (newEntity.id && newEntity.id !== sup.id)) { // Validation of a create or an update operation!
      if (sup.codigo_produto === intEntity.codigo_produto || sup.produto_nome === intEntity.produto_nome) {
        error = { error: 'Codigo produto ou Nome de Produto repetido!' }
        return true;
      } else {
        return false;
      }
    }
    return false
  })
  return error ? error : intEntity;
};

export default validator;
