import React from 'react';

export default user => {
  const columns = [
    {
      title: 'Nome',
      field: 'mp_nome',
      sorting: false,
      width: 400,
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'left'
      }
    },
    {
      title: 'CAS',
      field: 'cas',
      sorting: false,
      width: 200,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      render: rowData => rowData.cas && rowData.cas.length !== 0 ? rowData.cas.join(', ') : '-'
    },
    {
      title: 'EC',
      field: 'ec',
      sorting: false,
      width: 200,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      render: rowData => rowData.ec && rowData.ec.length !== 0 ? rowData.ec.join(', ') : '-'
    }
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100
    },
    ...columns
  ];

  return user.is_staff ? columnsAdmin : columns;
}
