import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable';
import React from 'react';

const Component = props => {
  const { columns, data } = props;
  const { closeErrors } = props;

  return (
    <Grid item xs={12}>
      <BaseTable
        titleAction={closeErrors}
        titleBack={true}
        title={'ERROS DE IMPORTAÇÃO'}
        columns={columns}
        data={data}
      />
    </Grid>
  )
};

export default Component;
