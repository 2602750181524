import Grid from '@material-ui/core/Grid';
import Drawer from 'components/Drawer';
import ProductScenario from 'pages/Products/ProductScenarios/ProductScenario';
import ProductsTable from 'pages/Products/ProductScenarios/ProductsTable';
import ScenariosTable from 'pages/Products/ProductScenarios/ScenariosTable';
import React, { useState } from 'react';


const Component = props => {

  const [productId, setProductId] = useState(undefined);
  const [lcsId, setLcsId] = useState(undefined);
  const [scenariosOpen, setScenariosOpen] = useState(false);
  const [descriptorsOpen, setDescriptorsOpen] = useState(false);

  const backToProductsTable = () => {
    setProductId(undefined);
    setLcsId(undefined);
    setDescriptorsOpen(false)
    setScenariosOpen(false);
  };

  const openDescriptors = (productId, lcsId) => {
    setScenariosOpen(false);
    setProductId(productId);
    setLcsId(lcsId);
    setDescriptorsOpen(true)
  }

  const openProductScenarios = productId => {
    setProductId(productId);
    setDescriptorsOpen(false);
    setScenariosOpen(true);
  }

  return (
    <Grid item xs={12}>
      {
        descriptorsOpen ?
          <ProductScenario
            productId={productId}
            lcsId={lcsId}
            backToProductsTable={backToProductsTable}
          /> :
          <ProductsTable
            openProductScenarios={openProductScenarios}
            openDescriptors={openDescriptors}
          />
      }

      <Drawer
        open={!!productId && scenariosOpen}
        onClose={backToProductsTable}
      >
        {
          scenariosOpen &&
          <ScenariosTable
            productId={productId}
            openDescriptors={openDescriptors}
            backToProductsTable={backToProductsTable}
          />
        }
      </Drawer>
    </Grid>
  )
};


export default Component;
