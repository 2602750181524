import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/consumptions';
import { actions as actionsRawMaterial } from 'ducks/rawMaterials';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { connect } from 'react-redux';
import tableIcons from 'utils/icons/materialTableIcons';
import consumptionColumns from 'utils/tables/columns/consumption';
import validator from 'utils/tables/validators/consumptions';


const Component = props => {
  const { consumptions, mpConsumptions, mpId, rawMaterials } = props;
  const { alertError, create, fetchRawMaterial, onCloseDrawer, remove, update } = props;

  const rows = orderBy(mpConsumptions.map(id => consumptions[id]), ['ano'], ['asc']);

  return (
    <Grid item xs={12}>
      <BaseTable
        titleBack={true}
        titleAction={onCloseDrawer}
        title={rawMaterials[mpId].mp_nome}
        icons={tableIcons}
        onRowClick={() => null}
        columns={consumptionColumns}
        data={rows}
        editable={{
          onRowAdd: newData => {
            const validation = validator(newData, rows);
            if (validation.error) {
              alertError(validation.error)
              return Promise.reject();
            }
            const data = { id_mp: `${mpId}`, ...newData };
            return create(data)
              .then(() => fetchRawMaterial(mpId));
          },
          onRowUpdate: (newData, oldData) => {
            const validation = validator(newData, rows);
            if (validation.error) {
              alertError(validation.error)
              return Promise.reject();
            }
            update(newData.id, newData).then(() => fetchRawMaterial(mpId))
          },
          onRowDelete: oldData =>
            remove(oldData.id).then(() => fetchRawMaterial(mpId))
        }}
      />
    </Grid>
  )
};

const mapStateToProps = (state) => ({
  consumptions: state.rawMaterialConsumptions.dataById,
  rawMaterials: state.rawMaterials.dataById
});

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  create: data => dispatch(actions.createPromise(data)),
  fetchRawMaterial: id => dispatch(actionsRawMaterial.fetchOne(id)),
  remove: id => dispatch(actions.removePromise(id)),
  update: (id, data) => dispatch(actions.updatePromise(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
