import Grid from '@material-ui/core/Grid';
import Drawer from 'components/Drawer';
import ConditionsTable from 'pages/Flows/FlowScenarios/ConditionsTable';
import Descriptors from 'pages/Flows/FlowScenarios/DescriptorsTable';
import PROCSteps from 'pages/Flows/FlowScenarios/PROCSteps';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ERC, ERC_TAB, PC, PC_TAB, PROC, PROC_TAB, SU, SU_TAB } from 'utils/constants/descriptorTypes'


const Component = props => {
  const { lcsById, flowsById, scenariosById, scenarioId, scenarioDescriptors } = props;
  const { backToScenarios } = props;

  const [descriptorTab, setDescriptorTab] = useState(PC_TAB);
  const [descriptor, setDescriptor] = useState(null);
  const [stepId, setStepId] = useState(null);

  const scenario = scenariosById[scenarioId];
  const flow = flowsById[scenario.id_fluxograma];
  const lcs = lcsById[scenario.id_lcs];

  const filteredScenarioDescriptors = scenario ?
    scenarioDescriptors.filter(sd => sd.id_cenario === scenario.id) : [];

  // Only used for ERC and PROC (for proc should filter with stepId to)
  const selectedScenarioDescriptor = stepId ?
    filteredScenarioDescriptors.find(fsd =>
      (descriptor && fsd.id_descritor === descriptor.id && fsd.id_etapa === stepId)
    ) :
    filteredScenarioDescriptors.find(fsd =>
      (descriptor && fsd.id_descritor === descriptor.id)
    );

  const closeConditions = () => setDescriptor(null);
  const closeDescriptors = () => setStepId(null);

  const descriptorCase = useDescriptor => {
    switch (useDescriptor) {
      case PC_TAB:
        return (
          <Descriptors
            descriptorTab={PC_TAB}
            descriptorFilter={PC}
            scenarioDescriptors={filteredScenarioDescriptors}
            setDescriptorTab={setDescriptorTab}
            scenario={scenario}
            flow={flow}
            lcs={lcs}
            backToScenarios={backToScenarios}
          />
        )
      case SU_TAB:
        return (
          <Descriptors
            descriptorTab={SU_TAB}
            descriptorFilter={SU}
            scenarioDescriptors={filteredScenarioDescriptors}
            setDescriptorTab={setDescriptorTab}
            scenario={scenario}
            flow={flow}
            lcs={lcs}
            backToScenarios={backToScenarios}
          />
        )
      case ERC_TAB:
        return (
          <React.Fragment>
            <Descriptors
              descriptorTab={ERC_TAB}
              descriptorFilter={ERC}
              setDescriptor={setDescriptor}
              scenarioDescriptors={filteredScenarioDescriptors}
              setDescriptorTab={setDescriptorTab}
              scenario={scenario}
              flow={flow}
              lcs={lcs}
              backToScenarios={backToScenarios}
            />
            {
              descriptor &&
              <Drawer open={true} onClose={closeConditions}>
                <ConditionsTable
                  conditionsFilter={'Ambiente'}
                  closeConditions={closeConditions}
                  scenarioDescriptor={selectedScenarioDescriptor ? selectedScenarioDescriptor : {}}
                />
              </Drawer>
            }
          </React.Fragment>
        )
      case PROC_TAB:
        return (
          <React.Fragment>
            <PROCSteps
              scenarioDescriptors={filteredScenarioDescriptors}
              setDescriptorTab={setDescriptorTab}
              setStepId={step => setStepId(step)}
              scenario={scenario}
              flow={flow}
              lcs={lcs}
              backToScenarios={backToScenarios}
            />
            {
              stepId &&
              <Drawer open={true} onClose={closeDescriptors}>
                <Descriptors
                  descriptorTab={PROC_TAB}
                  descriptorFilter={PROC}
                  takeOutTabsHeader={true}
                  stepId={stepId}
                  closeDescriptors={closeDescriptors}
                  setDescriptor={setDescriptor}
                  scenarioDescriptors={filteredScenarioDescriptors}
                  setDescriptorTab={setDescriptorTab}
                  scenario={scenario}
                  flow={flow}
                  lcs={lcs}
                />
              </Drawer>
            }
            {
              descriptor &&
              <Drawer open={true} coverage={82} onClose={closeConditions}>
                <ConditionsTable
                  conditionsFilter={'Trabalhadores'}
                  closeConditions={closeConditions}
                  scenarioDescriptor={selectedScenarioDescriptor ? selectedScenarioDescriptor : {}}
                />
              </Drawer>
            }
          </React.Fragment>
        )
      default:
        console.log('ERROR, descriptor doesnt exist!');
    }
  }


  return (
    <Grid item xs={12}>
      {descriptorCase(descriptorTab)}
    </Grid>
  )
};

const mapStateToProps = state => ({
  lcsById: state.descriptors.dataById,
  flowsById: state.flows.dataById,
  scenarios: state.scenarios.data,
  scenariosById: state.scenarios.dataById,
  scenarioDescriptors: state.scenarioDescriptors.data
})

export default connect(mapStateToProps)(Component);
