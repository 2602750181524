import { STEP } from 'actionTypes';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';
import thunkFactory from 'utils/redux/baseEntitiesFactory/thunk';

// ACTIONS
const rawActions = actionsFactory(STEP);
const actions = { ...rawActions, ...thunkFactory(STEP, '/etapa/') };
export { actions };

// REDUCER
const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};

export default reducerFactory(STEP, initialState);

// SAGAS
const sagas = sagasFactory(STEP, '/etapa/');
export { sagas };
