import Alert from 'components/Alert';
import { cleanPersistentAlert } from 'ducks/alerts';
import React from 'react';
import { connect } from 'react-redux';

const Component = props => {
  const { alert } = props;
  const { cleanPersistentAlert } = props;

  return (
    <Alert
      open={alert.persistentMessage.length !== 0}
      message={alert.persistentMessage}
      handleClose={cleanPersistentAlert}
      autoHideDuration={15000}
      placement={{ vertical: 'bottom', horizontal: 'center' }}
      variant={'error'}
    />
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  cleanPersistentAlert: () => dispatch(cleanPersistentAlert())
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
