import MaterialTable, { MTableBodyRow, MTableToolbar } from '@material-table/core';
import { TablePagination } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import { TABLE_HEIGHT, TABLE_HEIGHT_SPLIT } from 'utils/ui/constants';

const styles = {
  pagination: {
    backgroundColor: theme.palette.primary.light
  },
  rowStyleNotClickable: {
    "&:hover": {
      cursor: "default !important"
    }
  },
}

const Component = React.forwardRef((props, ref) => {
  const {
    actions, cellEditable, classes, columns, components, data, detailPanel, editable, halfSize, isLoading, localization,
    onDoubleClickRowEdit, onRowDoubleClick, onRowClick, onSelectionChange, onSearchChange, options, paging = true, title, titleAction, titleBack
  } = props;

  const [stateForcedRefresh, setStateForcedRefresh] = useState(true);

  const enhancedTitle = () => {
    if (titleBack) {
      return (
        <React.Fragment>
          <Typography variant='h6'>
            <IconButton
              color='secondary'
              aria-label='go back'
              onClick={titleAction}
              style={{ borderStyle: 'dashed', borderWidth: 1 }}
            >
              <ArrowBack/>
            </IconButton>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            {title}
          </Typography>
        </React.Fragment>
      )
    } else if (title) {
      return title;
    }
    return '';
  }

  const tableRowComponent = () => {

    if (onDoubleClickRowEdit) {
      return props => (
        <MTableBodyRow
          onDoubleClick={() => {
            const rowToEdit = props.data.tableData.id;
            ref.current.state.data[rowToEdit].tableData.editing = 'update'
            setStateForcedRefresh(!stateForcedRefresh);
          }}
          {...props}
        />
      )
    } else if (onRowDoubleClick) {
      return props => <MTableBodyRow
        {...props}
        onDoubleClick={() => {
          const rowToEdit = props.data.tableData.id;
          const rowData = ref.current.state.data[rowToEdit];
          onRowDoubleClick(rowData);
        }}
      />
    }

    return props => <MTableBodyRow
      {...props}
      className={onRowClick ? classes.rowStyleNotClickable : null}
    />
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        tableRef={ref}
        title={enhancedTitle()}
        icons={tableIcons}
        columns={columns}
        data={data}
        isLoading={isLoading}
        onSelectionChange={onSelectionChange}
        onSearchChange={onSearchChange}
        options={{
          //actionsColumnIndex: -1,
          //tableLayout: 'fixed',
          actionsCellStyle: {
            minWidth: 100,
            paddingLeft: 20
            // alignItems: 'stretch',
            // display:'flex',
            // justifyContent: 'center',
          },
          searchAutoFocus: true,
          debounceInterval: 300,
          searchFieldStyle: onSearchChange && options && options.searchText !== '' ?
            {
              backgroundColor: theme.palette.primary.light,
              borderRadius: '6px 6px 0px 0px'
            } :
            null,
          addRowPosition: 'first',
          detailPanelColumnAlignment: 'right',
          headerStyle: {
            backgroundColor: theme.palette.primary.main,
            //color: theme.palette.secondary.main,
            color: 'white',
            position: 'sticky',
            top: 0
          },
          paging: paging,
          padding: 'dense',
          paginationType: 'normal',
          pageSize: 25,
          pageSizeOptions: [],
          maxBodyHeight: halfSize ? TABLE_HEIGHT_SPLIT(paging, 0) : TABLE_HEIGHT(paging, 0),
          minBodyHeight: halfSize ? TABLE_HEIGHT_SPLIT(paging, 0) : TABLE_HEIGHT(paging, 0),
          ...options
        }}
        components={{
          Toolbar: props => (
            <div style={{ backgroundColor: theme.palette.background.veryLight }}>
              <MTableToolbar {...props} />
            </div>
          ),
          Row: tableRowComponent() ,
          Pagination: props => <TablePagination {...props} className={classes.pagination}/>,
          ...components
        }}
        onRowClick={onRowClick}
        editable={editable}
        cellEditable={cellEditable}
        actions={actions}
        detailPanel={detailPanel}
        localization={
          {
            body: {
              deleteTooltip: 'Apagar',
              editTooltip: 'Editar',
              addTooltip: 'Adicionar'
            },
            toolbar: {
              exportName: 'Exportar CSV',
              exportTitle: 'Exportar CSV'
            },
            header: {
              actions: 'Acções'
            },
            ...localization
          }
        }
      />
    </MuiThemeProvider>
  )
})

export default withStyles(styles)(Component);

Component.propTypes = {
  actions: PropTypes.array,
  cellEditable: PropTypes.object,
  columns: PropTypes.array,
  components: PropTypes.object,
  data: PropTypes.array,
  detailPanel: PropTypes.array,
  editable: PropTypes.object,
  halfSize: PropTypes.bool,
  isLoading: PropTypes.bool,
  localization: PropTypes.object,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onDoubleClickRowEdit: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  options: PropTypes.object,
  paging: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  titleAction: PropTypes.func,
  titleBack: PropTypes.bool
}
