import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions as flowActions } from 'ducks/flows';
import { actions } from 'ducks/steps';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreById } from 'selectors/generic';
import columns from 'utils/tables/columns/steps';
import validator from 'utils/tables/validators/steps';

const Component = props => {
  const { masterStepId, user, steps } = props;
  const { alertError, create, fetch, fetchFlow, remove, update } = props;
  const tableRef = React.useRef(null);

  const subSteps = cloneDeep(steps[masterStepId]['subetapas']);

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        title={`SUB-ETAPAS, ETAPA ${steps[masterStepId].nome}`}
        columns={columns(user)}
        data={subSteps}
        onRowClick={() => null}
        options={{
          maxBodyHeight: '50vh',
          minBodyHeight: '50vh'
        }}
        editable={{
          onRowAdd: newData => {
            const validation = validator(newData, subSteps)
            if (validation.error) {
              alertError(validation.error)
              return Promise.reject();
            } else {
              return (
                create({
                  ...validation,
                  id_master_etapa: masterStepId,
                  id_fluxograma: steps[masterStepId].id_fluxograma.id
                })
                  .then(() => {
                    fetch(masterStepId);
                    return fetchFlow(steps[masterStepId].id_fluxograma.id);
                  })
              )
            }
          },
          onRowUpdate: (newData, oldData) => {
            const validation = validator(newData, subSteps)
            if (validation.error) {
              alertError(validation.error)
              return Promise.resolve();
              // TODO Instead of rejecting we are resolving, should be rejecting. Have to update library later github issue #2086
            } else {
              return (
                update(newData.id, newData)
                  .then(() => {
                    fetch(masterStepId);
                    return fetchFlow(steps[masterStepId].id_fluxograma.id);
                  })
              );
            }
          },
          onRowDelete: oldData =>
            remove(oldData.id)
              .then(() => {
                fetch(masterStepId);
                return fetchFlow(steps[masterStepId].id_fluxograma.id);
              })
        }}
      />
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const steps = getImmutableStoreById('steps');
  const mapStateToProps = (state, props) => ({
    steps: steps(state),
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  create: data => dispatch(actions.createPromise(data)),
  remove: id => dispatch(actions.removePromise(id)),
  update: (id, data) => dispatch(actions.updatePromise(id, data)),
  fetch: id => dispatch(actions.fetchOne(id)),
  fetchFlow: id => dispatch(flowActions.fetchOne(id))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
