import BaseTable from 'components/Tables/BaseTable';
import { sortBy } from 'lodash';
import ProducListDetailPanel from 'pages/ScenarioExposure/Product/ScenariosManagement/ProducListDetailPanel';
import React from 'react';
import { connect } from 'react-redux';
import { lookUpIdValueSelector } from 'selectors/generic';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/scenarioConditions';
import { TABLE_HEIGHT_SPLIT_4VIEW } from 'utils/ui/constants';

const TABLE_HEIGHT = TABLE_HEIGHT_SPLIT_4VIEW(64);

const Component = props => {
  const {
    conditionsById, descriptorId, productsById, scenariosById, scenarioDescriptors,
    scenarioDescriptorConditionsData, units, user
  } = props;

  const selectedSD = scenarioDescriptors.filter(sd =>
    (descriptorId && sd.id_descritor === descriptorId)
  );

  let filteredSDC = {};
  const sdcConditionRepetitions = [];

  selectedSD.forEach(sSD => {
    const productId = scenariosById[sSD.id_cenario].id_produto;
    const productInfo = {
      product_id: productId,
      code: productsById[productId].codigo_produto,
      name: productsById[productId].produto_nome
    };

    const sdcs = scenarioDescriptorConditionsData.filter(sdc => sdc.id_cenariodescritor === sSD.id)
    sdcs.forEach(sdc => {
      // TODO Probably later Ana will want some crazy distintion, so I will leave this code here to remember
      // const uniqueId = `${sdc.id_condicao}-${sdc.valor}-${sdc.unidade}`
      const uniqueId = `${sdc.id_condicao}`

      if (sdcConditionRepetitions.includes(uniqueId)) {
        filteredSDC[uniqueId].productsInfo.push(productInfo);
      } else {
        filteredSDC[uniqueId] = { ...sdc, productsInfo: [productInfo] };
        sdcConditionRepetitions.push(uniqueId);
      }
    })
  });

  const rows = Object.values(filteredSDC).map(element => {
    const subtipo = conditionsById[element.id_condicao].subtipo;
    const nome = conditionsById[element.id_condicao].nome;
    return { ...element, subtipo, nome };
  });

  const rowsSorted = sortBy(rows, ['subtipo']);

  return (
    <React.Fragment>
      <BaseTable
        title={'CONDIÇÕES DO PRODUTO'}
        columns={columns(user, units)}
        data={descriptorId ? rowsSorted : []}
        paging={false}
        options={{
          // toolbar: false,
          // search: false,
          maxBodyHeight: TABLE_HEIGHT,
          minBodyHeight: TABLE_HEIGHT,
          rowStyle: () => ({ backgroundColor: theme.palette.background.veryLight })
        }}
        detailPanel={[
          () => ({
            icon: tableIcons.Products,
            tooltip: 'Produtos',
            render: rowData => <ProducListDetailPanel row={rowData}/>
          })
        ]}
      />
    </React.Fragment>
  )
};

const makemapStateToProps = () => {
  const units = lookUpIdValueSelector('units', ['nome']);
  const mapStateToProps = (state, props) => ({
    conditionsById: state.conditions.dataById,
    productsById: state.products.dataById,
    scenariosById: state.scenarios.dataById,
    scenarioDescriptorConditionsData: state.scenarioDescriptorConditions.data,
    units: units(state),
    user: state.profile.entity
  })
  return mapStateToProps
};

export default connect(makemapStateToProps)(Component);
