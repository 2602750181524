import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/products';
import { setContextSearchProduct } from 'ducks/ui';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/products/productComposition';
import { getImmutableStoreById, getImmutableStoreData } from 'selectors/generic';
import theme from 'source/theme';
import { MIXTURE } from 'utils/constants/coreConstants';
import { DISTRIBUIDOR, IMPORTADOR } from 'utils/constants/papel_reach';
import tableIcons, { DistributionIcon } from 'utils/icons/materialTableIcons';
import productColumns from 'utils/tables/columns/initialProduct';
import validator from 'utils/tables/validators/product';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const { contextSearch, products, rawMaterialsById, user } = props;
  const { setInOutFinalRM, setProductId, setSearch, update } = props;
  const tableRef = React.useRef(null);

  const [loading, setLoading] = useState(false);

  const exportPDF = () => {
    const filteredTableData = tableRef.current.state.data;
    pdfMake.createPdf(docDefinition(filteredTableData, rawMaterialsById)).open();
  };

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={productColumns(user)}
        data={products}
        onRowClick={() => null}
        isLoading={loading}
        onSearchChange={ text => setSearch(text)}
        options={{
          actionsCellStyle: {
            width: 200,
            paddingLeft: 20
          },
          searchText: contextSearch
        }}
        editable={{
          onRowUpdate: (newData, oldData) => {
            setLoading(true);
            const validation = validator(newData, products)
            if (validation.error) {
              setLoading(false);
              alertError(validation.error)
              return Promise.resolve();
              // TODO Instead of rejecting we are resolving, should be rejecting. Have to update library later github issue #2086
            } else {
              return update(newData.id, newData)
                .catch((e) => alert(e))
                .finally(() => setLoading(false))
            }
          }
        }}
        actions={[
          rowData => {
            if (
              rowData.papel_reach === DISTRIBUIDOR ||
              rowData.papel_reach === IMPORTADOR
            ) {
              return null;
            }

            const matchProductRM = rowData.Produto_Mps.find(prm =>
              !prm.id_mistura_caminho_mp && prm.mp_formulacao && prm.id_mp.cod_mp !== '' && prm.id_mp.cod_mp !== null
            );
            return {
              icon: matchProductRM ?
                tableIcons.AddToCargo() :
                tableIcons.AddToCargo(theme.palette.error.main),
              tooltip: 'Carga',
              position: 'row',
              onClick: (event, rowData) => {
                setProductId(rowData.id);
                setInOutFinalRM('in');
              }
            }
          },
          rowData => {
            if (
              rowData.papel_reach === DISTRIBUIDOR ||
              rowData.papel_reach === IMPORTADOR
            ) {
              return null;
            }

            const matchProductsRM = rowData.Produto_Mps.find(prm =>
              !prm.mp_formulacao && prm.id_mistura_caminho_mp === null && rawMaterialsById[prm.id_mp.id].tipo !== MIXTURE
            );

            return {
              icon: matchProductsRM ?
                tableIcons.Formulation() :
                tableIcons.Formulation(theme.palette.secondary.light),
              tooltip: 'Reacção',
              position: 'row',
              onClick: (event, rowData) => {
                setProductId(rowData.id);
                setInOutFinalRM('out');
              }
            }
          },
          rowData => rowData.papel_reach === DISTRIBUIDOR || rowData.papel_reach === IMPORTADOR ?
            null :
            {
              icon: tableIcons.FinalProduct(),
              tooltip: 'Produto Final',
              position: 'row',
              onClick: (event, rowData) => {
                setProductId(rowData.id);
                setInOutFinalRM('final');
              }
            },
          rowData => {
            if (!(rowData.papel_reach === DISTRIBUIDOR || rowData.papel_reach === IMPORTADOR)) {
              return null;
            }

            const matchProductsRM = rowData.Produto_Mps.find(prm =>
              !prm.mp_formulacao && prm.id_mistura_caminho_mp === null && rawMaterialsById[prm.id_mp.id].tipo !== MIXTURE
            );

            return {
              icon: matchProductsRM ?
                tableIcons.Distribution() :
                tableIcons.Distribution(theme.palette.error.main),
              tooltip: 'Distribuição',
              position: 'row',
              onClick: (event, rowData) => {
                setProductId(rowData.id);
                setInOutFinalRM('distr');
              }
            };
          },
          {
            isFreeAction: true,
            icon: tableIcons.Pdf,
            tooltip: 'Exportar PDF',
            onClick: exportPDF
          }
        ]}
      />
    </Grid>
  )
};


const makeMapStateToProps = () => {
  const immutableProducts = getImmutableStoreData('products');
  const rawMaterialsById = getImmutableStoreById('rawMaterials')
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchProduct,
    products: immutableProducts(state),
    rawMaterialsById: rawMaterialsById(state),
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  setSearch: search => dispatch(setContextSearchProduct(search)),
  update: (id, data) => dispatch(actions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
