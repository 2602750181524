import SelectableTable from 'components/Tables/SelectableTable';
import React from 'react';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';

export default (rowData, onSelection, scenarios, lcsById) => {
  const columns = [
    { title: 'Código', field: 'codigo' },
    { title: 'LCS', field: 'lcs_name' },
    { title: 'Designação', field: 'designacao' }
  ];

  const productScenarios = scenarios.filter(s => s.id_produto && s.id_produto == rowData.id);

  let augmentedScenarios = []
  productScenarios.forEach( scenario => {
    augmentedScenarios.push({
      ... scenario,
      lcs_name: lcsById[scenario.id_lcs].descricao
    });
  })

  return ({
    icon: tableIcons.Descriptors,
    tooltip: 'Visualizar cenários',
    render: () =>
      <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
        <SelectableTable
          title='Cenários Produto'
          currentRow={{}}
          columns={columns}
          rows={augmentedScenarios}
          onSelection={onSelection}
        />
      </div>
  })
}
