import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columns = [
  { title: 'Nome', field: 'nome', label: 'Nome', key: 'nome' },
  { title: 'CAS', field: 'cas', label: 'CAS', key: 'cas' },
  { title: 'EC', field: 'ec', label: 'EC', key: 'ec' },
  { title: 'SVHC descr.', field: 'svhc_descricao', label: 'SVHC descrição', key: 'svhc_descricao' },
  { title: 'SVHC inclusão', field: 'svhc_razao_inclusao', label: 'SVHC inclusão', key: 'svhc_razao_inclusao' },
  { title: 'SVHC data', field: 'svhc_data_inclusao', label: 'SVHC data inclusão', key: 'svhc_data_inclusao' },
  { title: 'Restrições descr.', field: 'restricoes_descricao', label: 'Restrições descrição', key: 'restricoes_descricao' },
  { title: 'Restrições entrada', field: 'restricoes_entrada', label: 'Restrições entrada', key: 'restricoes_entrada' },
  { title: 'Autorização data', field: 'autorizacao_data_limite', label: 'Autorização data limite', key: 'autorizacao_data_limite' },
  { title: 'Autorização isenções', field: 'autorizacao_isencoes', label: 'Autorização isenções', key: 'autorizacao_isencoes' }
];

export default props => {
  const { rowData, data, title } = props;
  // TODO: Solve on the backend API is not sending only ids in some cases sends Objects with full entity
  // cons rows = rowData.map(id => data[id]);
  let rows = [];
  if (typeof rowData[0] === 'number' || typeof rowData[0] === 'string') {
    rows = rowData.map(id => data[id]);
  } else {
    rows = rowData.map(obj => data[obj.id_substancia]);
  }

  rows = rows.map(row => {
    if (row.restricoes_entrada && Array.isArray(row.restricoes_entrada)) {
      return {
        ...row,
        restricoes_entrada: (
          <div>
            {
              row.restricoes_entrada.map((value, index) =>
                <p key={index} style={{ backgroundColor: index % 2 ? 'lightGrey' : 'white' }}>{value}</p>
              )
            }
          </div>
        ),
        restricoes_descricao: (
          <div>
            {
              row.restricoes_descricao.map((value, index) =>
                <p key={index} style={{ backgroundColor: index % 2 ? 'lightGrey' : 'white' }}>{value === '' ? '-' : value}</p>
              )
            }
          </div>
        )
      }
    }
    return row
  });

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={rows} title={title}/>
    </div>
  )
}
