import { sortBy } from 'lodash';
import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';
import { F, F_ID, IS, IS_ID, lcsNames, M, M_ID } from 'utils/constants/lcsTypes';

const columnSteps = flow => {
  const columnSteps = [];
  const steps = sortBy(flow.etapas, n => n.nome);
  steps.forEach(step => {
    if (step.id_master_etapa === null) {
      if (step.subetapas.length > 0) {
        step.subetapas.forEach(substep => columnSteps.push(substep));
      } else {
        columnSteps.push(step)
      }
    }
  })
  return columnSteps;
};

const rowGenerator = (data, flow, stepsRMs) => {
  const cSteps = columnSteps(flow);
  const rows = [];

  data.forEach(mp => {
    const stepsRM = stepsRMs
      .filter(srm => srm.id_mp === mp.id)
      .map(s => s.id_etapa);
    if (stepsRM.length > 0) {
      // Only put substances where there is some intersection between LCS and step for the FLOW
      //  Look at line where we iterate matchs -> ...cSteps.map(cs => stepsRM.includes(cs.id) ? 'x' : '-')
      if ( cSteps.some(cs => stepsRM.includes(cs.id)) ) {
        rows.push([
          {
            text: [
              { text: mp.cod_mp, color: theme.palette.primary.main },
              ' - ',
              mp.mp_nome
            ]
          },
          ...cSteps.map(cs => stepsRM.includes(cs.id) ? 'x' : '-')
        ]);
      }
    }
  })
  return rows;
};

const infoGenerator = (flow) => {
  const preTableInfo = [];
  preTableInfo.push({ style: 'header_small', text: 'Legenda etapas:' });

  const steps = sortBy(flow.etapas, n => n.nome);
  steps.forEach(step => {
    if (step.id_master_etapa === null) {
      preTableInfo.push({ style: 'list', text: `${step.nome} - ${step.descricao}` });
      if (step.subetapas.length > 0) {
        step.subetapas.forEach(substep =>
          preTableInfo.push({ style: 'sublist', text: `${substep.nome} - ${substep.descricao}` })
        );
      }
    }
  })
  return preTableInfo;
}

const dd = (data, flow, stepRM) => {

  // Filter bu descriptor Types M, F, IS
  const stepRM_F = stepRM.filter(srm => srm.descriptors && srm.descriptors.includes(F));
  const stepRM_M = stepRM.filter(srm => srm.descriptors && srm.descriptors.includes(M));
  const stepRM_IS = stepRM.filter(srm => srm.descriptors && srm.descriptors.includes(IS));

  const columnStepNames = columnSteps(flow).map(s => s.nome);

  return ({
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [ 40, 60, 40, 40 ],

    footer: function (currentPage, pageCount) {
        return {
          text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
          alignment: 'right',
          relativePosition: { x: -15, y: 20 },
          fontSize: 7
        }
    },
    header: function (currentPage, pageCount, pageSize) {
      return [
        {
          image: newLogoReachOk64,
          width: 50,
          relativePosition: { x: 20, y: 10 }
        }
      ]
    },
    content: [
      {
        style: 'header',
        text: `R - Fluxogramas e Etapas: ${flow.nome}` //title,
      },
      ... infoGenerator(flow),
      {
        style: 'header2',
        text: `LCS - ${lcsNames[F_ID]}`
      },
      {
        style: 'tableExample',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 2,
          widths: [150, ...Array(columnStepNames.length).fill('*')],
          body: [
            [
              { text: '' },
              { text: 'Etapas', colSpan: columnStepNames.length - 1, alignment: 'center' },
              ...Array(columnStepNames.length - 1).fill({})
            ],
            ['Matérias Primas', ...columnStepNames],
            ...rowGenerator(data, flow, stepRM_F)
          ]
        }
      },
      {
        style: 'header2',
        text: `LCS - ${lcsNames[M_ID]}`
      },
      {
        style: 'tableExample',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 2,
          widths: [150, ...Array(columnStepNames.length).fill('*')],
          body: [
            [
              { text: '' },
              { text: 'Etapas', colSpan: columnStepNames.length - 1, alignment: 'center' },
              ...Array(columnStepNames.length - 1).fill({})
            ],
            ['Matérias Primas', ...columnStepNames],
            ...rowGenerator(data, flow, stepRM_M)
          ]
        }
      },
      {
        style: 'header2',
        text: `LCS - ${lcsNames[IS_ID]}`
      },
      {
        style: 'tableExample',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 2,
          widths: [150, ...Array(columnStepNames.length).fill('*')],
          body: [
            [
              { text: '' },
              { text: 'Etapas', colSpan: columnStepNames.length - 1, alignment: 'center' },
              ...Array(columnStepNames.length - 1).fill({})
            ],
            ['Matérias Primas', ...columnStepNames],
            ...rowGenerator(data, flow, stepRM_IS)
          ]
        }
      }
    ],

    styles: {
      header: {
        color: theme.palette.primary.main,
        bold: true,
        fontSize: 16,
        alignment: 'center',
        marginTop: 15
      },
      header2: {
        color: theme.palette.primary.main,
        bold: true,
        fontSize: 12,
        alignment: 'center',
        marginTop: 15
      },
      header_small: {
        fontSize: 8,
        bold: true,
        alignment: 'left'
      },
      list: {
        fontSize: 8,
        alignment: 'left'
      },
      sublist: {
        fontSize: 8,
        alignment: 'left',
        marginLeft: 10
      },
      tableExample: {
        margin: [0, 15, 0, 0],
        fontSize: 8
      }
    }
  })
};

export default dd;
