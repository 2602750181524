import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';

const Component = props => {
  const { options, value } = props;
  const { onChange } = props;

  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <RadioGroup
      //ref={radioGroupRef}
      aria-label="ringtone"
      name="ringtone"
      value={value}
      onChange={handleChange}
    >
      {options.map(option => (
        <FormControlLabel
          value={option}
          key={option}
          control={<Radio color='primary'/>}
          label={option}
        />
      ))}
    </RadioGroup>
  );
};

Component.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
};

export default Component;
