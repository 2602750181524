import React from 'react';
import XLSX from 'xlsx';


const columns = [
  {
    title: 'Código MP',
    field: 'cod_mp',
    nested: 'ignore'
  },
  {
    title: 'Nome',
    field: 'mp_nome'
  },
  {
    title: 'Percentagem',
    field: 'percentagem',
    nested: true
  },
  {
    title: 'CAS',
    field: 'cas'
  },
  {
    title: 'EC',
    field: 'ec'
  },
  {
    title: 'Perigosa',
    field: 'perigosa',
    render: value => value ? 'Sim' : 'Não'
  }
];

export default (mixtures, rawMaterialsById, fileName) => () => {

  let sheetData = [];

  mixtures.current.state.data.forEach(mixture => {
    const sheetMixture = {};
    // MIXTURE
    columns.forEach(column => {
      if (Array.isArray(mixture[column.field])) {
        sheetMixture[column.field] = mixture[column.field] && mixture[column.field].length !== 0 ?
          mixture[column.field].join(', ') : '';
      } else if (column.render) {
        sheetMixture[column.field] = column.render(mixture[column.field]);
      } else {
        sheetMixture[column.field] = mixture[column.field];
      }
    });
    sheetData.push(sheetMixture)

    // SUBSTANCDES OF MIXTURE COMPOSITION
    const mixtureComposition = rawMaterialsById[mixture.id].inter_substancias.map(interSubstance => {

      console.log('interSubstance', interSubstance);
      const substance = rawMaterialsById[interSubstance.id_substancia];
      const sheetSubstance = {};
      // SUBSTANCE
      columns.forEach(column => {
        if (column.nested !== 'ignore') {
          if (Array.isArray(substance[column.field])) {
            sheetSubstance[column.field] = substance[column.field] && substance[column.field].length !== 0 ?
              substance[column.field].join(', ') : '';
          } else if (column.render) {
            sheetSubstance[column.field] = column.render(substance[column.field]);
          } else if (column.nested) {
            sheetSubstance[column.field] = parseFloat(interSubstance[column.field]);
          } else {
            sheetSubstance[column.field] = substance[column.field];
          }
        }
      });
      return sheetSubstance
    })

    sheetData = [...sheetData, ...mixtureComposition]
  })
  /* make the worksheet */
  var ws = XLSX.utils.json_to_sheet(sheetData);
  /* add to workbook */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Data');
  /* generate an XLSX file */
  XLSX.writeFile(wb, fileName ? fileName : 'exportReachok.xlsx');
};
