const Joi = require('@hapi/joi');
import React from 'react';
import { MIXTURE, POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';

const baseObject = {
  cod_mp: '',
  mp_nome: '',
  tipo: SUBSTANCE,
  cas: '',
  ec: '',
  observacoes: '',
  perigosa: false
};

export const rawMaterialsSchema = Joi.object({
  cod_mp: Joi.string()
    .min(3)
    .max(60)
    .pattern(/^[a-zA-Z0-9\-_\s]+$/)
    .empty('')
    .required()
    .messages({
      "any.required": `"Código MP" campo de preenchimento obrigatório`,
      "string.empty": `"Código MP" campo vazio`,
      "string.min": `"Código MP" tem de ter pelo menos 3 caracteres`,
      "string.max": `"Código MP" só pode 60 caracteres máximo`,
      "string.pattern.base": `"Código MP" só pode usar caracteres alfanuméricos e "-_ "`
    }),
  //mp_nome: Joi.string().min(3).max(250).pattern(/^[a-zA-Z0-9\-_\s]+$/).required()
  mp_nome: Joi.string()
    .min(3)
    .max(250)
    .required()
    .messages({
      "any.required": `"Nome" campo de preenchimento obrigatório`,
      "string.empty": `"Nome" campo vazio`,
      "string.min": `"Nome" tem de ter pelo menos 3 caracteres`,
      "string.max": `"Nome" só pode 250 caracteres máximo`
      //"string.pattern.base": `"Nome" só pode usar caracteres alfanuméricos e "-_ "`
    }),
  tipo: Joi.string()
    .valid(SUBSTANCE, MIXTURE, POLIMER)
    .required()
    .messages({
      "any.required": `"Tipo" campo de preenchimento obrigatório`,
      "string.empty": `"Tipo" campo vazio`,
      "any.only": `"Tipo" Só pode ser "Substancia", "Mistura" e "Polímero"`
    }),
  cas: Joi.string()
    .allow('')
    .pattern(/^([0-9]+\-){2}([0-9]+){1}$|^(([0-9]+\-){2}([0-9]+){1},)+([0-9]+\-){2}([0-9]+){1}$/)
    .messages({
      "string.pattern.base": `"CAS" tem de usar o seguinte padrão "Ndigitos-Ndigitos-Ndigitos" separados por ",". Também pode não ser preenchido.. Exemplos: 34523-453-2345 ou 34523-453-2345, 32-34-34, 3453-565-456`
    }),
  ec: Joi.string()
    .pattern(/^([0-9]{3}\-){2}[0-9]{1}$/)
    .allow('')
    .messages({
      "string.pattern.base": `"EC" tem de usar o seguinte padrão "3digitos-3digitos-1digitos" apenas ou é aceite ou então pode não ser preenchido. Exemplos: 345-453-2 ou 123-123-1`
    }),
  observacoes: Joi.string()
    .allow(null)
    .empty(''),
  // perigosa: Joi.boolean().required()
  perigosa: Joi.boolean()
    .required()
    .messages({
      "any.required": `"Perigosa" campo de preenchimento obrigatório`,
      "boolean.empty": `"Perigosa" campo vazio`,
      "boolean.base": `"Perigosa" campo de preenchimento obrigatório`
    })
});

const MATCH_CAS = /[0-9]+\-[0-9]+\-[0-9]+/g;
export const convertCasToArray = cas => Array.from(cas.matchAll(MATCH_CAS)).map(element => element[0]);

const rawMaterialValidator = (newRawMaterial, rawMaterials) => {
  let error = undefined;
  let iRawMaterial = { ...baseObject, ...newRawMaterial };

  const baseObjectKeys = Object.keys(baseObject);
  Object.keys(iRawMaterial).forEach(k => {
    if (!baseObjectKeys.includes(k)) {
      delete iRawMaterial[k];
    }
  })

  if (Array.isArray(iRawMaterial.ec)) {
    iRawMaterial.ec = iRawMaterial.ec.length === 0 ? '' : iRawMaterial.ec[0];
  }
  if (Array.isArray(iRawMaterial.cas)) {
    iRawMaterial.cas = iRawMaterial.cas.length === 0 ? '' : iRawMaterial.cas.join(',');
  }

  // Validate data correctness
  const validateRawMaterial = rawMaterialsSchema.validate(iRawMaterial, { abortEarly: false });
  if (validateRawMaterial.error) {
    return { error: validateRawMaterial.error.details.map(obj => obj.message) };
  }

  // Preparing to respect data used
  iRawMaterial.cas = convertCasToArray(iRawMaterial.cas);
  iRawMaterial.ec = iRawMaterial.ec === '' ? [] : [iRawMaterial.ec];

  // Compare if rest of restrictions are respected
  if (iRawMaterial.tipo === MIXTURE && (iRawMaterial.cas.length > 0 || iRawMaterial.ec.length > 0)) {
    return { error: 'Misturas não têm CAS nem EC' }
  }

  if (iRawMaterial.tipo === SUBSTANCE && iRawMaterial.cas.length === 0 && iRawMaterial.ec.length === 0) {
    return { error: 'Substâncias têm de possuir pelo menos um CAS ou EC' }
  }

  if (iRawMaterial.tipo === POLIMER) {
    if (iRawMaterial.cas.length === 0) {
      return { error: 'Polímeros têm de ter pelo menos um CAS' }
    }
  }

  rawMaterials.some(rm => {
    if (!newRawMaterial.id || (newRawMaterial.id && newRawMaterial.id !== rm.id)) { // Validation of a create or an update operation!

      /** Case trying to add a mixture **/
      if (iRawMaterial.tipo === MIXTURE) {
        if (rm.cod_mp === iRawMaterial.cod_mp) {
          error = { error: 'Codigo MP repetido!' }
          return true;
        } else if (rm.mp_nome === iRawMaterial.mp_nome) {
          error = { error: 'Nome MP repetido!' }
          return true;
        } else {
          return false;
        }

        /** Case trying to add a substance or polimer **/
      } else if (iRawMaterial.tipo === SUBSTANCE || iRawMaterial.tipo === POLIMER) {
        if (rm.cod_mp === iRawMaterial.cod_mp) {
          error = { error: 'Codigo MP repetido!' }
          return true;
        } else if (
          (
            (rm.ec.length === 1 && iRawMaterial.ec.length === 1 && rm.ec[0] === iRawMaterial.ec[0]) &&
            (iRawMaterial.cas.length !== 0 && rm.cas.length === iRawMaterial.cas.length && iRawMaterial.cas.map(newCas => rm.cas.includes(newCas)).every(a => a))
          ) ||
          (
            (rm.ec.length === 1 && iRawMaterial.ec.length === 1 && rm.ec[0] === iRawMaterial.ec[0]) &&
            (rm.cas.length === 0 && iRawMaterial.cas.length === 0)
          ) ||
          (
            (rm.ec.length === 0 && iRawMaterial.ec.length === 0) &&
            (iRawMaterial.cas.length !== 0 && rm.cas.length === iRawMaterial.cas.length && iRawMaterial.cas.map(newCas => rm.cas.includes(newCas)).every(a => a))
          )
        ) {
          if (rm.cod_mp === null || rm.cod_mp === '') {
            error = {
              confirm: () => <div>
                <p>Existe uma substância com nome <b>{rm.mp_nome}</b> presente na Base de Dados com os mesmos CAS e EC.</p>
                <p>Pretende sobrepor o nome <b>{iRawMaterial.mp_nome}</b> ou manter <b>{rm.mp_nome}</b>?</p>
              </div>,
              newData: iRawMaterial,
              oldData: rm
            }
            return true;
          } else {
            error = { error: 'Já existe uma MP com o mesmo CAS e EC!'}
            return true;
          }
        } else if (rm.mp_nome === iRawMaterial.mp_nome) {
          error = { error: 'Nome MP repetido!' }
          return true;
        }
      }
    }
    return false
  })

  return error ? error : iRawMaterial;
};

export default rawMaterialValidator;
