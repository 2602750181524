import cloneDeep from 'lodash/cloneDeep';
import { AUTORIZACAO, CAS, DESCRIPTION_RESTRICTIONS, EC, ENTRYNO, RESTRICOES, SVHC } from 'utils/echa/constants';
import echaColumns from 'utils/echa/columns';


export const sanitizeAUTORIZACAO = {
  eliminateEmptyRows: true,
  purgeSingularUndefined: true,
  typeCoercer: true,
  trimProps: [CAS, EC],
  repeatCasRow: true,
  fieldAlias: [
    { field: 'DescriptionAutorizacao', colRename: 'Description' },
    { field: 'autorizacaoEntrada', colRename: ENTRYNO }
  ],
  trimIfens: true,
  EcCasToArray: true,
  fillEmptyFields: true
};
export const sanitizeSVHC = {
  eliminateEmptyRows: true,
  purgeSingularUndefined: true,
  typeCoercer: true,
  trimProps: [CAS, EC],
  repeatCasRow: true,
  fieldAlias: [
    { field: 'DescriptionSvhc', colRename: 'Description' }
  ],
  trimIfens: true,
  EcCasToArray: true,
  fillEmptyFields: true
};
export const sanitizeRESTRICOES = {
  eliminateEmptyRows: true,
  purgeSingularUndefined: true,
  typeCoercer: true,
  trimProps: [CAS, EC, ENTRYNO],
  repeatCasRow: true,
  fieldAlias: [
    { field: 'DescriptionRestricoes', colRename: 'Description' }
  ],
  entryNoMerge: true,
  trimIfens: true,
  EcCasToArray: true,
  fillEmptyFields: true
};

export const sanitizedEcha = (echa, type) => {
  let sanitizedEcha = cloneDeep(echa);
  let sanitizeOptions = {};

  switch (type) {
    case SVHC:
      sanitizeOptions = sanitizeSVHC;
      break;
    case AUTORIZACAO:
      sanitizeOptions = sanitizeAUTORIZACAO;
      break;
    case RESTRICOES:
      sanitizeOptions = sanitizeRESTRICOES;
      break;
  }

  /**
   * ATTENTION: sanitization order is relevant because of operations performed
   * do not change without care. To improve should split sanitization in modules
   * and call in the correct order at a higher level
   */
  if( sanitizeOptions.eliminateEmptyRows) {
    sanitizedEcha = sanitizedEcha.filter(row => {
      const props = Object.keys(row);
      const isRow = props.map( prop => {
        return row[prop] === undefined
      })
      return isRow.every(v => v) ? undefined : row;
    })
  }

  if( sanitizeOptions.purgeSingularUndefined) {
    const props = Object.keys(sanitizedEcha[0]);
    sanitizedEcha = sanitizedEcha.map(row => {
      props.forEach( prop => {
        if(row[prop] === undefined) {
          row[prop] = '';
        }
      })
      return row;
    })
  }


  if (sanitizeOptions.typeCoercer) {
    const props = Object.keys(sanitizedEcha[0]);
    sanitizedEcha = sanitizedEcha.map(row => {
      props.forEach( prop => {
        if (typeof row[prop] !== 'string') {
          row[prop] = row[prop].toString();
        }
      })
      return row;
    })
  }

  if (sanitizeOptions.trimProps) {
    sanitizedEcha.forEach(row => {
      // TODO they are not all strings

      sanitizeOptions.trimProps.forEach(prop => {
        if (typeof row[prop] === 'string') {
          row[prop] = row[prop].replace(/ /g, '')
        }
      })
    })
  }

  // When there are several CAS on the same Row, separate Rows are created for each
  if (sanitizeOptions.repeatCasRow) {
    const elementsToPush = [];
    const elementsToDelete = [];
    sanitizedEcha.forEach((row, index) => {
      let startPosition = [];
      let n = 0;

      // Finding multiple CAS
      n = row[CAS].indexOf(',', 0);
      while (n !== -1) {
        startPosition.push(n);
        n = row[CAS].indexOf(',', n + 1);
      }
      // Duplicating rows if multiple CAS
      if (startPosition.length !== 0) {
        elementsToDelete.push(index);
        let k = 0;
        elementsToPush.push({ ...row, [CAS]: row[CAS].slice(0, startPosition[k]) });
        while (k !== startPosition.length) {
          // last index[k+1] is undefined, so selects until the end
          elementsToPush.push({ ...row, [CAS]: row[CAS].slice(startPosition[k] + 1, startPosition[k + 1]) });
          k++;
        }
      }
    });
    elementsToDelete.forEach((k, index) => sanitizedEcha.splice(k - index, 1));
    elementsToPush.forEach(element => sanitizedEcha.push(element));
  }


  // Allows to transform repeated column headers to an alias, sometimes imported files have equal names headers
  if (sanitizeOptions.fieldAlias) {
    sanitizedEcha.forEach(row => {
      sanitizeOptions.fieldAlias.forEach(field => {
        row[field.field] = row[field.colRename];
        delete row[field.colRename];
      })
    });
  }

  // Merges Multiple rows with same CAS and EC and diferent EntryNo
  if (sanitizeOptions.entryNoMerge) {
    const rowsMatched = [];
    const sEcha = sanitizedEcha;
    const newSEcha = [];

    for (let i = 0; i < sEcha.length; i++) {
      const newRow = {
        ...sEcha[i],
        [ENTRYNO]: [sEcha[i][ENTRYNO]],
        [DESCRIPTION_RESTRICTIONS]: [sEcha[i][DESCRIPTION_RESTRICTIONS]]
      }
      if (
        sEcha[i][ENTRYNO] !== '' &&
        (sEcha[i][EC] !== '-' && sEcha[i][EC] !== '') &&
        (sEcha[i][CAS] !== '-' && sEcha[i][CAS] !== '') &&
        !rowsMatched.includes(i)
      ) {
        for (let n = i + 1; n < sEcha.length; n++) {
          if (sEcha[i][EC] === sEcha[n][EC] && sEcha[i][CAS] === sEcha[n][CAS] && !rowsMatched.includes(n)) {
            newRow[ENTRYNO].push(sEcha[n][ENTRYNO]);
            newRow[DESCRIPTION_RESTRICTIONS].push(sEcha[n][DESCRIPTION_RESTRICTIONS]);
            rowsMatched.push(n);
          }
        }
        newSEcha.push(newRow);
      } else if (!rowsMatched.includes(i)) {
        newSEcha.push(newRow);
      }
    }
    sanitizedEcha = newSEcha;
  }

  // TrimIfens
  if (sanitizeOptions.trimIfens) {
    sanitizedEcha.forEach(echa => {
      Object.keys(echa).forEach(field => {
        if (Array.isArray(echa[field])) {
          echa[field] = echa[field].map(value => value.replace(/^-$/g, ''))
        } else if (typeof echa[field] === 'string') {
          echa[field] = echa[field].replace(/^-$/g, '')
        }
      })
    })
  }

  // Transform CAS and EC to array
  if (sanitizeOptions.EcCasToArray) {
    sanitizedEcha.forEach(echa => {
      // echa[CAS] = echa[CAS].length === 0 ? [] : [echa[CAS]];
      // echa[EC] = echa[EC].length === 0 ? [] : [echa[EC]];
      echa[CAS] = [echa[CAS]];
      echa[EC] = [echa[EC]];
    })
  }

  // Transform RESTRICTION ENTRY and DESCRIPTION to array if they are not yet
  if (sanitizeOptions.fillEmptyFields) {
    let columns = [];
    switch (type) {
      case SVHC:
        columns = echaColumns.svhcCols;
        break;
      case AUTORIZACAO:
        columns = echaColumns.autorizacaoCols;
        break;
      case RESTRICOES:
        columns = echaColumns.restricoesCols;
        break;
    }
    sanitizedEcha.forEach(echa => {
      echaColumns.echaColumns.forEach( ({ field , init}) => {
        if (!echa[field] && init === 'array') {
          echa[field] = [''];
        } else if (!echa[field]) {
          echa[field] = '';
        }
      })
    })
  }

  return sanitizedEcha;
};

export default sanitizedEcha;
