export default [
  {
    title: 'Descritor',
    field: 'codigo',
    editable: 'never',
    width: 200,
    headerStyle: {
      textAlign: 'left',
      paddingLeft: 50
    },
    cellStyle: {
      textAlign: 'left',
      paddingLeft: 50
    }
  },
  {
    title: 'Descrição',
    field: 'descricao',
    editable: 'never',
    width: 600,
    headerStyle: {
      textAlign: 'left'
    },
    cellStyle: {
      textAlign: 'left'
    },
  }
];

