import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import theme from 'source/theme';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    paddingLeft: 75
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles({
  title: {
    backgroundColor: theme.palette.background.veryLight,
    padding: '7px 0px 7px 16px',
    flex: '1 1 100%'
  },
  tableBody: {
    cursor: 'pointer'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.background.veryLight
    }
  }
});

export default props => {
  const { currentRow, columns, rows, title } = props;
  const { onSelection } = props;
  const classes = useStyles();

  const [selectedRow, setSelectedRow] = useState(currentRow);
  const handleSelectRow = row => () => {
    setSelectedRow(row);
    onSelection(row);
  }

  const cellValue = (row, column) => {
    if (column.field) {
      if (column.nested) {
        return column.render ? column.render(row[column.nested][column.field]) : row[column.nested][column.field]
      } else {
        return column.render ? column.render(row[column.field]) : row[column.field]
      }
    } else {
      return ''
    }
  }

  return (
    <TableContainer component={Paper}>
      {
        title &&
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {title}
        </Typography>
      }
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(column =>
              <StyledTableCell key={column.title}>{column.title}</StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {rows.map(row => (
              <TableRow
                key={row.id}
                className={classes.tableRow}
                style={
                  selectedRow.id === row.id ?
                    { backgroundColor: theme.palette.primary.main } :
                    null
                }
                onClick={handleSelectRow(row)}
              >
                {columns.map((column, index) =>
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    style={{ paddingLeft: 75 }}
                  >
                    {cellValue(row, column)}
                  </TableCell>
                )}
              </TableRow>
            )
          )}
          <TableRow>
            {columns.map(col => <TableCell key={col.field}/>)}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
