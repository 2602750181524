import cloneDeep from 'lodash/cloneDeep';

const Joi = require('@hapi/joi');

const schema = {
  cod_fornecedor: Joi.number().required(),
  fornecedor: Joi.string().min(3).max(250).required(),
  ativo: Joi.string().required()
};

const rootSchema = Joi.array().min(1)
  .items(Joi.object(schema))
  .required();

export const validate = (data, suppliers) => {
  const validation = rootSchema.validate(data, { abortEarly: false });

  const fornecedores = [];
  const cod_fornecedor = [];
  data.forEach(v => {
    fornecedores.push(v.fornecedor);
    cod_fornecedor.push(v.cod_fornecedor);
  });


  const error = (entity, index, value, msg)  => ({
    context: {
      key: entity,
      label: `[${index}].${entity} repetido`,
      value: value
    },
    message: `"${entity}" ${msg ? msg : ' valor repetido!'}`
  });

  const logRepetitions = (data, entity) => {
    const auxData = [...data];
    for (let i = 0; i < data.length; i += 1) {
      auxData.shift();
      const index = auxData.indexOf(data[i])
      if (index !== -1) {
        const iError = error(entity,i + index + 1, data[i]);
        if (validation.error) {
          validation.error.details.push(iError);
        } else {
          validation.error = { details: [iError] };
        }
      }
    }
  }

  // Verify repetitions inside imported file
  logRepetitions(fornecedores, 'fornecedor');
  logRepetitions(cod_fornecedor, 'codigo fornecedor');

  // Verify repetitions against existing suppliers
  suppliers.forEach(sup => {
    data.forEach( (newSup, index) => {
      if (sup.fornecedor === newSup.fornecedor) {
        const iError = error('fornecedor', index, newSup.fornecedor,' existe na BD de Fornecedores');
        if (validation.error) {
          validation.error.details.push(iError);
        } else {
          validation.error = { details: [iError] };
        }
      }
      if (sup.cod_fornecedor === parseInt(newSup.cod_fornecedor)) {
        const iError = error('codigo fornecedor', index, newSup.cod_fornecedor,' existe na BD de Fornecedores');
        if (validation.error) {
          validation.error.details.push(iError);
        } else {
          validation.error = { details: [iError] };
        }
      }
    })
  })
  return validation
};

export const sanitize = (data) => {
  let sanitizedData = cloneDeep(data);
  let sanitizeOptions = {
    eliminateEmptyRows: true,
    ativoToBool: true
  };
  if( sanitizeOptions.eliminateEmptyRows) {
    sanitizedData = sanitizedData.filter(row => {
      const props = Object.keys(row);
      const isNotRow = props.map(prop => row[prop] === undefined).every(v => v)
      return isNotRow ? undefined : row;
    })
  }

  // Transform ativo to Bool
  if (sanitizeOptions.ativoToBool) {
    sanitizedData.forEach(row => {
      switch (row.ativo) {
        case 'True':
        case 'TRUE':
        case 'true':
        case true:
          row.ativo = 'true';
          break;
        case 'False':
        case 'false':
        case 'FALSE':
        case false:
          row.ativo = 'false';
          break;
      }
    })
  }
  return sanitizedData;
};

export default validate;
