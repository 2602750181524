import React from 'react';

// title and field properties are used in material table,
// label and key are used in CSV export

export default user => {
  const columns = [
    { title: 'Username', field: 'username' },
    { title: 'Primeiro nome', field: 'first_name' },
    { title: 'Último nome', field: 'last_name' },
    { title: 'E-mail', field: 'email' },
    { title: 'Contacto', field: 'contact' },
    {
      title: 'Credenciais de Gestão',
      field: 'manager',
      editable: 'never',
      render: rowData => {
        if (rowData.manager === 'true' || rowData.manager === true) {
          return <span style={{ color: 'blue' }}>Manager</span>;
        } else if (rowData.manager === 'false' || rowData.manager === false) {
          return <span style={{ color: 'grey' }}>Utilizador</span>;
        }
      },
    },
    { title: 'Conta cliente', field: 'account_name', editable: 'never' },
    { title: 'Ultimo Login', field: 'last_login', editable: 'never' }
  ];

  const columnsAdmin = [
    {
      title: 'Identificador',
      field: 'id',
      editable: 'never',
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};
