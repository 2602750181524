import React from 'react';
import theme from 'source/theme';

export default ({ row }) =>
  <div style={{ backgroundColor: theme.palette.background.light }}>
    <ul style={{ margin: 0, paddingTop: 10, paddingBottom: 10 }}>
      {
        row.flowsInfo.map(flow =>
          <React.Fragment key={flow.flow_code}>
            <li>{flow.flow_code + ' - ' + flow.flow_name}</li>
            <ul style={{ margin: 0, paddingTop: 2, paddingBottom: 2 }}>
              {
                row.stepsInfo[flow.flow_id].map(step =>
                  <li key={step.step_code}>{step.step_code + ' - ' + step.step_name}</li>
                )
              }
            </ul>
          </React.Fragment>
        )
      }
    </ul>
  </div>
