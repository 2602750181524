import BaseTable from 'components/Tables/BaseTable';
import SubstanceShipmentsListDetailPanel from 'pages/Evaluation/Product/ScenariosManagement/SubstanceShipmentsListDetailPanel';
import React from 'react';
import { connect } from 'react-redux';
import { lookUpIdValueSelector } from 'selectors/generic';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/evaluation/scenarioConditions';
import { TABLE_HEIGHT_SPLIT_4VIEW } from 'utils/ui/constants';

const TABLE_HEIGHT = TABLE_HEIGHT_SPLIT_4VIEW(64);

const Component = props => {
  const { descriptorId, units, user, augmentedConditionsByDescriptorId } = props;

  const rowsObject = descriptorId ? augmentedConditionsByDescriptorId[descriptorId] : []
  const rows = rowsObject ? Object.values(rowsObject).flat() : [];

  return (
    <React.Fragment>
      <BaseTable
        title={'CONDIÇÕES DO PRODUTO'}
        columns={columns(user, units)}
        data={rows}
        paging={false}
        options={{
          // toolbar: false,
          // search: false,
          maxBodyHeight: TABLE_HEIGHT,
          minBodyHeight: TABLE_HEIGHT,
          rowStyle: () => ({ backgroundColor: theme.palette.background.veryLight })
        }}
        detailPanel={[
          () => ({
            icon: tableIcons.Substance,
            tooltip: 'Produtos',
            render: rowData => <SubstanceShipmentsListDetailPanel row={rowData}/>
          })
        ]}
      />
    </React.Fragment>
  )
};

const makemapStateToProps = () => {
  const units = lookUpIdValueSelector('units', ['nome']);
  const mapStateToProps = (state, props) => ({
    conditionsById: state.conditions.dataById,
    productsById: state.products.dataById,
    scenariosById: state.scenarios.dataById,
    scenarioDescriptorConditionsData: state.scenarioDescriptorConditions.data,
    units: units(state),
    user: state.profile.entity
  })
  return mapStateToProps
};

export default connect(makemapStateToProps)(Component);
