import Selector from 'components/Buttons/ButtonRadioDialog'
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const Component = props => {
  const { currentOption, disabled } = props;
  const { onSelection } = props;

  return (
    <Selector
      buttonText='Produto: '
      currentOption={currentOption}
      disabled={disabled}
      header='Seleccione um produto'
      optionKey='produto_nome'
      options={props.products.data}
      optionsById={props.products.dataById}
      onSelection={onSelection}
    />

  )
};

const mapStateToProps = state => ({
  products: state.products
})

export default connect(mapStateToProps)(Component);

Component.propTypes = {
  currentOption: PropTypes.object.isRequired,
  onSelection: PropTypes.func.isRequired
};
