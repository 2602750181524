export default (user, units) => {
  const columns = [

    {
      title: 'Sub Tipo',
      field: 'subtipo',
      editable: 'never',
      width: 200,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      }
    },
    {
      title: 'Nome',
      field: 'nome',
      editable: 'never',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'left'
      }
    }

  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100
    },
    ...columns
  ];

  return user.is_staff ? columnsAdmin : columns;
};
