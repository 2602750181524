import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';

const dataMapping = [
  { id: 'cod_fornecedor', name: 'Código fornecedor' },
  { id: 'fornecedor', name: 'Nome' },
  { id: 'ativo', name: 'Estado', transform: value => value ? 'Activo' : 'Inactivo' }
];

const rowGenerator = (dataMapping, data) => {
  return data.map(item => {
    return dataMapping.map(field => {
      if (field.transform) {
        return item[field.id] === null ? '' : field.transform(item[field.id])
      } else {
        return item[field.id] === null ? '' : item[field.id]
      }
    })
  })
};

const dd = (data) => ({
  pageSize: 'A4',
  pageMargins: [40, 60, 40, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: 'R - Listagem de Fornecedores' //title,
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: [100, '*', 70],
        body: [
          dataMapping.map(i => i.name),
          ...rowGenerator(dataMapping, data)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 8
    }
  }

});

export default dd;
