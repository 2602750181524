import { MTableToolbar } from '@material-table/core';
import BaseTable from 'components/Tables/BaseTable';
import TabsBar from 'components/TabsBars/TabsBarsDescriptors';
import { alertError } from 'ducks/alerts';
import { actions as scenariosActions } from 'ducks/scenarios'
import { actions as scenariosDescriptorsActions } from 'ducks/scenariosDescriptors'
import { sortBy } from 'lodash';
import ConditionsDetailPanel from 'pages/Products/ProductScenarios/ConditionsDetailPanel';
import HeaderWSelectors from 'pages/Products/ProductScenarios/HeaderWSelectors';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/products/productsScenario';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/scenarioDescriptors';
import noIcon from 'utils/tables/noIcon';
import { TABLE_HEIGHT } from 'utils/ui/constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TABLE_HEIGHT_WITHOUT_HEADER = TABLE_HEIGHT(false, 48);

const Component = props => {
  const {
    conditions, descriptorTab, descriptors, descriptorFilter,
    scenarioDescriptors, lcs, lcsById, product, products, scenario,
    scenarioDescriptorConditions, units, user
  } = props;
  const {
    alertError, backToProductsTable, createScenarioDescriptor,
    removeScenarioDescriptor, setDescriptor, setDescriptorTab, setProduct, setLcs
  } = props;
  const tableRef = React.useRef(null);

  const filteredDescriptors = descriptors.data.filter(v => v.id_tipo === descriptorFilter);

  const filteredScenarioDescriptors = scenarioDescriptors.filter(sd =>
    descriptors.dataById[sd.id_descritor].id_tipo === descriptorFilter
  );

  const [selectedIds, setSelectedIds] = useState(filteredScenarioDescriptors.map(fsd => fsd.id_descritor));
  const [loading, setLoading] = useState(false);

  useEffect(
    () => setSelectedIds(filteredScenarioDescriptors.map(fsd => fsd.id_descritor)),
    [loading, lcs, product, descriptorTab]
  );

  const exportPDF = () => {
    pdfMake.createPdf(
      docDefinition(lcs, product, descriptors, conditions, scenario,
        scenarioDescriptors, scenarioDescriptorConditions, units))
      .open();
  }

  const exportPDFActions = [
    {
      position: 'toolbarOnSelect',
      icon: tableIcons.Pdf,
      tooltip: 'Exportar PDF',
      onClick: exportPDF
    },
    {
      position: 'toolbar',
      icon: tableIcons.Pdf,
      tooltip: 'Exportar PDF',
      onClick: exportPDF
    }
  ];

  const manageConditions = rowData => {
    if (rowData.tableData.checked) {
      setDescriptor(rowData)
    } else {
      setLoading(true);
      createScenarioDescriptor({ id_descritor: rowData.id, id_cenario: scenario.id })
        .then(() => setDescriptor(rowData))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }
  };

  // TODO THIS IS NOT CORRECT OR NEEDED WITH HEADER SELECTOR LIKE IT IS CURRENTLY
  if (!scenario) {
    alertError(
      "Cenário não criado para combinação Produto e Lcs selecionada. " +
      "Por favor crie Cenário de produto primeiro"
    );
    backToProductsTable();
  }

  // TODO Adding Checked info to table, there should be a better way
  const rows = filteredDescriptors.map(descriptor =>
    selectedIds.includes(descriptor.id) ?
      { ...descriptor, tableData: { checked: true } } :
      descriptor.tableData && descriptor.tableData.checked ?
        { ...descriptor, tableData: { checked: false } } : descriptor
  );
  const reOrderedRows = sortBy(rows, [o => !selectedIds.includes(o.id)]);

  return (
    <React.Fragment>
      <BaseTable
        ref={tableRef}
        titleBack={true}
        titleAction={backToProductsTable}
        title={
          <HeaderWSelectors
            currentOptionProduct={product}
            currentOptionLcs={lcs}
            currentOptionScenario={scenario}
            onSelectionProduct={id => setProduct(products.dataById[id])}
            onSelectionLcs={id => setLcs(lcsById[id])}
          />
        }
        columns={columns(user)}
        data={scenario ? reOrderedRows : []}
        onRowClick={() => null}
        onRowDoubleClick={setDescriptor ? manageConditions : null}
        paging={false}
        options={{
          maxBodyHeight: TABLE_HEIGHT_WITHOUT_HEADER,
          minBodyHeight: TABLE_HEIGHT_WITHOUT_HEADER,
          selection: true,
          showTextRowsSelected: false,
          showSelectAllCheckbox: false,
          selectionProps: rowData => ({
            //checked: prevInterSubstanceIds.includes(rowData.id)
            //onClick: (event , rowData) => this.handleCheckboxClick(event,rowData),
          })
        }}
        isLoading={loading}
        onSelectionChange={(rows, difRow) => {
          setLoading(true);
          if (rows.length > selectedIds.length) {
            createScenarioDescriptor({ id_descritor: difRow.id, id_cenario: scenario.id })
              .catch(e => alert('ERROR', e))
              .finally(() => setLoading(false))
          } else {
            const id_sd = filteredScenarioDescriptors.find(fsd => fsd.id_descritor === difRow.id).id;
            removeScenarioDescriptor(id_sd)
              .catch(e => alert('ERROR', e))
              .finally(() => setLoading(false))
          }
        }}
        actions={setDescriptor ?
          [
            {
              icon: tableIcons.Conditions,
              tooltip: 'Gerir condições',
              onClick: (event, rowData) => manageConditions(rowData),
              position: 'row'
            },
            ...exportPDFActions
          ] :
          exportPDFActions
        }
        detailPanel={setDescriptor && [
          rowData => {
            const rowScenarioDescriptor = filteredScenarioDescriptors.find(fsd => fsd.id_descritor === rowData.id);
            return rowScenarioDescriptor ?
              {
                icon: tableIcons.Conditions,
                tooltip: 'Condições',
                render: () => <ConditionsDetailPanel scenarioDescriptor={rowScenarioDescriptor}/>
              } :
              noIcon
          }]
        }
        components={{
          Toolbar: props => (
            <div style={{ backgroundColor: theme.palette.background.veryLight }}>
              <MTableToolbar {...props}/>
              <TabsBar value={descriptorTab} setValue={setDescriptorTab}/>
            </div>
          )
        }}
      />
    </React.Fragment>
  )
};

const mapStateToProps = state => ({
  conditions: state.conditions,
  descriptors: state.descriptors,
  lcsById: state.descriptors.dataById,
  products: state.products,
  scenarioDescriptorConditions: state.scenarioDescriptorConditions.data,
  units: state.units.dataById,
  user: state.profile.entity
})


const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  fetchScenarios: () => dispatch(scenariosActions.fetchAll()),
  createScenarioDescriptor: data => dispatch(scenariosDescriptorsActions.createPromise(data)),
  removeScenarioDescriptor: id => dispatch(scenariosDescriptorsActions.removePromise(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
