import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import theme from 'source/theme';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.veryLight,
    padding: 10,
    width: '100%'
  },
  header: {
    backgroundColor: theme.palette.primary.light
  },
  heading: {
    flexBasis: '90%',
    flexShrink: 0,
    height: 60,
    overflow: 'visible'
  },
  textHeader: {
    color: 'black'
  },
  text: {
    color: 'black'
  },
  expanded: {
    '&:first-child': {
      margin: 0
    }
  }
}));

// Hook
const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {

        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mouseup", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mouseup", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

const Component = props => {
  const { header, text, update } = props;
  const { goBack } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const ref = useRef();
  useOnClickOutside(ref, () => setExpanded(false));

  useEffect(() => setExpanded(true), [update])

  const togglePanel = () => setExpanded(!expanded);

  return (
    <ExpansionPanel ref={ref} classes={{ expanded: classes.expanded }} expanded={expanded} onChange={togglePanel}>
      <ExpansionPanelSummary
        className={classes.header}
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel-content"
        id="panel-header"
      >

        <Typography variant='h6'>
          <IconButton
            color='secondary'
            aria-label='go back'
            onClick={goBack}
            style={{ borderStyle: 'dashed', borderWidth: 1 }}
          >
            <ArrowBack/>
          </IconButton>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </Typography>

        <div className={classes.heading}>
          <Typography className={classes.textHeader} variant='button' component='span'>
            {header}&nbsp;
          </Typography>
          <Typography className={classes.text} variant='body2' component='span'>
            {text()}
          </Typography>
        </div>


      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ backgroundColor: theme.palette.primary.light }}>
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
};

export default Component;

Component.propTypes = {
  header: PropTypes.string,
  text: PropTypes.func,
  goBack: PropTypes.func
};
