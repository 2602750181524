import React from 'react';
import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';
import { MIXTURE } from 'utils/constants/coreConstants';

const themeMain = theme.palette.primary.main;
const themeLight = theme.palette.primary.light;
const lightGrey = '#EEEEEE';

const productHeader = [
  { text: 'Código produto', fillColor: themeLight, colSpan: 2 },
  { text: 'Nome produto', fillColor: themeLight, colSpan: 3 },
  { text: '', fillColor: themeLight },
  { text: '', fillColor: themeLight },
  { text: '', fillColor: themeLight },
  { text: 'Perigoso', fillColor: themeLight }
];
const substanceHeader = [
  { text: 'EC', alignment: 'center' },
  { text: 'CAS', alignment: 'center' },
  { text: 'Substância/Polímero' },
  { text: 'MP carga', alignment: 'center' },
  { text: 'Produto Final ', alignment: 'center' },
  { text: 'Campo 3 FDS', alignment: 'center' }
];
const mixtureHeader = [
  { text: '', fillColor: lightGrey },
  { text: 'Código MP', fillColor: lightGrey },
  { text: 'Mistura', fillColor: lightGrey },
  { text: 'MP carga', alignment: 'center', fillColor: lightGrey },
  { text: 'Produto Final ', alignment: 'center', fillColor: lightGrey },
  { text: 'Campo 3 FDS', alignment: 'center', fillColor: lightGrey }
];

const rowGenerator = (products, rawMaterialsById) => {
  let rows = []
  products.forEach(product => {
    rows.push([
      { text: product.codigo_produto, fillColor: themeMain, colSpan: 2 },
      { text: '', fillColor: themeMain },
      { text: product.produto_nome, fillColor: themeMain, colSpan: 3 },
      { text: '', fillColor: themeMain },
      { text: '', fillColor: themeMain },
      { text: product.perigoso ? 'x' : '', alignment: 'center', fillColor: themeMain }
    ])

    const summaryRawMaterials = [];
    const substancesFromMixtureIds = [];
    /**
     * Algorithm to filter:
     *  - Subtances from mixtures in CARGA
     *  - Substances in CARGA
     *  - Substances in MP FINAL
     */

    /**
     * First adding all substances related with mixtures, assuming that the substance
     * has not been modified in exclusions AND all substances that have cod_mp (CARGA)
     **/
    product.Produto_Mps.forEach(prm => {
      if (prm.mp_formulacao) {
        if (rawMaterialsById[prm.id_mp.id].tipo === MIXTURE) {
          rawMaterialsById[prm.id_mp.id].inter_substancias.forEach(interSubstance => {
            const substanceId = interSubstance.id_substancia
            substancesFromMixtureIds.push(substanceId);
            summaryRawMaterials.push({
              ...rawMaterialsById[substanceId],
              mp_final: false,
              mp_formulacao: true,
              mp_excluido_final: false,
              id_produto_mp: null,
              contribui_perigo: false,
              cod_mp: rawMaterialsById[prm.id_mp.id].cod_mp,
              mp_nome_mistura: rawMaterialsById[prm.id_mp.id].mp_nome,
              id_mistura_caminho: prm.id_mp.id
            });
          })
        }
        else if (!prm.id_mistura_caminho_mp) {
          summaryRawMaterials.push({
            ...rawMaterialsById[prm.id_mp.id],
            mp_final: prm.mp_final,
            mp_formulacao: prm.mp_formulacao,
            mp_excluido_final: prm.mp_excluido_final,
            id_produto_mp: prm.id,
            contribui_perigo: prm.contribui_perigo,
            mp_nome_mistura: '',
            id_mistura_caminho: null
          });
        }
      }
    })
    /**
     * Finding Substances that overlap with the default/lazy ones created above
     * and putting these ones on top
     **/
    product.Produto_Mps.forEach(prm => {
      if (prm.id_mistura_caminho_mp && prm.mp_formulacao) {
        const index = summaryRawMaterials.findIndex(s =>
          s.id === prm.id_mp.id &&
          s.id_mistura_caminho === prm.id_mistura_caminho_mp.id
        );
        if (index >= 0) {
          summaryRawMaterials[index] =
            {
              ...summaryRawMaterials[index],
              id_produto_mp: prm.id,
              mp_excluido_final: prm.mp_excluido_final,
              contribui_perigo: prm.contribui_perigo
            }
        }
      }
    })
    /**
     * Finding Substances with mp_final True (FINAL)
     */
    product.Produto_Mps.forEach(prm => {
      if (!prm.mp_formulacao && !prm.id_mistura_caminho_mp ) {
        summaryRawMaterials.push({
          ...rawMaterialsById[prm.id_mp.id],
          mp_final: prm.mp_final,
          mp_formulacao: prm.mp_formulacao,
          mp_excluido_final: prm.mp_excluido_final,
          id_produto_mp: prm.id,
          contribui_perigo: prm.contribui_perigo,
          cod_mp: null,
          mp_nome_mistura: null
        });
      }
    })


    /**
     * Searching for substances that might be repeated in Mixtures or just as
     * Substances in CARGA and Substances added in MP FINAL to concatenate them
     */
    const finalProductRawMaterials = []
    const sortAccented = (a, b) => {
      if (a.mp_nome && b.mp_nome) {
        return a.mp_nome.localeCompare(b.mp_nome);
      } else {
        console.log('ERROR THIS SHOULDNT HAPPEN DATA IS NOT THERE');
        return true;
      }
    }
    const sortedSubstances = summaryRawMaterials.sort(sortAccented)
    const preAnalysedIds = [];
    let i, k;

    for (i = 0; i < sortedSubstances.length; i++) {

      if (preAnalysedIds.includes(i)) {
        continue;
      }

      const repeatedSubstances = [sortedSubstances[i]];
      for (k = i + 1; k < sortedSubstances.length; k++) {
        if (sortedSubstances[i].id === sortedSubstances[k].id) {
          repeatedSubstances.push(sortedSubstances[k]);
          preAnalysedIds.push(k);
        }
      }

      finalProductRawMaterials.push([
        { text: repeatedSubstances[0]['ec'], border: [false, true, false, false] },
        { text: repeatedSubstances[0]['cas'], border: [false, true, false, false] },
        { text: repeatedSubstances[0]['mp_nome'], border: [false, true, false, false] },
        { text: repeatedSubstances.some(s => s.mp_formulacao) ? 'x' : '', alignment: 'center' },
        { text: repeatedSubstances.some(s => s.mp_excluido_final) ? '' : 'x', alignment: 'center' },
        { text: repeatedSubstances.some(s => s.contribui_perigo) ? 'x' : '', alignment: 'center' }
      ])
      if (repeatedSubstances.some(s => s.mp_nome_mistura && s.mp_nome_mistura !== '') || repeatedSubstances.length > 1) {
        repeatedSubstances.forEach((s, index) => {
          if (!s.mp_formulacao && !s.id_mistura_caminho_mp) {
            finalProductRawMaterials.push([
              { text: '', border: [false, false, false, false] },
              { text: '', fillColor: lightGrey },
              { text: 'REACÇÃO', fillColor: lightGrey },
              { text: s.mp_formulacao ? 'x' : '', alignment: 'center', fillColor: lightGrey },
              { text: s.mp_excluido_final ? '' : 'x', alignment: 'center', fillColor: lightGrey },
              { text: s.contribui_perigo ? 'x' : '', alignment: 'center', fillColor: lightGrey }
            ])
          } else {
            finalProductRawMaterials.push([
              { text: '', border: [false, false, false, false] },
              { text: s.cod_mp, fillColor: lightGrey },
              { text: s.mp_nome_mistura && s.mp_nome_mistura !== '' ? s.mp_nome_mistura : s.mp_nome, fillColor: lightGrey },
              { text: s.mp_formulacao ? 'x' : '', alignment: 'center', fillColor: lightGrey },
              { text: s.mp_excluido_final ? '' : 'x', alignment: 'center', fillColor: lightGrey },
              { text: s.contribui_perigo ? 'x' : '', alignment: 'center', fillColor: lightGrey }
            ])
          }
        })
      }
    }

    rows = [...rows, ...finalProductRawMaterials]
  })

  return rows;
};


const dd = (products, rawMaterialsById) => ({
  pageSize: 'A4',
  pageOrientation: 'landscape',
  pageMargins: [40, 60, 40, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: 'R - Composição do Produto'
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 3,
        widths: [100, 100, 240, 70, 70, 70],
        body: [
          productHeader,
          substanceHeader,
          mixtureHeader,
          ...rowGenerator(products, rawMaterialsById)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 8
    }
  }

});

export default dd;
