import { FETCH_SUPPLIERS, REJECTED, SUPPLIER } from 'actionTypes';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';
import thunkFactory from 'utils/redux/baseEntitiesFactory/thunk';
import { apiRequest } from 'utils/request';

// CUSTOM ACTIONS
const handleError = (TYPE, error, dispatch) => {
  console.log('error: ', error);
  if (error.response) {
    if (error.response.status === 401) {
      dispatch({ type: 'LOGOUT' });
    } else {
      dispatch({ type: TYPE + REJECTED, payload: error.response.statusText });
    }
  } else {
    dispatch({ type: TYPE + REJECTED, payload: 'Unknown error' });
  }
};

const importSuppliersMultiple = data => dispatch => {
  console.log('data multiple', data);
  return apiRequest('/fornecedor_multiple/', 'POST', data)
    .then(response => {
      // Insertion on DB takes a while so the FETCH is delayed
      setTimeout(() => dispatch({ type: FETCH_SUPPLIERS }), 5000);
    })
    .catch(error => handleError(SUPPLIER, error, dispatch))
}

// ACTIONS
const rawActions = actionsFactory(SUPPLIER);
const actions = {
  ...rawActions,
  ...thunkFactory(SUPPLIER, '/fornecedor/'),
  importSuppliers: importSuppliersMultiple
};
export { actions };

// REDUCER
const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};

const options = {
  orderBy: {
    what: ['fornecedor'],
    how: ['asc']
  }
};

export default reducerFactory(SUPPLIER, initialState, options);

// SAGAS
const sagas = sagasFactory(SUPPLIER, '/fornecedor/');
export { sagas };
