import { SUPPLIER_REGISTER } from 'actionTypes';
import thunkFactory from 'source/utils/redux/baseEntitiesFactory/thunk';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';

// ACTIONS
const rawActions = actionsFactory(SUPPLIER_REGISTER);
const actions = { ...rawActions, ...thunkFactory(SUPPLIER_REGISTER, '/registo/') };
export { actions };

// REDUCER
const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};


const options = {
  flattenFields: {
    id_mp: ['materia_prima'],
    id_fornecedor: ['fornecedor', 'id']
  }
};

export default reducerFactory(SUPPLIER_REGISTER, initialState, options);

// SAGAS
const sagas = sagasFactory(SUPPLIER_REGISTER, '/registo/');
export { sagas };
