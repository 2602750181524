import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';

const themeLight = theme.palette.primary.light;
const lightGrey = '#EEEEEE';
const productHeader = [
  { text: '', fillColor: themeLight },
  { text: 'Fluxo / Produto', fillColor: themeLight },
  { text: 'Perigoso', fillColor: themeLight }
];

const rowGenerator = (products, flows) => {
  const rows = []

  flows.forEach(flow => {

    rows.push([
      { text: flow.codigo_fluxo, fillColor: lightGrey },
      { text: flow.nome, fillColor: lightGrey },
      { text: '', fillColor: lightGrey }
    ]);

    const productsInFlow = products.filter(p => p.fluxos_produto.map(fp => fp.id_flux).includes(flow.id))
    productsInFlow.forEach(pif => {
      rows.push([
        { text: pif.codigo_produto },
        { text: pif.produto_nome },
        { text: pif.perigoso ? 'x' : '' }
      ]);
    })

  })
  return rows;

};

const dd = (products, flows) => ({
  pageSize: 'A4',
  pageMargins: [40, 60, 40, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: 'R - Produtos por Fluxograma' //title,
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: [100, '*', 100],
        body: [
          productHeader,
          ...rowGenerator(products, flows)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 8
    }
  }

});

export default dd;
