import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';
import { ERC, PC, PROC, SU } from 'utils/constants/descriptorTypes';

const rowGenerator_SU_PC_ERC_PROC = (product, scenario, descriptors, conditions, scenarioDescriptors, scenarioDescriptorConditions, units) => {

  const filteredSD = scenarioDescriptors.filter(fsd => fsd.id_cenario === scenario.id);
  const filteredSDIds = filteredSD.map(sd => sd.id);

  const filteredSDC = scenarioDescriptorConditions.filter(sdc =>
    filteredSDIds.includes(sdc.id_cenariodescritor)
  );

  const PCDescriptorIds = descriptors.data.filter(v => v.id_tipo === PC).map(obj => obj.id);
  const SUDescriptorIds = descriptors.data.filter(v => v.id_tipo === SU).map(obj => obj.id);
  const ERCdescriptorIds = descriptors.data.filter(v => v.id_tipo === ERC).map(obj => obj.id);
  const PROCdescriptorIds = descriptors.data.filter(v => v.id_tipo === PROC).map(obj => obj.id);

  const PC_SD = filteredSD.filter(sd => PCDescriptorIds.includes(sd.id_descritor));
  const SU_SD = filteredSD.filter(sd => SUDescriptorIds.includes(sd.id_descritor));
  const ERC_SD = filteredSD.filter(sd => ERCdescriptorIds.includes(sd.id_descritor));
  const PROC_SD = filteredSD.filter(sd => PROCdescriptorIds.includes(sd.id_descritor));

  const PC_D_ToRender = PC_SD.map(sd => ({ ...descriptors.dataById[sd.id_descritor], ...sd }));
  const SU_D_ToRender = SU_SD.map(sd => ({ ...descriptors.dataById[sd.id_descritor], ...sd }));
  const ERC_D_ToRender = ERC_SD.map(sd => ({ ...descriptors.dataById[sd.id_descritor], ...sd }));
  const PROC_D_ToRender = PROC_SD.map(sd => ({ ...descriptors.dataById[sd.id_descritor], ...sd }));


  const ERC_SDC = ERC_SD.map(sd => filteredSDC.filter(sdc => sdc.id_cenariodescritor === sd.id));
  const ERC_C_ToRender = ERC_SDC.map(sdcArray => sdcArray.map(sdc =>
      ({
        ...conditions.dataById[sdc.id_condicao],
        ...sdc
      })
    )
  );

  const PROC_SDC = PROC_SD.map(sd => filteredSDC.filter(sdc => sdc.id_cenariodescritor === sd.id));
  const PROC_C_ToRender = PROC_SDC.map(sdcArray => sdcArray.map(sdc =>
      ({
        ...conditions.dataById[sdc.id_condicao],
        ...sdc
      })
    )
  );

  const renderDescriptorsWithoutConditions = descriptors =>
    descriptors.map(d =>
      [
        d.codigo,
        { text: d.descricao, colSpan: 3 },
        '',
        '',
        { text: d.ok_flag ? 'OK' : 'NOT OK', alignment: 'center', bold: true },
        { text: d.notas, alignment: 'left', bold: true }
      ]
    );

  const renderDescriptorsWithConditions = (descriptors, conditions, units) => {
    const dataToRender = [];
    descriptors.forEach((d, index) => {
      dataToRender.push([
        { text: d.codigo, rowSpan: conditions[index].length + 1, bold: true },
        { text: d.descricao, colSpan: 3, bold: true },
        '',
        '',
        { text: d.ok_flag ? 'OK' : 'NOT OK', alignment: 'center', bold: true },
        { text: d.notas, alignment: 'left', bold: true }
      ]);
      conditions[index].forEach(c =>
        dataToRender.push([
          { text: '', border: [false, false, false, false] },
          { text: c.valor ? c.valor : '' },
          { text: c.id_unidade ? units[c.id_unidade].nome : '' },
          { text: c.nome },
          { text: c.ok_flag ? 'OK' : 'NOT OK', alignment: 'center' },
          { text: c.notas, alignment: 'left' }
        ])
      );
    });
    return dataToRender;
  };

  return [
    ...renderDescriptorsWithoutConditions(PC_D_ToRender),
    ...renderDescriptorsWithoutConditions(SU_D_ToRender),
    ...renderDescriptorsWithConditions(ERC_D_ToRender, ERC_C_ToRender, units),
    ...renderDescriptorsWithConditions(PROC_D_ToRender, PROC_C_ToRender, units)
  ]
}

const rowGenerator_SU_PC_ERC_PROC_all_scenarios = (
  product, scenarios, descriptors, conditions, scenarioDescriptors, scenarioDescriptorConditions, units, lcsById
) => {
  let rows = [];

  scenarios.forEach(scenario => {
    const filteredScenarioDescriptors = scenarioDescriptors.filter(sd => sd.id_cenario === scenario.id);

    rows = [
      ...rows,
      [
        { text: scenario.codigo, colSpan: 2, fillColor: theme.palette.primary.light },
        {},
        { text: lcsById[parseInt(scenario.id_lcs)].descricao, colSpan: 2, fillColor: theme.palette.primary.light },
        {},
        { text: scenario.designacao, colSpan: 2, fillColor: theme.palette.primary.light },
        {}
      ],
      ...rowGenerator_SU_PC_ERC_PROC(
        product,
        scenario,
        descriptors,
        conditions,
        filteredScenarioDescriptors,
        scenarioDescriptorConditions,
        units
      )
    ];
  });

  return rows;
}

const dd = (
  product, descriptors, conditions, scenarios, scenarioDescriptors,
  scenarioDescriptorConditions, units, lcsById
) => {

  return {
    pageSize: 'A4',
    pageMargins: [40, 60, 40, 40],

    footer: function (currentPage, pageCount) {
      return {
        text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
        alignment: 'right',
        relativePosition: { x: -15, y: 20 },
        fontSize: 7
      }
    },
    header: function (currentPage, pageCount, pageSize) {
      return [
        {
          image: newLogoReachOk64,
          width: 50,
          relativePosition: { x: 20, y: 10 }
        }
      ]
    },
    content: [
      {
        style: 'header',
        text: `Cenários Avaliação Final`
      },
      {
        style: 'header',
        text: `${product.codigo_produto} - ${product.produto_nome}`
      },
      {
        style: 'tableExample',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 0,
          widths: [30, 30, 40, 160, 40, 150],
          body: [
            ...rowGenerator_SU_PC_ERC_PROC_all_scenarios(
              product,
              scenarios,
              descriptors,
              conditions,
              scenarioDescriptors,
              scenarioDescriptorConditions,
              units,
              lcsById
            )
          ]
        }
      }
    ],
    styles: {
      header: {
        color: theme.palette.primary.main,
        fontSize: 16,
        bold: true,
        alignment: 'center'
      },
      tableExample: {
        margin: [0, 15, 0, 0],
        fontSize: 8
      }
    }
  }
};

export default dd;
