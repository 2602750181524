import Grid from '@material-ui/core/Grid';
import AnalysisDescriptorsTable from 'pages/ScenarioExposure/Flow/ScenariosManagement/AnalysisDescriptorsTable'
import Descriptors from 'pages/ScenarioExposure/Flow/ScenariosManagement/DescriptorsTable'
import React from 'react';
import { SU } from 'utils/constants/descriptorTypes';
import { TABLE_HEIGHT_SPLIT_2VIEW } from 'utils/ui/constants';

const TABLE_HEIGHT = TABLE_HEIGHT_SPLIT_2VIEW(0);

const Component = props => {
  const { lcs, scenario, flowScenarioDescriptors, substanceId } = props;

  return (
    <Grid container>
      <Grid item xs={7}>
        <Descriptors
          descriptorFilter={SU}
          scenario={scenario}
          substanceId={substanceId}
          lcs={lcs}
          TABLE_HEIGHT={TABLE_HEIGHT}
        />
      </Grid>
      <Grid item xs={5}>
        <AnalysisDescriptorsTable
          descriptorFilter={SU}
          flowScenarioDescriptors={flowScenarioDescriptors}
          TABLE_HEIGHT={TABLE_HEIGHT}
        />
      </Grid>
    </Grid>
  )
};

export default Component;
