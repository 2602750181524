import RMProductsDetailPanel from 'components/DetailPanels/RMProductsDetailPanel';
import ShipmentsDetailPanel from 'components/DetailPanels/ShipmentsDetailPanel';
import SubstancesDetailPanel from 'components/DetailPanels/SubstanceDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions as rawMaterialActions, actions } from 'ducks/rawMaterials'
import { setContextSearchRM } from 'ducks/ui';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/inventory/mixtures';
import { filterByFieldSelector } from 'selectors/generic';
import theme from 'source/theme';
import { MIXTURE } from 'utils/constants/coreConstants';
import exportXLSX from 'utils/export/mixturesComposition';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/mixtures';
import { modifiedFields } from 'utils/tables/modifiedFields';
import noIcon from 'utils/tables/noIcon';
import rawMaterialValidator from 'utils/tables/validators/rawMaterial';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const {
    contextSearch, productsById, rawMaterialsById, suppliersById, mixtures, user
  } = props;
  const {
    alertError, create, fetch, handleOpenRmInserter, remove, setMixtureId,
    setSearch, update
  } = props;
  const tableRef = React.useRef(null);

  const [loading, setLoading] = useState(false);

  const exportPDF = () => {
    const filteredTableData = tableRef.current.state.data;
    pdfMake.createPdf(
      docDefinition(filteredTableData, rawMaterialsById))
      .open();
  };

  return (
    <BaseTable
      ref={tableRef}
      columns={columns(user)}
      data={mixtures}
      onRowClick={() => null}
      onRowDoubleClick={rowData => setMixtureId(rowData.id)}
      onSearchChange={text => setSearch(text)}
      options={{
        actionsCellStyle: {
          minWidth: 150,
          paddingLeft: 20
        },
        searchText: contextSearch
      }}
      isLoading={loading}
      actions={[
        {
          isFreeAction: true,
          icon: tableIcons.Pdf,
          tooltip: 'Exportar PDF',
          onClick: exportPDF
        },
        {
          isFreeAction: true,
          icon: tableIcons.Xlsx,
          tooltip: 'Exportar XLSX',
          onClick: exportXLSX(tableRef, rawMaterialsById, 'MISTURAS.xlsx')
        },
        {
          icon: tableIcons.ManageEntity,
          tooltip: 'Gerir composição',
          onClick: (event, rowData) => setMixtureId(rowData.id)
        },
        {
          icon: tableIcons.AddSubstancePolimer,
          tooltip: 'Adicionar Substância ou Polímero',
          onClick: () => handleOpenRmInserter(),
          position: 'toolbar'
        }
      ]}
      editable={{
        onRowAdd: newData => {
          newData['tipo'] = MIXTURE;
          const validation = rawMaterialValidator(newData, mixtures);
          if (validation.error) {
            alertError(validation.error)
            return Promise.reject()
          } else {
            setLoading(true);
            return create(validation)
              .catch(e => alert(e))
              .finally(() => setLoading(false));
          }
        },
        onRowUpdate: (newData, oldData) => {
          const validation = rawMaterialValidator(newData, mixtures);
          if (validation.error) {
            alertError(validation.error)
            return Promise.reject()
          } else {
            setLoading(true);
            const modifiedData = modifiedFields(columns(user), newData, oldData);
            return update(newData.id, modifiedData)
              .catch(e => alert(e))
              .finally(() => setLoading(false))
          }
        },
        onRowDelete: oldData => {
          if (oldData.inter_fornecedores.length > 0 || oldData.produtos.length > 0) {
            let actionsPending = ['Por favor apague a seguinte informação associada a esta MP:'];
            if (oldData.inter_fornecedores.length > 0) {
              actionsPending.push('- Fornecimentos com registos associados.')
            }
            if (oldData.produtos.length > 0) {
              const pList = oldData.produtos.map(id => productsById[id].produto_nome);
              actionsPending.push('- Produtos com registos associados: ' + pList.join(', '));
            }
            alertError(actionsPending);
            return Promise.resolve();
          } else {
            setLoading(true);
            if (oldData.tipo === MIXTURE) {
              // TODO UPDATE PRODUCTS / SHIPMENTS / RAWMATERIALS RELATED
              return remove(oldData.id)
                .then(() => {
                  const promises = oldData.inter_substancias.map(s => fetch(s.id_substancia));
                  Promise.all(promises)
                })
                .catch(e => alert(e))
                .finally(() => setLoading(false))
            } else {
              return update(oldData.id, { cod_mp: null })
                .catch(e => alert(e))
                .finally(() => setLoading(false))
            }
          }
        }
      }}
      detailPanel={[
        rowData => rowData.tipo !== MIXTURE ?
          noIcon :
          {
            icon: tableIcons.Substance,
            disabled: rowData.inter_substancias.length === 0,
            tooltip: 'Composição',
            render: () => <SubstancesDetailPanel rowData={rowData.inter_substancias} data={rawMaterialsById} title={'Substâncias'}/>
          },
        rowData =>
          ({
            icon: rowData.inter_fornecedores.length > 0 ?
              tableIcons.Shipments :
              tableIcons.ShipmentsStyled({htmlColor: theme.palette.error.main}),
            disabled: rowData.inter_fornecedores.length === 0,
            tooltip: 'Fornecimentos',
            render: () => <ShipmentsDetailPanel rowData={rowData} suppliersById={suppliersById} rawMaterialsById={rawMaterialsById} title={'Fornecimentos'}/>
          }),
        rowData =>
          ({
            icon: rowData.produtos.length > 0 ?
              tableIcons.Products :
              tableIcons.ProductsStyled({htmlColor: theme.palette.error.main}),
            disabled: rowData.produtos.length === 0,
            tooltip: 'Produtos',
            render: () => <RMProductsDetailPanel
              rowData={rowData}
              productsById={productsById}
              rawMaterialsById={rawMaterialsById}
              title={'Produtos'}
            />
          }),
      ]}
    />
  )
};


const makeMapStateToProps = () => {
  const filteredRawMaterials = filterByFieldSelector('rawMaterials', 'tipo', MIXTURE);
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchRM,
    mixtures: filteredRawMaterials(state),
    productsById: state.products.dataById,
    rawMaterialsById: state.rawMaterials.dataById,
    suppliersById: state.suppliers.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  fetch: id => dispatch(rawMaterialActions.fetchOne(id)),
  create: data => dispatch(actions.createPromise(data)),
  remove: id => dispatch(actions.removePromise(id)),
  update: (id, data) => dispatch(actions.updatePromise(id, data)),
  setSearch: search => dispatch(setContextSearchRM(search))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
