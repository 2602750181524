const emptyCellFiller = field => field ? field : '-';

export default user => {
  const columns = [
    {
      title: 'Codigo',
      field: 'codigo',
      editable: 'never',
      width: 200,
      headerStyle: {
        textAlign: 'left',
        paddingLeft: 50
      },
      cellStyle: {
        textAlign: 'left',
        paddingLeft: 50
      },
    },
    {
      title: 'Descrição',
      field: 'descricao',
      editable: 'never',
      width: 1200,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
  ];


  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};

