import LCSSelector from 'components/Buttons/ButtonRadioDialog'
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { filterByFunctionSelector } from 'selectors/generic';

const Component = props => {
  const { currentOption, disabled } = props;
  const { onSelection } = props;

  return (
    <LCSSelector
      buttonText='LCS: '
      currentOption={currentOption}
      disabled={disabled}
      header='Seleccione um LCS'
      optionKey='descricao'
      options={props.lcsData}
      optionsById={props.lcsById}
      onSelection={onSelection}
    />
  )
};

const makeMapStateToProps = () => {
  const immutableCoreDescriptors = filterByFunctionSelector('descriptors', v => v.id_tipo === 70001);
  const mapStateToProps = (state) => ({
    lcsData: immutableCoreDescriptors(state),
    lcsById: state.descriptors.dataById
  })
  return mapStateToProps;
}

export default connect(makeMapStateToProps)(Component);

Component.propTypes = {
  currentOption: PropTypes.object.isRequired,
  onSelection: PropTypes.func.isRequired
};
