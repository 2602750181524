import FluxogramSelector from 'components/Buttons/ButtonRadioDialog'
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const Component = props => {
  const { currentOption, disabled } = props;
  const { onSelection } = props;

  return (
    <FluxogramSelector
      buttonText='Fluxograma: '
      currentOption={currentOption}
      disabled={disabled}
      header='Seleccione um fluxograma'
      optionKey='nome'
      options={props.flows.data}
      optionsById={props.flows.dataById}
      onSelection={onSelection}
    />

  )
};

const mapStateToProps = state => ({
  flows: state.flows
});

export default connect(mapStateToProps)(Component);

Component.propTypes = {
  currentOption: PropTypes.object.isRequired,
  onSelection: PropTypes.func.isRequired
};
