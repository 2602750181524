import Grid from '@material-ui/core/Grid';
import Drawer from 'components/Drawer';
import ScenarioManagement from 'pages/ScenarioExposure/Product/ScenariosManagement'
import ScenariosTable from 'pages/ScenarioExposure/Product/ScenariosTable';
import SubstancesTable from 'pages/ScenarioExposure/Product/SubstancesTable'
import React, { useState } from 'react';


const Component = () => {

  const [substanceId, setSubstanceId] = useState(undefined);
  const [scenariosOpen, setScenariosOpen] = useState(false);
  const [scenariosManagementOpen, setScenariosManagementOpen] = useState(false);

  const [shipmentInitializer, setShipmentInitializer] = useState(null);
  const [scenarioInitializer, setScenarioInitializer] = useState(null);

  const closeScenarioManagement = () => {
    setScenariosOpen(true);
    setScenariosManagementOpen(false);
  }

  const closeScenariosContainerConfirm = () => {
    setSubstanceId(null);
    setScenariosOpen(false);
  };

  return (
    <Grid item xs={12}>
      {
        scenariosManagementOpen ?
          <ScenarioManagement
            substanceId={substanceId}
            setSubstanceId={setSubstanceId}
            closeScenarioManagement={closeScenarioManagement}

            setShipmentInitializer={setShipmentInitializer}
            shipmentInitializer={shipmentInitializer}

            setScenarioInitializer={setScenarioInitializer}
            scenarioInitializer={scenarioInitializer}
          /> :
          <SubstancesTable
            //TODO to remove lower line
            substanceId={substanceId}

            setSubstanceId={setSubstanceId}
            setScenariosManagementOpen={setScenariosManagementOpen}
            setScenariosOpen={setScenariosOpen}

            setShipmentInitializer={setShipmentInitializer}
          />
      }
      <Drawer
        open={scenariosOpen}
        onClose={closeScenariosContainerConfirm}
      >
        {
          scenariosOpen &&
          <ScenariosTable
            closeParentContainerConfirm={closeScenariosContainerConfirm}
            substanceId={substanceId}

            setScenariosManagementOpen={setScenariosManagementOpen}
            setShipmentInitializer={setShipmentInitializer}
            setScenariosOpen={setScenariosOpen}

            setScenarioInitializer={setScenarioInitializer}
          />
        }
      </Drawer>
    </Grid>
  )
};

export default Component;
