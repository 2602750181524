export default units => [
  {
    title: 'ID',
    field: 'id',
    editable: 'never',
    width: 100,
  },
  {
    title: 'Tipo',
    field: 'tipo',
    lookup: {
      'Ambiente': 'Ambiente',
      'Trabalhadores': 'Trabalhadores',
    },
    width: 150,
  },
  {
    title: 'Subtipo',
    field: 'subtipo',
    width: 150,
  },
  {
    title: 'Nome',
    field: 'nome',
    width: 500,
  },
  {
    title: 'Unidade',
    field: 'id_unidade',
    lookup: { '': 'N/A', ...units },
    initialEditValue: null,
    width: 200,
  }
];
