
/**
 * Checks diferences between newData and oldData, and intersets with columns
 * data that is supposed to be submitted to API
 *
 * @param columns
 * @param newData
 * @param oldData
 * @returns {{}} object with the updated fields from oldData to newData
 */
export const modifiedFields = (columns, newData, oldData) => {

  const submitableFields = columns.map(prop => prop.field);
  const updatedFields = {};
  submitableFields.forEach(field => {
    if (Array.isArray(oldData[field])) {
      if (JSON.stringify(newData[field]) !== JSON.stringify(oldData[field])) {
        updatedFields[field] = newData[field];
      }
    } else if (newData[field] !== oldData[field]) {
      updatedFields[field] = newData[field];
    }
  });
  return updatedFields;
}

