import BaseTable from 'components/Tables/BaseTable'
import { actions as flowsActions } from 'ducks/flows';
import { actions as stepRawMaterialsActions } from 'ducks/stepRawMaterials';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { filterStepRawMaterials } from 'selectors/generic';
import theme from 'source/theme';
import arrayToObject from 'utils/arrayToObject';
import { F, F_ID, IS, IS_ID, LCS_LIST, M, M_ID } from 'utils/constants/lcsTypes';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/flowStepsRawMaterials';

const Component = props => {
  let {
    flowId, flowSteps, rawMaterials, rawMaterialId, lcsByFLowIdScenarios,
    stepRawMaterials, user
  } = props;
  const { create, fetchStepRawMaterials, fetchFlow, handleCloseDrawer, remove, update } = props;
  const tableRef = React.useRef(null);

  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSelectedIds(stepRawMaterials.map(rm => rm.id_etapa));
    setSteps(stepsWithLCSInfo())
  }, [stepRawMaterials, flowSteps]);

  const stepsWithLCSInfo = () => {
    const stepRawMaterialsById = arrayToObject(stepRawMaterials, 'id_etapa');
    return flowSteps
      .filter(fs => fs.id_master_etapa || (!fs.id_master_etapa && fs.subetapas.length === 0))
      .map(s => {
        if (stepRawMaterialsById[s.id] && stepRawMaterialsById[s.id].descriptors) {
          return s.id_master_etapa ?
            {
              ...s,
              nome_master: s.id_master_etapa.nome,
              descricao_master: s.id_master_etapa.descricao,
              nome_substep: s.nome,
              descricao_substep: s.descricao,
              id_srm: stepRawMaterialsById[s.id].id,
              M: stepRawMaterialsById[s.id].descriptors.includes(M),
              F: stepRawMaterialsById[s.id].descriptors.includes(F),
              IS: stepRawMaterialsById[s.id].descriptors.includes(IS),
              M_error: !lcsByFLowIdScenarios[flowId].includes(M_ID),
              F_error: !lcsByFLowIdScenarios[flowId].includes(F_ID),
              IS_error: !lcsByFLowIdScenarios[flowId].includes(IS_ID)
            } :
            {
              ...s,
              nome_master: s.nome,
              descricao_master: s.descricao,
              nome_substep: '',
              descricao_substep: '',
              id_srm: stepRawMaterialsById[s.id].id,
              M: stepRawMaterialsById[s.id].descriptors.includes(M),
              F: stepRawMaterialsById[s.id].descriptors.includes(F),
              IS: stepRawMaterialsById[s.id].descriptors.includes(IS),
              M_error: !lcsByFLowIdScenarios[flowId].includes(M_ID),
              F_error: !lcsByFLowIdScenarios[flowId].includes(F_ID),
              IS_error: !lcsByFLowIdScenarios[flowId].includes(IS_ID)
            }

        } else {
          return s.id_master_etapa ?
            {
              ...s,
              nome_master: s.id_master_etapa.nome,
              descricao_master: s.id_master_etapa.descricao,
              nome_substep: s.nome,
              descricao_substep: s.descricao,
              id_srm: '',
              M: false,
              F: false,
              IS: false
            } :
            {
              ...s,
              nome_master: s.nome,
              descricao_master: s.descricao,
              nome_substep: '',
              descricao_substep: '',
              id_srm: '',
              M: false,
              F: false,
              IS: false
            }
        }
      })
  }

  const selectedSteps = steps.map(step =>
    selectedIds.includes(step.id) ?
      { ...step, tableData: { checked: true } } :
      step.tableData && step.tableData.checked ?
        { ...step, tableData: { checked: false } } : step
  );
  const orderedSteps = sortBy(selectedSteps, [o => !selectedIds.includes(o.id)]);

  return (
    <BaseTable
      ref={tableRef}
      titleBack={true}
      titleAction={handleCloseDrawer}

      title={`${rawMaterials[rawMaterialId].cod_mp} - ${rawMaterials[rawMaterialId].mp_nome}`}
      icons={tableIcons}
      columns={columns(user)}
      data={orderedSteps}
      onRowClick={() => null}
      editable={{
        onRowUpdate: (newData, oldData) => {
          setLoading(true);
          const stepRawMaterialById_etapa = arrayToObject(stepRawMaterials, 'id_etapa');
          const descriptors = LCS_LIST.filter(lcs => newData[lcs] === true || newData[lcs] === 'true');
          if (oldData.tableData.checked) {
            return update(
              stepRawMaterialById_etapa[newData.id].id,
              {
                descriptors,
                id_fluxograma: flowId,
                id_master_etapa: newData.id
              }
            )
              .then(() => setLoading(false))
              .catch(e => {
                setLoading(false)
                alert(e)
              })
          } else {
            return create({
              descriptors,
              id_etapa: newData.id,
              id_mp: rawMaterialId
            })
              .then(() => fetchFlow(flowId))
              .then(() => setLoading(false))
              .catch(e => {
                setLoading(false)
                alert(e)
              })
          }
        }
      }}
      options={{
        selection: true,
        showTextRowsSelected: false,
        showSelectAllCheckbox: false,
        rowStyle: rowData => ({
          backgroundColor: (selectedIds.includes(rowData.id) && !rowData.M && !rowData.F && !rowData.IS) ?
            theme.palette.rowErrorBackground.main  : ''
        })
      }}
      isLoading={loading}
      onSelectionChange={(rows, difRow) => {
        setLoading(true);
        if (rows.length > selectedIds.length) {
          const descriptors = LCS_LIST.find(lcs => difRow[lcs] === true || difRow[lcs] === 'true');
          create({
            descriptors,
            id_etapa: difRow.id,
            id_mp: rawMaterialId
          })
            .then(() => fetchFlow(flowId))
            .then(() => setLoading(false))
            .catch(e => {
              setLoading(false)
              alert(e)
            })
        } else {
          const stepRawMaterialById_etapa = arrayToObject(stepRawMaterials, 'id_etapa');
          remove(stepRawMaterialById_etapa[difRow.id].id)
            .then(() => fetchFlow(flowId))
            .then(() => setLoading(false))
            .catch(e => {
              setLoading(false)
              alert(e)
            })
        }
      }}
    />
  )
};

const makeMapStateToProps = () => {
  const stepRawMaterials = filterStepRawMaterials();
  const mapStateToProps = (state, props) => ({
    stepRawMaterials: stepRawMaterials(state, props),
    rawMaterials: state.rawMaterials.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  fetchStepRawMaterials: () => dispatch(stepRawMaterialsActions.fetchAll()),
  fetchFlow: (id) => dispatch(flowsActions.fetchOne(id)),
  create: data => dispatch(stepRawMaterialsActions.createPromise(data)),
  remove: id => dispatch(stepRawMaterialsActions.removePromise(id)),
  update: (id, data) => dispatch(stepRawMaterialsActions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
