import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

// const variantIcon = {
//   success: icons.check,
//   warning: icons.warning,
//   error: icons.error,
//   info: icons.info
// };

// TODO: component should be refactored so the styles start including theme.palette.augmentColor.
const Content = props => {
  const { classes, className, message, onClose, variant, ...other } = props;
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {/*<Icon className={classNames(classes.icon, classes.iconVariant)} icon={variantIcon[variant]}/>*/}

          {Array.isArray(message) ?
            <Grid container>
              {message.map((item, index) => <Grid key={index} item xs={12}>{item}</Grid>)}
            </Grid> :
            message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          {/*<Icon className={classes.icon} icon={icons.close}/>*/}
        </IconButton>
      ]}
      {...other}
      style={{maxWidth: '70%'}}
    />
  );
};

Content.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
};

const StyledContent = withStyles(styles.content)(Content);

const Alert = props => {

  return (
    <Snackbar
      anchorOrigin={
        props.placement ?
          props.placement :
          { vertical: 'top', horizontal: 'right' }
      }
      open={props.open}
      autoHideDuration={props.autoHideDuration ? props.autoHideDuration : 5000}
      onClose={props.handleClose}
    >
      <StyledContent
        onClose={props.handleClose}
        variant={props.variant ? props.variant : 'error'}
        message={props.message}
      />
    </Snackbar>
  );
};

Alert.propTypes = {
  autoHideDuration: PropTypes.oneOfType([
    PropTypes.number,
    null
  ]),
  /** Super prop types */
  open: PropTypes.bool.isRequired,
  /** Super prop types 2*/
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  handleClose: PropTypes.func,
  placement: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right'])
  }),
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
};

export default withStyles(styles.snackbar)(Alert);
