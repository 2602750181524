import { CREATE, UPDATE, DELETE, FULFILLED, REJECTED, ALERT_ERROR } from 'source/actionTypes';
import { apiRequest } from 'utils/request';


const handleError = (TYPE, error, dispatch) => {

  if (error.response) {

    if (error.response.status === 401) {
      dispatch({ type: ALERT_ERROR, payload: error.response.data.detail });
      dispatch({ type: 'LOGOUT' });
    } else {
      let payload = error.response.statusText
      if (error.response.data) {
        payload = payload + ' - ' + JSON.stringify(error.response.data);
      }
      dispatch({ type: TYPE + REJECTED, payload });
      dispatch({ type: ALERT_ERROR, payload });
    }
  } else {
    dispatch({ type: TYPE + REJECTED, payload: 'Unknown error' });
    dispatch({ type: ALERT_ERROR, payload: 'Unknown error' });
  }
};

export default (TYPE, url) => {

  const create = (TYPE, url) => data => dispatch => {
    return apiRequest(`${url}`, 'POST', data)
      .then(response => {
        dispatch({ type: CREATE + TYPE + FULFILLED, payload: response.data });
        return response.data;
      })
      .catch(error => {
        handleError(TYPE, error, dispatch);
        return Promise.reject()
      })
  };

  const remove = (TYPE, url) => id => dispatch => {
    return apiRequest(`${url}` + id, 'DELETE')
      .then(response => {
        dispatch({ type: DELETE + TYPE + FULFILLED, payload: id });
        return response.data;
      })
      .catch(error => {
        handleError(TYPE, error, dispatch);
        return Promise.reject()
      })
  };

  const update = (TYPE, url) => (id, data) => dispatch => {
    return apiRequest(`${url}` + id, 'PUT', data)
      .then(response => {
        dispatch({ type: UPDATE + TYPE + FULFILLED, payload: response.data });
        return response.data;
      })
      .catch(error => {
        handleError(TYPE, error, dispatch);
        return Promise.reject()
      })
  };

  return {
    createPromise: create(TYPE, url),
    removePromise: remove(TYPE, url),
    updatePromise: update(TYPE, url),
  }
}
