import Grid from '@material-ui/core/Grid';
import Drawer from 'components/Drawer';
import ProductsTable from 'pages/Evaluation/Product/ProductsTable';
import ScenarioManagement from 'pages/Evaluation/Product/ScenariosManagement';
import ScenariosTable from 'pages/Evaluation/Product/ScenariosTable';
import React, { useState } from 'react';


const Component = props => {

  const [productId, setProductId] = useState(undefined);
  const [lcsId, setLcsId] = useState(undefined);
  const [scenarioId, setScenarioId] = useState(undefined);
  const [scenariosOpen, setScenariosOpen] = useState(false);
  const [scenariosManagementOpen, setScenariosManagementOpen] = useState(false);

  const openScenarioManagement = (productId, lcsId, scenarioId) => {
    setScenariosOpen(false);
    setProductId(productId);
    setLcsId(lcsId);
    setScenarioId(scenarioId)
    setScenariosManagementOpen(true)
  }

  const openProductScenarios = productId => {
    setProductId(productId);
    setScenariosManagementOpen(false);
    setScenariosOpen(true);
  }

  const closeScenarioManagement = () => {
    setScenariosOpen(true);
    setScenarioId(null)
    setScenariosManagementOpen(false);
  }

  const closeScenariosContainerConfirm = () => {
    setProductId(null);
    setLcsId(null);
    setScenarioId(null)
    setScenariosOpen(false);
  };

  return (
    <Grid item xs={12}>
      {
        scenariosManagementOpen ?
          <ScenarioManagement
            productId={productId}
            setProductId={setProductId}
            lcsId={lcsId}
            setLcsId={setLcsId}
            scenarioId={scenarioId}
            setScenarioId={setScenarioId}
            closeScenarioManagement={closeScenarioManagement}
          /> :
          <ProductsTable
            openProductScenarios={openProductScenarios}
            openDescriptors={openScenarioManagement}
          />
      }

      <Drawer
        open={productId && scenariosOpen}
        onClose={closeScenariosContainerConfirm}
      >
        {
          scenariosOpen &&
          <ScenariosTable
            productId={productId}
            openScenarioManagement={openScenarioManagement}
            backToProductsTable={closeScenariosContainerConfirm}
          />
        }
      </Drawer>
    </Grid>
  )
};


export default Component;
