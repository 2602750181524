const Joi = require('@hapi/joi');
import React from 'react';

const baseObject = {
  compra_anual_kg: '',
  consumo_anual_kg: '',
  ano: ''
};

export const dataSchema = Joi.object({
  consumo_anual_kg: Joi.number()
    .required()
    .messages({
      "any.required": `"Consumo Anual" campo de preenchimento obrigatório`,
      "number.type": `"Consumo Anual" campo vazio`,
    }),
  compra_anual_kg: Joi.number()
    .required()
    .messages({
      "any.required": `"Compra Anual" campo de preenchimento obrigatório`,
      "number.type": `"Compra Anual" campo vazio`,
    }),
  ano: Joi.number()
    .required()
    .messages({
      "any.required": `"Ano" campo de preenchimento obrigatório`,
      "number.type": `"Ano" campo vazio`,
    }),
});


const validator = (newData, data) => {
  let error = undefined;
  let iData = { ...baseObject, ...newData };

  const baseObjectKeys = Object.keys(baseObject);
  Object.keys(iData).forEach(k => {
    if (!baseObjectKeys.includes(k)) {
      delete iData[k];
    }
  })

  // Validate data correctness
  const validateData = dataSchema.validate(iData, { abortEarly: false });
  if (validateData.error) {
    return { error: validateData.error.details.map(obj => obj.message) };
  }

  // Validations against all data
  const isYearRepeated = data.some(c => c.ano == newData.ano);
  if (isYearRepeated) {
    error = { error: 'O Ano inserido já existe, não podem haver anos repetidos.' }
  }

  return error ? error : iData;
};

export default validator;
