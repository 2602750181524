import ButtonTable from 'components/Buttons/ButtonTableDialog'
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';


const BUTTONS_TEXT = ['Matéria Prima: ', 'Fornecimento: '];

const OPTIONS_KEY1 = [
  { title: 'CODIGO_MP', tag: 'shipment_cod_mp' },
  { title: 'MP_NOME', tag: 'shipment_mp_nome' }
];

const OPTIONS_KEY2 = [
  { title: 'CODIGO', tag: 'codigo' },
  { title: 'DESIGNAÇÃO COMERCIAL', tag: 'designacao_comercial' },
  { title: 'FORNECEDOR', tag: 'fornecedor', nested: 'id_fornecedor' }
  // { title: 'DATA FDS', tag: 'data_fds' },
  // { title: 'REVISÃO FDS', tag: 'revisao_fds' }
]

const OPTIONS_KEYS = [OPTIONS_KEY1, OPTIONS_KEY2]

const COLUMNS = [
  { title: 'Código MP', field: 'shipment_cod_mp' },
  { title: 'Matéria prima', field: 'shipment_mp_nome' },
  { title: 'Fornecimento', field: 'codigo' },
  { title: 'Desig. Comercial', field: 'designacao_comercial' },
  { title: 'Fornecedor', field: 'fornecedor', nested: 'id_fornecedor' }
];

const Component = props => {
  const { currentOption, rawMaterialsById, substance } = props;
  const { onSelection } = props;

  let augmentedCurrentOption = {};

  if (
    rawMaterialsById[substance.id].inter_fornecedores
      .find(shipment => currentOption.id === shipment.id)
  ) {
    augmentedCurrentOption = {
      ...rawMaterialsById[substance.id].inter_fornecedores
        .find(shipment => currentOption.id === shipment.id),
      shipment_substance_id: substance.id,
      shipment_id_mp: substance.id,
      shipment_mp_nome: substance.mp_nome,
      shipment_cod_mp: substance.cod_mp
    };
  } else {
    const mixIds = substance.inter_misturas.map(mix => mix.id_mistura);
    mixIds.forEach(id => {
      const mixShipment = rawMaterialsById[id].inter_fornecedores.find(shipment => currentOption.id === shipment.id)
      if (mixShipment) {
        augmentedCurrentOption = {
          ...mixShipment,
          shipment_mixture_id: id,
          shipment_id_mp: id,
          shipment_mp_nome: rawMaterialsById[id].mp_nome,
          shipment_cod_mp: rawMaterialsById[id].cod_mp
        };
      }
    })
  }

  /**
   * On shipment there should be shipment_substance or shipment_mistura to
   * signal that the Shipment is direct or indirect from others mixs instead the
   * original substance
   */
  const shipments = [];
  substance.inter_fornecedores.forEach(shipment =>
    shipments.push({
      ...shipment,
      shipment_substance_id: substance.id,
      shipment_id_mp: substance.id,
      shipment_mp_nome: substance.mp_nome,
      shipment_cod_mp: substance.cod_mp
    })
  )
  substance.inter_misturas.forEach(mix =>
    rawMaterialsById[mix.id_mistura].inter_fornecedores.forEach(shipment =>
      shipments.push({
        ...shipment,
        shipment_mixture_id: mix.id_mistura,
        shipment_id_mp: mix.id_mistura,
        shipment_mp_nome: rawMaterialsById[mix.id_mistura].mp_nome,
        shipment_cod_mp: rawMaterialsById[mix.id_mistura].cod_mp
      })
    )
  )

  return (
    <ButtonTable
      buttonsText={BUTTONS_TEXT}
      currentOption={augmentedCurrentOption}
      header='Seleccione um Fornecimento'
      optionKeys={OPTIONS_KEYS}
      options={shipments}
      onSelection={onSelection}
      columns={COLUMNS}
      maxWidth='xl'
    />
  )
};

const mapStateToProps = (state, props) => ({
  rawMaterialsById: state.rawMaterials.dataById
})

export default connect(mapStateToProps)(Component);

Component.propTypes = {
  currentOption: PropTypes.object,
  onSelection: PropTypes.func.isRequired
};
