import { MTableBody } from '@material-table/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SpinButton from 'components/Buttons/SpinButton';
import Drawer from 'components/Drawer';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/echa'
import cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import theme from 'source/theme';
import { importEchaTableColumns } from 'utils/echa/columns';
import { AUTORIZACAO, DESCRIPTION_RESTRICTIONS, ENTRYNO, RESTRICOES, SVHC } from 'utils/echa/constants';
import mergeEcha from 'utils/echa/merge';
import sanitizeEcha from 'utils/echa/sanitize';
import validateEcha from 'utils/echa/validate';
import tableIcons from 'utils/icons/materialTableIcons';
import { TABLE_HEIGHT } from 'utils/ui/constants';
import { xlsxToAoO } from 'utils/xlsx';
import DropzoneButton from '../DropzoneButton';
import ErrorTable from '../ErrorTable';
import TemplateDownloadArea from './TemplateDownloadArea';

const TABLE_HEIGHT_CUSTOM = TABLE_HEIGHT(true,71);

const useStyles = makeStyles({
  buttons: {
    backgroundColor: theme.palette.background.light
    //height: 60
  }
});

const columns = [
  { title: 'Tipo ficheiro', field: 'fileType' },
  { title: 'Coluna', field: 'column' },
  {
    title: 'Linha / Coluna / Erro',
    field: 'message',
    render: rowData => <div style={{ color: 'red', fontWeight: 'bold' }}>{rowData.message}</div>
  }
];

const Component = props => {
  const { importEchas, alertError } = props;
  const ref = React.useRef(null);
  const classes = useStyles();

  const [svhc, setSvhc] = useState(false);
  const [svhcSubmit, setSvhcSubmit] = useState(false);
  const [restricoes, setRestricoes] = useState(false);
  const [restricoesSubmit, setRestricoesSubmit] = useState(false);
  const [autorizacao, setAutorizacao] = useState(false);
  const [autorizacaoSubmit, setAutorizacaoSubmit] = useState(false);
  const [importedEchas, setImportedEchas] = useState([]);
  const [importedEchasSubmit, setImportedEchasSubmit] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [importErrors, setImportErrors] = useState([]);

  const toggleShowErrors = () => setShowErrors(!showErrors);


  // TODO this might help later with the XLSX export of ECHAS
  const sanitizedEchaCSV = () => {
    const clonedImportedEchas = cloneDeep(importedEchas);
    return clonedImportedEchas.map(obj => {
      if (Array.isArray(obj[DESCRIPTION_RESTRICTIONS])) {
        obj[DESCRIPTION_RESTRICTIONS] = obj[DESCRIPTION_RESTRICTIONS].map((val, index) => val !== '' ? `${val} || ` : '').join('');
      }
      if (Array.isArray(obj[ENTRYNO])) {
        obj[ENTRYNO] = obj[ENTRYNO].join(', ');
      }
      return obj
    })
  };

  const loadEchaJson = (jsonObj, type) => {
    const sanitizedEcha = sanitizeEcha(jsonObj, type);
    const validation = validateEcha(sanitizedEcha, type);

    if (validation.error) {
      const validationErrors = validation.error.details.map(({ context, message }) => ({
        fileType: type,
        column: context.key,
        message
      }));
      setImportErrors([...importErrors, ...validationErrors]);
      toggleShowErrors();
    }
    setImportedEchas(mergeEcha(importedEchas, sanitizedEcha, type));
  };

  return (
    <React.Fragment>

      <Grid container alignItems='flex-start'>
        <Grid container className={classes.buttons} style={{ height: '100%', padding: 20 }}>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DropzoneButton
              disabled={restricoes}
              submitting={restricoesSubmit}
              label='Importar Restrições'
              onClick={() => {
                setRestricoesSubmit(true);
                document.body.onfocus = () => {
                  setRestricoesSubmit(false);
                  document.body.onfocus = null;
                }
              }}
              onLoadFile={(reader, file) => {
                setRestricoesSubmit(true);
                if (
                  file.name.includes('RESTRICOES') &&
                  (file.name.includes('xlsx') || file.name.includes('XLSX'))
                ) {

                  console.log('xlsxToAoO(reader)', xlsxToAoO(reader));
                  loadEchaJson(xlsxToAoO(reader), RESTRICOES);
                  setRestricoesSubmit(false);
                  setRestricoes(true);
                } else {
                  alertError('Nome do ficheiro tem de conter a palavra "RESTRICOES e formato .XLSX"!');
                  setRestricoesSubmit(false);
                }
              }}
            />
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DropzoneButton
              disabled={svhc}
              submitting={svhcSubmit}
              label='Importar SVHC'
              onClick={() => {
                setSvhcSubmit(true);
                document.body.onfocus = () => {
                  setSvhcSubmit(false)
                  document.body.onfocus = null;
                }
              }}
              onLoadFile={(reader, file) => {
                setSvhcSubmit(true);
                if (
                  file.name.includes('SVHC') &&
                  (file.name.includes('xlsx') || file.name.includes('XLSX'))
                ) {
                  loadEchaJson(xlsxToAoO(reader), SVHC);
                  setSvhcSubmit(false);
                  setSvhc(true);

                } else {
                  alertError('Nome do ficheiro tem de conter a palavra "SVHC" e formato .XLSX!');
                  setSvhcSubmit(false);
                }
              }}
            />
          </Grid>

          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DropzoneButton
              disabled={autorizacao}
              submitting={autorizacaoSubmit}
              label='Importar Autorização'
              onClick={() => {
                setAutorizacaoSubmit(true);
                document.body.onfocus = () => {
                  setAutorizacaoSubmit(false);
                  document.body.onfocus = null;
                }
              }}
              onLoadFile={(reader, file) => {
                setAutorizacaoSubmit(true);
                if (
                  file.name.includes('AUTORIZACAO') &&
                  (file.name.includes('xlsx') || file.name.includes('XLSX'))
                ) {
                  loadEchaJson(xlsxToAoO(reader), AUTORIZACAO);
                  setAutorizacaoSubmit(false);
                  setAutorizacao(true)
                } else {
                  alertError('Nome do ficheiro tem de conter a palavra "AUTORIZACAO" e formato .XLSX!');
                  setAutorizacaoSubmit(false)
                }
              }}
            />
          </Grid>

          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SpinButton
              color='primary'
              disabled={(!svhc || !restricoes || !autorizacao) || importErrors.length > 0}
              submitting={importedEchasSubmit}
              label='Importar ECHAs'
              onClick={() => {
                setImportedEchasSubmit(true)
                importEchas(importedEchas)
                  .then(() => {
                    setImportedEchasSubmit(false)
                    setAutorizacao(false);
                    setRestricoes(false);
                    setSvhc(false);
                    setImportedEchas([]);
                    setImportErrors([]);
                  })
              }}
            />
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12}>
        <BaseTable
          tableRef={ref}
          columns={svhc || restricoes || autorizacao ? importEchaTableColumns : []}
          data={importedEchas}
          onRowClick={() => null}
          options={{
            maxBodyHeight: TABLE_HEIGHT_CUSTOM,
            minBodyHeight: TABLE_HEIGHT_CUSTOM
          }}
          actions={[
            {
              icon: tableIcons.ClearData,
              isFreeAction: true,
              tooltip: 'Limpar tabela',
              onClick: () => {
                setImportedEchas([]);
                setImportErrors([]);
                setAutorizacao(false);
                setRestricoes(false);
                setSvhc(false);
                setAutorizacaoSubmit(false);
                setRestricoesSubmit(false);
                setSvhcSubmit(false);
                setImportedEchasSubmit(false)
              }
            },
            {
              icon: importErrors.length > 0 ? tableIcons.ErrorStyled : tableIcons.EchaListStyled,
              isFreeAction: true,
              tooltip: 'Erros importação ECHA',
              onClick: () => toggleShowErrors()
            }
          ]}
          components={{
            Body: props => (!svhc && !restricoes && !autorizacao ? <TemplateDownloadArea/> : <MTableBody {...props} />)
          }}
        />
      </Grid>
      <Drawer open={showErrors} onClose={toggleShowErrors} coverage={82}>
        <ErrorTable
          columns={columns}
          data={importErrors}
          closeErrors={() => setShowErrors(false)}
        />
      </Drawer>

    </React.Fragment>
  )
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  importEchas: data => dispatch(actions.importEchas(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
