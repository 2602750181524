import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import SpinButton from 'components/Buttons/SpinButton';
import BaseTable from 'components/Tables/BaseTable';
import { actions as actionsAccounts } from 'ducks/accounts';
import { alertError } from 'ducks/alerts';
import { actions as actionsUserAccount } from 'ducks/userAccount';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import theme from 'source/theme';

const styles = theme => ({
  text: {
    marginBottom: theme.spacing(1)
  }
});

const columns = suppliersById => [
  { title: 'Conta cliente', field: 'account_name' },
  { title: 'NIF', field: 'nif' },
  { title: 'Morada', field: 'address' },
  {
    title: 'Fabricante ID / Fornecedor',
    field: 'account_id_fornecedor',
    editable: 'never',
    render: rowData => rowData.account_id_fornecedor ? suppliersById[rowData.account_id_fornecedor].fornecedor : ''
  },
];

const Component = props => {
  const { account, classes, suppliersById } = props;
  const { updateAccount, fetchUserAccount } = props;

  const [submitting, setSubmitting] = useState(false);

  return (
    <Grid item xs={12}>
      <BaseTable
        columns={columns(suppliersById)}
        data={[account]}
        options={{
          search: false,
          toolbar: false,
          maxBodyHeight: null,
          minBodyHeight: null,
          pageSize: 5
        }}
        editable={{
          onRowUpdate: (newData, oldData) => updateAccount(newData.id, newData).then(() => fetchUserAccount())
        }}
      />
      <Grid item xs={12} style={{ padding: theme.spacing(3) }}>
        <div className={classes.text}>
          Adicionar Logotipo ( 100pixels x 100pixels máximo):
        </div>
        <SpinButton
          color='primary'
          label='Adicionar Logotipo'
          onClick={() => {
            setSubmitting(true);
          }}
          submitting={submitting}
        />
      </Grid>
    </Grid>
  )
};


const mapStateToProps = state => ({
  account: state.userAccount.entity,
  suppliersById: state.suppliers.dataById
});

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  updateAccount: (id, data) => dispatch(actionsAccounts.updatePromise(id, data)),
  fetchUserAccount: (params, meta) => dispatch(actionsUserAccount.fetchAll(params, meta))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, theme)(Component));
