import { ALERT_ERROR, DELETE, FETCH, FETCH_RAW_MATERIALS, FULFILLED, LOGOUT, RAW_MATERIAL, REJECTED } from 'actionTypes';
import cloneDeep from 'lodash/cloneDeep';
import { fork, put, select, takeLatest } from 'redux-saga/effects';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';
import thunkFactory from 'utils/redux/baseEntitiesFactory/thunk';
import options from 'utils/redux/reducingOptions/rawMaterials';
import { apiRequest } from 'utils/request';
import { handlerError as sagaErrorHandler } from 'utils/sagasHelpers';


/** Actions -----------------------------------  **/

const handleError = (TYPE, error, dispatch) => {
  console.log('handle error rawMaterials helpers: ', error.response);
  if (error.response) {
    if (error.response.status === 401) {
      dispatch({ type: ALERT_ERROR, payload: error.response.data.detail });
      dispatch({ type: 'LOGOUT' });
    } else {
      dispatch({ type: TYPE + REJECTED, payload: error.response.statusText });
      dispatch({ type: ALERT_ERROR, payload: error.response.statusText });
    }
  } else {
    dispatch({ type: TYPE + REJECTED, payload: 'Unknown error' });
    dispatch({ type: ALERT_ERROR, payload: 'Unknown error' });
  }
};

const importRawMaterialsMultiple = data => dispatch => {
  let submitData = cloneDeep(data)
  submitData = submitData.map(row => {
    row.cas = JSON.stringify(row.cas);
    row.ec = JSON.stringify(row.ec);
    return row;
  })
  return apiRequest('/mp_multiple/', 'POST', submitData)
    .then(response => {
      // Insertion on DB takes a while so the FETCH is delayed
      setTimeout(() => dispatch({ type: FETCH_RAW_MATERIALS }), 5000);
    })
    .catch(error => handleError(RAW_MATERIAL, error, dispatch))
}

const rawActions = actionsFactory(RAW_MATERIAL);
const actions = {
  ...rawActions,
  ...thunkFactory(RAW_MATERIAL, '/mp/'),
  importRawMaterials: importRawMaterialsMultiple
};
export { actions };

/** REDUCER -----------------------------------  **/

const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};


export default reducerFactory(RAW_MATERIAL, initialState, options);



/** SAGAS -------------------------------------  **/

/**
 * When we delete a Raw Material of type MIXTURE should fetch RawMaterials related
 * to maintain store raw material consistency
 *
 * @param payload
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{payload: *, type: string}>>, void, *>}
 */
function* posDeleteRM({ payload }) {
  const getRawMaterial = id => state => state.rawMaterials.dataById[id];
  try {
    const rawMaterial = yield select(getRawMaterial(payload));
    for (let i = 0; i < rawMaterial.inter_substancias.length; i++) {
      yield put({
        type: FETCH + RAW_MATERIAL,
        payload: rawMaterial.inter_substancias[i].id_substancia
      });
    }
  } catch (error) {
    sagaErrorHandler(RAW_MATERIAL, error);
  }
}

function* watchPosDeleteRM() {
  yield takeLatest(DELETE + RAW_MATERIAL + FULFILLED, posDeleteRM);
}

// SAGAS
const sagas = [
  ...sagasFactory(RAW_MATERIAL, '/mp/'),
  fork(watchPosDeleteRM)
];

export { sagas };
