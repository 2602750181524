import { CREATE, DELETE, FETCH, FULFILLED, REJECTED, UPDATE } from 'actionTypes';
import { cloneDeep } from 'lodash';
import arrayToObject from 'utils/arrayToObject';
import pluralize from 'utils/pluralize';
import dataNormalizer from './optionsBaseDataTransform';

let indexToDelete, indexToUpdate, data, dataById;

export default (TYPE, initialState, options) => {
  const PLURAL = pluralize(TYPE);

  return (state = initialState, { meta, payload, type }) => {
    switch (type) {

      case FETCH + TYPE:
      case FETCH + PLURAL:
        return { ...state, errorMessage: '', loading: true };

      case CREATE + TYPE:
      case UPDATE + TYPE:
      case DELETE + TYPE:
        return { ...state, errorMessage: '', loading: true, submitting: true };

      case CREATE + TYPE + FULFILLED:
        payload = dataNormalizer(payload, options);
        data = [...state.data, payload];
        dataById = { ...state.dataById, [payload.id]: payload };
        return { ...state, errorMessage: '', loading: false, submitting: false, data, dataById };

      case UPDATE + TYPE + FULFILLED:
        if (!state.data && !state.dataById) {
          return { ...state, entity: payload };
        }
        payload = dataNormalizer(payload, options);
        data = [...state.data];
        indexToUpdate = data.findIndex(item => item.id === payload.id);
        if (indexToUpdate > -1) {
          data.splice(indexToUpdate, 1, payload)
        }
        dataById = { ...state.dataById, [payload.id]: payload };
        return { ...state, errorMessage: '', loading: false, submitting: false, data, dataById };

      case DELETE + TYPE + FULFILLED:
        dataById = { ...state.dataById };
        delete data[payload];
        data = [...state.data];
        indexToDelete = data.findIndex(item => item.id === payload);
        if (indexToDelete > -1) {
          data.splice(indexToDelete, 1);
        }
        return { ...state, errorMessage: '', loading: false, submitting: false, data, dataById };

      case FETCH + TYPE + FULFILLED:
        if (!state.data && !state.dataById) {
          return { ...state, entity: payload };
        }
        payload = dataNormalizer(payload, options);
        data = [...state.data];
        indexToUpdate = data.findIndex(item => item.id === payload.id);
        if (indexToUpdate > -1) {
          data.splice(indexToUpdate, 1, payload)
        } else {
          data.push(payload);
        }
        dataById = { ...state.dataById, [payload.id]: cloneDeep(payload) };
        return { ...state, errorMessage: '', loading: false, data, dataById };

      case FETCH + PLURAL + FULFILLED:
        if (!state.data && !state.dataById) {
          return { ...state, entity: payload };
        }
        payload = dataNormalizer(payload, options);
        dataById = arrayToObject(payload);
        data = cloneDeep(payload);
        return { ...state, errorMessage: '', loaded: true, loading: false, data, dataById };

      case FETCH + PLURAL + REJECTED:
      case FETCH + TYPE + REJECTED:
        return { ...state, loading: false, errorMessage: payload };

      case CREATE + TYPE + REJECTED:
      case UPDATE + TYPE + REJECTED:
      case DELETE + TYPE + REJECTED:
        return { ...state, loading: false, errorMessage: payload, submitting: false };

      default:
        return state;
    }
  };
};
