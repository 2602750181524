import BaseTable from 'components/Tables/BaseTable';
import { actions as scenariosDescriptorsActions } from 'ducks/scenariosDescriptors'
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreData } from 'selectors/generic';
import theme from 'source/theme';
import arrayToObject from 'utils/arrayToObject';
import columns from 'utils/tables/columns/scenarioExposure/scenarioDescriptorsExposure';

const Component = props => {
  const {
    descriptorId, descriptorsById, descriptorsData, descriptorFilter, lcs,
    scenarioDescriptorsData, productId, scenario, TABLE_HEIGHT, user
  } = props;
  const {
    createScenarioDescriptor, removeScenarioDescriptor, setDescriptorId,
    updateScenarioDescriptor
  } = props;
  const tableRef = React.useRef(null);

  const filteredDescriptors = descriptorsData.filter(v => v.id_tipo === descriptorFilter);

  const filteredScenarioDescriptors = scenarioDescriptorsData.filter(sd =>
    sd.id_cenario === scenario.id &&
    descriptorsById[sd.id_descritor].id_tipo === descriptorFilter
  );

  const fSDByDescriptorId = arrayToObject(filteredScenarioDescriptors, 'id_descritor');

  const [selectedIds, setSelectedIds] = useState(filteredScenarioDescriptors.map(fsd => fsd.id_descritor));
  const [loading, setLoading] = useState(false);

  useEffect(
    () => setSelectedIds(filteredScenarioDescriptors.map(fsd => fsd.id_descritor)),
    // TODO Falta adicionar no useEffect rerun o shipment_id
    [loading, lcs, scenario, productId, scenarioDescriptorsData]
  );

  // TODO Adding Checked info to table, there should be a better way
  const rows = filteredDescriptors.map(descriptor => {
      const ok_flag = fSDByDescriptorId[descriptor.id] && fSDByDescriptorId[descriptor.id].ok_flag;
      const notas = fSDByDescriptorId[descriptor.id] && fSDByDescriptorId[descriptor.id].notas;
      return selectedIds.includes(descriptor.id) ?
        {
          ...descriptor,
          ok_flag: ok_flag ? ok_flag : false,
          notas: notas ? notas : '',
          tableData: { checked: true }
        } :
        descriptor.tableData && descriptor.tableData.checked ?
          { ...descriptor, tableData: { checked: false } } : descriptor
    }
  );
  const reOrderedRows = sortBy(rows, [o => !selectedIds.includes(o.id), 'id']);

  return (
    <React.Fragment>
      <BaseTable
        ref={tableRef}
        title={'ESCOLHA OS DESCRITORES'}
        columns={columns(user)}
        data={scenario ? reOrderedRows : []}
        onRowClick={() => null}
        onRowDoubleClick={setDescriptorId ? rowData => setDescriptorId(rowData.id) : null}
        paging={false}
        options={{
          // toolbar: false,
          // search: false,
          maxBodyHeight: TABLE_HEIGHT,
          minBodyHeight: TABLE_HEIGHT,
          selection: true,
          showTextRowsSelected: false,
          showSelectAllCheckbox: false,
          rowStyle: setDescriptorId ?
            rowData => rowData.id === descriptorId ?
              { backgroundColor: theme.palette.primary.light } :
              null :
            null
        }}
        isLoading={loading}
        onSelectionChange={(rows, difRow) => {
          setLoading(true);
          if (rows.length > selectedIds.length) {
            createScenarioDescriptor({ id_descritor: difRow.id, id_cenario: scenario.id })
              .finally(() => setLoading(false))
          } else {
            const id_sd = filteredScenarioDescriptors.find(fsd => fsd.id_descritor === difRow.id).id;
            removeScenarioDescriptor(id_sd)
              .finally(() => setLoading(false))
          }
        }}
        editable={{
          // isEditable: rowData => selectedIds.includes(rowData.id),
          onRowUpdate: (newData) => {
            setLoading(true);
            if (selectedIds.includes(newData.id)) {
              // update
              const id_sd = filteredScenarioDescriptors.find(fsd => fsd.id_descritor === newData.id).id;
              return updateScenarioDescriptor(id_sd, newData)
                .finally(() => setLoading(false))
            } else {
              // create
              return createScenarioDescriptor({
                id_descritor: newData.id,
                id_cenario: scenario.id,
                notas: newData.notas,
                ok_flag: newData.ok_flag
              })
                .finally(() => setLoading(false))
            }
          }
        }}
      />
    </React.Fragment>
  )
};


const makeMapStateToProps = () => {
  const immutableDescriptorsData = getImmutableStoreData('descriptors')
  const mapStateToProps = (state, props) => ({
    descriptorsData: immutableDescriptorsData(state),
    descriptorsById: state.descriptors.dataById,
    scenarioDescriptorsData: state.scenarioDescriptors.data,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  createScenarioDescriptor: data => dispatch(scenariosDescriptorsActions.createPromise(data)),
  removeScenarioDescriptor: id => dispatch(scenariosDescriptorsActions.removePromise(id)),
  updateScenarioDescriptor: (id, data) => dispatch(scenariosDescriptorsActions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
