import { MTableEditField } from '@material-table/core';
import Done from '@material-ui/icons/Done';
import Remove from '@material-ui/icons/Remove';
import React from 'react';

// title and field properties are used in material table,
// label and key are used in CSV export

export default user => {
  const columns = [
    {
      title: 'Código fornecedor',
      field: 'cod_fornecedor',
      minWidth: 100,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      }
    },
    {
      title: 'Nome',
      field: 'fornecedor',
      minWidth: 500,
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'left'
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>
    },
    {
      title: 'Activo',
      field: 'ativo',
      type: 'boolean',
      minWidth: 50,
      initialEditValue: 'false',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      },
      lookup: {
        true: 'Sim',
        false: 'Não'
      },
      render: rowData =>
        <span style={{fontWeight: rowData.ativo ? 'bold' : null}}>
        {rowData.ativo ? 'Sim' : 'Não' }
      </span>
    }
  ];

  const columnsAdmin = [
    {
      title: 'Identificador',
      field: 'id',
      editable: 'never',
      width: 100
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};
