import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columnsBase = [
  { title: 'Código MP', field: 'mix_cod_mp' },
  { title: 'MP', field: 'mix_mp_nome' },
  { title: 'Código', field: 'codigo_produto' },
  { title: 'Produto', field: 'produto_nome' },
  { title: 'Papel Reach', field: 'papel_reach' }
];

export default props => {
  const { columns = columnsBase, productsById, rawMaterialsById, rowData, title } = props;

  // Direct Products for the substance
  let products = [];
  if (rowData.cod_mp && rowData.cod_mp !== '') {
    const productsIdsSet = new Set(rowData.produtos);
    const productsIds = Array.from(productsIdsSet);
    productsIds.forEach(productId => {
      const isRMOnCarga = productsById[productId].Produto_Mps.find(pmp =>
        pmp.id_mp.id === rowData.id && !pmp.id_mistura_caminho_mp
      );

      if (isRMOnCarga) {
        products.push(productsById[productId])
      }
    });
  }

  // Indirect Products in case it is a Substance used in Mixtures
  rowData.inter_misturas.forEach(interMixture => {
    const mixture = rawMaterialsById[interMixture.id_mistura];
    const productsIdsSet = new Set(mixture.produtos);
    const productsIds = Array.from(productsIdsSet);
    const mixtureProducts = productsIds.map(productId => ({
        ...productsById[productId],
        mix_cod_mp: mixture.cod_mp,
        mix_mp_nome: mixture.mp_nome
      })
    );
    products = [...products, ...mixtureProducts];
  });

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={products} title={title}/>
    </div>
  )
}
