import cloneDeep from 'lodash/cloneDeep';
import echaColumns from 'utils/echa/columns';
import { AUTORIZACAO, CAS, DESCRIPTION_RESTRICTIONS, EC, ENTRYNO, RESTRICOES, SVHC } from 'utils/echa/constants';

export const mergeEcha = (masterEcha = [], echaToMerge, type) => {
  let echa = cloneDeep(masterEcha);
  let columns = [];
  let echaColumnsToMerge, sanitizeOptions = {};

  switch (type) {
    case SVHC:
      columns = [...echaColumns.commonCols, ...echaColumns.svhcCols];
      echaColumnsToMerge = echaColumns.svhcCols;
      break;
    case AUTORIZACAO:
      columns = [...echaColumns.commonCols, ...echaColumns.autorizacaoCols];
      echaColumnsToMerge = echaColumns.autorizacaoCols;
      break;
    case RESTRICOES:
      columns = [...echaColumns.commonCols, ...echaColumns.restricoesCols];
      echaColumnsToMerge = echaColumns.restricoesCols;
      break;
  }

  if (echa.length === 0) {
    echaToMerge.forEach(row => {
      const newRow = {};
      echaColumns.echaColumns.forEach(({ field }) => newRow[field] = row[field]);
      echa.push(newRow);
    });
    return echa;
  }

  echaToMerge.forEach(row => {
    let n = 0;
    // n is for case priority to match should only match one of the n
    while (n < 4) {
      if (n === 0) {
        const matchAll = echa.find(line => row[CAS][0] !== '' && row[EC][0] !== '' && line[CAS][0] === row[CAS][0] && line[EC][0] === row[EC][0]);
        if (matchAll) {
          echaColumnsToMerge.forEach(({ field }) => matchAll[field] = row[field]);
          break;
        }
      } else if (n === 1) {
        const matchEc = echa.find(line => row[EC][0] !== '' && row[CAS][0] === '' && line[CAS][0] === '' && line[EC][0] === row[EC][0]);
        if (matchEc) {
          echaColumnsToMerge.forEach(({ field }) => matchEc[field] = row[field]);
          break;
        }
      } else if (n === 2) {
        const matchCas = echa.find(line => row[CAS][0] !== '' && row[EC][0] === '' && line[EC][0] === '' && line[CAS][0] === row[CAS][0]);
        if (matchCas) {
          echaColumnsToMerge.forEach(({ field }) => matchCas[field] = row[field]);
          break;
        }
      } else if (n === 3) {
        const noMatch = {};
        // columns.forEach(({ field }) => noMatch[field] = row[field]);
        echaColumns.echaColumns.forEach(({ field }) => noMatch[field] = row[field]);
        echa.push(noMatch);
        break;
      }
      n++;
    }
  });

  return echa;
};

export default mergeEcha;

