import React from 'react';
import theme from 'source/theme';

export default ({ row }) =>
  <div style={{ backgroundColor: theme.palette.background.light }}>
    <ul style={{ margin: 0, paddingTop: 10, paddingBottom: 10 }}>
      {
        row.flowsInfo.map((product, index) =>
          <li key={index}>{product.code + ' - ' + product.name}</li>
        )
      }
    </ul>
  </div>
