import { Button, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from 'components/Alert';
import Header from 'connectedComponents/Header';
import { cleanAlert } from 'ducks/alerts';
import { register } from 'ducks/auth';
import styles from 'pages/Auth/LoginPage/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch'
  },
  container: {
    bottom: 0,
    left: 0,
    position: 'relative',
    right: 0,
    top: 64,
    height: 'calc(100vh - 64px)'
  },
  item: {
    marginTop: 10
  },
  heading: {
    marginTop: 20
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}));



const Component = props => {
  const { alert } = props;
  const { cleanAlert, register } = props;
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [nif, setNif] = useState('');
  const [contacto, setContacto] = useState('');


  const handleChangeEmail = event => setEmail(event.target.value);
  const handleChangePassword = event => setPassword(event.target.value);
  const handleChangeUsername = event => setUsername(event.target.value);
  const handleChangeFirstName = event => setFirstName(event.target.value);
  const handleChangeLastName = event => setLastName(event.target.value);
  const handleChangeCompany = event => setCompany(event.target.value);
  const handleChangeNif = event => setNif(event.target.value);
  const handleChangeContacto = event => setContacto(event.target.value);
  const handleSubmit = () => {
    const data = {
      user: {
        email,
        password,
        username,
        first_name: firstName,
        last_names: lastName,
      },
      account: {
        account_name: company,
      }
    };
    register(data);
  };

  return (
    <React.Fragment>
      <Header noLoggedUser={true}/>
      <div className={classes.container}>
        <Alert
          open={alert.message.length !== 0}
          message={alert.message}
          handleClose={cleanAlert}
          placement={{ vertical: 'bottom', horizontal: 'left' }}
          variant={'error'}
        />

        <ValidatorForm onSubmit={handleSubmit}>
          <Grid
            container
            // direction='column'
            justify='center'
            //alignItems='center'
          >
            <Grid item xs={8} className={classes.heading} >
              <Typography variant="h5" component="h2">
                Registo de empresa:
              </Typography>
            </Grid>

            <Grid item xs={8} className={classes.item}>
              <TextValidator
                id="username"
                name="username"
                onChange={handleChangeUsername}
                value={username}
                label="Username"
                style={{ margin: 8 }}
                placeholder="John Doe"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                validators={['required']}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={8} className={classes.item}>
              <TextValidator
                id="password"
                name="password"
                onChange={handleChangePassword}
                value={password}
                label="Password"
                style={{ margin: 8 }}
                placeholder="************"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                validators={['required']}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={8} className={classes.item}>
              <TextValidator
                id="first-name"
                name="first-name"
                onChange={handleChangeFirstName}
                value={firstName}
                label="Primeiro nome"
                style={{ margin: 8 }}
                placeholder="John"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                validators={['required']}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={8} className={classes.item}>
              <TextValidator
                id="last-name"
                name="last-name"
                onChange={handleChangeLastName}
                value={lastName}
                label="Ultimo nome"
                style={{ margin: 8 }}
                placeholder="Doe"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                validators={['required']}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={8} className={classes.item}>
              <TextValidator
                id="email"
                name="email"
                onChange={handleChangeEmail}
                value={email}
                label="Email"
                style={{ margin: 8 }}
                placeholder="johndoe@reachok.com"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                validators={['required', 'isEmail']}
                errorMessages={['this field is required', 'email is not valid']}
              />
            </Grid>
            <Grid item xs={8} className={classes.item}>
              <TextValidator
                id="company"
                name="company"
                onChange={handleChangeCompany}
                value={company}
                label="Empresa"
                style={{ margin: 8 }}
                placeholder="ReachOK Lda."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                validators={['required']}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={8} className={classes.item}>
              <TextValidator
                id="nif"
                name="nif"
                onChange={handleChangeNif}
                value={nif}
                label="NIF"
                style={{ margin: 8 }}
                placeholder="999999999"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                validators={['required']}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={8} className={classes.item}>
              <TextValidator
                id="contacto"
                name="contacto"
                onChange={handleChangeContacto}
                value={contacto}
                label="Contacto"
                style={{ margin: 8 }}
                placeholder="963428475"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                validators={['required']}
                errorMessages={['this field is required']}
              />
            </Grid>

            <Grid item xs={8} className={classes.item} style={{marginTop: 10}}>
              <Button
                type="submit"
                className={classes.textTransformationNone}
                variant="contained"
                color="primary"
              >
                Criar conta
              </Button>
            </Grid>


          </Grid>
        </ValidatorForm>
      </div>
    </React.Fragment>
  )

}

Component.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  register(data) {
    dispatch(register(data));
  },
  cleanAlert() {
    dispatch(cleanAlert());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
