import BigDialog from 'components/BigDialog';
import BaseTable from 'components/Tables/BaseTable';
import { actions as productActions } from 'ducks/products';
import { actions as productRMActions } from 'ducks/productsRM';
import { sortBy } from 'lodash';
import ShipmentInserterTable from 'pages/Products/ProductRM/shipmentInserterTable';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { filterByFunctionSelector } from 'selectors/generic';
import theme from 'source/theme';
import { MIXTURE } from 'utils/constants/coreConstants';
import { UJ } from 'utils/constants/papel_reach';
import columns from 'utils/tables/columns/product/productInRawMaterials';

const defaultProductRM = (
  id_produto,
  id_mp,
  mp_final = false,
  mp_formulacao = true,
  mp_excluido_final = false,
  contribui_perigo = false,
  id_mistura_caminho_mp = null,
  id_mistura_caminho = null,
) => ({
  id_produto, id_mp, mp_final, mp_formulacao, mp_excluido_final,
  contribui_perigo, id_mistura_caminho_mp, id_mistura_caminho
});

const customProductRM = (id_produto, id_mp, preExistentMP, id_mistura_caminho_mp = null, id_mistura_caminho = null) => ({
  id_produto,
  id_mp,
  // mp_final: preExistentMP.mp_final,
  mp_final: false,
  // mp_formulacao: preExistentMP.mp_formulacao,
  mp_formulacao: true,
  mp_excluido_final: preExistentMP.mp_excluido_final,
  contribui_perigo: preExistentMP.contribui_perigo,
  id_mistura_caminho_mp,
  id_mistura_caminho
});

const Component = props => {
  let {
    highlightMP, productId, productsById, rawMaterials, rawMaterialsById, user
  } = props;
  const {
    createProductRM, fetchProduct, handleCloseDrawer, setHighlightSubstances,
    removeProductRM, setHighlightMP
  } = props;

  const tableRef = useRef(null);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedPMps, setselectedPMps] = useState([]);

  const [RMShipmentInsert, setRMShipmentInsert] = useState(null);
  const [posShipmentInsertRequest, setPosShipmentInsertRequest] = useState(null);
  const handleCloseShipmentInserter = () => {
    setLoading(false);
    setPosShipmentInsertRequest(null);
  }

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // TODO take a look at this implementation ... ??? no optimal
    // TODO the conditions ( prm.id_mp.cod_mp !== '' &&  prm.id_mp.cod_mp !== null ) are to avoid the garbage that might come from API
    const matchProductRM = productsById[productId].Produto_Mps.filter(prm =>
      prm.id_mp && !prm.id_mistura_caminho_mp && prm.mp_formulacao && prm.id_mp.cod_mp !== '' && prm.id_mp.cod_mp !== null
    );

    setselectedPMps(matchProductRM);
    setSelectedIds(matchProductRM.map(prm => prm.id_mp.id));
  }, [productsById]);

  /**
   * return true if no shipment is found for MP as UJ. If any is detected
   * returns false. No need to create new shipment as UJ
   */
  const createShipmentForRM = (rm) => {
    const shipments = rm.inter_fornecedores;
    if (shipments.length === 0) {
      return true;
    } else {
      const notUJ = shipments.map(s => s.papel_reach !== UJ).every(t => t);
      if (notUJ) {
        return true;
      }
    }
    return false;
  };

  rawMaterials = rawMaterials.map(subst => {
    if (selectedIds.includes(subst.id)) {
      const pMp = selectedPMps.find(pMp => pMp.id_mp.id === subst.id)
      return { ...subst, tableData: { checked: true }, id_pmp: pMp.id }
    } else if (subst.tableData && subst.tableData.checked) {
      return { ...subst, tableData: { checked: false } }
    } else {
      return subst;
    }
  });

  const orderedSubstances = sortBy(rawMaterials, [o => !selectedIds.includes(o.id)]);

  const InRawMaterialSelectorTable = () => (
    <BaseTable
      ref={tableRef}
      titleBack={true}
      titleAction={handleCloseDrawer}
      title={`CARGA`}
      columns={columns(user)}
      data={orderedSubstances}
      options={{
        selection: true,
        showTextRowsSelected: false,
        showSelectAllCheckbox: false,
        rowStyle: rowData => ({
          backgroundColor: highlightMP.includes(rowData.id) && rowData.tableData.checked ?
            theme.palette.primary.light : ''
        })
      }}
      onRowClick={() => null}
      onRowDoubleClick={rowData => {
        if (rowData.tipo === MIXTURE) {
          setHighlightSubstances(rowData.inter_substancias.map(substance => substance.id_substancia));
        } else {
          setHighlightSubstances([rowData.id]);
        }
        setHighlightMP([rowData.id]);
      }}
      isLoading={loading}
      onSelectionChange={(rows, difRow) => {
        setLoading(true);
        if (rows.length > selectedIds.length) {

          const filteredProductRM = productsById[productId].Produto_Mps.filter(pMP => rawMaterialsById[pMP.id_mp.id].tipo !== MIXTURE);

          // Case you are inserting a SUBSTANCE OR POLIMER
          if (rawMaterialsById[difRow.id].tipo !== MIXTURE) {
            const productRMCreate = () => () =>
              createProductRM(baseProductRM)
                .then(() => fetchProduct(productId))
                .catch(e => alert(e))
                .finally(() => setLoading(false))

            let baseProductRM = defaultProductRM(productId, difRow.id);
            const preExistentMP = filteredProductRM.find(pMP => pMP.id_mp.id === difRow.id)
            if (preExistentMP) {
              baseProductRM = customProductRM(productId, difRow.id, preExistentMP);
            }
            if (createShipmentForRM(difRow)) {
              setRMShipmentInsert(difRow);
              //TODO send CLOSE to SHIPMENT dialog box
              setPosShipmentInsertRequest(productRMCreate);
            } else {
              createProductRM(baseProductRM)
                .then(() => fetchProduct(productId))
                .catch(e => alert(e))
                .finally(() => setLoading(false))
            }
            // Case you are inserting a MIXTURE
          } else {
            const promiseArray = [() => createProductRM(defaultProductRM(productId, difRow.id))];

            // const substancesFromMixIds = rawMaterialsById[difRow.id].inter_substancias.map(s => s.id_substancia);
            // substancesFromMixIds.forEach(substanceId => {
            //   const preExistentMP = filteredProductRM.find(pMP => pMP.id_mp.id === substanceId)
            //   if (preExistentMP) {
            //     promiseArray.push(() => createProductRM(customProductRM(productId, substanceId, preExistentMP, difRow.id)));
            //   }
            // });

            const interSubstances = rawMaterialsById[difRow.id].inter_substancias;
            interSubstances.forEach( interSubstances => {
              const preExistentMP = filteredProductRM.find(pMP => pMP.id_mp.id ===  interSubstances.id_substancia)
              if (preExistentMP) {
                promiseArray.push(() => createProductRM(
                  customProductRM(productId,  interSubstances.id_substancia, preExistentMP, difRow.id, interSubstances.id))
                );
              }
            });

            const productRMmixCreate = () => () => {
              return Promise.all(promiseArray.map( promise => promise()))
                .then(() => fetchProduct(productId))
                .catch(e => alert(e))
                .finally(() => setLoading(false))
            }

            if (createShipmentForRM(difRow)) {
              setRMShipmentInsert(difRow);
              //TODO send CLOSE to SHIPMENT dialog box
              setPosShipmentInsertRequest(productRMmixCreate);
            } else {
              return Promise.all(promiseArray.map( promise => promise()))
                .then(() => fetchProduct(productId))
                .catch(e => alert(e))
                .finally(() => setLoading(false))
            }
          }
        } else {
          const productRMToRemove = productsById[productId].Produto_Mps.find(pmp => difRow.id_pmp === pmp.id)
          removeProductRM(productRMToRemove.id)
            .then(() => fetchProduct(productId))
            .catch(e => alert(e))
            .finally(() => setLoading(false))
        }
      }}
    />
  )

  return (
    <React.Fragment>
      <InRawMaterialSelectorTable/>
      {
        posShipmentInsertRequest &&
        <BigDialog open={true} handleClose={handleCloseShipmentInserter}>
          <ShipmentInserterTable
            fullSize={true}
            rawMaterial={RMShipmentInsert}
            postInsertRequest={posShipmentInsertRequest}
            closeParentContainer={handleCloseShipmentInserter}
            shipmentType={productsById[productId].papel_reach}
          />
        </BigDialog>
      }
    </React.Fragment>

  )
};


const makeMapStateToProps = () => {
  const rawMaterials = filterByFunctionSelector(
    'rawMaterials',
    rm => rm.cod_mp !== null && rm.cod_mp !== undefined && rm.cod_mp !== ''
  );
  const mapStateToProps = (state, props) => ({
    rawMaterials: rawMaterials(state),
    rawMaterialsById: state.rawMaterials.dataById,
    productsById: state.products.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  fetchProduct: id => dispatch(productActions.fetchOne(id)),
  createProductRM: data => dispatch(productRMActions.createPromise(data)),
  removeProductRM: id => dispatch(productRMActions.removePromise(id))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
