import BigDialog from 'components/BigDialog';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions as actionsRawMaterials } from 'ducks/rawMaterials';
import { actions as actionsRawMatSuppliers } from 'ducks/rawMaterialSuppliers';
import { actions as actionsSupplierRegister } from 'ducks/supplierRegister';
import Suppliers from 'pages/Inventory/Suppliers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { lookUpIdValueSelector } from 'selectors/generic';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/shipment';
import { validator as shipmentRegisterValidator } from 'utils/tables/validators/shipmentRegistersMixtures';
import { validator as shipmentValidator } from 'utils/tables/validators/shipments';

const Component = props => {
  const { idAccount, fullSize, productsById, rawMaterial, shipmentId, suppliers } = props;
  const {
    alertError, closeParentContainer, createRawMatSupplier, createSupplierRegister,
    fetchRawMaterial, removeRawMatSupplier, removeSupplierRegister, rawMaterials,
    setParentLoading, setShipment, updateRawMatSupplier, updateSupplierRegister
  } = props;
  const tableRef = React.useRef(null);

  let shipments = rawMaterial.inter_fornecedores;

  const [boxSuppliers, setBoxSuppliers] = useState(false);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (rawMaterial.inter_fornecedores.length !== 0) {
      const firstShipment = rawMaterial.inter_fornecedores[0];
      setShipment(firstShipment.id);
    }
  }, [])

  const onToggleBoxSuppliers = () => setBoxSuppliers(!boxSuppliers);

  const initValuesForColumns = {};

  shipments = shipments.map(shipment => {
    //const registration = shipment.registos.find(register => register['id_mp_fornecedor'] && !register['id_mp_substancia']);
    const registration = shipment['registos_directos'] && shipment['registos_directos'].length === 1 ?
      shipment['registos_directos'][0] : initValuesForColumns;
    const supplier = shipment['id_fornecedor'] && shipment['id_fornecedor']['id'];

    return {
      ...registration,
      ...shipment,
      id_fornecedor: supplier
    }
  })

  return (
    <React.Fragment>
      <BaseTable
        ref={tableRef}
        titleBack={true}
        titleAction={closeParentContainer}
        title={
          `${rawMaterial.cod_mp} - 
          ${rawMaterial.mp_nome}
          ${rawMaterial.cas.length > 0 ? ` - [CAS] ${rawMaterial.cas}` : ''}
          ${rawMaterial.ec.length > 0 ? ` - [EC] ${rawMaterial.ec}` : ''}`
        }
        columns={columns(rawMaterial.tipo, suppliers)}
        data={shipments}
        isLoading={loading}
        paging={false}
        halfSize={!fullSize}
        options={{
          rowStyle: rowData => ({
            backgroundColor: rowData.id === shipmentId ?
              theme.palette.primary.light : ''
          })
        }}
        onRowClick={()=> null}
        onRowDoubleClick={rowData => setShipment(rowData.id)}
        editable={{
          onRowAdd: newData => {
            const sValidation = shipmentValidator(newData, rawMaterials);
            const srValidation = shipmentRegisterValidator(newData, undefined);
            if (sValidation.error || srValidation.error) {
              alertError([
                ...sValidation.error ? sValidation.error : [],
                ...srValidation.error ? srValidation.error : []
              ])
              return Promise.reject()
            }
            setLoading(true);
            setParentLoading(true);
            const id_mp = rawMaterial.id;
            const tempData = { ...newData, id_account: idAccount, id_mp };
            return createRawMatSupplier(tempData)
              .then((data) => {
                const id_mp_fornecedor = data.id;
                setShipment(id_mp_fornecedor);
                const tempData = { ...newData, id_account: idAccount, id_mp_fornecedor };
                return createSupplierRegister(tempData);
              })
              .then(() => fetchRawMaterial(rawMaterial.id))
              .catch(e => alert(e))
              .finally(() => {
                setLoading(false);
                setParentLoading(false);
              })
          },

          onRowUpdate: (newData) => {
            const sValidation = shipmentValidator(newData, rawMaterials);
            const srValidation = shipmentRegisterValidator(newData, undefined);
            if (sValidation.error || srValidation.error) {
              alertError([
                ...sValidation.error ? sValidation.error : [],
                ...srValidation.error ? srValidation.error : []
              ])
              return Promise.reject()
            }
            setLoading(true);
            setParentLoading(true);
            return updateRawMatSupplier(newData.id, { ...newData })
              .then(() => {
                if (newData['registos_directos'].length === 0) {
                  const id_mp_fornecedor = newData.id;
                  const tempData = { ...newData, id_account: idAccount, id_mp_fornecedor };
                  return createSupplierRegister(tempData);
                } else {
                  updateSupplierRegister(newData['registos_directos'][0].id, { ...newData })
                }
              })
              .then(() => fetchRawMaterial(rawMaterial.id))
              .catch(e => alert(e))
              .finally(() => {
                setLoading(false);
                setParentLoading(false);
              })
          },

          onRowDelete: (oldData) => {
            // TODO If PapelReach UJ cannot delete the last shipmentUJ if MP in a product mp_formulação true or inter_substâncias de mix com mp_formulação true
            // TODO If PapelReach Fabricante mp_formulação false && id_mistura_caminho_mp === null

            // TODO Should be corrected in API
            const productSet = new Set(rawMaterial.produtos);
            const productIds = Array.from(productSet);

            // switch (oldData.papel_reach) {
            //   case FABRICANTE:
            //   case DISTRIBUIDOR:
            //     const productsRMwhereProducerDistributor = [];
            //     productIds.forEach( productId =>
            //       productsById[productId].Produto_Mps.forEach( prm => {
            //         if (prm.id_mp.id === rawMaterial.id && !prm.mp_formulacao && !prm.id_mistura_caminho_mp) {
            //           productsRMwhereProducerDistributor.push(prm);
            //         }
            //       })
            //     );
            //
            //     if (productsRMwhereProducerDistributor.length > 0) {
            //       const productList = productsRMwhereProducerDistributor.map(prm => prm.id_produto.produto_nome).join(', ');
            //       alertError(`Fornecimento não pode ser apagado, substância usada na reacção dos seguintes produtos:  ${productList}`);
            //       return Promise.resolve();
            //     }
            //
            //     break;
            //
            //   case UJ:
            //     const productsRMwhereUJ = [];
            //     productIds.forEach( productId =>
            //       productsById[productId].Produto_Mps.forEach( prm => {
            //         const mixturesWhereRawMaterial = rawMaterial.inter_misturas.map( mix => mix.id_mistura);
            //         if (prm.id_mp.id === rawMaterial.id && prm.mp_formulacao || mixturesWhereRawMaterial.includes(prm.id_mp.id)) {
            //           productsRMwhereUJ.push(prm);
            //         }
            //       })
            //     );
            //
            //     if (productsRMwhereUJ.length > 0) {
            //       const productList = productsRMwhereUJ.map(prm => prm.id_produto.produto_nome).join(', ');
            //       alertError(`Fornecimento não pode ser apagado, substância usada na carga dos seguintes produtos:  ${productList}`);
            //       return Promise.resolve();
            //     }
            //     break;
            // }

            setLoading(true);
            setParentLoading(true);
            setShipment(undefined);
            return removeRawMatSupplier(oldData.id)
              .then(() => fetchRawMaterial(rawMaterial.id))
              .catch(e => alert(e))
              .finally(() => {
                setLoading(false);
                setParentLoading(false);
              })
          }
        }}
        actions={
          [
            {
              isFreeAction: true,
              icon: tableIcons.Supplier,
              tooltip: 'Visualizar Fornecedores',
              onClick: (event, rowData) => onToggleBoxSuppliers()
            }
          ]
        }
      />
      <BigDialog open={boxSuppliers} handleClose={onToggleBoxSuppliers}>
        {
          boxSuppliers &&
          <Suppliers tableOptions={{ maxBodyHeight: '50vh', minBodyHeight: '50vh', pageSize: 20 }}/>
        }
      </BigDialog>
    </React.Fragment>
  )
};

const makeMapStateToProps = () => {
  const suppliers = lookUpIdValueSelector('suppliers', ['cod_fornecedor', 'fornecedor'], item => item.ativo);
  const mapStateToProps = (state, props) => ({
    rawMaterials: state.rawMaterials.data,
    productsById: state.products.dataById,
    idAccount: state.profile.entity.id_account,
    suppliers: suppliers(state)
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  createRawMatSupplier: data => dispatch(actionsRawMatSuppliers.createPromise(data)),
  removeRawMatSupplier: id => dispatch(actionsRawMatSuppliers.removePromise(id)),
  updateRawMatSupplier: (id, data) => dispatch(actionsRawMatSuppliers.updatePromise(id, data)),
  createSupplierRegister: data => dispatch(actionsSupplierRegister.createPromise(data)),
  removeSupplierRegister: id => dispatch(actionsSupplierRegister.removePromise(id)),
  updateSupplierRegister: (id, data) => dispatch(actionsSupplierRegister.updatePromise(id, data)),
  fetchRawMaterial: id => dispatch(actionsRawMaterials.fetchOne(id))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
