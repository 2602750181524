import {
  SET_APP_BAR_TITLE,
  SET_CONTEXT_SEARCH_RM,
  SET_CONTEXT_SEARCH_PRODUCT,
  TOGGLE_MENU,
  TOGGLE_MODAL,
  TOGGLE_SIDEBAR
} from 'actionTypes';

export const toggleMenu = () => ({
  type: TOGGLE_MENU
});

export const toggleModal = () => ({
  type: TOGGLE_MODAL
});

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR
});

export const setContextSearchRM = searchText => ({
  type: SET_CONTEXT_SEARCH_RM,
  payload: searchText
});

export const setContextSearchProduct = searchText => ({
  type: SET_CONTEXT_SEARCH_PRODUCT,
  payload: searchText
});

// PAGE RAW MATERIALS
export const confirmBoxOpen = payload => ({
  type: 'CONFIRM_BOX_OPEN',
  payload
});

export const confirmBoxClose = () => ({
  type: 'CONFIRM_BOX_CLOSE',
});

export const setAppBarTitle = payload => ({
  type: 'SET_APP_BAR_TITLE',
  payload
});

const INITIAL_STATE = {
  booting: false,
  showMenu: false,
  showModal: false,
  showSidebar: false,
  pageRM: {
    confirmBox: null
  },
  appBarTitle: '',
  context: {
    searchRM: '',
    searchProduct: '',
  }
};

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case TOGGLE_MENU:
      return { ...state, showMenu: !state.showMenu };

    case TOGGLE_MODAL:
      return { ...state, showModal: !state.showModal };

    case TOGGLE_SIDEBAR:
      return { ...state, showSidebar: !state.showSidebar };

    case SET_APP_BAR_TITLE:
      return { ...state, appBarTitle: payload };

    case SET_CONTEXT_SEARCH_RM:
      return { ...state, context: { ...state.context, searchRM: payload } };

    case SET_CONTEXT_SEARCH_PRODUCT:
      return { ...state, context: { ...state.context, searchProduct: payload } };

    case 'CONFIRM_BOX_OPEN':
      return {
        ...state,
        pageRM: { confirmBox: payload }
      };

    case 'CONFIRM_BOX_CLOSE':
      return {
        ...state,
        pageRM: { confirmBox: null }
      };

    default:
      return state;
  }
};
