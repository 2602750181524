import Grid from '@material-ui/core/Grid';
import Conditions from 'pages/ScenarioExposure/Flow/ScenariosManagement/ConditionsTable';
import Descriptors from 'pages/ScenarioExposure/Flow/ScenariosManagement/DescriptorsTable';
import AnalysisConditionsTable from 'pages/ScenarioExposure/Flow/ScenariosManagement/PROCTrabalhadores/AnalysisConditionsTable';
import AnalysisDescriptorsTable from 'pages/ScenarioExposure/Flow/ScenariosManagement/PROCTrabalhadores/AnalysisDescriptorsTable';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PROC } from 'utils/constants/descriptorTypes';
import { lcsTranslator } from 'utils/constants/lcsTypes';
import { TABLE_HEIGHT_SPLIT_4VIEW } from 'utils/ui/constants';

const TABLE_HEIGHT = TABLE_HEIGHT_SPLIT_4VIEW(0);


const Component = props => {
  const {
    descriptorsData, lcs, scenario, flowScenarioDescriptors, shipmentsById, substanceId,
    stepRawMaterialsData
  } = props;

  // TODO Conditions at this level
  const [descriptorId, setDescriptorId] = useState(null);
  const [analysisDescriptorId, setAnalysisDescriptorId] = useState(null);

  /**
   *  Filter stepRawMaterials to detect if a substance participates in flow/step/lcs.
   *  If a substance doesn't participate in a step (Flow) && lcs, that !!step should
   *  be filtered out from flowScenarioDescriptors fSD.
   *  So below we are finding the STEPS list for the present LCS and SUBSTANCE
   */

  const stepsFromSubstance = stepRawMaterialsData
    .filter(srm =>
      srm.id_mp === shipmentsById[scenario.id_substancia_fornecedor].id_mp &&
      (srm.descriptors && srm.descriptors.includes(lcsTranslator[lcs.id]))
    )
    .map(srm => srm.id_etapa);

  /**
   * Filter ScenarioDescriptors that make sense according to DESCRIPTOR and STEPS found above.
   */
  const filteredDescriptors = descriptorsData.filter(v => v.id_tipo === PROC);
  const filteredDescriptorsIds = filteredDescriptors.map(fD => fD.id);
  const filteredSD = flowScenarioDescriptors.filter(fSD =>
    filteredDescriptorsIds.includes(fSD.id_descritor) && stepsFromSubstance.includes(fSD.id_etapa)
  );

  return (
    <Grid container>
      <Grid item xs={7}>
        <Descriptors
          descriptorId={descriptorId}
          setDescriptorId={setDescriptorId}
          descriptorFilter={PROC}
          scenario={scenario}
          substanceId={substanceId}
          lcs={lcs}
          TABLE_HEIGHT={TABLE_HEIGHT}
        />
      </Grid>
      <Grid item xs={5}>
        <AnalysisDescriptorsTable
          lcs={lcs}
          substanceId={substanceId}
          descriptorId={analysisDescriptorId}
          setDescriptorId={setAnalysisDescriptorId}
          descriptorFilter={PROC}
          flowScenarioDescriptors={filteredSD}
          TABLE_HEIGHT={TABLE_HEIGHT}
        />
      </Grid>
      <Grid item xs={7}>
        <Conditions
          descriptorFilter={PROC}
          descriptorId={descriptorId}
          conditionsFilter={'Trabalhadores'}
          scenario={scenario}
        />
      </Grid>
      <Grid item xs={5}>
        <AnalysisConditionsTable
          descriptorId={analysisDescriptorId}
          flowScenarioDescriptors={filteredSD}
          conditionsFilter={'Trabalhadores'}
        />
      </Grid>
    </Grid>
  )
};

const mapStateToProps = (state) => ({
  stepRawMaterialsData: state.stepRawMaterials.data,
  descriptorsData: state.descriptors.data,
  shipmentsById: state.rawMaterialSuppliers.dataById
})

export default connect(mapStateToProps)(Component);
