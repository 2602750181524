const shortDate = date => {
  if (!date) {
    return ""
  }
  if (typeof date === 'string') {
    const d = new Date(date);
    return isNaN(d.getDate()) ?
      undefined :
      (d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear())
  }
  return (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())
};


export {
  shortDate
}
