import BaseTable from 'components/Tables/BaseTable';
import SubstanceShipmentsListDetailPanel from 'pages/Evaluation/Product/ScenariosManagement/SubstanceShipmentsListDetailPanel';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreData } from 'selectors/generic';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/evaluation/productPcSuScenarioDescriptorExposure';

const Component = props => {
  const { augmentedDescriptors, descriptorId, TABLE_HEIGHT } = props;
  const { setDescriptorId } = props;
  const tableRef = useRef();

  return (
    <React.Fragment>
      <BaseTable
        ref={tableRef}
        title={'DESCRITORES DE PRODUTO'}
        columns={columns}
        data={augmentedDescriptors}
        paging={false}
        onRowClick={() => null}
        onRowDoubleClick={setDescriptorId ? rowData => setDescriptorId(rowData.id) : null}
        options={{
          // toolbar: false,
          // search: false,
          maxBodyHeight: TABLE_HEIGHT,
          minBodyHeight: TABLE_HEIGHT,
          rowStyle: setDescriptorId ? rowData => ({
              backgroundColor: (rowData.id === descriptorId) ?
                theme.palette.primary.light :
                theme.palette.background.veryLight
            }) :
            ({ backgroundColor: theme.palette.background.veryLight })
        }}
        detailPanel={[
          () => ({
            icon: tableIcons.Substance,
            tooltip: 'Produtos',
            render: rowData => <SubstanceShipmentsListDetailPanel row={rowData}/>
          })
        ]}
      />
    </React.Fragment>
  )
};

const makeMapStateToProps = () => {
  const immutableDescriptorsData = getImmutableStoreData('descriptors')
  const mapStateToProps = (state, props) => ({
    descriptorsData: immutableDescriptorsData(state),
    descriptorsById: state.descriptors.dataById,
    productsById: state.products.dataById,
    rawMaterialsById: state.rawMaterials.dataById,
    scenarios: state.scenarios.data,
    scenariosById: state.scenarios.dataById,
    scenarioDescriptors: state.scenarioDescriptors.data,
    shipmentsById: state.rawMaterialSuppliers.dataById,
  })
  return mapStateToProps;
}

export default connect(makeMapStateToProps)(Component);
