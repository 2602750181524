import { FETCH_MESSAGES, FULFILLED, REJECTED } from 'actionTypes';
import { delay, fork, put, takeLatest } from 'redux-saga/effects';
import { fetch } from 'utils/sagasHelpers';

// actions
const actions = {
  fetchMessages: () => ({
    type: FETCH_MESSAGES
  })
};

export { actions };

// reducer
const INITIAL_STATE = {
  data: [],
  error: null,
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MESSAGES:
      return { ...state, loading: true };

    case `${FETCH_MESSAGES}${FULFILLED}`:
      return { ...INITIAL_STATE, data: action.payload };

    case `${FETCH_MESSAGES}${REJECTED}`:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};

// sagas
const POOL_INTERVAL = 60 * 1000;

function* poolMessages() {
  while (true) {
    try {
      yield delay(POOL_INTERVAL);
      yield put({ type: FETCH_MESSAGES });
    } catch (error) {
      console.error(error);
    }
  }
}

function* watchMessages() {
  yield takeLatest(FETCH_MESSAGES, fetch(FETCH_MESSAGES, '/messages'));
}

const sagas = [
  //fork(poolMessages),
  fork(watchMessages)
];

export { sagas };
