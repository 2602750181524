import Grid from '@material-ui/core/Grid';
import ScenariosProductSelectableDetailPanel from 'components/DetailPanels/ScenariosProductSelectableDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { setContextSearchProduct } from 'ducks/ui';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { filterByFieldSelector } from 'selectors/generic';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/product/productSimple';
import noIcon from 'utils/tables/noIcon';


const Component = props => {
  const { contextSearch, lcsById, products, scenarios, user } = props;
  const { openDescriptors, openProductScenarios, setSearch } = props;
  const tableRef = useRef(null);

  const onSelectingScenario = rowData => productScenario =>
    openDescriptors(rowData.id, productScenario.id_lcs)

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={columns(user)}
        data={products}
        onRowClick={() => null}
        onRowDoubleClick={rowData => openProductScenarios(rowData.id)}
        onSearchChange={ text => setSearch(text)}
        options={{
          actionsCellStyle: {
            width: 150,
            paddingLeft: 20
          },
          searchText: contextSearch
        }}
        actions={[
          {
            icon: tableIcons.Scenarios(),
            tooltip: 'Gerir cenários',
            onClick: (event, rowData) => openProductScenarios(rowData.id)
          },
          // {
          //   icon: tableIcons.ContentCopy,
          //   tooltip: 'Copiar e criar cenário',
          //   onClick: (event, rowData) => {
          //     alert('Cópia de cenário ainda não implementada');
          //   }
          // }
        ]}
        detailPanel={[
          rowData => {
            const productScenario = scenarios.find(s => s.id_produto && s.id_produto == rowData.id);
            if (productScenario) {
              return ScenariosProductSelectableDetailPanel(rowData, onSelectingScenario(rowData), scenarios, lcsById)
            } else {
              return noIcon;
            }
          }
        ]}
      />
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const immutableProducts = filterByFieldSelector('products', 'perigoso', [true]);
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchProduct,
    products: immutableProducts(state),
    scenarios: state.scenarios.data,
    lcsById: state.descriptors.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  setSearch: search => dispatch(setContextSearchProduct(search))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
