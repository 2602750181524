import arrayToObject from 'utils/arrayToObject';

export const matchEchaRawMaterial = (rawMaterials, echas) => {
  let match = {};
  let newMatchs = [];
  const rawMaterialsObj = arrayToObject(rawMaterials);

  /** 1st level match **/
    //extract CAS && EC echas
  let ecCasEchas = echas.filter(item => item.cas.length > 0 && item.ec.length > 0);

  let ecEchas = echas.filter(item => item.cas.length === 0 && item.ec.length > 0);
  let casEchas = echas.filter(item => item.cas.length > 0 && item.ec.length === 0);



  //extract CAS && EC raw material cases
  let tempRawMaterials = rawMaterials.filter(item => item.cas.length > 0 && item.ec.length > 0);

  tempRawMaterials.forEach(rm => {
    rm.ec.forEach(rmEc => {
      rm.cas.forEach(rmCas => {
        ecCasEchas.forEach(echa => {
          echa.ec.forEach(echaEc => {
            echa.cas.forEach(echaCas => {
              if (echaCas === rmCas && echaEc === rmEc) {
                if (match[rm.id]) {
                    match[rm.id].push(echa.id);
                } else {
                  match[rm.id] = [echa.id];
                  newMatchs.push(rm.id);
                }
              }
            })
          });
        });
      });
    });
  });

  /** 2nd level match **/
  //extract only EC echas
  ecCasEchas = echas.filter(item => item.ec.length > 0);

  // CAS && EC matched removal from raw materials
  newMatchs.forEach(id => delete rawMaterialsObj[id]);
  tempRawMaterials = Object.values(rawMaterialsObj).filter(item => item.ec.length > 0);
  newMatchs = [];

  tempRawMaterials.forEach(rm => {
    rm.ec.forEach(rmEc => {
      ecEchas.forEach(echa => {
        echa.ec.forEach(echaEc => {
          if (echaEc === rmEc) {
            if (match[rm.id]) {
                match[rm.id].push(echa.id);
            } else {
              match[rm.id] = [echa.id];
              newMatchs.push(rm.id);
            }
          }
        })
      });
    });
  });

  /** 3rd level match **/
  //extract only CAS echas
  ecCasEchas = echas.filter(item => item.cas.length > 0);

  // CAS && EC matched removal from raw materials
  newMatchs.forEach(id => delete rawMaterialsObj[id]);
  tempRawMaterials = Object.values(rawMaterialsObj).filter(item => item.cas.length > 0);
  newMatchs = [];

  tempRawMaterials.forEach(rm => {
    rm.cas.forEach(rmCas => {
      casEchas.forEach(echa => {
        echa.cas.forEach(echaCas => {
          if (echaCas === rmCas ) {
            if (match[rm.id]) {
                match[rm.id].push(echa.id);
            } else {
              match[rm.id] = [echa.id];
              newMatchs.push(rm.id);
            }
          }
        });
      });
    });
  });

  return match
};
