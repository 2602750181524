import Grid from '@material-ui/core/Grid';
import Drawer from 'components/Drawer';
import ShipmentDetailTable from 'pages/Inventory/Shipments/ShipmentDetailTable';
import ShipmentTable from 'pages/Inventory/Shipments/ShipmentTable';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { MIXTURE } from 'utils/constants/coreConstants';
import RawMaterialsTable from './rawMaterialsTable';

const Component = props => {
  const { rawMaterialsById } = props;

  const [rawMaterial, setRawMaterial] = useState(undefined);
  const [shipmentId, setShipmentId] = useState(undefined);
  const [parentLoading, setParentLoading] = useState(false);

  const onCloseDrawer = () => {
    setRawMaterial(undefined);
    setShipmentId(undefined);
  }

  const shipments = () => {
    if (rawMaterial) {
      if (rawMaterial.tipo === MIXTURE) {
        return (
          <Drawer open={true} onClose={onCloseDrawer}>
            <ShipmentTable
              fullSize={false}
              shipmentId={shipmentId}
              rawMaterial={rawMaterialsById[rawMaterial.id]}
              setShipment={setShipmentId}
              setParentLoading={setParentLoading}
              closeParentContainer={onCloseDrawer}
            />
            <ShipmentDetailTable
              parentLoading={parentLoading}
              rawMaterial={rawMaterialsById[rawMaterial.id]}
              shipmentId={shipmentId}
              closeParentContainer={onCloseDrawer}
            />
          </Drawer>
        )
      } else {
        return (
          <Drawer open={true} onClose={onCloseDrawer}>
            <ShipmentTable
              fullSize={true}
              shipmentId={shipmentId}
              rawMaterial={rawMaterialsById[rawMaterial.id]}
              setShipment={setShipmentId}
              setParentLoading={setParentLoading}
              closeParentContainer={onCloseDrawer}
            />
          </Drawer>
        )
      }
    }
  }

  return (
    <Grid item xs={12}>
      <RawMaterialsTable setRawMaterial={setRawMaterial}/>
      {shipments()}
    </Grid>
  )
};

const mapStateToProps = state => ({
  rawMaterialsById: state.rawMaterials.dataById
});

export default connect(mapStateToProps, null)(Component);
