import deepClone from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import arrayToObject from 'utils/arrayToObject';
import { POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';
import { DISTRIBUIDOR } from 'utils/constants/papel_reach';

export const lookUpIdValueSelector = (storeTag, fields, filterFunction) => createSelector(
  [getStoreById(storeTag, filterFunction)],
  dataStoreById => {
    const keys = Object.keys(dataStoreById);
    const lookUp = {};
    keys.forEach(key => lookUp[key] = fields.map(field => dataStoreById[key][field]).join(' - '));
    return lookUp;
  }
);

/**
 * deepClone is being used on the under selectors because
 * material-table mutates the state directly, to avoid this
 * problem and conflicts when there are multiple tables on the same view
 * we use deepClone.
 * (otherwise, warnings appear due to react key property collisions
 * inside material table)
 * To introduce immutability a selector that only clones is used
 */

const getStore = storeTag => state => state[storeTag];
const getStoreData = storeTag => state => state[storeTag].data;
const getStoreById = (storeTag, filterFunction) => state => filterFunction ?
  arrayToObject(state[storeTag].data.filter(filterFunction), 'id') :
  state[storeTag].dataById;

const getStoreDataByPropValue = (storeTag, prop, property) =>
  (state, props) =>
    state[storeTag].data.filter(i => {
        if (typeof property === 'string') {
          return i[property] === props[prop]
        } else {
          // TODO ask for change in API do not use for scenarios id_fluxogram = Object use integer id
          return i[Object.keys(property)[0]] && i[Object.keys(property)[0]][Object.values(property)[0]] === props[prop]
        }
      }
    );

export const getImmutableStore = storeTag => createSelector(
  [getStore(storeTag)],
  data => deepClone(data)
);

export const getImmutableStoreData = storeTag => createSelector(
  [getStoreData(storeTag)],
  data => deepClone(data)
);

export const getImmutableStoreById = storeTag => createSelector(
  [getStoreById(storeTag)],
  data => deepClone(data)
);

/**
 * Generic filters
 */
export const filterByFieldSelector = (storeTag, field, values) => createSelector(
  [getStoreData(storeTag)],
  data => {
    let clonedData = deepClone(data);
    if (typeof field === 'string') {
      if (Array.isArray(values)) {
        return clonedData.filter(item => values.includes(item[field]))
      } else {
        return clonedData.filter(item => item[field] === values)
      }
    } else if (Array.isArray(field)) {
      field.forEach((fd, index) => {
        if (Array.isArray(values[index])) {
          clonedData = clonedData.filter(item => values.includes(item[fd]))
        } else {
          clonedData = clonedData.filter(item => item[fd] === values)
        }
      })
    }
  }
);

export const filterByPropFieldSelector = (storeTag, prop, property) => createSelector(
  [getStoreDataByPropValue(storeTag, prop, property)],
  data => deepClone(data)
);

export const filterByFunctionSelector = (storeTag, filterFunction) => createSelector(
  [getStoreData(storeTag)],
  data => {
    const clonedData = deepClone(data);
    return clonedData.filter(filterFunction)
  }
);

/**
 * Specific filters
 */

/**
 --- stepRawMaterials
 Needs to go to Flows to know wich steps make part of that flow
 Nees to know the rawMaterial in question
 Crosses both to find if there are stepRawMaterials for that rawMaterial
 */

const getStepRawMaterials = () =>
  (state, props) => state.stepRawMaterials.data.filter(i =>
    i['id_mp'] === props.rawMaterialId
    && props.flowSteps.map(s => s.id).includes(i['id_etapa'])
  );

export const filterStepRawMaterials = () => createSelector(
  [getStepRawMaterials()],
  data => deepClone(data)
);

/**
 --- substanceWithDirectAndIndirectShipment
 Goes to raw Materials Filters substances with shipment
 or substance that are in Mixs that have Shipments
 Of The shipments mentioned above only the ones chosen are:
 shipment.papel_reach !== DISTRIBUIDOR && shipment.registos_directos[0].ce !== 'nao' && shipment.registos_directos[0].ce !== 'na'
 */

const getSubstanceWithDirectAndIndirectShipment = (state, props) =>
  state.rawMaterials.data.filter(rm => {
      if ([SUBSTANCE, POLIMER].includes(rm.tipo)) {

        if (rm.inter_fornecedores.length > 0) {

          for (let i = 0; i < rm.inter_fornecedores.length; i++) {
            const shipment = rm.inter_fornecedores[i];
            if (shipment.papel_reach !== DISTRIBUIDOR && shipment.registos_directos[0].ce !== 'nao' && shipment.registos_directos[0].ce !== 'na') {
              return true;
            }
          }
        }

        if (rm.inter_misturas.some(mix => state.rawMaterials.dataById[mix.id_mistura].inter_fornecedores.length > 0)) {

          for (let i = 0; i < rm.inter_misturas.length; i++) {

            const mix = state.rawMaterials.dataById[rm.inter_misturas[i].id_mistura];
            for (let k = 0; k < mix.inter_fornecedores.length; k++) {
              const shipment = mix.inter_fornecedores[k];

              if (shipment.papel_reach !== DISTRIBUIDOR) {
                if (shipment.substancias_c_registos.length > 0) { // If there are no registers is the equivalent to the empty ce case which means should be in the list

                  const reg = shipment.substancias_c_registos.find(register => register.id_substancia.id === rm.id);
                  if (!reg || reg.registos.length === 0 || (reg.registos[0].ce !== 'nao' && reg.registos[0].ce !== 'na')) {
                    return true;
                  }
                } else {
                  return true;
                }
              }
            }
          }
        }
      }
      return false
    }
  );

export const filterSubstanceWithDirectAndIndirectShipment = () => createSelector(
  [getSubstanceWithDirectAndIndirectShipment],
  data => deepClone(data)
);


const getDangerousSubstanceWithDirectAndIndirectShipment = (state, props) =>
  state.rawMaterials.data.filter(rm => {
      if ([SUBSTANCE, POLIMER].includes(rm.tipo)) {

        const dangerousProducts = rm.produtos.find(id => {
          const substanceInProduct = state.products.dataById[id].Produto_Mps.find(mp => mp.id_mp.id === rm.id);
          return substanceInProduct && substanceInProduct.contribui_perigo;
        });

        if (dangerousProducts) {
          /**
           * Should reuse the above selector, code repeated
           */
          if (rm.inter_fornecedores.length > 0) {

            for (let i = 0; i < rm.inter_fornecedores.length; i++) {
              const shipment = rm.inter_fornecedores[i];
              if (shipment.papel_reach !== DISTRIBUIDOR && shipment.registos_directos[0].ce !== 'nao' && shipment.registos_directos[0].ce !== 'na') {
                return true;
              }
            }
          }

          if (rm.inter_misturas.some(mix => state.rawMaterials.dataById[mix.id_mistura].inter_fornecedores.length > 0)) {

            for (let i = 0; i < rm.inter_misturas.length; i++) {

              const mix = state.rawMaterials.dataById[rm.inter_misturas[i].id_mistura];
              for (let k = 0; k < mix.inter_fornecedores.length; k++) {
                const shipment = mix.inter_fornecedores[k];

                if (shipment.papel_reach !== DISTRIBUIDOR) {
                  if (shipment.substancias_c_registos.length > 0) { // If there are no registers is the equivalent to the empty ce case which means should be in the list

                    const reg = shipment.substancias_c_registos.find(register => register.id_substancia.id === rm.id);
                    if (!reg || reg.registos.length === 0 || (reg.registos[0].ce !== 'nao' && reg.registos[0].ce !== 'na')) {
                      return true;
                    }
                  } else {
                    return true;
                  }
                }
              }
            }
          }
        }
      }
      return false
    }
  );

export const filterDangerousSubstanceWithDirectAndIndirectShipment = () => createSelector(
  [getDangerousSubstanceWithDirectAndIndirectShipment],
  data => deepClone(data)
);
