import Grid from '@material-ui/core/Grid';
import Drawer from 'components/Drawer';
import BaseTable from 'components/Tables/BaseTable'
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/flows';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/flows/flowsSteps';
import { getImmutableStoreData } from 'selectors/generic';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/flows';
import validator from 'utils/tables/validators/flows';
import StepsTable from './StepsTable';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const { flows, user } = props;
  const { alertError, create, remove, update } = props;
  const tableRef = React.useRef(null);

  const [flowId, setFlowId] = useState(undefined);
  const [stepsOpen, setStepsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseDrawer = () => {
    setFlowId(undefined);
    setStepsOpen(false);
  };

  const closeStepsContainerConfirm = () => {
    setFlowId(undefined);
    setStepsOpen(false);
  };

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={columns(user)}
        data={flows}
        onRowClick={() => null}
        onRowDoubleClick={rowData => {
          setFlowId(rowData.id);
          setStepsOpen(true);
        }}
        options={{
          actionsCellStyle: {
            width: 200,
            paddingLeft: 20
          }
        }}
        isLoading={loading}
        actions={[
          {
            icon: tableIcons.Steps,
            tooltip: 'Gerir etapas',
            onClick: (event, rowData) => {
              setFlowId(rowData.id);
              setStepsOpen(true);
            }
          },
          {
            isFreeAction: true,
            icon: tableIcons.Pdf,
            tooltip: 'Relatório PDF',
            onClick: (event, rowData) => {
              const filteredTableData = tableRef.current.state.data;
              pdfMake.createPdf(docDefinition(filteredTableData)).open();
            }
          }
        ]}
        editable={{
          onRowAdd: newData => {
            setLoading(true);
            const validation = validator(newData, flows)
            if (validation.error) {
              setLoading(false);
              alertError(validation.error)
              return Promise.reject();
            } else {
              return create(validation)
                .catch((e) => alert(e))
                .finally(() => setLoading(false))
            }
          },
          onRowUpdate: (newData, oldData) => {
            setLoading(true);
            const validation = validator(newData, flows)
            if (validation.error) {
              alertError(validation.error)
              setLoading(false);
              return Promise.resolve();
              // TODO Instead of rejecting we are resolving, should be rejecting. Have to update library later github issue #2086
            } else {
              return update(newData.id, newData)
                .catch((e) => alert(e))
                .finally(() => setLoading(false))
            }
          },
          onRowDelete: oldData => {
            setLoading(true);
            return remove(oldData.id)
              .catch((e) => alert(e))
              .finally(() => setLoading(false))
          }
        }}
      />
      <Drawer
        open={!!flowId && stepsOpen}
        onClose={handleCloseDrawer}
      >
        {
          stepsOpen &&
          <StepsTable
            flowId={flowId}
            closeParentContainerConfirm={closeStepsContainerConfirm}
          />
        }
      </Drawer>
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const flows = getImmutableStoreData('flows');
  const mapStateToProps = (state, props) => ({
    flows: flows(state),
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  create: data => dispatch(actions.createPromise(data)),
  remove: id => dispatch(actions.removePromise(id)),
  update: (id, data) => dispatch(actions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
