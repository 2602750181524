import { MTableBody } from '@material-table/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SpinButton from 'components/Buttons/SpinButton';
import Drawer from 'components/Drawer';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions as actionsRawMaterials } from 'ducks/rawMaterials'
import { actions as actionsSuppliers } from 'ducks/suppliers'
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import { IMPORT_RMS, IMPORT_SUPPLIERS } from 'utils/import/constants';
import rawMaterialColumns from 'utils/tables/columns/inventory/rawMaterials';
import supplierColumns from 'utils/tables/columns/inventory/supplier';
import { sanitize as sanitizeRawMaterial, validate as validateRawMaterial } from 'utils/tables/validators/importRawMaterials';
import { sanitize as sanitizeSupplier, validate as validateSupplier } from 'utils/tables/validators/importSupplier';
import { TABLE_HEIGHT } from 'utils/ui/constants';
import { xlsxToAoO } from 'utils/xlsx';
import XLSX from 'xlsx';
import DropzoneButton from '../DropzoneButton';
import ErrorTable from '../ErrorTable';
import TemplateDownloadArea from './TemplateDownloadArea';

const TABLE_HEIGHT_CUSTOM = TABLE_HEIGHT(true,71);

const useStyles = makeStyles({
  buttons: {
    backgroundColor: theme.palette.background.light
    //height: 60
  }
});

const errorColumns = [
  { title: 'Tipo ficheiro', field: 'fileType' },
  { title: 'Linha/Coluna', field: 'label' },
  { title: 'Valor', field: 'value' },
  {
    title: 'Linha / Coluna / Erro',
    field: 'message',
    render: rowData => <div style={{ color: 'red', fontWeight: 'bold' }}>{rowData.message}</div>
  }
];

const Component = props => {
  const {
    alertError, existingRawMaterials, existingSuppliers, importRawMaterials,
    importSuppliers
  } = props;
  const classes = useStyles();
  const tableRef = useRef();

  const [rms, setRms] = useState(false);
  const [rmsSubmit, setRmsSubmit] = useState(false);
  const [suppliers, setSuppliers] = useState(false);
  const [suppliersSubmit, setSuppliersSubmit] = useState(false);
  const [importedData, setImportedData] = useState([]);
  const [importedDataSubmit, setImportedDataSubmit] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [importErrors, setImportErrors] = useState([]);

  const toggleShowErrors = () => setShowErrors(!showErrors);
  const clearState = () => {
    setImportedDataSubmit(false)
    setSuppliers(false);
    setRms(false);
    setImportedData([]);
    setImportErrors([]);
    setSuppliersSubmit(false);
    setRmsSubmit(false);
  };

  const loadJson = (jsonObj, type) => {
    let sanitizedData;
    let validation;

    switch (type) {
      case IMPORT_RMS:
        sanitizedData = sanitizeRawMaterial(jsonObj);
        validation = validateRawMaterial(sanitizedData, existingRawMaterials);
        break;
      case IMPORT_SUPPLIERS:
        sanitizedData = sanitizeSupplier(jsonObj)
        validation = validateSupplier(sanitizedData, existingSuppliers);
        break;
    }

    if (validation.error) {
      const validationErrors = validation.error.details.map(({ context, message }) => ({
        fileType: type,
        column: context.key,
        label: context.label,
        value: context.value,
        message
      }));
      setImportErrors([...importErrors, ...validationErrors]);
      toggleShowErrors();
    }
    setImportedData(sanitizedData);
  };

  let columns = [];
  if (rms) {
    columns = rawMaterialColumns({});
  } else if (suppliers) {
    columns = supplierColumns({});
  }

  return (
    <React.Fragment>
      <Grid container alignItems='flex-start'>
        <Grid container className={classes.buttons} style={{ height: '100%', padding: 20 }}>

          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

            <DropzoneButton
              disabled={rms || suppliers}
              submitting={rmsSubmit}
              label='Importar Matérias Primas'
              onClick={() => {
                setRmsSubmit(true);
                document.body.onfocus = () => {
                  setRmsSubmit(false);
                  document.body.onfocus = null;
                }
              }}
              onLoadFile={(reader, file) => {
                setRmsSubmit(true);
                if (
                  file.name.includes('MATERIAS_PRIMAS') &&
                  (file.name.includes('xlsx') || file.name.includes('XLSX'))
                ) {
                  loadJson(xlsxToAoO(reader), IMPORT_RMS);
                  setRmsSubmit(false);
                  setRms(true);
                } else {
                  alertError('Nome do ficheiro tem de conter a palavra "MATERIAS_PRIMAS" e tem de ser formato .XLSX');
                  setRmsSubmit(false);
                }
              }}
            />
          </Grid>

          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DropzoneButton
              disabled={suppliers || rms}
              submitting={suppliersSubmit}
              label='Importar Fornecedores'
              onClick={() => {
                setSuppliersSubmit(true);
                document.body.onfocus = () => {
                  setSuppliersSubmit(false);
                  document.body.onfocus = null;
                }
              }}
              onLoadFile={(reader, file) => {
                setSuppliersSubmit(true);
                if (
                  file.name.includes('FORNECEDORES') &&
                  (file.name.includes('xlsx') || file.name.includes('XLSX'))
                ) {
                  loadJson(xlsxToAoO(reader), IMPORT_SUPPLIERS);
                  setSuppliersSubmit(false);
                  setSuppliers(true);
                } else {
                  alertError('Nome do ficheiro tem de conter a palavra "FORNECEDORES" e tem de ser formato .XLSX');
                  setSuppliersSubmit(false);
                }
              }}
            />
          </Grid>

          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SpinButton
              color='primary'
              disabled={(!rms && !suppliers) || importErrors.length > 0}
              submitting={importedDataSubmit}
              label='Importar Dados'
              onClick={() => {
                setImportedDataSubmit(true)
                if (rms) {
                  importRawMaterials(importedData)
                    .then(() => clearState())
                } else if (suppliers) {
                  importSuppliers(importedData)
                    .then(() => clearState())
                }
              }}
            />
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12}>
        <BaseTable
          ref={tableRef}
          columns={columns}
          data={importedData}
          onRowClick={() => null}
          options={{
            maxBodyHeight: TABLE_HEIGHT_CUSTOM,
            minBodyHeight: TABLE_HEIGHT_CUSTOM
          }}
          actions={[
            {
              icon: tableIcons.ClearData,
              isFreeAction: true,
              tooltip: 'Limpar tabela',
              onClick: () => clearState()
            },
            {
              icon: importErrors.length > 0 ? tableIcons.ErrorStyled : tableIcons.EchaListStyled,
              isFreeAction: true,
              tooltip: 'Avisos/Erros Importação',
              onClick: () => toggleShowErrors()
            }
          ]}
          components={{
            Body: props => (!rms && !suppliers ? <TemplateDownloadArea/> : <MTableBody {...props} />)
          }}
        />
      </Grid>
      <Drawer open={showErrors} onClose={toggleShowErrors} coverage={82}>
        <ErrorTable
          columns={errorColumns}
          data={importErrors}
          closeErrors={() => setShowErrors(false)}
        />
      </Drawer>
    </React.Fragment>
  )
};

const mapStateToProps = (state) => ({
  existingSuppliers: state.suppliers.data,
  existingRawMaterials: state.rawMaterials.data
});

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  importRawMaterials: data => dispatch(actionsRawMaterials.importRawMaterials(data)),
  importSuppliers: data => dispatch(actionsSuppliers.importSuppliers(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
