import Grid from '@material-ui/core/Grid';
import ShipmentsSupplierDetailPanel from 'components/DetailPanels/ShipmentsSupplierDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/suppliers';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/inventory/suppliers';
import { getImmutableStoreData } from 'selectors/generic';
import exportXLSX from 'utils/export/rawMaterials';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/supplier';
import noIcon from 'utils/tables/noIcon';
import supplierValidator from 'utils/tables/validators/suppliers';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const { suppliers, suppliersById, rawMaterials, user } = props;
  const { alertError, create, remove, update } = props;
  const tableRef = React.useRef(null);

  const shipmentsBySupplierId = {};
  rawMaterials.forEach(rm => {
    if (rm.inter_fornecedores.length > 0) {
      rm.inter_fornecedores.forEach(shipment => {
        if (shipment.id_fornecedor) {
          if (shipmentsBySupplierId[shipment.id_fornecedor.id]) {
            shipmentsBySupplierId[shipment.id_fornecedor.id].push(shipment);
          } else {
            shipmentsBySupplierId[shipment.id_fornecedor.id] = [shipment];
          }
        }
      })
    }
  })


  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={columns(user)}
        data={suppliers}
        onRowClick={() => null}
        actions={[
          {
            isFreeAction: true,
            icon: tableIcons.Pdf,
            tooltip: 'Relatório PDF',
            onClick: (event, rowData) => {
              const filteredTableData = tableRef.current.state.data;
              pdfMake.createPdf(docDefinition(filteredTableData)).open();
            }
          },
          {
            isFreeAction: true,
            icon: tableIcons.Xlsx,
            tooltip: 'Exportar XLSX',
            onClick: exportXLSX(columns(user), tableRef, 'FORNECEDORES.xlsx')
          }
        ]}
        detailPanel={[
          rowData => !shipmentsBySupplierId[rowData.id] || shipmentsBySupplierId[rowData.id].length === 0 ?
            noIcon :
            {
              icon: tableIcons.Shipments,
              tooltip: 'Fornecimentos',
              render: () => <ShipmentsSupplierDetailPanel rowData={shipmentsBySupplierId[rowData.id]} data={suppliersById} title={'Fornecimentos'}/>
            }
        ]}
        editable={{
          onRowAdd: newData => {
            const validation = supplierValidator(newData, suppliers)
            if (validation.error) {
              alertError(validation.error)
              return Promise.reject();
            } else {
              return create(validation);
            }
          },
          onRowUpdate: (newData, oldData) => {
            const validation = supplierValidator(newData, suppliers)
            if (validation.error) {
              alertError(validation.error)
              return Promise.reject();
            } else {
              return update(newData.id, newData);
            }

          },
          onRowDelete: oldData => {
            const supplierUsed = rawMaterials.some(rm =>
              rm.inter_fornecedores.some(ship => ship.id_fornecedor && (ship.id_fornecedor.id === oldData.id))
            );
            if (supplierUsed) {
              alertError('Não pode apagar o Fornecedor pois está a ser usado em Fornecimentos.');
              return Promise.resolve();
            } else {
              return remove(oldData.id)
            }
          }
        }}
      />
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const immutableSuppliers = getImmutableStoreData('suppliers');
  const mapStateToProps = (state, props) => ({
    rawMaterials: state.rawMaterials.data,
    suppliers: immutableSuppliers(state),
    suppliersById: state.suppliers.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  create: data => dispatch(actions.createPromise(data)),
  remove: id => dispatch(actions.removePromise(id)),
  update: (id, data) => dispatch(actions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
