import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columnsBase = [
  { title: 'Codigo MP', field: 'mix_cod_mp' },
  { title: 'MP', field: 'mix_mp_nome' },
  { title: 'Codigo Fornecimento', field: 'cod_fornecimento' },
  { title: 'Designação comercial', field: 'designacao_comercial' },
  { title: 'Papel Reach', field: 'papel_reach' },
  { title: 'Codigo Fornecedor', field: 'cod_fornecedor' },
  { title: 'Nome', field: 'fornecedor' },
  {
    title: 'Ativo',
    field: 'ativo',
    render: value =>
      <span style={{ fontWeight: value ? 'bold' : null }}>
        {value ? 'Sim' : 'Não'}
      </span>
  }
];

export default props => {
  const { columns = columnsBase, suppliersById, rawMaterialsById, rowData, title,  } = props;

  // Direct Shipments for the substance
  let shipments = rowData.inter_fornecedores.map(fsup => ({
      ...fsup.id_fornecedor ? suppliersById[fsup.id_fornecedor.id] : [],
      designacao_comercial: fsup.designacao_comercial,
      papel_reach: fsup.papel_reach,
      cod_fornecimento: fsup.codigo
    })
  );

  // Indirect Shipments in case it is a Substance used in Mixtures
  rowData.inter_misturas.forEach(interMixture => {
    const mixture = rawMaterialsById[interMixture.id_mistura];
    const mixtureShipments = mixture.inter_fornecedores.map(fsup => ({
        ...fsup.id_fornecedor ? suppliersById[fsup.id_fornecedor.id] : [],
        mix_cod_mp: mixture.cod_mp,
        mix_mp_nome: mixture.mp_nome,
        designacao_comercial: fsup.designacao_comercial,
        papel_reach: fsup.papel_reach,
        cod_fornecimento: fsup.codigo
      })
    );

    shipments = [...shipments, ...mixtureShipments];
  });


  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={shipments} title={title}/>
    </div>
  )
}
