import FluxogramSelector from 'connectedComponents/HeaderButtons/FluxogramRadioSelector';
import LCSSelector from 'connectedComponents/HeaderButtons/LcsRadioSelector';
import ScenarioRenderer from 'connectedComponents/HeaderButtons/ScenarioRadioSelector';
import React from 'react';

const Component = props => {
  const { currentOptionFlow, currentOptionLcs, currentOptionScenario, disabled } = props;

  return (
    <React.Fragment>
      &nbsp;&nbsp;
      <FluxogramSelector disabled={disabled} currentOption={currentOptionFlow} onSelection={()=>{}}/>
      &nbsp;&nbsp;
      <LCSSelector disabled={disabled} currentOption={currentOptionLcs} onSelection={()=>{}}/>
      &nbsp;&nbsp;
      <ScenarioRenderer disabled={disabled} currentOption={currentOptionScenario}/>
    </React.Fragment>
  )
};

export default Component;
