import ButtonTable from 'components/Buttons/ButtonTableDialog';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { lcsNames } from 'utils/constants/lcsTypes';

const BUTTONS_TEXT = ['Cenário: '];

const COLUMNS = [
  { title: 'Código', field: 'codigo' },
  { title: 'LCS', field: 'lcs' },
  { title: 'Designação', field: 'designacao' }
];

const OPTIONS_KEY = [
  { tag: 'codigo' },
  { tag: 'lcs' },
  { tag: 'designacao' }
];

const OPTIONS_KEYS = [OPTIONS_KEY];

const Component = props => {
    const { currentOption, options } = props;
    const { onSelection } = props;

    const augmentedOptions = options.map(option =>
      ({
        ...option,
        lcs: lcsNames[option['id_lcs']]
      })
    );

    const augmentedCurrentOption = currentOption ? {
      ...currentOption,
      lcs: lcsNames[currentOption['id_lcs']]
    } : null


    const onSelectionID = obj => onSelection(obj.id)

    return (
      <ButtonTable
        buttonsText={BUTTONS_TEXT}
        currentOption={augmentedCurrentOption}
        header='Seleccione um cenário'
        optionKeys={OPTIONS_KEYS}
        options={augmentedOptions}
        onSelection={onSelectionID}
        columns={COLUMNS}
        maxWidth='xl'
      />
    )
  }
;

const mapStateToProps = state => ({
  scenariosById: state.scenarios.dataById
})

export default connect(mapStateToProps)(Component);

Component.propTypes = {
  currentOption: PropTypes.object,
  onSelection: PropTypes.func.isRequired
};
