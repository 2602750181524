import React from 'react';

export default user => {
  const columns = [
    {
      title: 'Código',
      field: 'codigo_produto',
      minWidth: 100,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      }
    },
    {
      title: 'Nome',
      field: 'produto_nome',
      minWidth: 400,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      }
    },
    {
      title: 'Papel Reach',
      field: 'papel_reach',
      minWidth: 200,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      }
    }
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};
