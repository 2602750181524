import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';
import arrayToObject from 'utils/arrayToObject';
import { DESCRIPTOR_TYPES, DESCRIPTORS_WITH_CONDITIONS_TYPES, ERC, PROC } from 'utils/constants/descriptorTypes';
import { shortDate } from 'utils/helperFunctions/dateFormat';

const themeLight = theme.palette.primary.light;
const themeMain = theme.palette.primary.main;
const lightGrey = '#EEEEEE';

const rowGenerator = (substance, scenarios, data) => {
  const {
    conditionsData, descriptorsData, descriptorsById, scenarioDescriptorsData,
    scenarioDescriptorConditionsData
  } = data

  const rows = [];

  const conditionsFilter = {
    [ERC]: 'Ambiente',
    [PROC]: 'Trabalhadores'
  }

  const descriptorInjector = (rows, descriptor, fSDByDescriptorId) => {
    const ok_flag = fSDByDescriptorId[descriptor.id] && fSDByDescriptorId[descriptor.id].ok_flag;
    const notas = fSDByDescriptorId[descriptor.id] && fSDByDescriptorId[descriptor.id].notas;

    rows.push([
      { text: descriptor.codigo, alignment: 'left', bold: true },
      { text: descriptor.descricao, alignment: 'left', colSpan: 3, bold: true },
      {},
      {},
      { text: ok_flag ? 'OK' : 'NOT OK', alignment: 'center', bold: true },
      { text: notas, alignment: 'left', bold: true }
    ])

  }

  const conditionInjector = (rows, condition, fsdcByConditionId) => {
    const valor = fsdcByConditionId[condition.id].valor;
    const id_unidade = fsdcByConditionId[condition.id].id_unidade;
    const ok_flag = fsdcByConditionId[condition.id].ok_flag;
    const notas = fsdcByConditionId[condition.id].notas;

    rows.push([
      { text: '', border: [false, false, false, false] },
      { text: valor, alignment: 'left' },
      { text: id_unidade ? data.unitsById[parseInt(id_unidade)].nome : '', alignment: 'left' },
      { text: condition.nome, alignment: 'left' },
      { text: ok_flag ? 'OK' : 'NOT OK', alignment: 'center' },
      { text: notas, alignment: 'left' }
    ])
  }

  // Substance
  rows.push([
    { text: substance.mp_nome,  fillColor: themeMain, colSpan: 3, margin: [10, 0, 0, 0] },
    {},
    {},
    { text: `CAS: ${substance.cas}`, fillColor: themeMain, colSpan: 2 },
    {},
    { text: `EC: ${substance.ec}`, fillColor: themeMain},
  ]);

  scenarios.map(scenario => {

    //LCS
    rows.push([
      { text: data.lcsById[scenario.id_lcs].codigo, fillColor: themeLight, colSpan: 2, margin: [10, 0, 0, 0] },
      {},
      { text: data.lcsById[scenario.id_lcs].descricao, fillColor: themeLight, colSpan: 4 },
      {},
      {},
      {}
    ]);

    //Supplier shipment
    const shipment = data.shipmentsById[scenario.id_substancia_fornecedor];
    const supplier = data.suppliersById[shipment.id_fornecedor];
    rows.push([
      { text: supplier.fornecedor, alignment: 'left', fillColor: lightGrey, colSpan: 3, margin: [10, 0, 0, 0] },
      {},
      {},
      { text: shipment.designacao_comercial, alignment: 'left', fillColor: lightGrey, colSpan: 1 },
      { text: `Data FDS ${shortDate(shipment.data_fds)}`, fillColor: lightGrey, colSpan: 2 },
      {}
    ]);

    //scenario
    rows.push([
      { text: scenario.codigo, alignment: 'left', fillColor: lightGrey, colSpan: 2, margin: [10, 0, 0, 0] },
      {},
      { text: scenario.designacao, alignment: 'left', fillColor: lightGrey, colSpan: 2 },
      {},
      { text: scenario.notas, alignment: 'left', fillColor: lightGrey, colSpan: 2 },
      {}
    ]);


    DESCRIPTOR_TYPES.forEach(descriptorFilter => {

      const filteredDescriptors = descriptorsData.filter(v => v.id_tipo === descriptorFilter);
      const filteredScenarioDescriptors = scenarioDescriptorsData.filter(sd =>
        sd.id_cenario === scenario.id &&
        descriptorsById[sd.id_descritor].id_tipo == descriptorFilter
      );
      const fSDByDescriptorId = arrayToObject(filteredScenarioDescriptors, 'id_descritor')
      const selectedDescriptorIds = filteredScenarioDescriptors.map(fsd => fsd.id_descritor);

      filteredDescriptors.forEach(descriptor => {

        // Inject descriptor on rows
        if (selectedDescriptorIds.includes(descriptor.id)) {

          descriptorInjector(rows, descriptor, fSDByDescriptorId)

          // Inject conditions on rows
          // Only used for ERC and PROC
          if (DESCRIPTORS_WITH_CONDITIONS_TYPES.includes(descriptorFilter)) {

            const scenarioDescriptor = fSDByDescriptorId[descriptor.id]
            const filteredConditions = conditionsData.filter(condition => condition.tipo === conditionsFilter[descriptorFilter]);

            const filteredSDC = scenarioDescriptorConditionsData.filter(sdc =>
              sdc.id_cenariodescritor === scenarioDescriptor.id
            );

            const fsdcByConditionId = arrayToObject(filteredSDC, 'id_condicao');
            const selectedConditionsIds = filteredSDC.map(fsdc => fsdc.id_condicao)

            filteredConditions.forEach(condition => {

              if (selectedConditionsIds.includes(condition.id)) {
                // Inject conditions on rows
                conditionInjector(rows, condition, fsdcByConditionId);
              }
            });
          }
        }
      });
    })
  })

  return rows;

};

const dd = (substance, scenarios, data, title) => ({
  pageSize: 'A4',
  pageOrientation: 'portrait',
  pageMargins: [30, 60, 30, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: title //title,
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 0,
        widths: [30, 30, 40, 160, 40, 150],
        body: [
          ...rowGenerator(substance, scenarios, data)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 14,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 7
    }
  }

});

export default dd;
