import React from 'react';

export default (rmsById, flowsById, productsById, descriptorsById) =>  [
  {
    title: 'ID',
    field: 'id',
    minWidth: 50,
  },
  {
    title: 'Codigo',
    field: 'codigo',
    minWidth: 100,
  },
  {
    title: 'MP',
    field: 'id_substancia',
    minWidth: 50,
    render: rowData => rowData.id_substancia ?
      rmsById[rowData.id_substancia].mp_nome : ''
  },
  {
    title: 'Produto',
    field: 'id_produto',
    minWidth: 50,
    render: rowData => rowData.id_produto ?
      productsById[rowData.id_produto].codigo_produto + ' ' + productsById[rowData.id_produto].produto_nome : ''
  },
  {
    title: 'Fluxo',
    field: 'id_fluxograma',
    minWidth: 50,
    render: rowData => rowData.id_fluxograma ?
      flowsById[rowData.id_fluxograma].codigo_fluxo + ' ' + flowsById[rowData.id_fluxograma].nome : ''
  },
  {
    title: 'id_substancia_fornecedor',
    field: 'id_substancia_fornecedor',
    minWidth: 50,
  },
  {
    title: 'id_lcs',
    field: 'id_lcs',
    minWidth: 50,
    render: rowData => rowData.id_lcs ?
      descriptorsById[rowData.id_lcs].codigo + ' ' + descriptorsById[rowData.id_lcs].descricao : ''
  },
  {
    title: 'Tipo',
    field: 'tipo',
    minWidth: 50,
  },
  {
    title: 'Designacao',
    field: 'designacao',
    minWidth: 200,
  },
  {
    title: 'Notas',
    field: 'notas',
    minWidth: 200,
  },
];
