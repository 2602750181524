import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import styles from './styles';
import reachOKLogo from 'images/brand/LOGO_FINAL_REACHOK_Prancheta 1.png';

const Loader = ({ classes }) => {
  return (
    <div className={classes.container}>
      <div className={classes.loader}>
        <img src={reachOKLogo} style={{ width: '100%' }} alt=""/>
        <LinearProgress color="primary"/>
      </div>
    </div>
  );
};

export default withStyles(styles)(Loader);
