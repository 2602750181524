import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import history from 'appHistory';
import { toggleMenu, toggleSidebar } from 'ducks/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import theme from 'source/theme';

const drawerWidth = 260;

const styles = {
  fullList: {
    width: 'auto'
  },
  list: {
    width: drawerWidth,
    overflowY: 'auto'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerContent: {
    width: drawerWidth,
    position: 'relative'
  },
  drawerModal: {
    top: 64
  }
};

const Sidebar = props => {

  const [listOpen, setListOpen] = useState(props.sections.filter(s => s.onOpen).map(s => s.name));

  const navigate = event => {
    history.push(event.currentTarget.dataset.url);
    props.toggleSidebar();
  }

  const toggleListOpen = item => () => {
    if (listOpen.includes(item)) {
      setListOpen(listOpen.filter(i => i !== item));
    } else {
      setListOpen([...listOpen, item]);
    }
  };

  const renderSubsection = ({ icon: Icon, text, url }) => (
    <ListItem button key={url} data-url={url} onClick={navigate}>
      <ListItemIcon style={{ width: 16 }}>
        <Icon/>
      </ListItemIcon>
      <ListItemText primary={text}/>
    </ListItem>
  );

  const renderSections = sections => (
    sections.map(({ name, subsections }, index) => (
      <React.Fragment key={index}>
        <List dense >

          <ListItem button onClick={toggleListOpen(name)} style={{backgroundColor: theme.palette.primary.light}}>
            <ListItemText primary={name}/>
            {listOpen.includes(name) ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>

          <Collapse in={listOpen.includes(name)} timeout="auto" unmountOnExit>
            <List dense component="div" disablePadding>
              {subsections.map(renderSubsection)}
            </List>
          </Collapse>

        </List>
        {index === sections.length - 1 ? null : <Divider/>}
      </React.Fragment>
    ))
  );

  return (
    <Drawer
      open={props.showSidebar}
      onClose={props.toggleSidebar}
      className={props.classes.drawer}
      classes={{ paper: props.classes.drawerContent, modal: props.classes.drawerModal }}
    >
      <div
        tabIndex={0}
        role="button"
        //onClick={props.toggleSidebar}
        onKeyDown={props.toggleSidebar}
      >
        <div className={props.classes.list}>
          {renderSections(props.sections)}
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  showMenu: PropTypes.bool.isRequired,
  showSidebar: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  showMenu: state.ui.showMenu,
  showSidebar: state.ui.showSidebar
});

const mapDispatchToProps = ({ toggleMenu, toggleSidebar });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sidebar));
