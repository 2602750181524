import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columns = [
  { title: 'Nome etapa', field: 'nome' },
  { title: 'Descrição', field: 'descricao' },
];

export default props => {
  const {data, title} = props;

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={data} title={title}/>
    </div>
  )
}
