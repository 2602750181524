import TableEmpyArea from 'pages/import/TableEmptyArea';
import React from 'react';
import theme from 'source/theme';
import { TABLE_HEIGHT } from 'utils/ui/constants';

const TABLE_HEIGHT_CUSTOM = TABLE_HEIGHT(true,71);

export default () =>
  <TableEmpyArea bodyHeight={TABLE_HEIGHT_CUSTOM}>
    <div>
      <p>
        Nesta área pode importar ficheiros XLSX de dados relacionados com
        Matérias Primas e Fornecedores.
      </p>
      <p>
        Abaixo tem exemplos de ficheiros de importação:
      </p>
      <ul>
        <li>
          <a
            href="https://drive.google.com/file/d/1_eo2lHOvds866fsZr6FhUZO3FFALIhE9/view?usp=sharing"
            target="_blank"
            style={{ color: theme.palette.primary.main }}
          >
            Template XLSX Matérias Primas
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/1EHpfoLsSWdi4m13WyEuMCDsdHWnivNXK/view?usp=sharing"
            target="_blank"
            style={{ color: theme.palette.primary.main }}
          >
            Template XLSX Fornecedores
          </a>
        </li>
      </ul>
    </div>
  </TableEmpyArea>
