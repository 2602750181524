import { ECHA, FETCH_ECHAS, REJECTED } from 'actionTypes';
import { echaTransformForImport } from 'utils/echa/columns';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';
import { apiRequest } from 'utils/request';

// CUSTOM ACTIONS
const handleError = (TYPE, error, dispatch) => {
  console.log('error: ', error);
  if (error.response) {
    if (error.response.status === 401) {
      dispatch({ type: 'LOGOUT' });
    } else {
      dispatch({ type: TYPE + REJECTED, payload: error.response.statusText });
    }
  } else {
    dispatch({ type: TYPE + REJECTED, payload: 'Unknown error' });
  }
};

const importEchas = echas => dispatch => {
  const echasForImport = echas.map(echa => echaTransformForImport(echa));

  return apiRequest('/echa_multiple/', 'DELETE')
    .then(() => {
      apiRequest('/echa_multiple/', 'POST', echasForImport)
    })
    .then(response => {
      // Insertion on DB takes a while so the FETCH is delayed
      setTimeout( () => dispatch({ type: FETCH_ECHAS}), 10000);
    })
    .catch( error => handleError(ECHA, error, dispatch))
}

// ACTIONS
const rawActions = actionsFactory(ECHA);
const actions = { ...rawActions, importEchas };
export { actions };

// REDUCER
const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};

const parseJsonString = value => {
  return JSON.parse(value);
}

const options = {
  orderBy: {
    what: ['nome'],
    how: ['asc']
  },
  sanitizers: {
    cas: parseJsonString,
    ec: parseJsonString,
    restricoes_descricao: parseJsonString,
    restricoes_entrada: parseJsonString
  }
};

export default reducerFactory(ECHA, initialState, options);

// SAGAS
const sagas = sagasFactory(ECHA, '/echa/');
export { sagas };
