import { MTableEditField } from '@material-table/core';
import React from 'react';

export default [
  {
    title: 'ID',
    field: 'id',
    editable: 'never',
    width: 200,
  },
  {
    title: 'Descrição',
    field: 'descricao',
    width: 400,
    editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
  },
  {
    title: 'Tipo',
    field: 'tipo',
    width: 400,
    editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
  }
];
