import { MTableEditField } from '@material-table/core';
import Done from '@material-ui/icons/Done';
import Remove from '@material-ui/icons/Remove';
import React from 'react';

export default (user, units) => {
  const columns = [

    {
      title: 'Sub Tipo',
      field: 'subtipo',
      editable: 'never',
      width: 200,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Nome',
      field: 'nome',
      editable: 'never',
      width: 400,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Valor',
      field: 'valor',
      initialEditValue: '',
      width: 90,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Unidade',
      field: 'id_unidade',
      lookup: {'': 'N/A', ...units },
      initialEditValue: null,
      width: 200,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      }
    },
    {
      title: 'OK',
      field: 'ok_flag',
      type: 'boolean',
      sorting: false,
      maxWidth: 90,
      minWidth: 90,
      initialEditValue: 'false',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      },
      lookup: {
        true: <Done style={{ height: 16, width: 16 }}/>,
        false: <Remove style={{ height: 16, width: 16 }}/>
      },
      render: rowData => rowData.ok_flag ?
        <Done style={{ height: 16, width: 16 }}/> :
        <Remove style={{ height: 16, width: 16 }}/>
    },
    {
      title: 'Notas',
      field: 'notas',
      initialEditValue: '',
      sorting: false,
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
      width: 400
    },
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ];

  return user.is_staff ? columnsAdmin : columns;
};
