import CreateScenarioDialog from 'components/Dialog/ScenarioDialog'
import TabsBarsDescriptors from 'components/TabsBars/TabsBarsDescriptors';
import { actions as scenariosActions } from 'ducks/scenarios';
import ERCAmbiente from 'pages/ScenarioExposure/Flow/ScenariosManagement/ERCAmbiente';
import ExpandableScenarioConfig from 'pages/ScenarioExposure/Flow/ScenariosManagement/ExpansionBar';
import PCProduto from 'pages/ScenarioExposure/Flow/ScenariosManagement/PCProduto';
import PROCTrabalhadores from 'pages/ScenarioExposure/Flow/ScenariosManagement/PROCTrabalhadores';
import SUSectores from 'pages/ScenarioExposure/Flow/ScenariosManagement/SUSectores';
import BackgroundQuestion from 'pages/ScenarioExposure/sharedComponents/BackgroundQuestion';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { filterByFieldSelector } from 'selectors/generic';
import { SCENARIO_EF } from 'utils/constants/coreConstants';
import { ERC_TAB, PC_TAB, PROC_TAB, SU_TAB } from 'utils/constants/descriptorTypes';
import { lcsInternalExposure } from 'utils/constants/lcsTypes';
import { TABLE_HEIGHT } from 'utils/ui/constants';

const TABLE_HEIGHT_QUESTION = TABLE_HEIGHT(false, 100);

const Component = props => {
  const {
    flowsData, lcssFactory, lcssById, substanceId, rawMaterialsById, scenarioDescriptorsData,
    scenariosData, scenariosById,

    shipmentInitializer, scenarioInitializer
  } = props;
  const {
    createScenario, closeScenarioManagement, fetchScenarios, setSubstanceId,

    setShipmentInitializer, setScenarioInitializer
  } = props;

  const [createScenarioOpen, setCreateScenarioOpen] = useState(false);
  const handleCreateScenarioClose = () => setCreateScenarioOpen(false);
  const handleCreateScenarioOpen = () => setCreateScenarioOpen(true);

  /**  ******* Shipment initialization  ***********  */
  // Fetches Shipments from direct substance and from mixtures where substance might be in
  const shipmentInitialization = substance => {
      let shipment = {};
      if (substance.inter_fornecedores.length > 0) {
        shipment = {
          ...substance.inter_fornecedores[0],
          shipment_substance_id: substance.id,
          shipment_id_mp: substance.id,
          shipment_mp_nome: substance.mp_nome,
          shipment_cod_mp: substance.cod_mp
        };
      } else if (substance.inter_misturas.length > 0) {
        const mixIds = substance.inter_misturas.map(mix => mix.id_mistura);
        let shipmentsFromMix = [];
        mixIds.forEach(id => {
          rawMaterialsById[id].inter_fornecedores.forEach(shipt =>
            shipmentsFromMix.push({
              ...shipt,
              shipment_mixture_id: id,
              shipment_id_mp: id,
              shipment_mp_nome: rawMaterialsById[id].mp_nome,
              shipment_cod_mp: rawMaterialsById[id].cod_mp
            })
          )
        })
        if (shipmentsFromMix.length > 0) {
          shipment = shipmentsFromMix[0];
        }
      }
      return shipment;
    }
  /**  ******* Shipment initializarion END  ***********  */

  const scenarioInitialization = () => {
    const scenarios = scenariosData.filter(s =>
      s.id_substancia_fornecedor === shipment.id && parseInt(s.id_lcs) === lcs.id
    );
    return scenarios.length > 0 ? scenarios[0].id : null;
  }

  const substance = rawMaterialsById[substanceId];
  const [lcsId, setLcsId] = useState(scenarioInitializer ? scenarioInitializer.id_lcs : lcssFactory[0].id);
  const lcs = lcssById[lcsId];
  const [shipment, setShipment] = useState(shipmentInitializer ? shipmentInitializer : null);
  const [scenarioId, setScenarioId] = useState(scenarioInitializer ? scenarioInitializer.id : null);
  const scenario = scenariosById[scenarioId];
  const [loading, setLoading] = useState(null);
  const [descriptorTab, setDescriptorTab] = useState(PC_TAB)


  useEffect(() => {
      if (shipmentInitializer) {
        setShipmentInitializer(null);
      } else {
        setShipment(shipmentInitialization(substance));
      }
    }, [substanceId]
  )

  useEffect(() => {
      if (scenarioInitializer) {
        setScenarioInitializer(null);
      } else {
        setScenarioId(scenarioInitialization());
      }
    },
    [substanceId, shipment, lcsId, scenariosData]
  )



  /**  ******* Exposure Scenarios/Scenario information  ***********  */
  const scenarios = scenariosData.filter(s =>
    s.id_substancia_fornecedor === shipment.id && parseInt(s.id_lcs) === lcs.id
  );
  /**  ******* Exposure Scenario information END ***********  */

  /**  ******* Flow Scenario information  ***********  */
  const flowsShipment = flowsData.filter(flow => flow.mps.includes(shipment.shipment_id_mp));
  const flowsShipmetIds = flowsShipment.map(flowShip => flowShip.id);

  const flowScenarios = scenariosData.filter(scenario =>
    scenario.id_fluxograma && scenario.id_lcs == lcs.id && flowsShipmetIds.includes(scenario.id_fluxograma)
  );

  const flowScenariosIds = flowScenarios.map(fS => fS.id);
  const flowScenarioDescriptors = scenarioDescriptorsData.filter(scenarioDescriptor =>
    flowScenariosIds.includes(scenarioDescriptor.id_cenario)
  );
  /**  ******* Flow Scenario information END  ***********  */

  const newScenario = (code, description, notes) => {
    setLoading(true);
    createScenario({
      id_substancia: substanceId,
      id_substancia_fornecedor: shipment.id,
      codigo: code,
      designacao: description,
      id_lcs: lcs.id,
      tipo: SCENARIO_EF
    })
      .then(() => {
        fetchScenarios();
      })
      .finally(() => {
        setCreateScenarioOpen(false);
        setLoading(false);
      })
  }

  const CreateScenarioQuestion = () => {
    return (
      <BackgroundQuestion
        bodyHeight={TABLE_HEIGHT_QUESTION}
        label='Criar cenário'
        onClick={handleCreateScenarioOpen}
        submitting={loading}
      >
        <div>
          <p>
            O cenário seleccionado para a combinação de Substância <b>{rawMaterialsById[substanceId].mp_nome}</b>,
            LCS <b>{lcs.descricao}</b> e Fornecimento <b>{shipment.designacao_comercial}</b> não existe, pretende criá-lo?
          </p>
        </div>
      </BackgroundQuestion>
    )
  }

  const WorkingArea = descriptorTab => {
    switch (descriptorTab) {
      case PC_TAB:
        return (
          <PCProduto
            flowScenarioDescriptors={flowScenarioDescriptors}
            lcs={lcs}
            scenario={scenario}
            substanceId={substanceId}
          />
        );

      case SU_TAB:
        return (
          <SUSectores
            flowScenarioDescriptors={flowScenarioDescriptors}
            lcs={lcs}
            scenario={scenario}
            substanceId={substanceId}
          />
        )

      case ERC_TAB:
        return (
          <ERCAmbiente
            flowScenarioDescriptors={flowScenarioDescriptors}
            lcs={lcs}
            scenario={scenario}
            substanceId={substanceId}
          />
        )

      case PROC_TAB:
        return (
          <PROCTrabalhadores
            flowScenarioDescriptors={flowScenarioDescriptors}
            lcs={lcs}
            scenario={scenario}
            substanceId={substanceId}
            // Extra data needed to know the flows in use and take out steps/MPS
            flowScenarios={flowScenarios}
          />
        )
    }
  }

  return (
    <React.Fragment>
      <ExpandableScenarioConfig
        currentOptionSubstance={substance}
        onSelectionSubstance={setSubstanceId}
        currentOptionLcs={lcs}
        onSelectionLcs={setLcsId}
        currentOptionShipment={shipment}
        onSelectionShipment={setShipment}
        scenarios={scenarios}
        currentOptionScenario={scenario}
        onSelectionScenario={setScenarioId}
        handleCreateScenarioOpen={handleCreateScenarioOpen}
        closeScenarioManagement={closeScenarioManagement}
      />
      <TabsBarsDescriptors value={descriptorTab} setValue={setDescriptorTab}/>
      {
        scenario ?
          WorkingArea(descriptorTab) :
          CreateScenarioQuestion()
      }
      {
        createScenarioOpen &&
        <CreateScenarioDialog
          open={createScenarioOpen}
          code={`${rawMaterialsById[substanceId].mp_nome}_${shipment.codigo}_${lcs.descricao}`}
          description={lcs.descricao}
          handleClose={handleCreateScenarioClose}
          handleSubmit={newScenario}
        />
      }
    </React.Fragment>
  )
};

const makeMapStateToProps = () => {
  const lcssFactory = filterByFieldSelector('descriptors', 'id', lcsInternalExposure);
  const mapStateToProps = (state, props) => ({
    lcssFactory: lcssFactory(state),
    lcssById: state.descriptors.dataById,
    rawMaterialsById: state.rawMaterials.dataById,
    scenariosData: state.scenarios.data,
    scenariosById: state.scenarios.dataById,
    scenarioDescriptorsData: state.scenarioDescriptors.data,
    shipmentsById: state.rawMaterialSuppliers.dataById,
    flowsData: state.flows.data
  })
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  createScenario: data => dispatch(scenariosActions.createPromise(data)),
  fetchScenarios: () => dispatch(scenariosActions.fetchAll())
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
