import React from 'react';
import XLSX from 'xlsx';


const columns = [
  {
    title: 'Código MP',
    field: 'cod_mp',
    nested: 'ignore'
  },
  {
    title: 'Nome',
    field: 'mp_nome'
  },
  {
    title: 'Percentagem',
    field: 'percentagem',
    nested: true
  },
  {
    title: 'CAS',
    field: 'cas'
  },
  {
    title: 'EC',
    field: 'ec'
  },
  {
    title: 'Observações',
    field: 'observacoes'
  },
  {
    title: 'Perigosa',
    field: 'perigosa',
    render: value => value ? 'Sim' : 'Não'
  }
];

export default (rawMaterials, fileName) => () => {

  let sheetData = [];

  rawMaterials.current.state.data.forEach(rawMaterial => {
    const sheetRawMaterial = {};
    // MIXTURE
    columns.forEach(column => {
      if (Array.isArray(rawMaterial[column.field])) {
        sheetRawMaterial[column.field] = rawMaterial[column.field] && rawMaterial[column.field].length !== 0 ?
          rawMaterial[column.field].join(', ') : '';
      } else if (column.render) {
        sheetRawMaterial[column.field] = column.render(rawMaterial[column.field]);
      } else {
        sheetRawMaterial[column.field] = rawMaterial[column.field];
      }
    });
    sheetData.push(sheetRawMaterial)

  })
  /* make the worksheet */
  var ws = XLSX.utils.json_to_sheet(sheetData);
  /* add to workbook */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Data');
  /* generate an XLSX file */
  XLSX.writeFile(wb, fileName ? fileName : 'exportReachok.xlsx');
};
