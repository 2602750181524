import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable';
import { actions } from 'ducks/users'
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreData } from 'selectors/generic';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/userManagement';

const Component = props => {
  const { user, users } = props;
  const { create, fetchAll, remove, update } = props;

  const usersWithFlatGroups = users.map(user => ({
    ...user,
    access: user.groups && user.groups.length > 0 ? user.groups[0].name : null
  }))

  return (
    <Grid item xs={12}>
      <BaseTable
        columns={columns(user)}
        data={usersWithFlatGroups}
        options={{
          showTitle: false,
          searchFieldAlignment: 'left',
          pageSize: 10,
          maxBodyHeight: null,
          minBodyHeight: null,

          actionsCellStyle: {
            minWidth: 150,
            paddingLeft: 20
          }
        }}
        actions={[
          {
            icon: tableIcons.Password,
            tooltip: 'Change password',
            onClick: (event, rowData) => alert('Talk to ReachOk administrator please!')
          }
        ]}
        editable={{
          onRowAdd: (newData, oldData) => {
            newData.is_active = JSON.parse(newData.is_active);
            newData.groups = [{ name: newData.access }];
            return create(newData)
          },
          onRowUpdate: (newData, oldData) => {
            newData.is_active = JSON.parse(newData.is_active);
            let data = newData;
            Object.keys(newData).forEach(k => {
              if (newData[k] !== oldData[k]) {
                data = { ...data, [k]: newData[k] };
              }
            })
            if (data.access) {
              data.groups = [{ name: data.access }];
            }
            return update(newData.id, data).then(() => fetchAll())
          },
          onRowDelete: oldData => remove(oldData.id)
        }}
      />
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const users = getImmutableStoreData('users')
  const mapStateToProps = (state, props) => ({
    users: users(state),
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  fetchAll: () => dispatch(actions.fetchAll()),
  create: data => dispatch(actions.createPromise(data)),
  remove: id => dispatch(actions.removePromise(id)),
  update: (id, data) => dispatch(actions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
