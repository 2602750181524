import ButtonTable from 'components/Buttons/ButtonTableDialog'
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { filterByFieldSelector } from 'selectors/generic';
import { lcsInternalExposure } from 'utils/constants/lcsTypes';

const BUTTONS_TEXT = ['LCS: '];

const COLUMNS = [
  { title: 'Tipo de LCS', field: 'descricao' },
  { title: 'Nota', field: 'comment' }
];

const OPTIONS_KEY = [
  { tag: 'descricao' }
];

const OPTIONS_KEYS = [OPTIONS_KEY];

const Component = props => {
  const {
    currentOption, lcsData, scenariosData, currentOptionShipment
  } = props;
  const { onSelection } = props;

  const shipmentScenarios = scenariosData.filter(scenario =>
    currentOptionShipment.id === scenario.id_substancia_fornecedor
  )

  const lcsWithScenario = new Set([])
  shipmentScenarios.forEach(scenario => lcsWithScenario.add(parseInt(scenario.id_lcs)));

  lcsData.forEach(lcs => {
    if (!lcsWithScenario.has(lcs.id)) {
      lcs['comment'] = 'Não existem cenários.'
    } else {
      lcs['comment'] = ''
    }
  });

  const onSelectionID = obj => onSelection(obj.id)

  return (
    <ButtonTable
      buttonsText={BUTTONS_TEXT}
      currentOption={currentOption}
      header='Seleccione um LCS'
      optionKeys={OPTIONS_KEYS}
      options={lcsData}
      onSelection={onSelectionID}
      columns={COLUMNS}
    />
  )
};

const makeMapStateToProps = () => {
  const lcssFactoryDescriptors = filterByFieldSelector('descriptors', 'id', lcsInternalExposure);
  const mapStateToProps = (state) => ({
    lcsData: lcssFactoryDescriptors(state),
    scenariosData: state.scenarios.data,
  })
  return mapStateToProps;
}

export default connect(makeMapStateToProps)(Component);

Component.propTypes = {
  currentOption: PropTypes.object,
  currentOptionShipment: PropTypes.object,
  onSelection: PropTypes.func.isRequired
};
