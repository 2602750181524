import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';
import { SCENARIO_EF } from 'utils/constants/coreConstants';
import { lcsInternalExposure } from 'utils/constants/lcsTypes';
import { DISTRIBUIDOR } from 'utils/constants/papel_reach';

const columns = [
  { title: 'Código MP', field: 'shipment_cod_mp' },
  { title: 'Matéria prima', field: 'shipment_mp_nome' },
  { title: 'Fornecimento', field: 'codigo' },
  { title: 'Desig. Comercial', field: 'designacao_comercial' },
  { title: 'Fornecedor', field: 'fornecedor', nested: 'id_fornecedor' }
];


export default props => {

  const { rowData, dataById, title, extra } = props;
  const { scenarios, rawMaterialsById, shipmentsById } = extra

  let filteredScenarios = scenarios.filter(s =>
    s.tipo === SCENARIO_EF &&
    s.id_substancia_fornecedor &&
    lcsInternalExposure.includes(parseInt(s.id_lcs)) &&
    (
      rowData.id === shipmentsById[s.id_substancia_fornecedor].id_mp ||
      rawMaterialsById[rowData.id].inter_misturas
        .map(mix => mix.id_mistura)
        .includes(shipmentsById[s.id_substancia_fornecedor].id_mp)
    )
  );

  filteredScenarios = filteredScenarios.map( scenario => scenario.id_substancia_fornecedor);

  const rows = [];
  const augmentedRow = (primaryRow, secondaryRow) => ({
    ...primaryRow,
    shipment_mp_nome: secondaryRow.mp_nome,
    shipment_cod_mp: secondaryRow.cod_mp,
    fornecedor: primaryRow['id_fornecedor'].fornecedor,
    backgroundColor: !filteredScenarios.includes(primaryRow.id) ?
      theme.palette.error.light : null
  })

  rowData.inter_fornecedores.forEach(shipment => {
    if (shipment.papel_reach !== DISTRIBUIDOR && shipment.registos_directos[0].ce !== 'nao' && shipment.registos_directos[0].ce !== 'na') {
      rows.push(augmentedRow(shipment, rowData))
    }
  })
  rowData.inter_misturas.forEach(mix =>
    dataById[mix.id_mistura].inter_fornecedores.forEach(shipment => {
      if (shipment.papel_reach !== DISTRIBUIDOR && shipment.registos_directos[0].ce !== 'nao' && shipment.registos_directos[0].ce !== 'na') {
        rows.push(augmentedRow(shipment, dataById[mix.id_mistura]))
      }
    })
  );


  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable title={title} columns={columns} rows={rows}/>
    </div>
  )
}
