import { CONSUMPTIONS, FETCH, FULFILLED, REJECTED, REPORT } from 'actionTypes';
import { apiRequest } from 'utils/request';

// CUSTOM ACTIONS
const handleError = (TYPE, error, dispatch) => {
  console.log('error: ', error);
  if (error.response) {
    if (error.response.status === 401) {
      dispatch({ type: 'LOGOUT' });
    } else {
      dispatch({ type: TYPE + REJECTED, payload: error.response.statusText });
    }
  } else {
    dispatch({ type: TYPE + REJECTED, payload: 'Unknown error' });
  }
};

const fetchReportConsumptions = () => dispatch => {
  dispatch({ type: FETCH + REPORT + CONSUMPTIONS });

  apiRequest('/reports/consumptions/')
    .then(response => {
      const report = response.data;
      report.forEach( c => {
        if (c.cas[0] === '[') {
          c.cas = JSON.parse(c.cas)
        }
        if (c.ec[0] === '[') {
          c.ec = JSON.parse(c.ec)
        }
      });
      dispatch({ type: FETCH + REPORT + CONSUMPTIONS + FULFILLED, payload: report })
    })
    .catch(error =>
      handleError(FETCH + REPORT + CONSUMPTIONS, error, dispatch)
    )
}

// ACTIONS
//const rawActions = actionsFactory(ECHA);
const rawActions = {};
const actions = { ...rawActions, fetchReportConsumptions };
export { actions };

// REDUCER
const initialState = {
  consumptions: [],
  loaded: false,
  loading: false,
  errorMessage: ''
};

const reducer = initialState => {
  return (state = initialState, { payload, type }) => {
    switch (type) {

      case FETCH + REPORT + CONSUMPTIONS:
        return { ...state, errorMessage: '', loading: true };

      case FETCH + REPORT + CONSUMPTIONS + FULFILLED:
        return {
          ...state,
          errorMessage: '',
          loaded: true,
          loading: false,
          consumptions: payload
        };

      case FETCH + REPORT + CONSUMPTIONS + REJECTED:
        return { ...state, loading: false, errorMessage: payload };

      default:
        return state;
    }
  };
};

export default reducer(initialState);

// SAGAS
// const sagas = sagasFactory(REPORT + CONSUMPTIONS, '/report/consumptions/');
// export { sagas };
