import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columns = [
  {
    title: 'Mensagem de aviso',
    field: 'msg',
  },
];

export default props => {
  const { rowData, data, title } = props;

  const rows = data.map(msg => ({msg}) );

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={rows} title={title}/>
    </div>
  )
};
