export const M = 'M'; //Fabrico
export const F = 'F'; //Formulação
export const IS = 'IS'; //Industrial
export const C = 'C'; //Consumidor
export const PW = 'PW'; //Profissional

export const LCS_LIST = [M, F, IS];

export const M_ID = 70008; //Fabrico
export const F_ID = 70006; //Formulação
export const IS_ID = 70007; //Industrial
export const C_ID = 70005; //Consumidor
export const PW_ID = 70009; //Profissional

export const lcsAliasTranslator = {
  [M]: M_ID,
  [F]: F_ID,
  [IS]: IS_ID,
  [C]: C_ID,
  [PW]: PW_ID
};

export const lcsTranslator = {
  [M_ID]: M,
  [F_ID]: F,
  [IS_ID]: IS,
  [C_ID]: C,
  [PW_ID]: PW
};

export const lcsNames = {
  [M_ID]: 'Fabrico',
  [F_ID]: 'Formulação e reembalagem',
  [IS_ID]: 'Uso Industrial',
  [C_ID]: 'Uso consumidor',
  [PW_ID]: 'Uso Profissional'
};


export const lcsInternalExposure = [M_ID, F_ID, IS_ID, PW_ID];
export const lcsExternalExposure = [IS_ID, C_ID, PW_ID];

export const lcsInternalExposureLookup = {
  [M_ID]: 'Fabrico',
  [F_ID]: 'Formulação e reembalagem',
  [IS_ID]: 'Uso Industrial',
  [PW_ID]: 'Uso Profissional'
};
