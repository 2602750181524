import { PRODUCT_STEP } from 'actionTypes';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';
import thunkFactory from 'utils/redux/baseEntitiesFactory/thunk';

// ACTIONS
const rawActions = actionsFactory(PRODUCT_STEP);
const actions = {
  ...rawActions,
  ...thunkFactory(PRODUCT_STEP, '/etapaproduto/'),
};
export { actions };

// REDUCER
const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};

export default reducerFactory(PRODUCT_STEP, initialState);

// SAGAS
const sagas = sagasFactory(PRODUCT_STEP, '/etapaproduto/');
export { sagas };
