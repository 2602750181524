
// title and field properties are used in material table,
// label and key are used in CSV export

import { MTableEditField } from '@material-table/core';
import React from 'react';

export default user => {
  const columns = [
    {
      title: 'Código Fluxograma',
      field: 'codigo_fluxo',
      initialEditValue: '',
      width: 1000,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
    },
    {
      title: 'Nome',
      field: 'nome',
      width: 1000,
      initialEditValue: '',
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
    },
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};
