import cloneDeep from 'lodash/cloneDeep';
import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';

const themeLight = theme.palette.primary.light;
const lightGrey = '#EEEEEE';
const productHeader = [
  { text: 'Código Produto', fillColor: themeLight },
  { text: 'Nome', fillColor: themeLight },
  { text: 'Papel Reach', fillColor: themeLight },
  { text: 'Perigoso', fillColor: themeLight },
  { text: 'Utilização', fillColor: themeLight },
  { text: 'Notas', fillColor: themeLight },
];

const rowGenerator = (products, rawMaterialsById) => {

  const clonedRawMaterialsById = cloneDeep(rawMaterialsById);

  const rows = []
  products.forEach(item => {
    rows.push([
      { text: item['codigo_produto'] },
      { text: item['produto_nome'] },
      { text: item['papel_reach'] },
      { text: item['perigoso'] ? 'x' : '' },
      { text: item['utilizacao'] },
      { text: item['notas'] },
    ]);
  });

  return rows;
};

const dd = (products, data) => ({
  pageSize: 'A4',
  pageOrientation: 'portrait',
  pageMargins: [40, 60, 40, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: 'R - Situação de Produto (Inventário)' //title,
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: [40, 100, 50, 50, 50, 150 ],
        body: [
          productHeader,
          ...rowGenerator(products, data)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 8
    }
  }

});

export default dd;
