import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import AccountManagement from '@material-ui/icons/EmojiTransportation';
import Store from '@material-ui/icons/Store';
import history from 'appHistory';
import { logout } from 'ducks/auth';
import { toggleMenu } from 'ducks/ui';
import React from 'react';
import { connect } from 'react-redux';
import theme from 'source/theme';
import { AccountDetailsIcon, CoreSettingsIcon, LogoutIcon, MailIcon, ManualIcon, NewCompanyIcon, UserIcon, UsersIcon } from 'utils/icons/materialTableIcons';

const styles = theme => ({
  user: {
    marginRight: theme.spacing(2),
    fontWeight: 'bold'
  },

  icon: {
    color: theme.palette.secondary.main,
    height: 48,
    width: 48
  },
  sectionDesktop: {
    display: 'flex'
  }
});

const navigate = event => history.push(event.currentTarget.dataset.url);

const Component = props => {
  const { classes, logout, messageCount, showMenu, toggleMenu, user } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
    toggleMenu();
  };

  const handleClickMenuOption = event => {
    navigate(event);
    toggleMenu();
  };

  return (
    <React.Fragment>

      <Typography className={classes.user} variant='body1' style={{ color: theme.palette.primary.dark }}>
        {/*color='primary'>*/}
        {`${user.first_name} ${user.last_name}`}
      </Typography>

      <div className={classes.sectionDesktop}>
        <IconButton className={classes.icon} color="inherit">
          <Badge badgeContent={messageCount} color="primary">
            <MailIcon/>
          </Badge>
        </IconButton>
        <IconButton className={classes.icon} onClick={handleClickMenu} color="inherit">
          <Badge badgeContent={messageCount} color="primary">
            <CoreSettingsIcon/>
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={showMenu}
          onClose={toggleMenu}
        >
          <MenuList>
            {
              user.is_staff &&
              <MenuItem data-url='/clients' onClick={handleClickMenuOption}>
                <ListItemIcon>
                  <NewCompanyIcon/>
                </ListItemIcon>
                <Typography variant="inherit">Gestão ReachOK</Typography>
              </MenuItem>
            }

            <MenuItem data-url='/userdata' onClick={handleClickMenuOption}>
              <ListItemIcon>
                <UserIcon/>
              </ListItemIcon>
              <Typography variant="inherit">Dados utilizador</Typography>
            </MenuItem>

            <MenuItem data-url='/usersmanagement' onClick={handleClickMenuOption}>
              <ListItemIcon>
                <UsersIcon/>
              </ListItemIcon>
              <Typography variant="inherit">Gestão de utilizadores</Typography>
            </MenuItem>

            <MenuItem data-url='/accountmanagement' onClick={handleClickMenuOption}>
              <ListItemIcon>
                <AccountDetailsIcon/>
              </ListItemIcon>
              <Typography variant="inherit">Gestão de conta</Typography>
            </MenuItem>

            <MenuItem data-url='/manuals' onClick={handleClickMenuOption}>
              <ListItemIcon>
                <ManualIcon/>
              </ListItemIcon>
              <Typography variant="inherit">Manuais</Typography>
            </MenuItem>

            <MenuItem onClick={logout}>
              <ListItemIcon>
                <LogoutIcon/>
              </ListItemIcon>
              <Typography variant="inherit">Sair</Typography>
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  showMenu: state.ui.showMenu,
  messageCount: state.messages.data.length,
  user: state.profile.entity
});

const mapDispatchToProps = ({ logout, toggleMenu });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, theme)(Component));
