// GLOBAL AFFIXES
export const FULFILLED = '_FULFILLED';
export const REJECTED = '_REJECTED';

//actions
export const FETCH = 'FETCH_';
export const CREATE = 'CREATE_';
export const DELETE = 'DELETE_';
export const UPDATE = 'UPDATE_';

// GLOBAL Entities
export const ACCOUNT = 'ACCOUNT';
export const ACCOUNTS = 'ACCOUNTS';
export const ECHA = 'ECHA';
export const ECHAS = 'ECHAS';
export const RAW_MATERIAL_ECHA = 'RAW_MATERIAL_ECHA';
export const RAW_MATERIAL_ECHAS = 'RAW_MATERIAL_ECHAS';
export const SUPPLIER = 'SUPPLIER';
export const SUPPLIERS = 'SUPPLIERS';
export const USER = 'USER';
export const USERS = 'USERS';
export const USER_ACCOUNT = 'USER_ACCOUNT';
export const PROFILE = 'PROFILE';
export const RAW_MATERIAL = 'RAW_MATERIAL';
export const RAW_MATERIALS = 'RAW_MATERIALS';
export const RAW_MATERIAL_SUPPLIER = 'RAW_MATERIAL_SUPPLIER';
export const RAW_MATERIAL_SUPPLIERS = 'RAW_MATERIAL_SUPPLIERS';
export const CONSUMPTION = 'RAW_MATERIAL_CONSUMPTION';
export const CONSUMPTIONS = 'RAW_MATERIAL_CONSUMPTIONS';
export const MIXTURE = 'MIXTURE';
export const MIXTURES = 'MIXTURES';
export const SUPPLIER_REGISTER = 'SUPPLIER_REGISTER';
export const SUPPLIER_REGISTERS = 'SUPPLIER_REGISTERS';
export const UNIT = 'UNIT';
export const UNITS = 'UNITS';
export const DESCRIPTOR_TYPE = 'DESCRIPTOR_TYPE';
export const DESCRIPTOR_TYPES = 'DESCRIPTOR_TYPES';
export const DESCRIPTOR = 'DESCRIPTOR';
export const DESCRIPTORS = 'DESCRIPTORS';
export const CONDITION = 'CONDITION';
export const CONDITIONS = 'CONDITIONS';
export const STEP = 'STEP';
export const STEPS = 'STEPS';
export const STEP_RAW_MATERIAL = 'STEP_RAW_MATERIAL';
export const STEP_RAW_MATERIALS = 'STEP_RAW_MATERIALS';
export const FLOW = 'FLOW';
export const FLOWS = 'FLOWS';
export const SCENARIO = 'SCENARIO';
export const SCENARIOS = 'SCENARIOS';
export const SCENARIO_DESCRIPTOR = 'SCENARIO_DESCRIPTOR';
export const SCENARIO_DESCRIPTORS = 'SCENARIO_DESCRIPTORS';
export const SCENARIO_DESCRIPTOR_CONDITION = 'SCENARIO_DESCRIPTOR_CONDITION';
export const SCENARIO_DESCRIPTOR_CONDITIONS = 'SCENARIO_DESCRIPTOR_CONDITIONS';
export const PRODUCT = 'PRODUCT';
export const PRODUCTS = 'PRODUCTS';
export const PRODUCT_FLOW = 'PRODUCT_FLOW';
export const PRODUCT_FLOWS = 'PRODUCT_FLOWS';
export const PRODUCT_STEP = 'PRODUCT_STEP';
export const PRODUCT_STEPS = 'PRODUCT_STEPS';
export const PRODUCT_RAW_MATERIAL = 'PRODUCT_RAW_MATERIAL';
export const PRODUCT_RAW_MATERIALS = 'PRODUCT_RAW_MATERIALS';

// bootstrap
export const BOOT = 'BOOT';
export const BOOT_ADMIN = 'BOOT_ADMIN';
export const BOOT_MANAGER = 'BOOT_MANAGER';
export const BOOT_STAFF = 'BOOT_USER';
export const RELOAD_ADMIN = 'RELOAD_ADMIN';

// User types
export const ADMIN = 'ADMIN';
export const MANAGER = 'MANAGER';
export const STAFF = 'STAFF';

// authentication
export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const PASSWORD_UPDATE = 'PASSWORD_UPDATE';
export const PASSWORD_RECOVERY = 'PASSWORD_RECOVERY';
export const PASSWORD_FORGOT = 'PASSWORD_FORGOT';
export const UPDATE_ADMIN_ACCOUNT = 'UPDATE_ADMIN_ACCOUNT';

// UI
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_APP_BAR_TITLE = 'SET_APP_BAR_TITLE';
export const SET_CONTEXT_SEARCH_RM = 'SET_CONTEXT_SEARCH_RM';
export const SET_CONTEXT_SEARCH_PRODUCT = 'SET_CONTEXT_SEARCH_PRODUCT';

// USER_ACCOUNT
export const FETCH_USER_ACCOUNT = FETCH + USER_ACCOUNT;

// PROFILE
export const FETCH_PROFILE = FETCH + PROFILE;

// ACCOUNTS
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const FETCH_ACCOUNTS = FETCH + ACCOUNTS;

// ECHAS
export const FETCH_ECHAS = FETCH + ECHAS;

// ECHAS
export const FETCH_RAW_MATERIAL_ECHAS = FETCH + RAW_MATERIAL_ECHAS;

// FLOWS
export const FETCH_FLOWS = FETCH + FLOWS;

// PRODUCTS
export const FETCH_PRODUCTS = FETCH + PRODUCTS;

// PRODUCTS FLOWS
export const FETCH_PRODUCT_FLOWS = FETCH + PRODUCT_FLOWS;

// PRODUCTS STEPS
export const FETCH_PRODUCT_STEPS = FETCH + PRODUCT_STEPS;

// PRODUCTS RM
export const FETCH_PRODUCT_RAW_MATERIALS = FETCH + PRODUCT_RAW_MATERIALS;

// SCENARIOS
export const FETCH_SCENARIOS = FETCH + SCENARIOS;

// SCENARIO_DESCRIPTORS
export const FETCH_SCENARIO_DESCRIPTORS = FETCH + SCENARIO_DESCRIPTORS;

// SCENARIO_DESCRIPTOR_CONDITIONS
export const FETCH_SCENARIO_DESCRIPTOR_CONDITIONS = FETCH + SCENARIO_DESCRIPTOR_CONDITIONS;

// RAW_MATERIALS
export const FETCH_RAW_MATERIALS = FETCH + RAW_MATERIALS;

// RAW_MATERIALS_SUPPLIERS
export const FETCH_RAW_MATERIAL_SUPPLIERS = FETCH + RAW_MATERIAL_SUPPLIERS;

// CONSUMPTIONS
export const FETCH_CONSUMPTIONS = FETCH + CONSUMPTIONS;

// SUPPLIERS
export const FETCH_SUPPLIERS = FETCH + SUPPLIERS;

// USERS
export const FETCH_USERS = FETCH + USERS;

// UNITS
export const FETCH_UNITS = FETCH + UNITS;

// STEPS
export const FETCH_STEPS = FETCH + STEPS;

// STEP_RAW_MATERIALS
export const FETCH_STEP_RAW_MATERIALS = FETCH + STEP_RAW_MATERIALS;

// DESCRIPTOR_TYPES
export const FETCH_DESCRIPTOR_TYPES = FETCH + DESCRIPTOR_TYPES;

// DESCRIPTORS
export const FETCH_DESCRIPTORS = FETCH + DESCRIPTORS;

// CONDITIONS
export const FETCH_CONDITIONS = FETCH + CONDITIONS;

// MESSAGES
export const FETCH_MESSAGES = 'FETCH_MESSAGES';

// REPORT
export const REPORT = 'REPORT_';

// ALERTS
export const PERSISTENT_ALERT = 'PERSISTENT_ALERT';
export const CLEAN_PERSISTENT_ALERT = 'CLEAN_PERSISTENT_ALERT';
export const ALERT = 'ALERT';
export const ERROR = '_ERROR';
export const SUCCESS = '_SUCCESS';
export const CLEAN_ALERT = 'CLEAN_ALERT';
export const ALERT_ERROR = ALERT + ERROR;
export const ALERT_SUCCESS = ALERT + SUCCESS;


