import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable';
import React from 'react';
import { connect } from 'react-redux';
import columns from 'utils/tables/columns/administration/scenarios';

const Component = props => {
  const { scenariosData, rmsById, flowsById, productsById, descriptorsById } = props;
  const tableRef = React.useRef(null);

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={columns(rmsById, flowsById, productsById, descriptorsById)}
        data={scenariosData}
        onRowClick={() => null}
      />
    </Grid>
  )
};

const mapStateToProps = (state, props) => ({
  scenariosData: state.scenarios.data,
  rmsById: state.rawMaterials.dataById,
  flowsById: state.flows.dataById,
  productsById: state.products.dataById,
  descriptorsById: state.descriptors.dataById
})

export default connect(mapStateToProps)(Component);
