import { sagas as accounts } from 'ducks/accounts';
import { sagas as authentication } from 'ducks/auth';
import { sagas as bootstrap } from 'ducks/bootstrap';
import { sagas as conditions } from 'ducks/conditions';
import { sagas as rawMaterialConsumptions } from 'ducks/consumptions';
import { sagas as descriptorTypes } from 'ducks/descriptorTypes';
import { sagas as echa } from 'ducks/echa';
import { sagas as messages } from 'ducks/messages';
import { sagas as mixtures } from 'ducks/mixtures';
import { sagas as products } from 'ducks/products';
import { sagas as productFlows } from 'ducks/productFlows';
import { sagas as productSteps } from 'ducks/productSteps';
import { sagas as productsRM } from 'ducks/productsRM';
import { sagas as profile } from 'ducks/profile';
import { sagas as rawMaterials } from 'ducks/rawMaterials';
import { sagas as supplierRegisters } from 'ducks/supplierRegister';
import { sagas as suppliers } from 'ducks/suppliers';
import { sagas as units } from 'ducks/units';
import { sagas as userAccount } from 'ducks/userAccount';
import { sagas as users } from 'ducks/users';
import { sagas as steps } from 'ducks/steps';
import { sagas as stepRawMaterials } from 'ducks/stepRawMaterials';
import { sagas as flows } from 'ducks/flows';
import { sagas as descriptors } from 'ducks/descriptors';
import { sagas as scenarios } from 'ducks/scenarios';
import { sagas as scenariosDescriptors } from 'ducks/scenariosDescriptors';
import { sagas as scenariosDescriptorsConditions } from 'ducks/scenariosDescriptorsConditions';
import { sagas as rawMaterialSuppliers } from 'ducks/rawMaterialSuppliers';

import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  try {
    yield all([
      ...accounts,
      ...authentication,
      ...bootstrap,
      ...conditions,
      ...descriptors,
      ...descriptorTypes,
      ...echa,
      ...flows,
      ...messages,
      ...mixtures,
      ...products,
      ...productFlows,
      ...productSteps,
      ...productsRM,
      ...profile,
      ...rawMaterials,
      ...rawMaterialSuppliers,
      ...rawMaterialConsumptions,
      ...steps,
      ...stepRawMaterials,
      ...suppliers,
      ...supplierRegisters,
      ...units,
      ...users,
      ...userAccount,
      ...scenarios,
      ...scenariosDescriptors,
      ...scenariosDescriptorsConditions
    ]);
  } catch (error) {
    console.error(error);
  }
}
