import BaseTable from 'components/Tables/BaseTable';
import FlowListDetailPanel from 'pages/ScenarioExposure/Flow/ScenariosManagement/PROCTrabalhadores/FlowStepListDetailPanel';
import React from 'react';
import { connect } from 'react-redux';
import { lookUpIdValueSelector } from 'selectors/generic';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/scenarioConditions';
import { TABLE_HEIGHT_SPLIT_4VIEW } from 'utils/ui/constants';

const TABLE_HEIGHT = TABLE_HEIGHT_SPLIT_4VIEW(64);

const Component = props => {
  const {
    conditionsById, descriptorId, flowsById, scenariosById, flowScenarioDescriptors,
    scenarioDescriptorConditionsData, stepsById, units, user
  } = props;

  const selectedSD = flowScenarioDescriptors.filter(sd =>
    (descriptorId && sd.id_descritor === descriptorId)
  );

  let filteredSDC = {};
  const sdcConditionRepetitions = [];

  selectedSD.forEach(sSD => {
    const flowId = scenariosById[sSD.id_cenario].id_fluxograma;
    const flowInfo = {
      flow_id: flowId,
      flow_code: flowsById[flowId].codigo_fluxo,
      flow_name: flowsById[flowId].nome
    };
    const stepInfo = {
      step_id: sSD.id_etapa,
      step_code: stepsById[sSD.id_etapa].nome,
      step_name: stepsById[sSD.id_etapa].id_master_etapa ?
        stepsById[sSD.id_etapa].id_master_etapa.descricao + ' / ' + stepsById[sSD.id_etapa].descricao :
        stepsById[sSD.id_etapa].descricao
    };
    const sdcs = scenarioDescriptorConditionsData.filter(sdc => sdc.id_cenariodescritor === sSD.id)
    sdcs.forEach(sdc => {
      if (sdcConditionRepetitions.includes(sdc.id_condicao)) {

        // If the FlowInfo for this flowId as already been added to the list then
        // just need to add StepInfo else add everything
        if (Object.keys(filteredSDC[sdc.id_condicao].stepsInfo).includes(flowId.toString())) {
          filteredSDC[sdc.id_condicao].stepsInfo[flowId] = [
            ...filteredSDC[sdc.id_condicao].stepsInfo[flowId],
            stepInfo
          ]
        } else {
          filteredSDC[sdc.id_condicao].flowsInfo.push(flowInfo);
          filteredSDC[sdc.id_condicao].stepsInfo[flowId] = [stepInfo];
        }

      } else {
        filteredSDC[sdc.id_condicao] = {
          ...sdc,
          flowsInfo: [flowInfo],
          stepsInfo: { [flowId]: [stepInfo] }
        };
        sdcConditionRepetitions.push(sdc.id_condicao);
      }
    })
  });

  const rows = Object.values(filteredSDC).map(element => {
    const subtipo = conditionsById[element.id_condicao].subtipo;
    const nome = conditionsById[element.id_condicao].nome;
    return { ...element, subtipo, nome };
  });

  return (
    <React.Fragment>
      <BaseTable
        title={'CONDIÇÕES DO FLUXO'}
        columns={columns(user, units)}
        data={descriptorId ? rows : []}
        paging={false}
        options={{
          // toolbar: false,
          // search: false,
          maxBodyHeight: TABLE_HEIGHT,
          minBodyHeight: TABLE_HEIGHT,
          rowStyle: () => ({ backgroundColor: theme.palette.background.veryLight })
        }}
        detailPanel={[
          () => ({
            icon: tableIcons.Flow,
            tooltip: 'Fluxogramas',
            render: rowData => <FlowListDetailPanel row={rowData}/>
          })
        ]}
      />
    </React.Fragment>
  )
};

const makemapStateToProps = () => {
  const units = lookUpIdValueSelector('units', ['nome']);
  const mapStateToProps = (state, props) => ({
    conditionsById: state.conditions.dataById,
    flowsById: state.flows.dataById,
    scenariosById: state.scenarios.dataById,
    scenarioDescriptorConditionsData: state.scenarioDescriptorConditions.data,
    stepsById: state.steps.dataById,
    units: units(state),
    user: state.profile.entity
  })
  return mapStateToProps
};

export default connect(makemapStateToProps)(Component);
