import { setAppBarTitle } from 'ducks/ui';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

const PrivateRoute = props => {
  const { component: Component, title, isAuthenticated, ...rest } = props;
  const { setAppBarTitle } = props;

  const render = props => {
    if (isAuthenticated) {
      setAppBarTitle(title);
      return <Component {...props} />;
    } else {
      return (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }}/>
      )
    }
  };

  return (
    <Route {...rest} render={render}/>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
  setAppBarTitle: title => dispatch(setAppBarTitle(title))
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
