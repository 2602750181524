import orderBy from 'lodash/orderBy';

export default (payload, options) => {
  if (options) {
    if (options.flattenFields) {
      const flatKeys = Object.keys(options.flattenFields);
      if (Array.isArray(payload)) {
        payload = payload.map(item => {
          flatKeys.forEach(key =>
            options.flattenFields[key].forEach(field =>
              item[key + '_' + field] = item[key] && item[key][field]
            )
          );
          return item;
        });
      } else if (typeof payload === 'object') {
        flatKeys.forEach(key =>
          options.flattenFields[key].forEach(field =>
            payload[key + '_' + field] = payload[key] && payload[key][field]
          )
        );
      }
    }
    if (options.sanitizers) {
      const sanitizers = Object.keys(options.sanitizers);
      if (Array.isArray(payload)) {
        payload = payload.map(item => {
          sanitizers.forEach(key =>
            item[key] = options.sanitizers[key](item[key])
          );
          return item;
        })
      } else if (typeof payload === 'object') {
        sanitizers.forEach(key => payload[key] = options.sanitizers[key](payload[key]));
      }
    }
    if (options.orderBy && Array.isArray(payload)) {
      payload = orderBy(payload, options.orderBy.what, options.orderBy.how);
    }
  }
  return payload;
}
