import Grid from '@material-ui/core/Grid';
import ExpandableBar from 'components/ExpandableBar'
import LcsSelectorEvaluationProduct from 'connectedComponents/HeaderButtons/LcsSelectorEvaluationProduct';
import ScenarioSelector from 'connectedComponents/HeaderButtons/ScenarioTableSelector';
import React, { useEffect, useState } from 'react';
import theme from 'source/theme';

const Component = props => {
  const {
    currentOptionProduct, currentOptionLcs, currentOptionScenario, scenarios
  } = props;
  const {
    closeScenarioManagement, onSelectionScenario, onSelectionLcs
  } = props;


  const scenarioText = () =>
    <span>
      <span style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', paddingTop: 3 }}>&nbsp;PRODUTO&nbsp;</span>
      &nbsp;{currentOptionProduct['produto_nome']}&nbsp;
      <span style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', paddingTop: 3 }}>&nbsp;LCS&nbsp; </span>
      &nbsp;{currentOptionLcs['descricao']}&nbsp;
      <span style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', paddingTop: 3 }}>&nbsp;CENÁRIO&nbsp;</span>
      &nbsp;{currentOptionScenario && currentOptionScenario['codigo']}&nbsp;
    </span>

  return (
    <ExpandableBar
      header=''
      text={scenarioText}
      goBack={closeScenarioManagement}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 5 }}>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 5 }}>
          <LcsSelectorEvaluationProduct
            currentOption={currentOptionLcs}
            onSelection={onSelectionLcs}
            product={currentOptionProduct}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 5 }}>
          <ScenarioSelector
            currentOption={currentOptionScenario}
            onSelection={onSelectionScenario}
            options={scenarios}
          />
        </Grid>
      </Grid>
    </ExpandableBar>
  )
}

export default Component;
