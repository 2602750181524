import { USER_ACCOUNT } from 'actionTypes';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';
import thunkFactory from 'utils/redux/baseEntitiesFactory/thunk';

// ACTIONS
const rawActions = actionsFactory(USER_ACCOUNT);
const actions = { ...rawActions, ...thunkFactory(USER_ACCOUNT, '/user_account/') };
export { actions };

// REDUCER
const initialState = {
  entity: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};

export default reducerFactory(USER_ACCOUNT, initialState);

// SAGAS
const sagas = sagasFactory(USER_ACCOUNT, '/user_account/');
export { sagas };


