import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

const Component = props => {
  const { bodyHeight, children } = props;

  // wrapped with <tbody> <tr> <td> to avoid warnings on <table> childs
  return (
    <tbody>
    <tr>
      <td>
        <Grid container style={{ height: bodyHeight }} justify="center" alignItems="center">
          <Grid item xs={10} style={{ height: '50%' }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              {children}
            </Box>
          </Grid>
        </Grid>
      </td>
    </tr>
    </tbody>
  );
};

export default Component;

Component.propTypes = {
  bodyHeight: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired
};
