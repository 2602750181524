import BaseTable from 'components/Tables/BaseTable';
import { sortBy } from 'lodash';
import ProductListDetailPanel from 'pages/ScenarioExposure/Product/ScenariosManagement/ProducListDetailPanel';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreData } from 'selectors/generic';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/scenarioExposure/product/productPcSuScenarioDescriptorExposure';

const Component = props => {
  const {
    descriptorId, descriptorsById, descriptorsData, descriptorFilter,
    productsById, productScenarioDescriptors, scenariosById, TABLE_HEIGHT
  } = props;

  const { setDescriptorId } = props
  const tableRef = useRef();

  const filteredDescriptors = descriptorsData.filter(v => v.id_tipo === descriptorFilter);
  const filteredDescriptorsIds = filteredDescriptors.map(fD => fD.id);
  const filteredSD = productScenarioDescriptors.filter(fSD => filteredDescriptorsIds.includes(fSD.id_descritor))

  const mergedProductDescriptors = [];
  const descriptorRepetitions = [];
  filteredSD.forEach(fSD => {
    if (!descriptorRepetitions.includes(fSD.id_descritor)) {
      const scenariodescriptorGrouped = filteredSD.filter(item => item.id_descritor === fSD.id_descritor);
      const mergedDescriptor = {
        id: fSD.id_descritor,
        descriptor_code: descriptorsById[fSD.id_descritor].codigo,
        descriptor_description: descriptorsById[fSD.id_descritor].descricao,
        productsInfo: []
      };

      const repeatedProducts = [];
      scenariodescriptorGrouped.forEach(sdg => {
        const product = productsById[scenariosById[sdg.id_cenario].id_produto];
        if (!repeatedProducts.includes(product.id)) {
          mergedDescriptor.productsInfo.push({
            code: product.codigo_produto,
            name: product.produto_nome
          });
          repeatedProducts.push(product.id);
        }
      });

      descriptorRepetitions.push(fSD.id_descritor);
      mergedProductDescriptors.push(mergedDescriptor);
    }
  });

  const rows = sortBy(mergedProductDescriptors, ['id']);

  console.log('mergedProductDescriptors', rows);

  return (
    <React.Fragment>
      <BaseTable
        ref={tableRef}
        title={'DESCRITORES DE PRODUTO'}
        columns={columns}
        data={rows}
        paging={false}
        onRowClick={() => null}
        onRowDoubleClick={setDescriptorId ? rowData => setDescriptorId(rowData.id) : null}
        options={{
          // toolbar: false,
          // search: false,
          maxBodyHeight: TABLE_HEIGHT,
          minBodyHeight: TABLE_HEIGHT,
          rowStyle: setDescriptorId ? rowData => ({
              backgroundColor: (rowData.id === descriptorId) ?
                theme.palette.primary.light :
                theme.palette.background.veryLight
            }) :
            ({ backgroundColor: theme.palette.background.veryLight })
        }}
        detailPanel={[
          () => ({
            icon: tableIcons.Products,
            tooltip: 'Produtos',
            render: rowData => <ProductListDetailPanel row={rowData}/>
          })
        ]}
      />
    </React.Fragment>
  )
};


const makeMapStateToProps = () => {
  const immutableDescriptorsData = getImmutableStoreData('descriptors')
  const mapStateToProps = (state, props) => ({
    descriptorsData: immutableDescriptorsData(state),
    descriptorsById: state.descriptors.dataById,
    productsById: state.products.dataById,
    scenariosById: state.scenarios.dataById
  })
  return mapStateToProps;
}

export default connect(makeMapStateToProps)(Component);
