import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import PropTypes from 'prop-types';

const Component = props => {
  const { open, saveStyle, closeStyle } = props;
  const { handleSave, handleClose } = props;

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <div style={{ paddingTop: 25 }}>
            {props.children}
          </div>
        </DialogContent>
        <DialogActions>
          {
            handleSave &&
            <Button onClick={handleSave} variant={saveStyle} color="primary">
              Save
            </Button>
          }
          {
            handleClose &&
            <Button onClick={handleClose} variant={closeStyle} color="secondary">
              Close
            </Button>
          }
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Component;

Component.propTypes = {
  saveStyle: PropTypes.oneOf(['contained', 'oulined', undefined]),
  closeStyle: PropTypes.oneOf(['contained', 'oulined', undefined]),
  handleSave: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}
