import Drawer from '@material-ui/core/Drawer';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { HEADER_HEIGHT } from 'utils/ui/constants';

const styles = {
  drawerContent100: {
    top: 64,
    width: '100vw',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`
  },
  drawerContent92: {
    top: 64,
    width: '92vw',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`
  },
  drawerContent82: {
    top: 64,
    width: '82vw',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`
  },
  drawerContent60: {
    top: 64,
    width: '60vw',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`
  },
  drawerContent35: {
    top: 64,
    width: '35vw',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`
  }
};

const coverageClass = (classes, size) => {
  switch (size) {
    case 100:
      return classes.drawerContent100;
    case 92:
      return classes.drawerContent92;
    case 82:
      return classes.drawerContent82;
    case 60:
      return classes.drawerContent60;
    case 35:
      return classes.drawerContent35;
    default:
      return classes
  }
}

const Component = props => {
  const { classes, children, coverage = 92, anchor = 'right' } = props;

  return (
    <Drawer
      {...props}
      anchor={anchor}
      classes={{ paper: coverageClass(classes, coverage) }}
    >
      {children}
    </Drawer>
  )
};

export default withStyles(styles)(Component);
