import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columns = [
  { title: 'Nome', field: 'mp_nome' },
  {
    title: 'Percentagem (%)',
    field: 'percentagem',
    render: value => value ?
      parseFloat(value) :
      value
  },
  { title: 'Tipo', field: 'tipo' },
  { title: 'CAS', field: 'cas' },
  { title: 'EC', field: 'ec' },
  {
    title: 'Perigosa',
    field: 'perigosa',
    render: value =>
      <span style={{ fontWeight: value ? 'bold' : null }}>
        {value ? 'Sim' : 'Não'}
      </span>
  }
];

export default props => {
  const { rowData, data, title } = props;

  const rows = rowData.map(obj => ({
    ...data[obj.id_substancia],
    percentagem: obj.percentagem
  }));

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={rows} title={title}/>
    </div>
  )
};
