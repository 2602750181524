import { MTableEditField } from '@material-table/core';
import React from 'react';

export default user => {
  const columns = [
    {
      title: 'Código MP',
      field: 'cod_mp',
      minWidth: 100,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      }
    },
    {
      title: 'Nome',
      field: 'mp_nome',
      minWidth: 400,
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'left'
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>
    },
    {
      title: 'Observações',
      field: 'observacoes',
      minWidth: 400,
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'left'
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>
    },
    {
      title: 'Perigosa',
      field: 'perigosa',
      type: 'boolean',
      minWidth: 90,
      initialEditValue: 'true',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      },
      lookup: {
        true: 'Sim',
        false: 'Não'
      },
      render: rowData =>
        <span style={{fontWeight: rowData.perigosa ? 'bold' : null}}>
        {rowData.perigosa ? 'Sim' : 'Não' }
      </span>
    }
  ];

  const columnsAdmin = [
    {
      title: 'Identificador',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};
