import React from 'react';
import { POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';

export default [
  {
    title: 'Percentagem (%)',
    field: 'percentagem',
    type: 'numeric',
    minWidth: 100,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
    render: rowData => rowData.percentagem ?
      parseFloat(rowData.percentagem) : rowData.percentagem
  },
  {
    title: 'Tipo',
    field: 'tipo',
    minWidth: 100,
    lookup: {
      [SUBSTANCE]: 'Substância',
      [POLIMER]: 'Polímero'
    },
    editable: 'never',
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Nome',
    field: 'mp_nome',
    editable: 'never',
    minWidth: 600,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'CAS',
    field: 'cas',
    editable: 'never',
    minWidth: 100,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'EC',
    field: 'ec',
    editable: 'never',
    minWidth: 100,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  }
];
