import BigDialog from 'components/BigDialog';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions as actionsRawMaterials } from 'ducks/rawMaterials';
import { actions as actionsRawMatSuppliers } from 'ducks/rawMaterialSuppliers';
import { actions as actionsSupplierRegister } from 'ducks/supplierRegister';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { lookUpIdValueSelector } from 'selectors/generic';
import columns from 'utils/tables/columns/product/productShipment';
import { validator as productShipmentValidator } from 'utils/tables/validators/productShipment';
import rawMaterialValidator from 'utils/tables/validators/rawMaterial';
import { validator as shipmentRegisterValidator } from 'utils/tables/validators/shipmentRegistersMixtures';

const Component = props => {
  const { idAccount, rawMaterial, rawMaterialsData, suppliers, user, userAccount } = props;
  const {
    alertError, closeParentContainer, shipmentType, createRawMatSupplier, createSupplierRegister,
    fetchRawMaterial, postInsertRequest, removeRawMatSupplier,
    removeSupplierRegister, updateRawMatSupplier, updateRawMaterial, updateSupplierRegister
  } = props;
  let shipments = rawMaterial.inter_fornecedores;

  const [shipmentIdSelected, setShipmentIdSelected] = useState(undefined);
  const tableRef = useRef();

  shipments = shipments
    .filter(shipment => shipment.papel_reach === shipmentType)
    .map(shipment => {
    const registration = shipment['registos_directos'] && shipment['registos_directos'].length === 1 ?
      shipment['registos_directos'][0] : '';
      const supplier = shipment['id_fornecedor'] && shipment['id_fornecedor']['id'];
      return {
        ...registration,
        ...shipment,
        id_fornecedor: supplier,
        tableData: shipmentIdSelected === shipment.id ?
          { checked: true } : {}
      }
    })

  // Using a already present shipment. User only as to select the shipment to
  // activate the save button. The Save only as to add the product to composition
  // no need to create shipment.
  const handleSave = () => postInsertRequest()
      .then(() => fetchRawMaterial(rawMaterial.id))
      .then(() => closeParentContainer());

  return (
    <BigDialog
      open={true}
      handleSave={shipmentIdSelected ? handleSave : null}
      saveStyle={'contained'}
      handleClose={closeParentContainer}
    >
      <BaseTable
        ref={tableRef}
        titleBack={true}
        titleAction={closeParentContainer}
        title={
          rawMaterial.cod_mp ?
            '[ ' + rawMaterial.cod_mp + ' ] - ' + 'CAS [ ' + rawMaterial.cas + ' ] - ' + 'EC [ ' + rawMaterial.ec + ' ] - ' + rawMaterial.mp_nome +
            `  --  Crie ou selecione um fornecimento papel reach ${shipmentType}.` :
            '[ CODIGO MP VAZIO ] - ' + 'CAS [ ' + rawMaterial.cas + ' ] - ' + 'EC [ ' + rawMaterial.ec + ' ] - ' + rawMaterial.mp_nome +
            `  --  Crie ou selecione um fornecimento papel reach ${shipmentType}.`
        }
        columns={columns(user, userAccount, rawMaterial, suppliers, shipmentType)}
        data={shipments}
        onSelectionChange={(rows, difRow) => {
          if (rows.length !== 0) {
            setShipmentIdSelected(difRow.id);
          } else {
            setShipmentIdSelected(null);
          }
        }}
        options={{
          actionsCellStyle: {
            minWidth: 200,
            paddingLeft: 20
          },
          maxBodyHeight: '50vh',
          minBodyHeight: '50vh',
          selection: true,
          showTextRowsSelected: false,
          showSelectAllCheckbox: false,
          // selectionProps: rowData => {
          //   if (
          //     shipmentType !== rowData.papel_reach ||
          //     (shipmentIdSelected && rowData.id !== shipmentIdSelected)
          //   ) {
          //     return { disabled: true }
          //   }
          // }
        }}
        editable={{
          /** onRowUpdate is here because the Table will appear incorrectly if without ACÇÕES column*/
          onRowUpdate: () => Promise.resolve(),

          onRowAdd: newData => {
            const sValidation = productShipmentValidator(newData, undefined);
            const srValidation = shipmentRegisterValidator(newData, undefined);
            if (sValidation.error || srValidation.error) {
              alertError([
                ...sValidation.error ? sValidation.error : [],
                ...srValidation.error ? srValidation.error : []
              ]);
              return Promise.reject();
            }

            const rawMaterialToValidate = {... rawMaterial, cod_mp: newData.cod_mp};
            const rmValidation = rawMaterialValidator(rawMaterialToValidate, rawMaterialsData);
            if ((!rawMaterial.cod_mp || rawMaterial.cod_mp === '') && rmValidation.error) {
              alertError(rmValidation.error);
              return Promise.reject();
            }

            const id_mp = rawMaterial.id;
            const tempData = { ...newData, id_account: idAccount, id_mp };
            return createRawMatSupplier(tempData)
              .then((data) => {
                const id_mp_fornecedor = data.id;
                const tempData = { ...newData, id_account: idAccount, id_mp_fornecedor };
                return createSupplierRegister(tempData);
              })
              .then(() => {
                if (!rawMaterial.cod_mp || rawMaterial.cod_mp === '') {
                  return updateRawMaterial(rawMaterial.id, { cod_mp: newData.cod_mp })
                } else {
                  return Promise.resolve();
                }
              })
              .then(() => postInsertRequest())
              .then(() => fetchRawMaterial(rawMaterial.id))
              .then(() => closeParentContainer())
          }
        }}
      />
    </BigDialog>
  )
};

const makeMapStateToProps = () => {
  const suppliers = lookUpIdValueSelector('suppliers', ['cod_fornecedor', 'fornecedor']);
  const mapStateToProps = (state, props) => ({
    idAccount: state.profile.entity.id_account,
    rawMaterialsData: state.rawMaterials.data,
    suppliers: suppliers(state),
    user: state.profile.entity,
    userAccount: state.userAccount.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  createRawMatSupplier: data => dispatch(actionsRawMatSuppliers.createPromise(data)),
  removeRawMatSupplier: id => dispatch(actionsRawMatSuppliers.removePromise(id)),
  updateRawMatSupplier: (id, data) => dispatch(actionsRawMatSuppliers.updatePromise(id, data)),
  createSupplierRegister: data => dispatch(actionsSupplierRegister.createPromise(data)),
  removeSupplierRegister: id => dispatch(actionsSupplierRegister.removePromise(id)),
  updateSupplierRegister: (id, data) => dispatch(actionsSupplierRegister.updatePromise(id, data)),
  fetchRawMaterial: id => dispatch(actionsRawMaterials.fetchOne(id)),
  updateRawMaterial: (id, data) => dispatch(actionsRawMaterials.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
