import { Button, Grid, Paper, withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Fingerprint from '@material-ui/icons/Fingerprint';
import Alert from 'components/Alert';
import { cleanAlert } from 'ducks/alerts';
//import { login } from 'ducks/auth';
import reachOKLogo from 'images/brand/REACHok_branco.png';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import theme from 'source/theme';
import styles from './styles';

const Component = props => {
  const { classes, alert } = props;
  const { cleanAlert } = props;
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');

  const handleChangePassword = event => {
    setPassword(event.target.value);
  };

  const handleChangeVerifyPassword = event => {
    setVerifyPassword(event.target.value);
  };

  const handleSubmit = () => {
    //props.passwordRecovery(password);
  };

  return (
    <div className={classes.container}>
      <div className={classes.loginArea}>
        <Paper className={classes.paper}>
          <div className={classes.margin}>
            <Alert
              open={alert.message.length !== 0}
              message={alert.message}
              handleClose={cleanAlert}
              placement={{ vertical: 'top', horizontal: 'right' }}
              variant={'error'}
            />

            <Grid container justify="center">
              <Grid item xs={8}>
                <img src={reachOKLogo} style={{ width: '100%' }} alt=""/>
              </Grid>
            </Grid>

            <Divider style={{ marginBottom: '15px' }}/>

            <Grid container spacing={8} justify='center'>
              <Grid xs={10} item>
                <Typography
                  variant="h6" align='center' gutterBottom
                  style={{color: theme.palette.secondary.dark}}
                >
                  Password Recovery!
                </Typography>
                <Typography
                  color='textSecondary' variant="body2" align='justify' gutterBottom
                  style={{color: theme.palette.secondary.dark}}
                >
                  Please insert your new password, to recover your account.
                  Thank you!
                </Typography>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: '15px', marginBottom: '30px' }}/>

            <ValidatorForm onSubmit={handleSubmit}>
              <Grid container className={classes.textInput}>
                <Grid item md={true} sm={true} xs={true}>
                  <TextValidator
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Fingerprint className={classes.iconColor} style={{ fontSize: 30 }}/>
                        </InputAdornment>
                      )
                    }}
                    label="Password"
                    onChange={handleChangePassword}
                    name="password"
                    value={password}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    fullWidth
                    type='password'
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.textInput}>
                <Grid item md={true} sm={true} xs={true}>
                  <TextValidator
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Fingerprint className={classes.iconColor} style={{ fontSize: 30 }}/>
                        </InputAdornment>
                      )
                    }}
                    label="Verify Password"
                    onChange={handleChangeVerifyPassword}
                    name="verifypassword"
                    value={verifyPassword}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    fullWidth
                    type='password'
                  />
                </Grid>
              </Grid>

              <Grid container justify="center" style={{ marginTop: '30px' }}>
                <Button
                  type="submit"
                  className={classes.textTransformationNone}
                  variant="contained"
                  color="secondary"
                >
                  Submit
                </Button>
              </Grid>
            </ValidatorForm>

          </div>
        </Paper>
      </div>
    </div>
  );
};

Component.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  alert: state.alert
});

const mapDispatchToProps = dispatch => ({
  // passwordRecovery(password) {
  //   dispatch(passwordRecovery(password));
  // },
  cleanAlert() {
    dispatch(cleanAlert());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
