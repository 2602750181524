import { DISTRIBUIDOR, FABRICANTE, IMPORTADOR, UJ } from 'utils/constants/papel_reach';

const Joi = require('@hapi/joi');

const baseProperties = ['codigo', 'designacao_comercial', 'id_fornecedor', 'data_fds', 'revisao_fds', 'papel_reach'];

export const schema = Joi.object({
  codigo: Joi.string()
    .min(3)
    .max(60)
    .required()
    .messages({
      "any.required": `"Código" campo de preenchimento obrigatório`,
      "string.empty": `"Código" campo vazio`,
      "string.min": `"Código" tem de ter pelo menos 3 caracteres`,
      "string.max": `"Código" só pode 60 caracteres máximo`
    }),
  designacao_comercial: Joi.string()
    .min(3)
    .max(200)
    .required()
    .messages({
      "any.required": `"Designação Comercial" campo de preenchimento obrigatório`,
      "string.empty": `"Designação Comercial" campo vazio`,
      "string.min": `"Designação Comercial" tem de ter pelo menos 3 caracteres`,
      "string.max": `"Designação Comercial" só pode 200 caracteres máximo`
    }),
  id_fornecedor: Joi.number()
    .required()
    .messages({
      "any.required": `"Fornecedor" campo de preenchimento obrigatório`,
      "number.base": `"Fornecedor tem de ser um identificador numérico"`
    }),

  data_fds: Joi.date()
    .allow(null)
    .messages({
      "any.required": `"Data FDS" campo de preenchimento obrigatório`,
      "date.base": `"Data FDS" tem de ser uma data válida"`
    }),
  revisao_fds: Joi.string()
    .max(25)
    .allow('')
    .allow(null)
    .messages({
      "any.required": `"Revisão FDS" campo de preenchimento obrigatório`,
      "string.empty": `"Revisão FDS" campo vazio`,
      "string.max": `"Revisão FDS" só pode 25 caracteres máximo`
    }),
  papel_reach: Joi.string()
    .valid(UJ, FABRICANTE, IMPORTADOR, DISTRIBUIDOR)
    .required()
    .messages({
      "any.required": `"Papel Reach" campo de preenchimento obrigatório`,
      "string.empty": `"Papel Reach" campo vazio`,
      "any.only": `"Papel Reach" só pode ter as seguintes opções: UJ, Fabricante, Importador ou Distribuidor`,
      "string.valid": `"Papel Reach" só pode ter as seguintes opções: UJ, Fabricante, Importador ou Distribuidor`
    })
});

export const validator = (newEntity, rawMaterials) => {
  let iEntity = { ...newEntity };

  Object.keys(iEntity).forEach(k => {
    if (!baseProperties.includes(k)) {
      delete iEntity[k];
    }
  });
  // Validate data correctness
  const validateEntity = schema.validate(iEntity, { abortEarly: false });
  if (validateEntity.error) {
    return { error: validateEntity.error.details.map(obj => obj.message) };
  }

  // Validate repetitions code shipment
  const codeRepeatedRM = rawMaterials.find(rm => {
    if (rm.inter_fornecedores.length > 0) {
      return rm.inter_fornecedores.some(sh =>
        sh.codigo === newEntity.codigo && sh.id !== newEntity.id
      );
    }
    return false;
  })

  if (codeRepeatedRM) {
    return {
      error: [
        'Codigo de fornecimento repetido',
        `A Matéria Prima [Nome: ${codeRepeatedRM.mp_nome}, Codigo MP: ${codeRepeatedRM.cod_mp}] já possui um fornecimento com o mesmo código`
      ]
    }
  }

  return iEntity;
};

