import React from 'react';
import theme from 'source/theme';

export default ({ row }) =>
  <div style={{ backgroundColor: theme.palette.background.light }}>
    <ul style={{ margin: 0, paddingTop: 10, paddingBottom: 10 }}>
      {
        row.substances.map( substance =>
          <React.Fragment key={ substance.mp_nome}>
            <li>{ substance.mp_nome}</li>
            <ul style={{ margin: 0, paddingTop: 2, paddingBottom: 2 }}>
              {
                row.shipmentsBySubstanceId[substance.id].map(shipment =>
                  <li
                    key={shipment.codigo}>{shipment.codigo + ' - ' + shipment.designacao_comercial}
                    {
                      shipment.valor ?
                        <span> -&nbsp;
                          <span style={{ backgroundColor: theme.palette.background.veryLight, borderRadius: '5px' }}>
                             Valor: {shipment.valor + ' ' + shipment.unidade}
                          </span>
                        </span> :
                        null
                    }
                    {
                      shipment.notas && shipment.notas !== ''  ?
                        <span> -&nbsp;
                          <span style={{ color: theme.palette.background.veryLight }}>
                             Notas: {shipment.notas}
                          </span>
                        </span> :
                        null
                    }
                  </li>
                )
              }
            </ul>
          </React.Fragment>
        )
      }
    </ul>
  </div>
