import { Button, Grid, Paper, withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import reachOKLogo from 'images/brand/REACHok_branco.png';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const Component = props => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <div className={classes.loginArea}>
        <Paper className={classes.paper}>
          <div className={classes.margin}>

            <Grid container justify="center">
              <Grid item xs={8}>
                <img src={reachOKLogo} style={{ width: '100%' }} alt=""/>
              </Grid>
            </Grid>

            <Divider style={{ marginBottom: '15px' }}/>

            <Grid container spacing={8} justify='center'>
              <Grid xs={10} item>
                <Typography variant="h6" align='center' gutterBottom>
                  Session Logged Out !
                </Typography>
                <Typography variant="body2" align='justify' gutterBottom>
                  Your session was logged out by yourself, or because some issue with authentication was detected
                </Typography>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: '15px', marginBottom: '30px' }}/>


            <Grid container justify="center" style={{ marginTop: '30px' }}>
              <Button
                type="submit"
                className={classes.textTransformationNone}
                variant="contained"
                color="secondary"
              >
                Go to Login Page
              </Button>
            </Grid>

          </div>
        </Paper>
      </div>
    </div>
  );
};

Component.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Component);
