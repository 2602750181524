import { CONSUMPTION } from 'actionTypes';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';
import thunkFactory from 'utils/redux/baseEntitiesFactory/thunk';


// ACTIONS
const rawActions = actionsFactory(CONSUMPTION);
const actions = { ...rawActions, ...thunkFactory(CONSUMPTION, '/mp_quantidade/') };
export { actions };

// REDUCER
const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};

export default reducerFactory(CONSUMPTION, initialState);

// SAGAS
const sagas = sagasFactory(CONSUMPTION, '/mp_quantidade/');
export { sagas };
