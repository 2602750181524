import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/products';
import { setContextSearchProduct, setContextSearchRM } from 'ducks/ui';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/products/productsInventory';
import { getImmutableStoreData } from 'selectors/generic';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/product/product';
import validator from 'utils/tables/validators/product';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const { contextSearch, products, rawMaterialsById, user } = props;
  const { alertError, create, remove, setSearch, update } = props;
  const tableRef = React.useRef(null);

  const [loading, setLoading] = useState(false);

  const exportPDF = () => {
    const filteredTableData = tableRef.current.state.data;
    pdfMake.createPdf(docDefinition(filteredTableData, rawMaterialsById)).open();
  };

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={columns(user)}
        data={products}
        onRowClick={() => null}
        isLoading={loading}
        onSearchChange={ text => setSearch(text)}
        options={{
          actionsCellStyle: {
            width: 150,
            paddingLeft: 20
          },
          searchText: contextSearch
        }}
        actions={[
          {
            isFreeAction: true,
            icon: tableIcons.Pdf,
            tooltip: 'Exportar PDF',
            onClick: exportPDF
          }
        ]}
        editable={{
          onRowAdd: newData => {
            setLoading(true);
            const validation = validator(newData, products)
            if (validation.error) {
              setLoading(false);
              alertError(validation.error)
              return Promise.reject();
            } else {
              return create(validation)
                .catch((e) => alert(e))
                .finally(() => setLoading(false))
            }
          },
          onRowUpdate: (newData, oldData) => {
            setLoading(true);
            const validation = validator(newData, products)
            if (validation.error) {
              setLoading(false);
              alertError(validation.error)
              return Promise.resolve();
              // TODO Instead of rejecting we are resolving, should be rejecting. Have to update library later github issue #2086
            } else {
              return update(newData.id, newData)
                .catch((e) => alert(e))
                .finally(() => setLoading(false))
            }
          },
          onRowDelete: oldData => {
            setLoading(true);
            return remove(oldData.id)
              .catch((e) => alert(e))
              .finally(() => setLoading(false))
          }
        }}
      />
    </Grid>
  )
};


const makeMapStateToProps = () => {
  const immutableProducts = getImmutableStoreData('products');
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchProduct,
    products: immutableProducts(state),
    rawMaterialsById: state.rawMaterials.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  create: data => dispatch(actions.createPromise(data)),
  remove: id => dispatch(actions.removePromise(id)),
  setSearch: search => dispatch(setContextSearchProduct(search)),
  update: (id, data) => dispatch(actions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
