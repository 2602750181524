import SpinButton from 'components/Buttons/SpinButton';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

const Component = props => {
  const { disabled, label, onLoadFile, submitting } = props;

  return (
    <Dropzone onDrop={acceptedFiles => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => alert('file reading was aborted');
        reader.onerror = () => alert('file reading has failed');
        reader.onload = (e) => {
          onLoadFile(reader, file);
        };
        reader.readAsArrayBuffer(file);
      });
    }}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <SpinButton
              color='primary'
              disabled={submitting}
              submitting={submitting}
              label={label}
              {...props}
            />
          </div>
        </section>
      )}
    </Dropzone>
  )
};

export default Component;

Component.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onLoadFile: PropTypes.func.isRequired
};
