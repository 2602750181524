import { MTableEditField } from '@material-table/core';
import React from 'react';
import { SUBSTANCE } from 'utils/constants/coreConstants';
import { DISTRIBUIDOR, FABRICANTE, IMPORTADOR, UJ } from 'utils/constants/papel_reach';

const emptyCellFiller = field => field ? field : '-';

const dateFormater = date => {
  if (typeof date === 'string') {
    const d = new Date(date);
    return isNaN(d.getDate()) ?
      undefined :
      (d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear())
  }
  return (date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
};

export default (rawMaterialType, suppliers) => {
  const columns = [
    {
      title: 'Código',
      field: 'codigo',
      minWidth: 100,
      initialEditValue: '',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Designação Comercial',
      field: 'designacao_comercial',
      initialEditValue: '',
      minWidth: 200,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>
    },
    {
      title: 'Fornecedor',
      field: 'id_fornecedor',
      lookup: suppliers,
      initialEditValue: Object.keys(suppliers)[0],
      editable: 'onAdd',
      minWidth: 200,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
      editComponent: (props) => <MTableEditField fullWidth {...props}/>,
    },
    {
      title: 'Papel Reach',
      field: 'papel_reach',
      sorting: false,
      minWidth: 50,
      editable: 'onAdd',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      initialEditValue: UJ,
      lookup: {
        //'-': '-',
        [UJ]: 'UJ',
        [FABRICANTE]: 'Fabricante',
        [IMPORTADOR]: 'Importador',
        [DISTRIBUIDOR]: 'Distribuidor'
      },
    },
    {
      title: 'Data FDS',
      field: 'data_fds',
      type: 'date',
      minWidth: 100,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      initialEditValue: null,
      render: ({ data_fds }) => data_fds ? dateFormater(data_fds) : null,
      editComponent: (props) => <MTableEditField fullWidth {...props}/>
    },
    {
      title: 'Revisão FDS',
      field: 'revisao_fds',
      sorting: false,
      minWidth: 50,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'CE',
      field: 'ce',
      sorting: false,
      minWidth: 50,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      initialEditValue: 'sim',
      lookup: {
        //'-': '-',
        'sim': 'Sim',
        'nao': 'Não',
        'na': 'N/A'
      },
    },
    {
      title: 'Data Recepção CE',
      field: 'data_recepcao',
      initialEditValue: null,
      type: 'date',
      sorting: false,
      minWidth: 100,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: ({ data_recepcao }) => data_recepcao ? dateFormater(data_recepcao) : null
    },
  ];

  if (rawMaterialType === SUBSTANCE) {
    columns.push({
      title: 'Nº Registo',
      field: 'n_registo',
      initialEditValue: '',
      sorting: false,
      minWidth: 250,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    })
  }

  columns.push({
    title: 'Observações',
    field: 'observacoes',
    initialEditValue: '',
    sorting: false,
    minWidth: 200,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'left',
    },
    editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
  })

  return columns;
}
