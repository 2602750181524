import { FETCH_PROFILE, FETCH_RAW_MATERIALS, PROFILE, RAW_MATERIAL, REJECTED } from 'actionTypes';
import actionsFactory from 'utils/redux/baseEntitiesFactory/actions';
import reducerFactory from 'utils/redux/baseEntitiesFactory/reducer';
import sagasFactory from 'utils/redux/baseEntitiesFactory/sagas';
import thunkFactory from 'utils/redux/baseEntitiesFactory/thunk';
import { apiRequest } from 'utils/request';

// CUSTOM ACTIONS
const handleError = (TYPE, error, dispatch) => {
  console.log('error: ', error);
  if (error.response) {
    if (error.response.status === 401) {
      dispatch({ type: 'LOGOUT' });
    } else {
      dispatch({ type: TYPE + REJECTED, payload: error.response.statusText });
    }
  } else {
    dispatch({ type: TYPE + REJECTED, payload: 'Unknown error' });
  }
};

const ownUpdate = data => dispatch => {
  return apiRequest('/profile/', 'PUT', data)
    .then(response => {
      dispatch({ type: FETCH_PROFILE});
    })
    .catch( error => handleError(PROFILE, error, dispatch))
}

// ACTIONS
const rawActions = actionsFactory(PROFILE);
const actions = {
  ...rawActions,
  ...thunkFactory(PROFILE, '/profile/'),
  ownUpdate
};
export { actions };

// REDUCER
const initialState = {
  entity: {},
  loaded: false,
  loading: false,
  errorMessage: '',
  submitting: false
};

export default reducerFactory(PROFILE, initialState);

// SAGAS
const sagas = sagasFactory(PROFILE, '/profile/');
export { sagas };
