import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import RadioGroup from 'components/RadioGroup';
import { updateAdminAccount } from 'ducks/auth';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import theme from 'source/theme';

const useStyles = makeStyles({
  select1st: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  select2nd: {
    color: theme.palette.background.light,
    fontWeight: 'bold'
  }
});


const Component = props => {
  const { accounts, currentAccount, user } = props;
  const { updateAdminAccount } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [tempAccountId, setTempAccountId] = useState(currentAccount.id)

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleConfirm = () => {
    updateAdminAccount(tempAccountId)
    setOpen(false);
  };

  const handleAccountChange = accountName => {
    const selectedAccount = accounts.data[accountsList.indexOf(accountName)];
    setTempAccountId(selectedAccount.id);
  };

  const accountsList = accounts.data.map(v => v['account_name']);

  return (
    <div className={props.className}>
      {
        user.is_staff ?
          <React.Fragment>
            <Button variant='outlined' size='small' color='primary' onClick={handleOpen}>
              <span className={classes.select1st}>Cliente: &nbsp;</span>
              <span className={classes.select2nd}>{currentAccount.account_name}</span>
            </Button>
            {
              open &&
              <Dialog
                header='Seleccione organização'
                open={open}
                onClose={handleClose}
                onConfirm={handleConfirm}
              >
                <RadioGroup
                  onChange={handleAccountChange}
                  options={accountsList}
                  value={accounts.dataById[tempAccountId].account_name}
                />
              </Dialog>
            }
          </React.Fragment> :
          <Button variant='outlined' size='small' color='primary'>
            <span className={classes.select1st}>Cliente: &nbsp;</span>
            <span className={classes.select2nd}>{currentAccount.account_name}</span>
          </Button>
      }

    </div>
  )
};

const mapStateToProps = state => ({
  accounts: state.accounts,
  user: state.profile.entity,
  currentAccount: state.userAccount.entity
});

const mapDispatchToProps = ({
  updateAdminAccount
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
