import BaseTable from 'components/Tables/BaseTable';
import { actions as productActions } from 'ducks/products';
import { actions as productRMActions } from 'ducks/productsRM';
import { actions as rawMaterialsActions } from 'ducks/rawMaterials';
import { actions as actionsRawMatSuppliers } from 'ducks/rawMaterialSuppliers';
import { sortBy } from 'lodash';
import ShipmentInserterTable from 'pages/Products/ProductRM/shipmentInserterTable';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { filterByFieldSelector } from 'selectors/generic';
import theme from 'source/theme';
import { MIXTURE, POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';
import { DISTRIBUIDOR, FABRICANTE, UJ } from 'utils/constants/papel_reach';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/product/productOutRawMaterial';

const Component = props => {
  let {
    highlightSubstances, inOutFinalRM, productId, productsById, rawMaterials,
    rawMaterialsById, title, user
  } = props;
  const {
    createProductRM, fetchProduct, fetchRawMaterial, handleCloseDrawer,
    handleOpenRmInserter, removeProductRM, removeRawMatSupplier, setHighlightSubstances,
    updateRawMaterial
  } = props;

  const tableRef = useRef(null);

  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const [RMShipmentInsert, setRMShipmentInsert] = useState(null);
  const [posShipmentInsertRequest, setPosShipmentInsertRequest] = useState(null);
  const handleCloseShipmentInserter = () => {
    setLoading(false);
    setPosShipmentInsertRequest(null);
  }

  useEffect(() => {
    const tempSelectedIds = [];
    productsById[productId].Produto_Mps.forEach(prm =>
      !prm.mp_formulacao &&
      prm.id_mistura_caminho_mp === null &&
      tempSelectedIds.push(prm.id_mp.id)
    );
    setSelectedIds(tempSelectedIds)
  }, [productsById, rawMaterials]);

  rawMaterials = rawMaterials.map(subst => {
    if (selectedIds.includes(subst.id)) {
      return { ...subst, tableData: { checked: true } }
    } else if (subst.tableData && subst.tableData.checked) {
      return { ...subst, tableData: { checked: false } }
    } else {
      return subst;
    }
  });

  const orderedSubstances = sortBy(rawMaterials, [o => !selectedIds.includes(o.id)]);

  /**
   * return true if no shipment is found for MP as FABRICANTE or DISTRIBUIDOR. If any is detected
   * returns false. No need to create new shipment as FABRICANTE or DISTRIBUIDOR
   */
  const createShipmentForRM = (rm, productType) => {
    const shipments = rm.inter_fornecedores;
    if (shipments.length === 0) {
      return true;
    } else if (productType === 'out') {
      const notProduced = shipments.map(s => s.papel_reach !== FABRICANTE).every(t => t);
      if (notProduced) {
        return true;
      }
    } else if (productType === 'distr') {
      const notDistributed = shipments.map(s => s.papel_reach !== DISTRIBUIDOR).every(t => t);
      if (notDistributed) {
        return true;
      }
    }
    return false;
  };

  /**
   * return true if length of the array with all product_MP where MP is FABRICANTE  is 1.
   * If more than 1 product_MP as FABRICANTE  shipment shouldn't be deleted
   * ONLY EXISTS FOR FABRICANTE BECAUSE THERE IS ONLY ONE SHIPMENT FABRICANTE
   */
  const deleteShipmentOfRM = (pId, rm) => {
    // TODO this is here because API repeats the product everytime the RM is inserted in the product CARGA or REACÇÃO
    const productsSet = new Set(rm.produtos);
    const products = Array.from(productsSet);

    const productsToCheck = products.map(id => productsById[id]);
    const productsRMsToCheck = productsToCheck.reduce((acc, cv) => [...acc, ...cv.Produto_Mps], []);

    const rmProducedOnProduct = productsRMsToCheck.filter(prm => (prm.id_mp.id === rm.id) && !prm.mp_formulacao && !prm.id_mistura_caminho_mp);
    return rmProducedOnProduct.length === 1;
  };

  const OutRawMaterialSelectorTable = () => (
    <BaseTable
      ref={tableRef}
      titleBack={true}
      titleAction={handleCloseDrawer}
      title={title}
      onRowClick={() => null}
      onRowDoubleClick={rowData => setHighlightSubstances([rowData.id])}
      columns={columns(user)}
      data={orderedSubstances}
      options={{
        selection: true,
        showTextRowsSelected: false,
        showSelectAllCheckbox: false,
        rowStyle: rowData => ({
          backgroundColor: highlightSubstances.includes(rowData.id) && rowData.tableData.checked ?
            theme.palette.primary.light : ''
        })
      }}
      isLoading={loading}
      onSelectionChange={(rows, difRow) => {
        setLoading(true);
        if (rows.length > selectedIds.length) {
          const filteredProductRM = productsById[productId].Produto_Mps.filter(pMP => rawMaterialsById[pMP.id_mp.id].tipo !== MIXTURE);
          const preExistentMP = filteredProductRM.find(pMP => pMP.id_mp.id === difRow.id)

          // TODO Not clear why when passing a promise as parameter through useState
          // TODO Usetate executes a level of the function
          const productRMCreate = () => () =>
            createProductRM({
              id_produto: productId,
              id_mp: difRow.id,
              mp_final: true,
              mp_formulacao: false,
              mp_excluido_final: preExistentMP ? preExistentMP.mp_excluido_final : false,
              contribui_perigo: preExistentMP ? preExistentMP.contribui_perigo : inOutFinalRM === 'distr'
            })
              .then(() => fetchRawMaterial(difRow.id))
              .then(() => fetchProduct(productId))
              .catch(e => alert(e))
              .finally(() => setLoading(false))

          setRMShipmentInsert(difRow);
            //TODO send CLOSE to SHIPMENT dialog box
            setPosShipmentInsertRequest(productRMCreate);

        } else {
          const substanceToRemove = productsById[productId].Produto_Mps
            .find(prm => (prm.id_mp.id === difRow.id) && !prm.mp_formulacao && !prm.id_mistura_caminho_mp);
          removeProductRM(substanceToRemove.id)
            .then(() => fetchRawMaterial(difRow.id))
            .then(() => fetchProduct(productId))
            .catch(e => alert(e))
            .finally(() => setLoading(false))
        }
      }}
      actions={[
        {
          icon: tableIcons.AddSubstancePolimer,
          tooltip: 'Adicionar Substância ou Polímero',
          onClick: () => handleOpenRmInserter(),
          position: 'toolbar'
        },
        {
          icon: tableIcons.AddSubstancePolimer,
          tooltip: 'Adicionar Substância ou Polímero',
          onClick: () => handleOpenRmInserter(),
          position: 'toolbarOnSelect'
        }
      ]}
    />
  );

  return (
    <React.Fragment>
      <OutRawMaterialSelectorTable/>
      {
        posShipmentInsertRequest &&
          <ShipmentInserterTable
            fullSize={true}
            rawMaterial={RMShipmentInsert}
            postInsertRequest={posShipmentInsertRequest}
            closeParentContainer={handleCloseShipmentInserter}
            shipmentType={
              productsById[productId].papel_reach === UJ ?
                FABRICANTE :
                productsById[productId].papel_reach
            }
          />

      }
    </React.Fragment>
  )
};

const makeMapStateToProps = () => {
  const rawMaterials = filterByFieldSelector('rawMaterials', 'tipo', [SUBSTANCE, POLIMER]);
  const mapStateToProps = (state, props) => ({
    rawMaterials: rawMaterials(state),
    rawMaterialsById: state.rawMaterials.dataById,
    productsById: state.products.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}


const mapDispatchToProps = dispatch => ({
  fetchProduct: id => dispatch(productActions.fetchOne(id)),
  fetchRawMaterial: id => dispatch(rawMaterialsActions.fetchOne(id)),
  updateRawMaterial: (id, data) => dispatch(rawMaterialsActions.updatePromise(id, data)),
  createProductRM: data => dispatch(productRMActions.createPromise(data)),
  removeProductRM: id => dispatch(productRMActions.removePromise(id)),
  removeRawMatSupplier: id => dispatch(actionsRawMatSuppliers.removePromise(id))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
