import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columns = [
  {
    title: 'Codigo MP',
    field: 'cod_mp',
    render: (value, row) => row.mp_formulacao || row.id_mistura_caminho_mp ? value : 'REACÇÃO'
  },
  {
    title: 'Nome Mistura/Substância',
    field: 'mp_nome_mistura',
    render: (value, row) => value === null || value === '' ? row.mp_nome : value
  }

];

export default props => {
  const { rowData, data, title } = props;

  const rows = data.filter(obj => obj.id === rowData.id);

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={rows} title={title}/>
    </div>
  )
};
