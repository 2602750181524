import React from 'react';
import { CAS, DESCRIPTION_RESTRICTIONS, EC, ENTRYNO } from 'utils/echa/constants';

const cellEmptyElements = field => {
  if (field) {
    if (Array.isArray(field) && typeof field[0] === 'string') {
      return (
        <div>
          {
            field.map((value, index) => value === '' ?
              <div key={index}>-</div> :
              <div key={index}>{value}</div>
            )}
        </div>
      )
    } else if (typeof field === 'string' && field === '') {
      return '-'
    }
    return field
  }
  return '-'
};

const cellMultipleElements = field => {
  if (field && field.length > 0) {
    return (
      <div>
        {
          field.map((val, index) =>
            index % 2 === 0 ?
              <div key={index}>{val === '' ? '-' : val}</div> :
              <div key={index} style={{ backgroundColor: 'lightGrey' }}>{val === '' ? '-' : val}</div>
          )
        }
      </div>
    )
  }
  return '-';
}

/**
 * imported lables directly from raw CSV files
 */
// title and field properties are used in material table,
// label and key are used in CSV export
export const importEchaTableColumns = [
  { title: 'Nome', field: 'Name', label: 'Nome', key: 'Name' },
  {
    title: 'EC', field: EC, label: 'EC', key: EC,
    render: rowData => cellEmptyElements(rowData[EC])
  },
  {
    title: 'CAS', field: CAS, label: 'CAS', key: CAS,
    render: rowData => cellEmptyElements(rowData[CAS])
  },
  {
    title: 'SVHC descr.', field: 'DescriptionSvhc', colRename: 'Description', label: 'SVHC descr.', key: 'DescriptionSvhc',
    render: rowData => cellEmptyElements(rowData['DescriptionSvhc'])
  },
  {
    title: 'SVHC inclusão', field: 'Reason for inclusion', label: 'SVHC inclusão', key: 'Reason for inclusion',
    render: rowData => cellEmptyElements(rowData['Reason for inclusion'])
  },
  {
    title: 'SVHC data', field: 'Date of inclusion', label: 'SVHC data', key: 'Date of inclusion',
    render: rowData => cellEmptyElements(rowData['Date of inclusion'])
  },
  {
    title: 'Restrições descr.',
    field: DESCRIPTION_RESTRICTIONS,
    render: rowData => cellMultipleElements(rowData[DESCRIPTION_RESTRICTIONS]),
    label: 'Restrições descr.',
    key: DESCRIPTION_RESTRICTIONS
  },
  {
    title: 'Restrições entrada',
    field: ENTRYNO,
    render: rowData => cellMultipleElements(rowData[ENTRYNO]),
    label: 'Restrições entrada',
    key: ENTRYNO
  },
  {
    title: 'Autorização data', field: 'Sunset Date', label: 'Autorização data', key: 'Latest application date',
    render: rowData => cellEmptyElements(rowData['Sunset Date'])
  },
  {
    title: 'Autorização isenções', field: 'Exempted (categories of) uses', label: 'Autorização isenções', key: 'Exempted (categories of) uses',
    render: rowData => cellEmptyElements(rowData['Exempted (categories of) uses'])
  }
];

/**
 * Echa columns as an internal structure not like importEchaTableColumns
 *  which are still with raw types from files
 */
// title and field properties are used in material table,
// label and key are used in CSV export
export const echaTableColumns = [
  { title: 'Nome', field: 'nome', label: 'Nome', key: 'nome' },
  {
    title: 'CAS', field: 'cas', label: 'CAS', key: 'cas',
    render: rowData => cellEmptyElements(rowData['cas'])
  },
  {
    title: 'EC', field: 'ec', label: 'EC', key: 'ec',
    render: rowData => cellEmptyElements(rowData['ec'])
  },
  {
    title: 'SVHC descr.', field: 'svhc_descricao', label: 'SVHC descrição', key: 'svhc_descricao',
    render: rowData => cellEmptyElements(rowData['svhc_descricao'])
  },
  {
    title: 'SVHC inclusão', field: 'svhc_razao_inclusao', label: 'SVHC inclusão', key: 'svhc_razao_inclusao',
    render: rowData => cellEmptyElements(rowData['svhc_razao_inclusao'])
  },
  {
    title: 'SVHC data', field: 'svhc_data_inclusao', label: 'SVHC data inclusão', key: 'svhc_data_inclusao',
    render: rowData => cellEmptyElements(rowData['svhc_data_inclusao'])
  },
  {
    title: 'Restrições descr.', field: 'restricoes_descricao', label: 'Restrições descrição', key: 'restricoes_descricao',
    render: rowData => cellMultipleElements(rowData['restricoes_descricao'])
  },
  {
    title: 'Restrições entrada', field: 'restricoes_entrada', label: 'Restrições entrada', key: 'restricoes_entrada',
    render: rowData => cellMultipleElements(rowData['restricoes_entrada'])
  },
  {
    title: 'Autorização data', field: 'autorizacao_data_limite', label: 'Autorização data limite', key: 'autorizacao_data_limite',
    render: rowData => cellEmptyElements(rowData['autorizacao_data_limite'])
  },
  { title: 'Autorização isenções', field: 'autorizacao_isencoes', label: 'Autorização isenções', key: 'autorizacao_isencoes',
    render: rowData => cellEmptyElements(rowData['autorizacao_isencoes'])
  }
];

export const commonCols = [
  { title: 'Nome', field: 'Name', init: 'string'},
  { title: 'EC', field: EC, init: 'array'},
  { title: 'CAS', field: CAS, init: 'array'}
];

export const svhcCols = [
  { title: 'SVHC descr.', field: 'DescriptionSvhc', colRename: 'Description', init: 'string'},
  { title: 'SVHC inclusão', field: 'Reason for inclusion', init: 'string'},
  { title: 'SVHC data', field: 'Date of inclusion', init: 'string'}
];

export const restricoesCols = [
  { title: 'Restrições descr.', field: DESCRIPTION_RESTRICTIONS, colRename: 'Description', init: 'array'},
  { title: 'Restrições entrada', field: ENTRYNO, init: 'array'}
];

export const autorizacaoCols = [
  { title: 'Autorização data', field: 'Sunset Date', init: 'string'},
  { title: 'Autorização isenções', field: 'Exempted (categories of) uses', init: 'string'}
];

export const echaColumns = [...commonCols, ...svhcCols, ...restricoesCols, ...autorizacaoCols];

const echaToDbFieldTranslation = {
  nome: 'Name',
  ec: EC,
  cas: CAS,
  svhc_descricao: 'DescriptionSvhc',
  svhc_razao_inclusao: 'Reason for inclusion',
  svhc_data_inclusao: 'Date of inclusion',
  restricoes_descricao: DESCRIPTION_RESTRICTIONS,
  restricoes_entrada: ENTRYNO,
  autorizacao_data_limite: 'Sunset Date',
  autorizacao_isencoes: 'Exempted (categories of) uses'
};

/**
 * Does the field translation of fields to be imported and stringifies array fields
 * @param echa
 */
export const echaTransformForImport = echa => {
  const echaTransformed = {};
  Object.keys(echaToDbFieldTranslation).forEach(field => {
    if (echa[echaToDbFieldTranslation[field]]) {
      Array.isArray(echa[echaToDbFieldTranslation[field]]) ?
        echaTransformed[field] = JSON.stringify(echa[echaToDbFieldTranslation[field]]) :
        echaTransformed[field] = echa[echaToDbFieldTranslation[field]]
    } else {
      echaTransformed[field] = '';
    }
  });
  return echaTransformed;
}

export default {
  importEchaTableColumns,
  echaTableColumns,
  autorizacaoCols,
  echaColumns,
  commonCols,
  restricoesCols,
  svhcCols,
  echaTransformForImport
}
