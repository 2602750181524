import axios from 'axios/index';

export const apiRequest = (
  url = undefined,
  method = undefined,
  data = undefined,
  headers = {}
) => {

  const requestOptions = {
    method: method ? method : 'get',
    url: process.env.API_URL + (url ? url : '/'),
    headers: {
      'Content-Type': 'application/json',
      //'X-Requested-With': 'XMLHttpRequest',
      'Accept': '*/*',
      ...headers
    }
    //withCredentials: true
  };
  if (data) {
    requestOptions['data'] = data;
  }
  return axios(requestOptions);
};

export const apiRequestDirectURL = url => {
  const requestOptions = {
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      //'X-Requested-With': 'XMLHttpRequest',
      'Accept': '*/*'
    }
    //withCredentials: true
  };
  return axios(requestOptions);
};

