import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SpinButton from 'components/Buttons/SpinButton';
import PropTypes from 'prop-types';
import React from 'react';

const Component = props => {
  const { bodyHeight, children } = props;

  return (
    <Grid container style={{ height: bodyHeight }} justify="center" alignItems="center">
      <Grid item xs={5} style={{ height: '50%' }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {children}
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <div style={{ margin: 15 }}>
            <SpinButton {...props}/>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Component;

Component.propTypes = {
  bodyHeight: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired
};
