import {
  ConsumptionIcon,
  EchaListIcon,
  FinalProductIcon,
  FlowsIcon,
  ImportIcon,
  MixtureIcon,
  RawMaterialIcon,
  ScenariosIcon,
  ShipmentIcon,
  StepsIcon,
  SubstanceIcon,
  SupplierIcon
} from 'utils/icons/materialTableIcons';
import React from 'react';

const inventorySidebar = {
  name: 'INVENTÁRIO',
  onOpen: true,
  subsections: [
    { text: 'Matérias Primas', icon: RawMaterialIcon, url: '/raw-materials' },
    { text: 'Substâncias/Polímeros', icon: SubstanceIcon, url: '/substance-polimers' },
    { text: 'Misturas', icon: MixtureIcon, url: '/mixtures' },
    { text: 'Fornecedores', icon: SupplierIcon, url: '/suppliers' },
    { text: 'Fornecimentos', icon: ShipmentIcon, url: '/raw-materials-suppliers' },
    { text: 'Consumos', icon: ConsumptionIcon, url: '/raw-material-consumptions' }
  ]
};

const processSidebar = {
  name: 'PRODUÇÃO',
  onOpen: true,
  subsections: [
    { text: 'Fluxogramas', icon: FlowsIcon, url: '/flow-steps' },
    { text: 'Etapas-MP', icon: StepsIcon, url: '/flow-steps-rm' },
    { text: 'Cenários', icon: ScenariosIcon, url: '/flow-scenarios' }
  ]
};

const productSidebar = {
  name: 'PRODUTO',
  onOpen: true,
  subsections: [
    { text: 'Produtos', icon: FinalProductIcon, url: '/products' },
    { text: 'Fluxogramas', icon: FlowsIcon, url: '/product-flows' },
    { text: 'Composição', icon: MixtureIcon, url: '/product-rms' },
    { text: 'Cenários', icon: ScenariosIcon, url: '/product-scenarios' }
  ]
};

const scenarioSidebar = {
  name: 'CE DE MP',
  onOpen: true,
  subsections: [
    { text: 'Na Produção', icon: FlowsIcon, url: '/exposure-internal' },
    { text: 'No Produto', icon: FinalProductIcon, url: '/exposure-external' }
  ]
};

const evaluationSidebar = {
  name: 'AVALIAÇÃO',
  onOpen: true,
  subsections: [
    //{ text: 'Produção', icon: FlowsIcon, url: '/evaluation-product' },
    { text: 'Produto', icon: FinalProductIcon, url: '/evaluation-product' }
  ]
};

const consultationSidebar = {
  name: 'CONSULTA',
  subsections: [
    { text: 'Situação ECHA', icon: EchaListIcon, url: '/echa-status' }
  ]
};

const importSidebar = {
  name: 'IMPORTAÇÕES',
  subsections: [
    { text: 'Importar Dados', icon: ImportIcon, url: '/import-data' }
  ]
};


export default user => {
  if (user.groups && user.groups.length > 0) {
    const access = user.groups[0].name;
    switch (access) {
      case 'normal':
        return [
          inventorySidebar,
          processSidebar,
          productSidebar,
          scenarioSidebar,
          evaluationSidebar,
          consultationSidebar,
          importSidebar
        ];
      case 'restricted':
        return [
          inventorySidebar,
          consultationSidebar,
          importSidebar
        ];
    }
  } else {
    return [
      inventorySidebar,
      consultationSidebar,
      importSidebar
    ];
  }
}
