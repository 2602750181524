import React from 'react';
import theme from 'source/theme';


export default () =>
    <div style={{ marginLeft: 100, width: 1000 }}>
      <p>
        A aplicação ReachOK está dividida em vários módulos: Inventário, Produção, Produto e Cenários. Abaixo pode efectuar download dos manuais que necessite para esclarecer as suas dúvidas acerca da
        aplicação.
      </p>
      <ul>
        <li>
          <a
            href="https://drive.google.com/file/d/1BsqMlpysTC4IHaaP5kLoWWHbBwianPNF/view?usp=sharing"
            target="_blank"
            style={{ color: theme.palette.primary.main }}
          >
            Manual do módulo Inventário
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/171jb30a6YF6WvK-vSbAMdGZW5tbqHbuR/view?usp=sharing"
            target="_blank"
            style={{ color: theme.palette.primary.main }}
          >
            Manual do módulo Produção
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/1NxRXWiySPZm4PQX0i5laHcqwUR5KT50w/view?usp=sharing"
            target="_blank"
            style={{ color: theme.palette.primary.main }}
          >
            Manual do módulo Produto
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/16MEFoBqzqBbTIqLxonJnrQLc87I7kviL/view?usp=sharing"
            target="_blank"
            style={{ color: theme.palette.primary.main }}
          >
            Manual do módulo Cenários
          </a>
        </li>
      </ul>
    </div>
