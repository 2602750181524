import BaseTable from 'components/Tables/BaseTable';
import { actions as mixtureActions } from 'ducks/mixtures'
import { actions as rawMaterialActions } from 'ducks/rawMaterials';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { filterByFieldSelector } from 'selectors/generic';
import { POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/substance';

const Component = props => {
  let { mixtureId, rawMaterialsById, substances } = props;
  const { create, fetchRawMaterial, handleCloseDrawer, handleOpenRmInserter, remove, update } = props;
  const tableRef = React.useRef(null);

  const prevInterSubstanceIds = rawMaterialsById[mixtureId].inter_substancias.map(o => o.id_substancia);
  const prevInterSubstancePercents = rawMaterialsById[mixtureId].inter_substancias.map(o => o.percentagem);

  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedIds(prevInterSubstanceIds);
  }, [ rawMaterialsById ]);

  // TODO Adding Checked info to table, there should be a better way
  substances = substances.map(subst => {
    if (selectedIds.includes(subst.id)) {
      const index = selectedIds.indexOf(subst.id);
      return {
        ...subst,
        tableData: { checked: true },
        percentagem: prevInterSubstancePercents[index]
      }
    } else if (subst.tableData && subst.tableData.checked) {
      return {
        ...subst,
        tableData: { checked: false },
        percentagem: undefined
      }
    } else {
      return subst;
    }
  });

  const orderedSubstances = sortBy(substances, [o => !selectedIds.includes(o.id)]);


  return (
    <React.Fragment>
      <BaseTable
        ref={tableRef}
        titleBack={true}
        titleAction={handleCloseDrawer}
        title={rawMaterialsById[mixtureId].mp_nome}
        columns={columns}
        data={orderedSubstances}
        onRowClick={() => null}
        options={{
          selection: true,
          showTextRowsSelected: false,
          showSelectAllCheckbox: false
        }}
        isLoading={loading}
        onSelectionChange={(rows, difRow) => {
          setLoading(true);
          if (rows.length > selectedIds.length) {
            create({
              id_mistura: mixtureId,
              id_substancia: difRow.id,
              percentagem: 0
            })
              .then(() => fetchRawMaterial(mixtureId))
              .then(() => fetchRawMaterial(difRow.id))
              .then(() => setLoading(false))
              .catch(e => {
                setLoading(false)
                alert(e)
              })
          } else {
            remove(rawMaterialsById[mixtureId].inter_substancias.find(subst => subst.id_substancia === difRow.id).id)
              .then(() => fetchRawMaterial(mixtureId))
              .then(() => fetchRawMaterial(difRow.id))
              .then(() => setLoading(false))
              .catch(e => {
                setLoading(false)
                alert(e)
              })
          }
        }}
        editable={{
          //isEditable: rowData => selectedIds.includes(rowData.id),
          onRowUpdate: (newData, oldData) => {
            setLoading(true);
            if (oldData.tableData.checked) {
              return update(rawMaterialsById[mixtureId].inter_substancias.find(subst => subst.id_substancia === newData.id).id,
                {
                  id_mistura: mixtureId,
                  id_substancia: newData.id,
                  percentagem: newData.percentagem ? newData.percentagem/100 : 0
                }
              )
                .then(() => fetchRawMaterial(mixtureId))
                .then(() => fetchRawMaterial(newData.id))
                .then(() => setLoading(false))
                .catch(e => {
                  setLoading(false)
                  alert(e)
                })
            } else {
              return create({
                id_mistura: mixtureId,
                id_substancia: newData.id,
                percentagem: newData.percentagem ? newData.percentagem : 0
              })
                .then(() => fetchRawMaterial(mixtureId))
                .then(() => fetchRawMaterial(newData.id))
                .then(() => setLoading(false))
                .catch(e => {
                  setLoading(false)
                  alert(e)
                })
            }
          }
        }}
        actions={[
          {
            icon: tableIcons.AddSubstancePolimer,
            tooltip: 'Adicionar Substância ou Polímero',
            onClick: () => handleOpenRmInserter(),
            position: 'toolbar'
          },
          {
            icon: tableIcons.AddSubstancePolimer,
            tooltip: 'Adicionar Substância ou Polímero',
            onClick: () => handleOpenRmInserter(),
            position: 'toolbarOnSelect'
          }
        ]}
      />
    </React.Fragment>
  )
};

const makeMapStateToProps = () => {
  const substancesPolimers = filterByFieldSelector('rawMaterials', 'tipo', [SUBSTANCE, POLIMER]);
  const mapStateToProps = (state, props) => ({
    substances: substancesPolimers(state),
    rawMaterialsById: state.rawMaterials.dataById
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  fetchRawMaterial: id => dispatch(rawMaterialActions.fetchOne(id)),
  create: data => dispatch(mixtureActions.createPromise(data)),
  remove: id => dispatch(mixtureActions.removePromise(id)),
  update: (id, data) => dispatch(mixtureActions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
