import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const FormDialog = ({ handleClose, handleSubmit, name, open }) => {

  const [newPassword, setNewPassword] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');

  const handleChangeNewPassword = e => {
    setNewPassword(e.target.value);
  }

  const handleChangeOldPassword = e => {
    setOldPassword(e.target.value);
  }

  const submitPassword = () => {
    handleSubmit(oldPassword, newPassword);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Nova Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor insira a nova password para o utilizador {name}.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="old_password"
            label="Password antiga"
            type="old_pass"
            fullWidth
            value={oldPassword}
            onChange={handleChangeOldPassword}
          />
          <TextField
            margin="dense"
            id="new_password"
            label="Password nova"
            type="new_pass"
            fullWidth
            value={newPassword}
            onChange={handleChangeNewPassword}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={submitPassword} color="primary">
            Submeter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;
