import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/rawMaterials'
import React from 'react';
import { connect } from 'react-redux';
import { filterByFunctionSelector } from 'selectors/generic';
import { POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/substancePolimer'
import substancesPolimersValidator from 'utils/tables/validators/substancesPolimers';

const Component = props => {
  const { rawMaterials, user } = props;
  const { alertError, create, remove, update } = props;

  return (
    <BaseTable
      title="Criação de substâncias ou polímeros para misturas"
      icons={tableIcons}
      columns={columns(user)}
      data={rawMaterials}
      options={{
        maxBodyHeight: '50vh',
        minBodyHeight: '50vh'
      }}
      editable={{
        onRowAdd: newData => {
          const validation = substancesPolimersValidator(newData, rawMaterials)
          validation.cas = JSON.stringify(validation.cas);
          validation.ec = JSON.stringify(validation.ec);
          if (validation.error) {
            alertError(validation.error)
            return Promise.reject()
          } else {
            return create(validation)
          }
        },
        onRowUpdate: newData => {
          const validation = substancesPolimersValidator(newData, rawMaterials)
          validation.cas = JSON.stringify(validation.cas);
          validation.ec = JSON.stringify(validation.ec);
          validation.perigosa = JSON.stringify(validation.perigosa);
          if (validation.error) {
            alertError(validation.error)
            return Promise.resolve()
            // TODO Instead of rejecting we are resolving, should be rejecting. Have to update library later github issue #2086
          } else {
            return update(newData.id, validation)
          }
        },
        onRowDelete: oldData => remove(oldData.id)
      }}
    />
  )
};

const makeMapStateToProps = () => {
  const filteredRawMaterials = filterByFunctionSelector(
    'rawMaterials',
    rm => rm.tipo === SUBSTANCE || rm.tipo === POLIMER
  );
  const mapStateToProps = (state, props) => ({
    rawMaterials: filteredRawMaterials(state),
    user: state.profile.entity
  })
  return mapStateToProps;
}


const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  create: data => dispatch(actions.createPromise(data)),
  remove: id => dispatch(actions.removePromise(id)),
  update: (id, data) => dispatch(actions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
