import Done from '@material-ui/icons/Done';
import Remove from '@material-ui/icons/Remove';
import React from 'react';
import { POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';

export default user => {
  const columns = [
    {
      title: 'Nome',
      field: 'mp_nome',
      minWidth: 600,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Tipo',
      field: 'tipo',
      minWidth: 100,
      initialEditValue: SUBSTANCE,
      lookup: {
        [SUBSTANCE]: 'Substância',
        [POLIMER]: 'Polímero',
      },
    },
    {
      title: 'CAS',
      field: 'cas',
      minWidth: 100,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => rowData.cas && rowData.cas.length !== 0 ?
        rowData.cas.join(', ') :
        null,
    },
    {
      title: 'EC',
      field: 'ec',
      minWidth: 100,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => rowData.ec && rowData.ec.length !== 0 ?
        rowData.ec.join(', ') :
        null,
    },
    {
      title: 'Perigosa',
      field: 'perigosa',
      type: 'boolean',
      minWidth: 90,
      initialEditValue: 'true',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      },
      lookup: {
        true: 'Sim',
        false: 'Não'
      },
      render: rowData =>
        <span style={{fontWeight: rowData.perigosa ? 'bold' : null}}>
        {rowData.perigosa ? 'Sim' : 'Não' }
      </span>
    }
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ];

  return user.is_staff ? columnsAdmin : columns;
}
