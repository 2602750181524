import {
  ALERT_ERROR,
  BOOT_ADMIN,
  BOOT_MANAGER,
  BOOT_STAFF,
  FETCH_PROFILE,
  FETCH_USER_ACCOUNT,
  FULFILLED,
  LOGIN,
  LOGOUT,
  PASSWORD_FORGOT,
  PASSWORD_RECOVERY,
  PASSWORD_UPDATE,
  REGISTER,
  REJECTED,
  RELOAD_ADMIN,
  UPDATE_ADMIN_ACCOUNT
} from 'actionTypes';
import history from 'appHistory';
import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';

// actions
export const login = (username, password) => ({
  type: LOGIN,
  payload: { username, password }
});

export const logout = () => ({
  type: LOGOUT
});

export const passwordUpdate = (oldPassword, newPassword) => ({
  type: PASSWORD_UPDATE,
  payload: { oldPassword, newPassword }
});

export const passwordRecovery = password => ({
  type: PASSWORD_RECOVERY,
  payload: password
});

export const passwordForgot = email => ({
  type: PASSWORD_FORGOT,
  payload: email
});

export const register = data => ({
  type: REGISTER,
  payload: data
});

export const updateAdminAccount = id => ({
  type: UPDATE_ADMIN_ACCOUNT,
  payload: id
});


// reducer
const INITIAL_STATE = {
  token: undefined,
  isAuthenticated: false,
  passwordRecoverSubmitted: false,
  passwordForgotSubmitted: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LOGIN + FULFILLED:
      return {
        ...state,
        token: payload,
        isAuthenticated: true
      };
    case PASSWORD_UPDATE + FULFILLED:
      return state
    case PASSWORD_RECOVERY + FULFILLED:
      return {
        ...state,
        passwordRecoverSubmitted: true
      };
    case PASSWORD_FORGOT + FULFILLED:
      return {
        ...state,
        passwordForgotSubmitted: true
      };
    default:
      return state;
  }
};

// sagas
function* registerSaga({ payload }) {
  try {
    //const response = yield apiRequest('/register/', 'POST', { ... payload });
    yield put({ type: REGISTER + FULFILLED });
    history.push('/register_success');
  } catch (error) {
    console.log('error: ', error);
    yield put({ type: REGISTER + REJECTED, payload: error });
    yield put({ type: ALERT_ERROR, payload: 'Something incorrect on form data! Please, Contact RachOK support!' })
  }
}

function* loginSaga({ payload }) {
  try {
    let response = yield apiRequest('/token/', 'POST', { username: payload.username, password: payload.password });
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
    localStorage.setItem('token_access', response.data.access)
    localStorage.setItem('token_refresh', response.data.refresh)
    yield put({ type: LOGIN + FULFILLED, payload: response.data.access });

    response = yield apiRequest('/user_account/');
    yield put({ type: FETCH_USER_ACCOUNT + FULFILLED, payload: response.data });

    response = yield apiRequest('/profile/');
    const profile = response.data;
    yield put({ type: FETCH_PROFILE + FULFILLED, payload: profile })

    if (profile.is_staff) {
      yield put({ type: BOOT_ADMIN });
    } else if (profile.manager) {
      yield put({ type: BOOT_MANAGER });
    } else {
      yield put({ type: BOOT_STAFF });
    }

    history.push('/');

  } catch (error) {
    console.log('error: ', error.response);
    yield put({ type: LOGIN + REJECTED, payload: error });
    yield put({ type: ALERT_ERROR, payload: error.response.data.detail ? error.response.data.detail : 'Unrecognized Login Error, contact ReachOK Admin' });
  }
}

function* logoutSaga() {
  try {
    localStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
    console.log('LOGGING OUT');
    history.push('/login');
  } catch (error) {
    console.error(error);
    history.push('/login');
  }
}

function* passwordUpdateSaga({ payload: {oldPassword: old_password, newPassword: new_password }}) {
  try {
    const response = yield apiRequest('/update_password/', 'PUT', { old_password, new_password });
    yield put({ type: PASSWORD_UPDATE + FULFILLED });
    yield put({ type: LOGOUT })
  } catch (error) {
    console.log('error: ', error);
    yield put({ type: PASSWORD_UPDATE + REJECTED, payload: error });
  }
}

// TODO test recovery flow and view passwordRecover Submited and passwordForgotSubmitted

function* passwordRecoverySaga({ payload }) {
  try {
    const response = yield apiRequest('/PASSWORD RECOVERY URL/', 'POST', { password: payload });
    yield put({ type: PASSWORD_RECOVERY + FULFILLED });
    history.push('/login');
  } catch (error) {
    console.log('error: ', error);
    yield put({ type: PASSWORD_RECOVERY + REJECTED, payload: error });
  }
}

function* passwordForgotSaga({ payload }) {
  try {
    const response = yield apiRequest('/PASSWORD FORGOT URL/', 'POST', { email: payload });
    yield put({ type: PASSWORD_FORGOT + FULFILLED });
    history.push('/login');
  } catch (error) {
    console.log('error: ', error);
    yield put({ type: PASSWORD_FORGOT + REJECTED, payload: error });
  }
}

function* updateAdminAccountSaga({ payload }) {
  try {
    const response = yield apiRequest('/update_admin_account/', 'PUT', { id_account: payload });
    yield put({ type: UPDATE_ADMIN_ACCOUNT + FULFILLED, payload: response.data });
    yield put({ type: RELOAD_ADMIN });
    history.push('/');
  } catch (error) {
    console.log('error: ', error);
    yield put({ type: UPDATE_ADMIN_ACCOUNT + REJECTED, payload: error });
  }
}

function* watchRegister() {
  yield takeLatest(REGISTER, registerSaga);
}

function* watchLogin() {
  yield takeLatest(LOGIN, loginSaga);
}

function* watchLogout() {
  yield takeLatest(LOGOUT, logoutSaga);
}

function* watchPasswordUpdate() {
  yield takeLatest(PASSWORD_UPDATE, passwordUpdateSaga);
}

function* watchPasswordRecovery() {
  yield takeLatest(PASSWORD_RECOVERY, passwordRecoverySaga);
}

function* watchPasswordForgot() {
  yield takeLatest(PASSWORD_FORGOT, passwordForgotSaga);
}

function* watchUpdateAdminAccountSaga() {
  yield takeLatest(UPDATE_ADMIN_ACCOUNT, updateAdminAccountSaga);
}

const sagas = [
  fork(watchRegister),
  fork(watchLogin),
  fork(watchLogout),
  fork(watchPasswordUpdate),
  fork(watchPasswordRecovery),
  fork(watchPasswordForgot),
  fork(watchUpdateAdminAccountSaga)
];

export { sagas };
