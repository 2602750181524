import Done from '@material-ui/icons/Done';
import Remove from '@material-ui/icons/Remove';
import React from 'react';
import theme from 'source/theme';

export default user => {
  const columns = [
    {
    title: 'Etapa',
    field: 'nome_master',
    editable: 'never',
    width: 200,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Descrição',
    field: 'descricao_master',
    editable: 'never',
    width: 400,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Sub-etapa',
    field: 'nome_substep',
    editable: 'never',
    width: 200,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Descrição Sub-etapa',
    field: 'descricao_substep',
    editable: 'never',
    width: 400,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Fabrico',
    field: 'M',
    lookup: {
      true: <Done style={{height: 16, width: 16}}/>,
      false: <Remove style={{height: 16, width: 16}}/>
    },
    render: rowData => rowData.M ?
      <Done style={{
        height: 16,
        width: 16,
        backgroundColor: rowData.M_error ? theme.palette.rowErrorBackground.main : null,
        borderRadius: 5
      }}/> :
      <Remove style={{ height: 16, width: 16 }}/>
  },
  {
    title: 'Formulação',
    field: 'F',
    lookup: {
      true: <Done style={{height: 16, width: 16}}/>,
      false: <Remove style={{height: 16, width: 16}}/>
    },
    render: rowData => rowData.F ?
      <Done style={{
        height: 16,
        width: 16,
        backgroundColor: rowData.F_error ? theme.palette.rowErrorBackground.main : null,
        borderRadius: 5
      }}/> :
      <Remove style={{ height: 16, width: 16 }}/>
  },
  {
    title: 'Industrial',
    field: 'IS',
    lookup: {
      true: <Done style={{height: 16, width: 16}}/>,
      false: <Remove style={{height: 16, width: 16}}/>
    },
    render: rowData => rowData.IS ?
      <Done style={{
        height: 16,
        width: 16,
        backgroundColor: rowData.IS_error ? theme.palette.rowErrorBackground.main : null,
        borderRadius: 5
      }}/> :
      <Remove style={{ height: 16, width: 16 }}/>
  },
];

const columnsAdmin = [
  {
    title: 'ID',
    field: 'id_srm',
    editable: 'never',
    width: 50
  },
  ...columns
];

return user.is_staff ? columnsAdmin : columns;
}
