import { newLogoReachOk64 } from 'reports/base64Images';

import theme from 'source/theme';
import { sortBy } from 'lodash';

const dataMapping = [
  { id: 'nome', name: 'Código Etapa' },
  { id: 'descricao', name: 'Descrição' }
];

const rowGenerator = (dataMapping, flows) => {
  const rows = [];
  flows.forEach(flow => {
    rows.push([{
        text: `${flow.codigo_fluxo} - ${flow.nome}`,
        colSpan: 2,
        fillColor: theme.palette.primary.light
      }]
    );
    const steps = sortBy(flow.etapas, n => n.nome);
    steps.forEach(step => {
      if (step.id_master_etapa === null) {
        rows.push(dataMapping.map(field => step[field.id]));
        step.subetapas.forEach(substep =>
          rows.push(dataMapping.map(field => `.  ${substep[field.id]}`))
        )
      }
    })
  })

  console.log('rows', rows);

  return rows;
};

const dd = (data) => ({
  pageSize: 'A4',
  pageMargins: [ 40, 60, 40, 40 ],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: 'R - Fluxogramas e Etapas',
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        body: [
          dataMapping.map(i => i.name),
          ...rowGenerator(dataMapping, data)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center',
      marginTop: 15
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 10
    }
  }

});

export default dd;
