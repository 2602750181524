// ECHA TYPES
export const SVHC = 'SVHC';
export const RESTRICOES = 'RESTRICOES';
export const AUTORIZACAO = 'AUTORIZACAO';

// ECHA COLUMNS
// title and field properties are used in material table,
export const CAS = 'CAS no.';
export const EC = 'EC no.';
export const ENTRYNO = 'Entry no.';
export const DESCRIPTION_RESTRICTIONS = 'DescriptionRestricoes';

