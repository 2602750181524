import TableEmpyArea from 'pages/import/TableEmptyArea';
import React from 'react';
import theme from 'source/theme';
import { TABLE_HEIGHT } from 'utils/ui/constants';

const TABLE_HEIGHT_CUSTOM = TABLE_HEIGHT(true, 71);

export default () =>
  <TableEmpyArea bodyHeight={TABLE_HEIGHT_CUSTOM}>
    <div>
      <p>
        Nesta área pode importar ficheiros XLSX de dados relacionados com
        as ECHAs: restrições, svhc e autorizações.
      </p>
      <p>
        Abaixo tem exemplos de ficheiros de importação:
      </p>
      <ul>
        <li>
          <a
            href="https://drive.google.com/file/d/1P4mqE6KCs3rT5PM8Loybcxp2TJgH2mUG/view?usp=sharing"
            target="_blank"
            style={{ color: theme.palette.primary.main }}
          >
            Template XLSX Restrições
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/1ellzPHa1TvsQV91WSOzT-ZZNyY_v70m6/view?usp=sharing"
            target="_blank"
            style={{ color: theme.palette.primary.main }}
          >
            Template XLSX SVHC
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/1aO0bCsqTva1lKXC724o1O2kgpmp5DtcG/view?usp=sharing"
            target="_blank"
            style={{ color: theme.palette.primary.main }}
          >
            Template XLSX Autorizações
          </a>
        </li>
      </ul>
    </div>
  </TableEmpyArea>
