import BaseTable from 'components/Tables/BaseTable';
import { sortBy } from 'lodash';
import FlowListDetailPanel from 'pages/ScenarioExposure/Flow/ScenariosManagement/FlowListDetailPanel';
import React from 'react';
import { connect } from 'react-redux';
import { lookUpIdValueSelector } from 'selectors/generic';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/scenarioConditions';
import { TABLE_HEIGHT_SPLIT_4VIEW } from 'utils/ui/constants';

const TABLE_HEIGHT = TABLE_HEIGHT_SPLIT_4VIEW(64);

const Component = props => {
  const {
    conditionsById, descriptorId, flowsById, scenariosById, scenarioDescriptors,
    scenarioDescriptorConditionsData, units, user
  } = props;

  const selectedSD = scenarioDescriptors.filter(sd =>
    (descriptorId && sd.id_descritor === descriptorId)
  );

  let filteredSDC = {};
  const sdcConditionRepetitions = [];

  selectedSD.forEach(sSD => {
    const flowId = scenariosById[sSD.id_cenario].id_fluxograma;
    const flowInfo = {
      flow_id: flowId,
      code: flowsById[flowId].codigo_fluxo,
      name: flowsById[flowId].nome
    };
    const sdcs = scenarioDescriptorConditionsData.filter(sdc => sdc.id_cenariodescritor === sSD.id)
    sdcs.forEach(sdc => {
      if (sdcConditionRepetitions.includes(sdc.id_condicao)) {
        filteredSDC[sdc.id_condicao].flowsInfo.push(flowInfo);
      } else {
        filteredSDC[sdc.id_condicao] = { ...sdc, flowsInfo: [flowInfo] };
        sdcConditionRepetitions.push(sdc.id_condicao);
      }
    })
  });

  const rows = Object.values(filteredSDC).map(element => {
    const subtipo = conditionsById[element.id_condicao].subtipo;
    const nome = conditionsById[element.id_condicao].nome;
    return { ...element, subtipo, nome };
  });

  const rowsSorted = sortBy(rows, ['subtipo']);

  return (
    <React.Fragment>
      <BaseTable
        title={'CONDIÇÕES DO FLUXO'}
        columns={columns(user, units)}
        data={descriptorId ? rowsSorted : []}
        paging={false}
        options={{
          // toolbar: false,
          // search: false,
          maxBodyHeight: TABLE_HEIGHT,
          minBodyHeight: TABLE_HEIGHT,
          rowStyle: () => ({ backgroundColor: theme.palette.background.veryLight })
        }}
        detailPanel={[
          () => ({
            icon: tableIcons.Flow,
            tooltip: 'Fluxogramas',
            render: rowData => <FlowListDetailPanel row={rowData} />
          })
        ]}
      />
    </React.Fragment>
  )
};

const makemapStateToProps = () => {
  const units = lookUpIdValueSelector('units', ['nome']);
  const mapStateToProps = (state, props) => ({
    conditionsById: state.conditions.dataById,
    flowsById: state.flows.dataById,
    scenariosById: state.scenarios.dataById,
    scenarioDescriptorConditionsData: state.scenarioDescriptorConditions.data,
    units: units(state),
    user: state.profile.entity
  })
  return mapStateToProps
};

export default connect(makemapStateToProps)(Component);
