import React from 'react';
import Done from '@material-ui/icons/Done';
import Remove from '@material-ui/icons/Remove';

const emptyCellFiller = field => field ? field : '-';

export default user => {
  const columns = [
    {
      title: 'Produto Final',
      field: 'mp_excluido_final',
      sorting: false,
      minWidth: 50,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      type: 'boolean',
      initialEditValue: 'false',
      lookup: {
        true: <Remove style={{height: 16, width: 16}}/>,
        false: <Done style={{height: 16, width: 16}}/>
      },
      render: rowData => {
        if (rowData.mp_excluido_final === 'true' || rowData.mp_excluido_final === true) {
          return <Remove style={{height: 16, width: 16}}/>;
        } else if (rowData.mp_excluido_final === 'false' || rowData.mp_excluido_final === false) {
          return <Done style={{height: 16, width: 16}}/>;
        }
      },
    },
    {
      title: 'Campo 3 FDS',
      field: 'contribui_perigo',
      sorting: false,
      minWidth: 50,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      type: 'boolean',
      initialEditValue: 'false',
      lookup: {
        true: <Done style={{height: 16, width: 16}}/>,
        false: <Remove style={{height: 16, width: 16}}/>
      },
      render: rowData => {
        if (rowData.contribui_perigo === 'true' || rowData.contribui_perigo === true) {
          return <Done style={{height: 16, width: 16}}/>;
        } else if (rowData.contribui_perigo === 'false' || rowData.contribui_perigo === false) {
          return <Remove style={{height: 16, width: 16}}/>;
        }
      },
    },
    {
      title: 'Nome Substância',
      field: 'mp_nome',
      editable: 'never',
      minWidth: 200,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
      render: ({ mp_nome }) => emptyCellFiller(mp_nome),
    },
    {
      title: 'CAS',
      field: 'cas',
      editable: 'never',
      minWidth: 100,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => rowData.cas && rowData.cas.length !== 0 ? rowData.cas.join(', ') : '-',
    },
    {
      title: 'EC',
      field: 'ec',
      editable: 'never',
      minWidth: 100,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      render: rowData => rowData.ec && rowData.ec.length !== 0 ? rowData.ec.join(', ') : '-',
    },
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ];

  return user.is_staff ? columnsAdmin : columns;
}
