import Grid from '@material-ui/core/Grid';
import WarningMessageDetailPanel from 'components/DetailPanels/WarningMessageDetailPanel';
import BaseTable from 'components/Tables/BaseTable'
import { alertError, persistentAlert } from 'ducks/alerts';
import { actions } from 'ducks/scenarios';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { filterByFunctionSelector, getImmutableStoreById, lookUpIdValueSelector } from 'selectors/generic';
import theme from 'source/theme';
import { SCENARIO_F } from 'utils/constants/coreConstants';
import { lcsAliasTranslator, lcsNames, lcsTranslator } from 'utils/constants/lcsTypes';
import wMsg from 'utils/Error/warningMessages';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/flow/flowScenarios';

const Component = props => {
  const {
    flows, flowsLookup, flowsById, scenarios, stepRawMaterials, stepsById, user
  } = props;
  const {
    alertError, createScenario, fetchScenario, persistentAlert, removeScenario,
    setScenarioId, updateScenario
  } = props;
  const tableRef = React.useRef(null);

  const [loading, setLoading] = useState(false);

  /**
   * Computation of Error messages
   */

  /**
   * Calculation for the overall setOfFlowLcs stepRawMaterials
   */
  const setOfFlowLcsCombinations = flows.length * 3; // 3 => [M F IS]
  const setOfFlowLcs = new Set([]);
  for (let srm of stepRawMaterials) {
    if (setOfFlowLcs.size === setOfFlowLcsCombinations) {
      break;
    }
    if (srm.descriptors) {
      srm.descriptors.forEach(lcsAlias => {
        setOfFlowLcs.add(`${stepsById[srm.id_etapa].id_fluxograma.id} ${lcsAliasTranslator[lcsAlias]}`)
      })
    }
  }

  /**
   * Calculation for the overall scenarios
   */
  const flowIds = flows.data.map(flow => flow.id);
  const setOfFlowLcsScenarios = new Set([]);
  scenarios
    .filter(scenario =>
      scenario.id_fluxograma && !scenario.id_produto && !scenario.id_substancia
      && !scenario.id_substancia_fornecedor && flowIds.includes(scenario.id_fluxograma)
    )
    .forEach(scenario =>
      setOfFlowLcsScenarios.add(`${scenario.id_fluxograma} ${scenario.id_lcs}`)
    )

  const diffSetOfFlowLcs = [...setOfFlowLcs].filter( element => !setOfFlowLcsScenarios.has(element))

  if (diffSetOfFlowLcs.length > 0) {
    const alert = diffSetOfFlowLcs.map( couple => {
      const flowId = couple.split(' ')[0];
      const lcsId = couple.split(' ')[1];
      return <span>[ {flows.dataById[flowId].nome} --  {lcsNames[lcsId]} ]<br/></span>
    })

    persistentAlert(<div> ETAPAS-MP seleccionadas com LCSs marcados para FLUXOGRAMAS com cenários não criados, nomeadamente: <br/> {alert} </div>);
  }

  /**
   * Computation of Error messages --- END
   */

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={columns(user, flowsLookup)}
        data={scenarios}
        onRowClick={() => null}
        onRowDoubleClick={rowData => {
          setScenarioId(rowData.id);
        }}
        options={{
          actionsCellStyle: {
            minWidth: 150,
            paddingLeft: 20
          }
        }}
        isLoading={loading}
        editable={{
          onRowAdd: (data) => {
            setLoading(true);
            return createScenario({
              id_fluxograma: data.id_fluxograma,
              codigo: data.codigo,
              designacao: data.designacao,
              id_lcs: data.id_lcs,
              notas: data.notas,
              tipo: SCENARIO_F
            })
              .then(response => fetchScenario(response.id))
              .catch(e => alertError(e))
              .finally(() => setLoading(false))
          },
          onRowUpdate: newData => {
            setLoading(true);
            return updateScenario(newData.id, newData)
              .catch(e => alertError(e))
              .finally(() => setLoading(false))
          },
          onRowDelete: oldData => {
            setLoading(true);
            return removeScenario(oldData.id)
              .catch(e => alertError(e))
              .finally(() => setLoading(false))
          }
        }}
        actions={[
          {
            icon: tableIcons.ManageEntity,
            tooltip: 'Gerir cenários',
            onClick: (event, rowData) => {
              setScenarioId(rowData.id);
            }
          },

          // // TODO put noIcon when there is information already, this icon should only appear at creation
          // {
          //   icon: tableIcons.ContentCopy,
          //   tooltip: 'Copiar e criar cenário',
          //   onClick: (event, rowData) => {
          //     alert('Cópia de cenário ainda não implementada');
          //   }
          // }
        ]}
        detailPanel={[
          rowData => {
            /**
             * Validation warnings
             */
            const flowId = rowData.id_fluxograma;
            const stepIds = flowsById[flowId].etapas.map(step => step.id);
            const srm = stepRawMaterials.filter( srm => stepIds.includes(srm.id_etapa));
            const descriptors = new Set([]);
            srm.forEach( srm => srm.descriptors && srm.descriptors.forEach( descriptor => descriptors.add(descriptor)));
            const warnings = [];
            if (srm.length === 0) {
              warnings.push(wMsg.flow.scenarioFlowNoMPs);
            }
            if (!descriptors.has(lcsTranslator[rowData.id_lcs])) {
              warnings.push(wMsg.flow.scenarioFlowMPsNotInLCS);
            }
            if (warnings.length > 0 ) {
              return {
                icon: tableIcons.MessageError(theme.palette.error.main),
                tooltip: 'Warnings',
                render: () => <WarningMessageDetailPanel
                  rowData={rowData}
                  data={warnings}
                  title={'Avisos de irregularidades no preenchimento'} />
              }
            }
            return {
              icon: tableIcons.MessageError(),
              disabled: true,
              render: () => {}
            }
          }
        ]}
      />
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const flowsLookup = lookUpIdValueSelector('flows', ['codigo_fluxo', 'nome']);
  const scenarios = filterByFunctionSelector('scenarios', scenario => !!scenario.id_fluxograma);
  const mapStateToProps = (state, props) => ({
    flows: state.flows,
    flowsLookup: flowsLookup(state),
    flowsById: state.flows.dataById,
    scenarios: scenarios(state),
    stepRawMaterials: state.stepRawMaterials.data,
    stepsById: state.steps.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  persistentAlert: error => dispatch(persistentAlert(error)),
  fetchScenario: (id) => dispatch(actions.fetchOne(id)),
  updateScenario: (id, data) => dispatch(actions.updatePromise(id, data)),
  createScenario: data => dispatch(actions.createPromise(data)),
  removeScenario: id => dispatch(actions.removePromise(id))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
