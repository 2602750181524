import { MTableEditField } from '@material-table/core';
import Done from '@material-ui/icons/Done';
import Remove from '@material-ui/icons/Remove';
import React from 'react';

export default user => {
  const columns = [
    {
      title: 'Codigo',
      field: 'codigo',
      editable: 'never',
      width: 200,
      headerStyle: {
        textAlign: 'left',
        paddingLeft: 50
      },
      cellStyle: {
        textAlign: 'left',
        paddingLeft: 50
      },
    },
    {
      title: 'Descrição',
      field: 'descricao',
      editable: 'never',
      width: 400,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'OK',
      field: 'ok_flag',
      type: 'boolean',
      sorting: false,
      maxWidth: 90,
      minWidth: 90,
      initialEditValue: 'false',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      },
      lookup: {
        true: <Done style={{ height: 16, width: 16 }}/>,
        false: <Remove style={{ height: 16, width: 16 }}/>
      },
      render: rowData => rowData.ok_flag ?
        <Done style={{ height: 16, width: 16 }}/> :
        <Remove style={{ height: 16, width: 16 }}/>
    },
    {
      title: 'Notas',
      field: 'notas',
      initialEditValue: '',
      sorting: false,
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
      width: 400
    },
  ];


  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};

