import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import { connect } from 'react-redux';
import { lookUpIdValueSelector } from 'selectors/generic';
import theme from 'source/theme';
import arrayToObject from 'utils/arrayToObject';

const columns = units => [
  { title: 'Tipo', field: 'tipo' },
  { title: 'Subtipo', field: 'subtipo' },
  { title: 'Nome', field: 'nome' },
  { title: 'Valor', field: 'valor' },
  {
    title: 'Unidade',
    field: 'id_unidade',
    render: id => units[id]
  }
];

const Component = props => {
  const {
    conditions, scenarioDescriptor, scenarioDescriptorConditions,
    title, units
  } = props;

  const filteredSDC = scenarioDescriptorConditions.filter(sdc =>
    sdc.id_cenariodescritor === scenarioDescriptor.id
  );

  const fsdcByConditionId = arrayToObject(filteredSDC, 'id_condicao');

  const filteredConditions = conditions
    .filter(c => filteredSDC.map(sdc => sdc.id_condicao).includes(c.id))
    .map(c =>  ({
      ...c,
      id_unidade: fsdcByConditionId[c.id].id_unidade,
      valor: fsdcByConditionId[c.id].valor
    }));

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns(units)} rows={filteredConditions} title={title}/>
    </div>
  )
}

const makemapStateToProps = () => {
  const units = lookUpIdValueSelector('units', ['nome']);
  const mapStateToProps = (state, props) => ({
    conditions: state.conditions.data,
    scenarioDescriptorConditions: state.scenarioDescriptorConditions.data,
    units: units(state)
  })
  return mapStateToProps
};

export default connect(makemapStateToProps)(Component);
