import Grid from '@material-ui/core/Grid';
import Drawer from 'components/Drawer';
import BaseTable from 'components/Tables/BaseTable';
import { actions as rawMaterialsActions } from 'ducks/rawMaterials';
import { actions as reportActions } from 'ducks/report';
import { setContextSearchRM } from 'ducks/ui';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/inventory/consumptions';
import { filterByFunctionSelector } from 'selectors/generic';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/consumptions';
import ConsumptionsDetailPanel from './ConsumptionsDetailPanel';
import ConsumptionsTable from './ConsumptionsTable';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const {
    consumptionsById, loading, contextSearch, rawMaterialsById, rawMaterialsData,
    reportConsumptions
  } = props;
  const { create, fetchReportConsumptions, remove, setSearch, update } = props;
  const tableRef = useRef();

  const [mpId, setMpId] = useState(undefined);
  const onCloseDrawer = () => setMpId(undefined);

  // TODO What is this use effect doing
  useEffect(() => {
    fetchReportConsumptions();
  }, [rawMaterialsData])

  // useEffect(() => {
  //   toggleState.forEach(row =>
  //     tableRef.current.onToggleDetailPanel([row], tableRef.current.props.detailPanel)
  //   )
  // }, []);

  // const addToDetailPanel = (row) => {
  //   console.log('RUNS!!!!!', row, !panelDetailState.includes(row));
  //   if(panelDetailState.includes(row)) {
  //     console.log('RUNS!!!!! INSIDE');
  //     const tempState = [...panelDetailState];
  //     tempState.push(row);
  //     setPanelDetailState(tempState);
  //   }
  // };

  const onUpdate = (newData) => {
    update(newData.id, { observacoes: newData.observacoes});
    return Promise.resolve();
  };

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={columns}
        data={rawMaterialsData}
        onRowClick={() => null}
        onRowDoubleClick={rowData => setMpId(rowData.id)}
        isLoading={loading}
        onSearchChange={ text => setSearch(text)}
        options={{
          searchText: contextSearch,
        }}
        detailPanel={[
          rowData => ({
            disabled: rowData.consumos.length === 0,
            icon: tableIcons.Consumptions,
            render: () => <ConsumptionsDetailPanel rowData={rowData.consumos} data={consumptionsById} title={'Consumos'}/>
          })
        ]}
        actions={[
          {
            isFreeAction: true,
            icon: tableIcons.Pdf,
            tooltip: 'Generate Report',
            // onClick: (event, rowData) => null,
            onClick: (event, rowData) => {
              pdfMake.createPdf(docDefinition(rawMaterialsData, rawMaterialsById, consumptionsById)).open();
            }
          },
          {
            icon: tableIcons.Add,
            tooltip: 'Add Consumption',
            onClick: (event, rowData) => {
              setMpId(rowData.id)
            }
          }
        ]}
        editable={{
          onRowUpdate: onUpdate
        }}
      />
      {mpId &&
      <Drawer open={true} onClose={onCloseDrawer}>
        {mpId &&
        <ConsumptionsTable
          mpId={mpId}
          mpConsumptions={rawMaterialsById[mpId].consumos} create={create} remove={remove} update={update}
          onCloseDrawer={onCloseDrawer}
        />
        }
      </Drawer>
      }
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const filteredRawMaterials = filterByFunctionSelector('rawMaterials', rm => rm.cod_mp !== null && rm.cod_mp !== '');
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchRM,
    loading: state.rawMaterials.loading,
    rawMaterialsData: filteredRawMaterials(state),
    rawMaterialsById: state.rawMaterials.dataById,
    consumptionsById: state.rawMaterialConsumptions.dataById,
    reportConsumptions: state.report.consumptions
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  fetchReportConsumptions: () => dispatch(reportActions.fetchReportConsumptions()),
  setSearch: search => dispatch(setContextSearchRM(search)),
  update: (id, data) => dispatch(rawMaterialsActions.update(id, data)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
