import { FETCH, CREATE, DELETE, UPDATE, FULFILLED } from 'actionTypes';
import { fork, takeEvery, takeLatest } from 'redux-saga/effects';
import pluralize from 'utils/pluralize';
import { create, fetch, fetchPaginated, remove, update } from 'utils/sagasHelpers';

export default (ENTITY, url) => {
  const PLURAL = pluralize(ENTITY);

  function* watchCreate() {
    yield takeLatest(CREATE + ENTITY, create(CREATE + ENTITY, url));
  }

  function* watchDelete() {
    yield takeLatest(DELETE + ENTITY, remove(DELETE + ENTITY, url));
  }

  function* watchFetchAll() {
    yield takeLatest(FETCH + PLURAL, fetchPaginated(FETCH + PLURAL, url));
  }

  function* watchFetchOne() {
    yield takeEvery(FETCH + ENTITY, fetch(FETCH + ENTITY, url));
  }

  function* watchUpdate() {
    yield takeLatest(UPDATE + ENTITY, update(UPDATE + ENTITY, url));
  }

  return [
    fork(watchCreate),
    fork(watchDelete),
    fork(watchFetchAll),
    fork(watchFetchOne),
    fork(watchUpdate)
  ];
};
