import Grid from '@material-ui/core/Grid';
import Drawer from 'components/Drawer';
import ConditionsTable from 'pages/Products/ProductScenarios/ConditionsTable';
import Descriptors from 'pages/Products/ProductScenarios/DescriptorsTable';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ERC, ERC_TAB, PC, PC_TAB, PROC, PROC_TAB, SU, SU_TAB } from 'utils/constants/descriptorTypes'


const Component = props => {
  const {
    productId, lcsId, lcsById, productsById, scenarios, scenarioDescriptors
  } = props;

  const { backToProductsTable } = props;

  const [descriptorTab, setDescriptorTab] = useState(PC_TAB);
  const [product, setProduct] = useState(productsById[productId]);
  const [lcs, setLcs] = useState(lcsById[lcsId]);
  const [descriptor, setDescriptor] = useState(null);

  const scenario = scenarios.find(s => s.id_produto && s.id_produto == product.id && s.id_lcs == lcs.id);

  const filteredScenarioDescriptors = scenario ?
    scenarioDescriptors.filter(sd => sd.id_cenario === scenario.id) : [];

  // Only used for ERC and PROC
  const selectedScenarioDescriptor = filteredScenarioDescriptors.find(fsd =>
    (descriptor && fsd.id_descritor === descriptor.id)
  );

  const closeConditions = () => setDescriptor(null);

  const descriptorCase = useDescriptor => {
    switch (useDescriptor) {
      case PC_TAB:
        return (
          <Descriptors
            descriptorTab={PC_TAB}
            descriptorFilter={PC}
            scenarioDescriptors={filteredScenarioDescriptors}
            setDescriptorTab={setDescriptorTab}
            scenario={scenario}
            product={product}
            setProduct={setProduct}
            lcs={lcs}
            setLcs={setLcs}
            backToProductsTable={backToProductsTable}
          />
        )
      case SU_TAB:
        return (
          <Descriptors
            descriptorTab={SU_TAB}
            descriptorFilter={SU}
            scenarioDescriptors={filteredScenarioDescriptors}
            setDescriptorTab={setDescriptorTab}
            scenario={scenario}
            product={product}
            setProduct={setProduct}
            lcs={lcs}
            setLcs={setLcs}
            backToProductsTable={backToProductsTable}
          />
        )
      case ERC_TAB:
        return (
          <React.Fragment>
            <Descriptors
              descriptorTab={ERC_TAB}
              descriptorFilter={ERC}
              setDescriptor={setDescriptor}
              scenarioDescriptors={filteredScenarioDescriptors}
              setDescriptorTab={setDescriptorTab}
              scenario={scenario}
              product={product}
              setProduct={setProduct}
              lcs={lcs}
              setLcs={setLcs}
              backToProductsTable={backToProductsTable}
            />
            {
              descriptor &&
              <Drawer open={true} onClose={closeConditions}>
                <ConditionsTable
                  conditionsFilter={'Ambiente'}
                  closeConditions={closeConditions}
                  scenarioDescriptor={selectedScenarioDescriptor ? selectedScenarioDescriptor : {}}
                />
              </Drawer>
            }
          </React.Fragment>
        )
      case PROC_TAB:
        return (
          <React.Fragment>
            <Descriptors
              descriptorTab={PROC_TAB}
              descriptorFilter={PROC}
              setDescriptor={setDescriptor}
              scenarioDescriptors={filteredScenarioDescriptors}
              setDescriptorTab={setDescriptorTab}
              scenario={scenario}
              product={product}
              setProduct={setProduct}
              lcs={lcs}
              setLcs={setLcs}
              backToProductsTable={backToProductsTable}
            />
            {
              descriptor &&
              <Drawer open={true} onClose={closeConditions}>
                <ConditionsTable
                  conditionsFilter={'Trabalhadores'}
                  closeConditions={closeConditions}
                  scenarioDescriptor={selectedScenarioDescriptor ? selectedScenarioDescriptor : {}}
                />
              </Drawer>
            }
          </React.Fragment>
        )
      default:
        alert('ERROR, descriptor doesnt exist!');
    }
  }


  return (
    <Grid item xs={12}>
      {descriptorCase(descriptorTab)}
    </Grid>
  )
};

const mapStateToProps = state => ({
  lcsById: state.descriptors.dataById,
  productsById: state.products.dataById,
  scenarios: state.scenarios.data,
  scenarioDescriptors: state.scenarioDescriptors.data
})

export default connect(mapStateToProps)(Component);
