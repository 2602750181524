import ShipmentsDetailPanel from 'components/DetailPanels/ShipmentsDetailPanel';
import SubstancesDetailPanel from 'components/DetailPanels/SubstanceDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { setContextSearchRM } from 'ducks/ui';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/inventory/shipments';
import { filterByFunctionSelector } from 'selectors/generic';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/rawMaterialSupplier';
import noIcon from 'utils/tables/noIcon';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const { contextSearch, rawMaterials, rawMaterialsById, suppliersById, user } = props;
  const { setRawMaterial, setSearch } = props;
  const tableRef = React.useRef(null);

  return (
    <BaseTable
      ref={tableRef}
      columns={columns(user)}
      data={rawMaterials}
      onRowClick={() => null}
      onRowDoubleClick={rowData => setRawMaterial(rowData)}
      onSearchChange={text => setSearch(text)}
      options={{
        searchText: contextSearch
      }}
      actions={[
        {
          icon: tableIcons.ManageEntity,
          tooltip: 'Gerir fornecimentos',
          onClick: (event, rowData) => {
            setRawMaterial(rowData)
          }
        },
        {
          isFreeAction: true,
          icon: tableIcons.Pdf,
          tooltip: 'Exportar PDF',
          onClick: () => {
            const filteredTableData = tableRef.current.state.data;
            pdfMake.createPdf(
              docDefinition(filteredTableData, { suppliers: suppliersById }))
              .open();
          }
        },
      ]}
      detailPanel={[
        rowData => rowData.inter_substancias.length === 0 ?
          noIcon :
          {
            icon: tableIcons.Substance,
            tooltip: 'Composição',
            render: () => <SubstancesDetailPanel rowData={rowData.inter_substancias} data={rawMaterialsById} title={'Substâncias'}/>
          },
        rowData => rowData.inter_fornecedores.length === 0 ?
          noIcon :
          {
            icon: tableIcons.Shipments,
            tooltip: 'Fornecimentos',
            render: () => <ShipmentsDetailPanel rowData={rowData} suppliersById={suppliersById} rawMaterialsById={rawMaterialsById} title={'Fornecimentos'}/>
          }
      ]}
    />
  )
};

const makeMapStateToProps = () => {
  const filteredRawMaterials = filterByFunctionSelector('rawMaterials', rm => rm.cod_mp !== null && rm.cod_mp !== '');
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchRM,
    rawMaterials: filteredRawMaterials(state),
    rawMaterialsById: state.rawMaterials.dataById,
    suppliersById: state.suppliers.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  setSearch: search => dispatch(setContextSearchRM(search))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
