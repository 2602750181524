import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable';
import React from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreData } from 'selectors/generic';
import { echaTableColumns } from 'utils/echa/columns';
import tableIcons from 'utils/icons/materialTableIcons';

const Component = props => {
  const { echas, consumptions, suppliers } = props;
  const ref = React.useRef(null);


  return (
    <Grid item xs={12}>
      <BaseTable
        tableRef={ref}
        icons={tableIcons}
        columns={echaTableColumns}
        onRowClick={() => null}
        data={echas}
      />
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const echas = getImmutableStoreData('echa');
  const mapStateToProps = (state, props) => ({
    echas: echas(state)
  })
  return mapStateToProps;
}

export default connect(makeMapStateToProps)(Component);
