import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions as actionsRawMaterials } from 'ducks/rawMaterials';
import { actions as actionsSupplierRegister } from 'ducks/supplierRegister';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreById } from 'selectors/generic';
import shipmentRegisterColumns from 'utils/tables/columns/inventory/shipmentRegister';
import { validator as shipmentRegisterValidator } from 'utils/tables/validators/shipmentRegisters';

const Component = props => {
  const { idAccount, parentLoading, rawMaterials, rawMaterial, shipmentId, tableOptions } = props;
  const {
    alertError, createSupplierRegister, fetchRawMaterial, updateSupplierRegister
  } = props;
  let shipmentDetails = [];
  let shipment = undefined;
  const tableRef = React.useRef(null);

  // TODO Removable after Data on DB is equalized
  const initValuesForColumns = {};

  const [loading, setLoading] = useState(loading);

  if (shipmentId) {
    shipment = rawMaterial['inter_fornecedores'].find(s => s.id === shipmentId);
    if (shipment) {
      shipmentDetails = shipment['substancias_c_registos'].map(sourcedSubstance => {
        const interSubstance = rawMaterial.inter_substancias.find(s => s.id === sourcedSubstance['id']);
        const rawMaterialData = rawMaterials[sourcedSubstance['id_substancia']['id']];
        return {
          update: sourcedSubstance['registos'].length !== 0,
          tipo: rawMaterialData.tipo,
          mp_nome: rawMaterialData.mp_nome,
          ec: rawMaterialData.ec,
          cas: rawMaterialData.cas,
          ...initValuesForColumns,
          ...sourcedSubstance['registos'][0],
          id_mp_substancia: interSubstance['id'],
          percentagem: interSubstance['percentagem'],
          id_mp_fornecedor: shipment.id,
          id_account: idAccount
        }
      })
    }
  }

  return (
    <BaseTable
      ref={tableRef}
      title={
        shipmentId && shipment ?
          `${shipment.codigo} - ${shipment.designacao_comercial}` :
          'Seleccione um fornecimento de matéria prima acima...'
      }
      isLoading={loading || parentLoading}
      columns={shipmentRegisterColumns}
      data={shipmentDetails}
      onRowClick={() => null}
      paging={false}
      halfSize={true}
      editable={{
        onRowUpdate: (newData, oldData) => {
          if (newData.update) {
            const srValidation = shipmentRegisterValidator(newData, undefined);
            if (srValidation.error) {
              alertError(srValidation.error);
              return Promise.reject()
            }
            setLoading(true);
            return updateSupplierRegister(newData.id, { ...newData })
              .then(() => fetchRawMaterial(rawMaterial.id))
              .catch(e => alert(e))
              .finally(() => setLoading(false));
          } else {
            const srValidation = shipmentRegisterValidator(newData, undefined);
            if (srValidation.error) {
              alertError(srValidation.error);
              return Promise.reject()
            }
            setLoading(true);
            return createSupplierRegister({ ...newData })
              .then(() => fetchRawMaterial(rawMaterial.id))
              .catch(e => alert(e))
              .finally(() => setLoading(false));
          }
        }
      }}
    />
  )
};

const makeMapStateToProps = () => {
  const rawMaterials = getImmutableStoreById('rawMaterials');
  const mapStateToProps = (state, props) => ({
    rawMaterials: rawMaterials(state),
    idAccount: state.profile.entity.id_account
  })
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  updateSupplierRegister: (id, data) => dispatch(actionsSupplierRegister.updatePromise(id, data)),
  createSupplierRegister: (data) => dispatch(actionsSupplierRegister.createPromise(data)),
  fetchRawMaterial: id => dispatch(actionsRawMaterials.fetchOne(id))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
