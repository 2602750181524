import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import history from 'appHistory';
import Header from 'connectedComponents/Header/index';
import Loader from 'components/Loader/index';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import LoginPage from 'pages/Auth/LoginPage';
import Logout from 'pages/Auth/Logout';
import PasswordRecovery from 'pages/Auth/PasswordRecovery';
import Register from 'pages/Auth/Register';
import RegisterSuccess from 'pages/Auth/RegisterSuccess';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import adminSections from 'utils/navigationSections/adminSidebarSections';
import userSections from 'utils/navigationSections/userSidebarSections';
import Routes from './Routes';
import theme from './theme';
import ErrorAlert from 'connectedComponents/ErrorAlert';
import PersistentAlert from 'connectedComponents/PersistentAlert';

const styles = () => ({
  container: {
    bottom: 0,
    left: 0,
    position: 'relative',
    right: 0,
    top: 64,
    height: 'calc(100vh - 64px)'
  }
});

const App = ({ booting, classes, user }) => {

  console.log('user', user);
  const sidebarSections = user.is_staff ? adminSections : userSections(user);

  if (booting) {
    return (
      <MuiThemeProvider theme={theme}>
        <Loader/>
      </MuiThemeProvider>
    );
  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline/>
      <Router history={history}>
        <Switch>
          <Route path='/login' component={LoginPage}/>
          <Route path='/forgot_password' component={ForgotPassword}/>
          <Route path='/password_recovery/:code' component={PasswordRecovery}/>
          <Route path='/logout' component={Logout}/>
          <Route path='/register' component={Register}/>
          <Route path='/register_success' component={RegisterSuccess}/>
          <React.Fragment>
            <Header sections={sidebarSections}/>
            <ErrorAlert />
            <PersistentAlert />
            <Grid className={classes.container} container spacing={0}>
              <Routes/>
            </Grid>
          </React.Fragment>
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => ({
  booting: state.bootstrap.booting,
  user: state.profile.entity
});

export default connect(mapStateToProps)(withStyles(styles)(App));
