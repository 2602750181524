export default [
  {
    title: 'Consumo Anual (kg)',
    field: 'consumo_anual_kg',
    type: 'numeric'
  },
  {
    title: 'Compra Anual (kg)',
    field: 'compra_anual_kg',
    type: 'numeric'
  },
  {
    title: 'Ano',
    field: 'ano',
    type: 'numeric'
    //type: 'date'
  }
];
