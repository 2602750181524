import { MIXTURE, SUBSTANCE } from 'utils/constants/coreConstants';

const columns = [
  // {title: 'Identificador', field: 'id', type: 'numeric', editable: 'never' },
  {
    title: 'Código MP',
    field: 'cod_mp',
    width: 200,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },
  {
    title: 'Nome',
    field: 'mp_nome',
    width: 1000,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      textAlign: 'left',
    },
  },

  {
    title: 'Tipo',
    field: 'tipo',
    sorting: false,
    width: 200,
    initialEditValue: SUBSTANCE,
    lookup: {
      [SUBSTANCE]: 'Substância',
      [MIXTURE]: 'Mistura'
    }
  },
  {
    title: 'CAS',
    field: 'cas',
    sorting: false,
    width: 200,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
    render: rowData => rowData.cas && rowData.cas.length !== 0 ? rowData.cas.join(', ') : '-',
  },
  {
    title: 'EC',
    field: 'ec',
    sorting: false,
    width: 200,
    headerStyle: {
      textAlign: 'left',
    },
    cellStyle: {
      textAlign: 'left',
    },
    render: rowData => rowData.ec && rowData.ec.length !== 0 ? rowData.ec.join(', ') : '-',
  }
  // {
  //   title: 'Ligações Echa',
  //   field: 'link_echa'
  // }
];


export default columns;
