import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import PropTypes from 'prop-types';

const Component = (props) => {

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirmação de acção"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            typeof props.alert === 'string' ?
              props.alert :
              props.alert()
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          props.backText &&
          <Button onClick={props.onClose} color="primary">
            {props.backText}
          </Button>
        }
        <Button onClick={props.onConfirmNo} color="primary">
          {props.noText ? props.noText : 'Não'}
        </Button>
        <Button onClick={props.onConfirmYes} color="primary" autoFocus>
          {props.yesText ? props.yesText : 'Sim'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;

Component.propTypes = {
  //alert: PropTypes.string,
  open: PropTypes.bool,
  onConfirmYes: PropTypes.func,
  onConfirmNo: PropTypes.func,
  onClose: PropTypes.func
};
