import cloneDeep from 'lodash/cloneDeep'
import theme from 'source/theme';
import { newLogoReachOk64 } from '../base64Images';

const dataMapping = [
  { id: 'mp_nome', name: 'Substância' },
  { id: 'ec', name: 'EC' },
  { id: 'cas', name: 'CAS' },
  { id: 'svhc_descricao', name: 'Descrição', nested: 'echas' },
  { id: 'svhc_razao_inclusao', name: 'Razão', nested: 'echas' },
  { id: 'svhc_data_inclusao', name: 'Data', nested: 'echas' },
  { id: 'autorizacao_data_limite', name: 'Data limite', nested: 'echas' },
  { id: 'autorizacao_isencoes', name: 'Isenções', nested: 'echas' },
  { id: 'restricoes_descricao', name: 'Descrição', nested: 'echas' },
  { id: 'restricoes_entrada', name: 'Entrada', nested: 'echas' }
];

const dateFormater = date => {
  if (typeof date === 'string') {
    const d = new Date(date);
    return isNaN(d.getDate()) ?
      undefined :
      (d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear())
  }
};

const rowGenerator = (dataMapping, data, rawMaterialsById) => {
  const rows = [];
  // Cloning the data to avoid modification of Echas on redux store
  const clonedData = cloneDeep(data);

  const nestedShipmentsCount = rawMaterial => {
    const mixtureIds = rawMaterial.inter_misturas.map(mix => mix.id_mistura);
    return mixtureIds.map(id => {
        return rawMaterialsById[id].inter_fornecedores.length;
      }
    ).reduce((acc, cv) => acc + cv, 0)
  }

  const firstRowGenerationSingleRestricao = (rawMaterial, echa) => {
    let echasPlusEntries = rawMaterial['echas'].length; // Calculates number of spanned rows
    rawMaterial['echas'].forEach(echa => {
      if (echa.restricoes_entrada.length > 1) {
        echasPlusEntries = echasPlusEntries + echa.restricoes_entrada.length - 1;
      }
    });
    return dataMapping.map(field => {
      if (field.nested) {
        return { text: echa[field.id], fillColor: '#EEEEEE' };
      } else {
        if (field.id === 'mp_nome') {
          return {
            text: rawMaterial[field.id],
            rowSpan: echasPlusEntries + rawMaterial.inter_fornecedores.length + nestedShipmentsCount(rawMaterial),
            fillColor: theme.palette.primary.light
          }
        } else {
          return {
            text: rawMaterial[field.id],
            rowSpan: echasPlusEntries,
            fillColor: '#EEEEEE'
          }
        }
      }
    });
  };

  const firstRowGenerationMultipleRestricoes = (rawMaterial, echa) => {
    let echasPlusEntries = rawMaterial['echas'].length; // Calculates number of spanned rows
    rawMaterial['echas'].forEach(echa => {
      if (echa.restricoes_entrada.length > 1) {
        echasPlusEntries = echasPlusEntries + echa.restricoes_entrada.length - 1;
      }
    });
    return dataMapping.map(field => {
      if (field.nested) {
        return field.id === 'restricoes_descricao' || field.id === 'restricoes_entrada' ?
          { text: echa[field.id][0], fillColor: '#EEEEEE' } :
          {
            text: echa[field.id],
            rowSpan: echa.restricoes_entrada.length,
            fillColor: '#EEEEEE'
          }
      } else {
        if (field.id === 'mp_nome') {
          return {
            text: rawMaterial[field.id],
            rowSpan: echasPlusEntries + rawMaterial.inter_fornecedores.length + nestedShipmentsCount(rawMaterial),
            fillColor: theme.palette.primary.light
          }
        } else {
          return {
            text: rawMaterial[field.id],
            rowSpan: echasPlusEntries,
            fillColor: '#EEEEEE'
          }
        }
      }
    });
  };

  // mixture is optional and only used when the shipment to be pushed is from
  // a substance that belongs to a mixture
  const pushShipment = (substance, shipment, mixture = null) => {
    let regs = shipment.registos_directos;
    regs = regs.length > 0 ? regs[0] : {};
    if (mixture) {
      regs = shipment.substancias_c_registos.find(shp => shp.id_substancia.id === substance.id);
      regs = (regs && regs.registos.length > 0) ? regs.registos[0] : {}
    }
    rows.push(
      [
        { text: '', border: [false, false, false, false] },
        { text: mixture ? mixture.cod_mp : substance.cod_mp, colSpan: 1 },
        { text: shipment.designacao_comercial, colSpan: 2 },
        {},
        { text: shipment.id_fornecedor ? shipment.id_fornecedor.fornecedor : '', colSpan: 1 },
        { text: regs.n_registo, colSpan: 1 },
        { text: dateFormater(regs.data_recepcao), colSpan: 1 },
        { text: regs.ce },
        { text: regs.observacoes, colSpan: 2 },
        {}
      ]
    );
  }

  clonedData.forEach(item => {
    if (item['echas']) {
      item['echas'].forEach((echa, index) => {

        if (index === 0) { // In the first Echa match

          if (echa.restricoes_entrada.length <= 1) {
            rows.push(firstRowGenerationSingleRestricao(item, echa))
          } else {
            echa.restricoes_entrada.forEach((re, i) => {
              if (i === 0) {
                rows.push(firstRowGenerationMultipleRestricoes(item, echa))
              } else {
                rows.push([
                  {},
                  { text: '', fillColor: '#EEEEEE' },
                  { text: '', fillColor: '#EEEEEE' },
                  { text: '', fillColor: '#EEEEEE' },
                  { text: '', fillColor: '#EEEEEE' },
                  { text: '', fillColor: '#EEEEEE' },
                  { text: '', fillColor: '#EEEEEE' },
                  { text: '', fillColor: '#EEEEEE' },
                  { text: echa.restricoes_descricao[i], fillColor: '#EEEEEE' },
                  { text: echa.restricoes_entrada[i], fillColor: '#EEEEEE' }]);
              }
            });
          }
          // TODO Not sure if this case happens
        } else { // If there are more echa matches. Are there multiple matches on the
          if (echa.restricoes_entrada.length <= 1) {
            rows.push(dataMapping.map(field => field.nested && echa[field.id]))
          } else {
            echa.restricoes_entrada.forEach((re, index) => {
              rows.push(
                dataMapping.map(field => field.id === 'restricoes_descricao' || field.id === 'restricoes_entrada' ?
                  { text: echa[field.id][index], fillColor: '#EEEEEE' } :
                  { text: echa[field.id], rowSpan: index === 0 ? echa.restricoes_entrada.length : 1, fillColor: '#EEEEEE' }
                )
              )
            });
          }
        }
      });
    } else {
      rows.push(
        dataMapping.map(field => field.id === 'mp_nome' ?
          {
            text: item[field.id],
            //rowSpan: 1 + item.inter_fornecedores.length + nestedShipmentsCount(item),
            fillColor: '#EEEEEE',
            border: [false, true, false, false]
          } :
          { text: item[field.id], fillColor: '#EEEEEE' }
        )
      );
    }

    if (item.inter_fornecedores.length > 0) {
      item.inter_fornecedores.forEach(supplier => pushShipment(item, supplier))
    }

    if (item.inter_misturas.length > 0) {
      item.inter_misturas.forEach(mistura => {
        if (rawMaterialsById[mistura.id_mistura].inter_fornecedores.length > 0) {
          rawMaterialsById[mistura.id_mistura].inter_fornecedores.forEach(supplier => pushShipment(item, supplier, rawMaterialsById[mistura.id_mistura]))
        }
      })

    }
  });
  return rows;
};

// playground requires you to assign document definition to a variable called dd

const dd = (data, rawMaterialsById) => {
  console.log('rowGenerator(data, extraData)', rowGenerator(dataMapping, data, rawMaterialsById));

  return {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [40, 60, 40, 40],

    footer: function (currentPage, pageCount) {
      return {
        text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
        alignment: 'right',
        relativePosition: { x: -15, y: 20 },
        fontSize: 7
      }
    },
    header: function (currentPage, pageCount, pageSize) {
      return [
        {
          image: newLogoReachOk64,
          width: 50,
          relativePosition: { x: 20, y: 10 }
        }
      ]
    },
    content: [
      {
        style: 'header',
        text: 'R - Inventário ECHA' //title,
      },
      {
        style: 'tableExample',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 3,
          widths: [80, 50, 50, 90, 100, 40, 40, 50, 90, 20],
          body: [
            [
              { text: 'Substância', rowSpan: 2, fillColor: '#EEEEEE', border: [false, false, false, false] },
              { text: 'EC', rowSpan: 2, fillColor: '#EEEEEE' },
              { text: 'CAS', rowSpan: 2, fillColor: '#EEEEEE' },
              { text: 'SVHC', colSpan: 3, alignment: 'center', fillColor: '#EEEEEE' },
              { text: '', fillColor: '#EEEEEE' },
              { text: '', fillColor: '#EEEEEE' },
              { text: 'Autorização', colSpan: 2, alignment: 'center', fillColor: '#EEEEEE' },
              { text: '', fillColor: '#EEEEEE' },
              { text: 'Restrições', colSpan: 2, alignment: 'center', fillColor: '#EEEEEE' },
              { text: '', fillColor: '#EEEEEE' }
            ],
            [
              { text: '', fillColor: '#EEEEEE' },
              { text: '', fillColor: '#EEEEEE' },
              { text: '', fillColor: '#EEEEEE' },
              { text: 'Descrição', alignment: 'center', fillColor: '#EEEEEE' },
              { text: 'Razão', alignment: 'center', fillColor: '#EEEEEE' },
              { text: 'Data', alignment: 'center', fillColor: '#EEEEEE' },
              { text: 'Data limite', alignment: 'center', fillColor: '#EEEEEE' },
              { text: 'Isenções', alignment: 'center', fillColor: '#EEEEEE' },
              { text: 'Descrição', alignment: 'center', fillColor: '#EEEEEE' },
              { text: 'Nº', alignment: 'center', fillColor: '#EEEEEE' }
            ],
            [
              { text: '', border: [false, false, false, false] },
              { text: 'Codigo MP', colSpan: 1 },
              { text: 'Designação Comercial', colSpan: 2 },
              { text: '' },
              { text: 'Fornecedor', colSpan: 1 },
              { text: 'Nº Registo', colSpan: 1 },
              { text: 'Data recepção CE', colSpan: 1 },
              { text: 'CE' },
              { text: 'Observações', colSpan: 2 },
              { text: '' }
            ],

            ...rowGenerator(dataMapping, data, rawMaterialsById)
          ]
        }
      }
    ],
    styles: {
      header: {
        color: theme.palette.primary.main,
        fontSize: 16,
        bold: true,
        alignment: 'center'
      },
      tableExample: {
        margin: [0, 15, 0, 0],
        fontSize: 7
      }
    }

  }
};

export default dd;
