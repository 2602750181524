import cloneDeep from 'lodash/cloneDeep'
import theme from 'source/theme';
import { newLogoReachOk64 } from '../base64Images';

const dataMapping = [
  { id: 'nome', name: 'nome' },
  { id: 'svhc_descricao', name: 'Descrição', nested: 'echas' },
  { id: 'svhc_razao_inclusao', name: 'Razão', nested: 'echas' },
  { id: 'svhc_data_inclusao', name: 'Data', nested: 'echas' },
  { id: 'autorizacao_data_limite', name: 'Data limite', nested: 'echas' },
  { id: 'autorizacao_isencoes', name: 'Isenções', nested: 'echas' },
  { id: 'restricoes_descricao', name: 'Descrição', nested: 'echas' },
  { id: 'restricoes_entrada', name: 'Entrada', nested: 'echas' }
];

const rowGenerator = (dataMapping, data) => {
  const rows = [];
  // Cloning the data to avoid modification of Echas on redux store
  const clonedData = cloneDeep(data);

  clonedData.forEach(item => {
    const thisRow = dataMapping.map(field => item[field.id]);
    rows.push(thisRow);
  });
  return rows;
};

const dd = (data) => ({
  pageSize: 'A4',
  pageOrientation: 'landscape',
  pageMargins: [40, 60, 40, 40],

  footer: function (currentPage, pageCount) {
    return {
      text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
      alignment: 'right',
      relativePosition: { x: -15, y: 20 },
      fontSize: 7
    }
  },
  header: function (currentPage, pageCount, pageSize) {
    return [
      {
        image: newLogoReachOk64,
        width: 50,
        relativePosition: { x: 20, y: 10 }
      }
    ]
  },
  content: [
    {
      style: 'header',
      text: 'R - Listas ECHA sem ligação directa' //title,
    },
    {
      style: 'tableExample',
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 2,
        widths: [100, 100, 90, 40, 40, 90, 90, 20],
        body: [
          [
            { text: 'Substância', rowSpan: 2 },
            { text: 'SVHC', colSpan: 3, alignment: 'center' },
            {},
            {},
            { text: 'Autorização', colSpan: 2, alignment: 'center' },
            {},
            { text: 'Restrições', colSpan: 2, alignment: 'center' },
            {}
          ],
          [
            {},
            { text: 'Descrição', alignment: 'center' },
            { text: 'Razão', alignment: 'center' },
            { text: 'Data', alignment: 'center' },
            { text: 'Data limite', alignment: 'center' },
            { text: 'Isenções', alignment: 'center' },
            { text: 'Descrição', alignment: 'center' },
            { text: 'Nº', alignment: 'center' }
          ],
          ...rowGenerator(dataMapping, data)
        ]
      }
    }
  ],
  styles: {
    header: {
      color: theme.palette.primary.main,
      fontSize: 16,
      bold: true,
      alignment: 'center'
    },
    tableExample: {
      margin: [0, 15, 0, 0],
      fontSize: 7
    }
  }

});

export default dd;
