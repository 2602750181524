import Grid from '@material-ui/core/Grid';
import Drawer from 'components/Drawer';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import RawMaterialsTable from './RawMaterialsTable';
import StepSelector from './StepRawMaterialSelector'

const Component = props => {
  const { flows, scenarios } = props;

  const [rawMaterial, setRawMaterial] = useState(null);
  const [flowId, setFlowId] = useState(flows.data[0].id);
  const handleCloseDrawer = () => setRawMaterial(null);

  /**
   * Compute all the production scenarios combinations [LCS + Flow, ..]
   * This will allow to give a warning in case there are step_rawMaterials
   * checked for production scenarios that haven't been created yet
   */
  const flowIds = flows.data.map(flow => flow.id);
  const lcsByFLowIdScenarios = {};
  scenarios.data
    .filter(scenario =>
      scenario.id_fluxograma && !scenario.id_produto && !scenario.id_substancia
      && !scenario.id_substancia_fornecedor && flowIds.includes(scenario.id_fluxograma)
    )
    .forEach(scenario => lcsByFLowIdScenarios[scenario.id_fluxograma] ?
      lcsByFLowIdScenarios[scenario.id_fluxograma].push(parseInt(scenario.id_lcs)) :
      lcsByFLowIdScenarios[scenario.id_fluxograma] = [parseInt(scenario.id_lcs)]
    )

  return (
    <Grid item xs={12}>
      <RawMaterialsTable
        flowId={flowId}
        setFlowId={setFlowId}
        setRawMaterial={setRawMaterial}
      />
      {
        rawMaterial &&
        <Drawer open={true} onClose={handleCloseDrawer}>
          <StepSelector
            flowId={flowId}
            flowSteps={flows.dataById[flowId]['etapas']}
            handleCloseDrawer={handleCloseDrawer}
            rawMaterialId={rawMaterial.id}
            lcsByFLowIdScenarios={lcsByFLowIdScenarios}
          />
        </Drawer>
      }
    </Grid>
  )
};

const mapStateToProps = (state, props) => ({
  flows: state.flows,
  scenarios: state.scenarios
});

export default connect(mapStateToProps)(Component);
