import { TextField } from "@material-ui/core";
import React from 'react';

const editComponent = (props) => (
  <TextField
    fullWidth
    multiline
    size="small"
    //variant="outlined"
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  />
);

export default user => {
  const columns = [
    {
      title: 'Código MP',
      field: 'cod_mp',
      minWidth: 100,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      editComponent: editComponent
    },
    {
      title: 'Nome',
      field: 'mp_nome',
      minWidth: 200,
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'left'
      },
      //editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
      editComponent: editComponent
    },
    {
      title: 'CAS',
      field: 'cas',
      minWidth: 150,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      render: rowData => rowData.cas && rowData.cas.length !== 0 ? rowData.cas.join(', ') : '',
      editComponent: editComponent
    },
    {
      title: 'EC',
      field: 'ec',
      minWidth: 150,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      render: rowData => rowData.ec && rowData.ec.length !== 0 ? rowData.ec.join(', ') : '',
      editComponent: editComponent
    }
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 50
    },
    ...columns
  ];

  return user.is_staff ? columnsAdmin : columns;
}
