import FlowScenariosTable from 'pages/Flows/FlowScenarios/FlowScenariosTable'
import FlowScenario from 'pages/Flows/FlowScenarios/FlowScenario'
import React, { useState } from 'react';


const Component = props => {

  const [scenarioId, setScenarioId] = useState(null);


  const backToScenarios = () => setScenarioId(null);

  if (scenarioId) {
    return <FlowScenario scenarioId={scenarioId} backToScenarios={backToScenarios}/>
  }

  return <FlowScenariosTable setScenarioId={setScenarioId}/>

};

export default Component;
