import BaseTable from 'components/Tables/BaseTable';
import { sortBy } from 'lodash';
import FlowListDetailPanel from 'pages/ScenarioExposure/Flow/ScenariosManagement/PROCTrabalhadores/FlowStepListDetailPanel';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import theme from 'source/theme';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/scenarioExposure/flow/flowPcSuScenarioDescriptorExposure';

const Component = props => {
  const {
    descriptorId, descriptorsById, scenariosById, stepsById, flowScenarioDescriptors,
    flowsById, TABLE_HEIGHT
  } = props;

  const { setDescriptorId } = props
  const tableRef = useRef();

  const mergedProductDescriptors = [];
  const descriptorRepetitions = [];

  /**
   * Received Filtered ScenarioDescriptors that make sense according to DESCRIPTOR and STEPS found above.
   * Adding information of Flow and Steps to each DESCRIPTOR below
   */
  flowScenarioDescriptors.forEach(fSD => {
    if (!descriptorRepetitions.includes(fSD.id_descritor)) {
      const scenariodescriptorGrouped = flowScenarioDescriptors.filter(item => item.id_descritor === fSD.id_descritor);
      const mergedDescriptor = {
        id: fSD.id_descritor,
        descriptor_code: descriptorsById[fSD.id_descritor].codigo,
        descriptor_description: descriptorsById[fSD.id_descritor].descricao,
        flowsInfo: [],
        stepsInfo: {}
      };

      const repeatedFlows = [];
      scenariodescriptorGrouped.forEach(sdg => {
        const flow = flowsById[scenariosById[sdg.id_cenario].id_fluxograma];
        if (!repeatedFlows.includes(flow.id)) {
          mergedDescriptor.flowsInfo.push({
            flow_id: flow.id,
            flow_code: flow.codigo_fluxo,
            flow_name: flow.nome
          });
          repeatedFlows.push(flow.id);
          mergedDescriptor.stepsInfo[flow.id] = [{
            step_id: sdg.id_etapa,
            step_code: stepsById[sdg.id_etapa].nome,
            step_name: stepsById[sdg.id_etapa].id_master_etapa ?
              stepsById[sdg.id_etapa].id_master_etapa.descricao + ' / ' + stepsById[sdg.id_etapa].descricao :
              stepsById[sdg.id_etapa].descricao
          }];
        } else {
          mergedDescriptor.stepsInfo[flow.id].push({
            step_id: sdg.id_etapa,
            step_code: stepsById[sdg.id_etapa].nome,
            step_name: stepsById[sdg.id_etapa].id_master_etapa ?
              stepsById[sdg.id_etapa].id_master_etapa.descricao + ' / ' + stepsById[sdg.id_etapa].descricao :
              stepsById[sdg.id_etapa].descricao
          });
        }
      });

      descriptorRepetitions.push(fSD.id_descritor);
      mergedProductDescriptors.push(mergedDescriptor);
    }
  });

  const rows = sortBy(mergedProductDescriptors, ['id']);

  return (
    <React.Fragment>
      <BaseTable
        ref={tableRef}
        title={'DESCRITORES DO FLUXO'}
        columns={columns}
        data={rows}
        paging={false}
        onRowClick={() => null}
        onRowDoubleClick={setDescriptorId ? rowData => setDescriptorId(rowData.id) : null}
        options={{
          // toolbar: false,
          // search: false,
          maxBodyHeight: TABLE_HEIGHT,
          minBodyHeight: TABLE_HEIGHT,
          rowStyle: setDescriptorId ? rowData => ({
              backgroundColor: (rowData.id === descriptorId) ?
                theme.palette.primary.light :
                theme.palette.background.veryLight
            }) :
            ({ backgroundColor: theme.palette.background.veryLight })
        }}
        detailPanel={[
          () => ({
            icon: tableIcons.Flow,
            tooltip: 'Fluxogramas',
            render: rowData => <FlowListDetailPanel row={rowData}/>
          })
        ]}
      />
    </React.Fragment>
  )
};


const mapStateToProps = (state) => ({
  descriptorsById: state.descriptors.dataById,
  scenariosById: state.scenarios.dataById,
  flowsById: state.flows.dataById,
  stepsById: state.steps.dataById
})

export default connect(mapStateToProps)(Component);
