import pluralize from 'pluralize';

export default string => {
  switch (string) {
    case 'EQUIPMENT':
      return 'EQUIPMENTS';
    case 'METADATA':
      return 'METADATAS';
    default:
      return pluralize(string);
  }
};
