import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/units';
import React from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreData } from 'selectors/generic';
import columns from 'utils/tables/columns/administration/units';

const Component = props => {
  const { units } = props;
  const { alertError, create, remove, update } = props;
  const tableRef = React.useRef(null);

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        onRowClick={() => null}
        columns={columns}
        data={units}
        editable={{
          onRowAdd: newData => create(newData),
          onRowUpdate: newData => update(newData.id, newData),
          onRowDelete: oldData => remove(oldData.id)
        }}
      />
    </Grid>
  )
};


const makeMapStateToProps = () => {
  const immutableUnits = getImmutableStoreData('units');
  const mapStateToProps = (state, props) => ({
    units: immutableUnits(state)
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  create: data => dispatch(actions.createPromise(data)),
  remove: id => dispatch(actions.removePromise(id)),
  update: (id, data) => dispatch(actions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
