import Grid from '@material-ui/core/Grid';
import ScenariosProductSelectableDetailPanel from 'components/DetailPanels/ScenariosProductSelectableDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { setContextSearchProduct } from 'ducks/ui';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/evaluation/evaluationProductScenarios';
import { getImmutableStoreData } from 'selectors/generic';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/product/productSimple';
import noIcon from 'utils/tables/noIcon';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const { contextSearch, lcsById, products, scenarios, user } = props;
  const { openDescriptors, openProductScenarios, setSearch } = props;

  const tableRef = useRef();

  const [filterCenario, setFilterCenarios] = useState(false);

  const onSelectingScenario = rowData => productScenario =>
    openDescriptors(rowData.id, productScenario.id_lcs)

  const exportPDF = () => {
    const filteredTableData = tableRef.current.state.data;
    pdfMake.createPdf(
      docDefinition(
        filteredTableData,
        lcsById,
        scenarios,
        'R - Listagem de Cenários Avaliação de Produto'
      ))
      .open();
  };

  let filteredProducts = products.filter( product => !!product.Produto_Mps.find( mp => mp.contribui_perigo));
  if (filterCenario) {
    filteredProducts = products
      .filter( product => scenarios.find(s => s.id_produto && s.id_produto == product.id))
      .filter( product => !!product.Produto_Mps.find( mp => mp.contribui_perigo));

  }

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={columns(user)}
        data={filteredProducts}
        onRowClick={() => null}
        onRowDoubleClick={rowData => openProductScenarios(rowData.id)}
        onSearchChange={ text => setSearch(text)}
        options={{
          actionsCellStyle: {
            width: 150,
            paddingLeft: 20
          },
          searchText: contextSearch
        }}
        actions={[
          rowData => ({
            icon: scenarios.find(s => s.id_produto && s.id_produto == rowData.id) ?
              tableIcons.Scenarios() :
              tableIcons.ScenariosEmpty(),
            tooltip: 'Gerir cenários',
            onClick: (event, rowData) => openProductScenarios(rowData.id)
          }),
          // {
          //   icon: tableIcons.ContentCopy,
          //   tooltip: 'Copiar e criar cenário',
          //   onClick: (event, rowData) => {
          //     alert('Cópia de cenário ainda não implementada');
          //   }
          // }
          {
            isFreeAction: true,
            icon: tableIcons.Pdf,
            tooltip: 'Exportar PDF',
            onClick: exportPDF
          },
          {
            isFreeAction: true,
            icon: filterCenario ? tableIcons.FilterWrongStyled : tableIcons.FilterWrong,
            tooltip: 'Filtrar cenários',
            onClick: () => setFilterCenarios(!filterCenario)
          },

        ]}
        detailPanel={[
          rowData => {
            const productScenario = scenarios.find(s => s.id_produto && s.id_produto == rowData.id);
            if (productScenario) {
              return ScenariosProductSelectableDetailPanel(rowData, onSelectingScenario(rowData), scenarios, lcsById)
            } else {
              return noIcon;
            }
          }
        ]}
      />
    </Grid>
  )
};


const makeMapStateToProps = () => {
  const immutableProducts = getImmutableStoreData('products');
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchProduct,
    products: immutableProducts(state),
    scenarios: state.scenarios.data,
    lcsById: state.descriptors.dataById,
    user: state.profile.entity
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  setSearch: search => dispatch(setContextSearchProduct(search))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
