import Grid from '@material-ui/core/Grid';
import ExpandableBar from 'components/ExpandableBar'
import LcsFactorySelectorExternalExposure from 'connectedComponents/HeaderButtons/LcsTableFactorySelectorExternalExposure';
import SubstanceSelector from 'connectedComponents/HeaderButtons/SubstanceTableSelector';
import SubstanceShipmentSelector from 'connectedComponents/HeaderButtons/SubstanceShipmentTableSelector';
import ScenarioSelector from 'connectedComponents/HeaderButtons/ScenarioTableSelector';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SpinButton from 'components/Buttons/SpinButton';
import theme from 'source/theme';

const Component = props => {
  const {
    currentOptionShipment, currentOptionSubstance, currentOptionLcs,
    currentOptionScenario, rawMaterialsById, scenarios
  } = props;
  const {
    closeScenarioManagement, handleCreateScenarioOpen, onSelectionShipment,
    onSelectionScenario, onSelectionSubstance, onSelectionLcs
  } = props;

  const [update, setUpdate] = useState({});
  useEffect(() =>
    setUpdate({}),
    [ currentOptionShipment, currentOptionSubstance, currentOptionLcs, currentOptionScenario ]
    );

  const optionKeys1 = [
    { title: 'CODIGO_MP', tag: 'shipment_cod_mp' },
    { title: 'MP_NOME', tag: 'shipment_mp_nome' },
  ];

  const optionKeys2 = [
    { title: 'CODIGO', tag: 'codigo' },
    { title: 'DESIGNAÇÃO COMERCIAL', tag: 'designacao_comercial' },
    { title: 'FORNECEDOR', tag: 'fornecedor', nested: 'id_fornecedor' },
  ]

  const shipments = [];
  currentOptionSubstance.inter_fornecedores.forEach(shipment =>
    shipments.push({
      ...shipment,
      shipment_substance_id: currentOptionSubstance.id,
      shipment_id_mp: currentOptionSubstance.id,
      shipment_mp_nome: currentOptionSubstance.mp_nome,
      shipment_cod_mp: currentOptionSubstance.cod_mp,
    })
  )
  currentOptionSubstance.inter_misturas.forEach(mix =>
    rawMaterialsById[mix.id_mistura].inter_fornecedores.forEach(shipment =>
      shipments.push({
        ...shipment,
        shipment_mixture_id: mix.id_mistura,
        shipment_id_mp: mix.id_mistura,
        shipment_mp_nome: rawMaterialsById[mix.id_mistura].mp_nome,
        shipment_cod_mp: rawMaterialsById[mix.id_mistura].cod_mp,
      })
    )
  )

  const mp = optionKeys1.map(option => currentOptionShipment[option.tag]).join(' / ');
  const ship = optionKeys2.map(option => option.nested ?
    currentOptionShipment[option.nested][option.tag] :
    currentOptionShipment[option.tag]
  ).join(' / ')

  const scenarioText = () =>
    <span>
      <span style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', paddingTop: 3 }}>&nbsp;SUBSTÂNCIA&nbsp;</span>
      &nbsp;{currentOptionSubstance['mp_nome']}&nbsp;
      <span style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', paddingTop: 3  }}>&nbsp;MATÉRIA_PRIMA&nbsp;</span>
      &nbsp;{mp}&nbsp;
      <span style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', paddingTop: 3  }}>&nbsp;FORNECIMENTO&nbsp;</span>
      &nbsp;{ship}&nbsp;
      <span style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', paddingTop: 3  }}>&nbsp;LCS&nbsp; </span>
      &nbsp;{currentOptionLcs['descricao']}&nbsp;
      <span style={{ backgroundColor: theme.palette.primary.main, borderRadius: '5px', paddingTop: 3  }}>&nbsp;CENÁRIO&nbsp;</span>
      &nbsp;{currentOptionScenario && currentOptionScenario['codigo']}&nbsp;
    </span>

  return (
    <ExpandableBar
      header=''
      text={scenarioText}
      update={update}
      goBack={closeScenarioManagement}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 5 }}>
          <SubstanceSelector
            currentOption={currentOptionSubstance}
            onSelection={onSelectionSubstance}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 5 }}>
          <SubstanceShipmentSelector
            currentOption={currentOptionShipment}
            onSelection={onSelectionShipment}
            substance={currentOptionSubstance}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 5 }}>
          <LcsFactorySelectorExternalExposure
            currentOptionShipment={currentOptionShipment}
            currentOption={currentOptionLcs}
            onSelection={onSelectionLcs}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 5 }}>
          <ScenarioSelector
            currentOption={currentOptionScenario}
            onSelection={onSelectionScenario}
            options={scenarios}
          />
        </Grid>
        <Grid item xs={12}>
          <SpinButton
            label='Criar novo cenário'
            onClick={handleCreateScenarioOpen}
          />
        </Grid>
      </Grid>
    </ExpandableBar>
  )
}

const mapStateToProps = (state, props) => ({
  rawMaterialsById: state.rawMaterials.dataById,
})

export default connect(mapStateToProps)(Component);
