import { lighten } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import merge from 'lodash/merge';
import React from 'react';

// TODO: Overall theme should be refactored so stylings start including theme.palette.augmentColor.
// https://github.com/mui-org/material-ui/issues/12390


const rawTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#F6B692',  // '#FEBD3C',
      main: '#e96316',
      dark: '#C0152E', //'#d87715',
      contrastText: '#EEEEEE'
    },
    secondary: {
      light: '#8c8c8c',
      main: '#5F5F5F',
      dark: '#353535',
      contrastText: '#EEEEEE'
    },
    type: 'light',
    background: {
      default: '#FFF'
    }
  },
  typography: {
    fontFamily: ['Gotham', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif'],
    useNextVariants: true
  }
});

const blue = { main: '#00BFFF' };
rawTheme.palette.augmentColor(blue);

const success = { main:'#40FF00' };
rawTheme.palette.augmentColor(success);

const warning = { main: '#FF8000' };
rawTheme.palette.augmentColor(warning);

const error = { main: '#FF0000' };
rawTheme.palette.augmentColor(error);

const rowErrorBackground = { main: '#ff4033' };
rawTheme.palette.augmentColor(rowErrorBackground);

const background = {
  main: rawTheme.palette.secondary.main,
  light: rawTheme.palette.secondary.light,
  veryLight: lighten(rawTheme.palette.secondary.light, 0.7),
};
rawTheme.palette.augmentColor(background);

const theme = merge(rawTheme, {
  palette: { blue, error, success, warning, background, rowErrorBackground }
});

console.log('theme', theme);
export default theme;
