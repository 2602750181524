import BaseTable from 'components/Tables/BaseTable'
import { actions as productActions } from 'ducks/products';
import { actions as productStepsActions } from 'ducks/productSteps';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/product/productSteps';

const Component = props => {
  let { flowSteps, productsById, productId, user } = props;
  const { createProductStep, fetchProduct, handleCloseDrawer, removeProductStep } = props;

  const [unselectedIds, setUnselectedIds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const flowStepIds = flowSteps
      .filter(fs => fs.subetapas.length === 0 || fs.id_master_etapa !== null)
      .map(fs => fs.id);

    setUnselectedIds(
      productsById[productId].etapa_produto
        .filter(s => flowStepIds.includes(s.id_etapa))
        .map(s => s.id_etapa)
    );
  }, [productsById]);

  const pickableflowSteps = flowSteps.filter(fs => fs.subetapas.length === 0 || fs.id_master_etapa !== null);
  const steps = pickableflowSteps.map(step =>
    !unselectedIds.includes(step.id) ?
      { ...step, tableData: { checked: true } } :
      step.tableData && step.tableData.checked ?
        { ...step, tableData: { checked: false } } : step
  );
  const orderedSteps = sortBy(steps, [o => unselectedIds.includes(o.id)]);

  return (
    <BaseTable
      titleBack={true}
      titleAction={handleCloseDrawer}
      title={`ETAPAS - ${productsById[productId].codigo_produto} - ${productsById[productId].produto_nome}`}
      icons={tableIcons}
      columns={columns(user)}
      data={orderedSteps}
      onRowClick={() => null}
      options={{
        selection: true,
        showTextRowsSelected: false,
        showSelectAllCheckbox: false
      }}
      isLoading={loading}
      onSelectionChange={(rows, difRow) => {
        setLoading(true);

        const unselectedRows = steps.length - rows.length
        if ( unselectedRows > unselectedIds.length) {
          createProductStep({ id_etapa: difRow.id, id_produto: productId })
            .then(() => fetchProduct(productId))
            .then(() => setLoading(false))
            .catch(e => {
              setLoading(false)
              alert(e)
            })
        } else {
          const productStepToDel = productsById[productId].etapa_produto
            .find(ps => ps.id_etapa === difRow.id);
          removeProductStep(productStepToDel.id)
            .then(() => fetchProduct(productId))
            .then(() => setLoading(false))
            .catch(e => {
              setLoading(false)
              alert(e)
            })

        }
      }}
    />
  )
};

const mapStateToProps = (state) => ({
  productsById: state.products.dataById,
  user: state.profile.entity
})

const mapDispatchToProps = dispatch => ({
  fetchProduct: (id) => dispatch(productActions.fetchOne(id)),
  createProductStep: data => dispatch(productStepsActions.createPromise(data)),
  removeProductStep: id => dispatch(productStepsActions.removePromise(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
