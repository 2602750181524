export const PC_TAB = 0;
export const SU_TAB = 1;
export const ERC_TAB = 2;
export const PROC_TAB = 3;

export const PC = 70004;
export const SU = 70002;
export const ERC = 70003;
export const PROC = 70005;

export const DESCRIPTOR_TYPES = [PC, SU, ERC, PROC];
export const DESCRIPTORS_WITH_CONDITIONS_TYPES = [ERC, PROC];
