import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columns = [
  { title: 'Consumo Anual (kg)', field: 'consumo_anual_kg'},
  { title: 'Compra Anual (kg)', field: 'compra_anual_kg'},
  { title: 'Ano', field: 'ano'},
];

export default props => {
  const {rowData, data, title} = props;

  // TODO: Solve on the backend API is not sending only ids in some cases sends Objects with full entity

  // cons rows = rowData.map(id => data[id]);
  let rows = [];
  if ( typeof rowData[0] === 'number') {
    rows = rowData.map(id => data[id]);
  } else {
    rows = rowData.map(obj => data[obj.id]);
  }

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={rows} title={title}/>
    </div>
  )
}
