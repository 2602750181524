import { AUTORIZACAO, CAS, DESCRIPTION_RESTRICTIONS, EC, ENTRYNO, RESTRICOES, SVHC } from 'utils/echa/constants';

const Joi = require('@hapi/joi');

const commonSchema = {
  Name: Joi.string().required(),
  DescriptionAutorizacao: Joi.string().allow(''),
  [DESCRIPTION_RESTRICTIONS]: Joi.array().required(),
  DescriptionSvhc: Joi.string().allow(''),
  [EC]: Joi.array().items(Joi.string().pattern(/^-$|^([0-9]+\-)+[0-9]+$/).allow('')).required(),
  [CAS]: Joi.array().items(Joi.string().pattern(/^-$|^([0-9]+\-)+[0-9]+$/).allow('')).required(),

  'Sunset Date': Joi.string().allow('').required(),
  'Exempted (categories of) uses': Joi.string().allow('').required(),

  [ENTRYNO]: Joi.array().required(),

  'Reason for inclusion': Joi.string().allow(''),
  'Date of inclusion': Joi.string().allow(''),
};


const schemaAutorizacao = {
  ... commonSchema,
  'autorizacaoEntrada': Joi.string().allow('').required(),
  'Latest application date': Joi.string().required(),
  'Intrinsic property(ies) referred to in Article 57': Joi.string().allow('').required(),
  'Review Periods': Joi.string().allow('').required(),
  'Remarks': Joi.string().allow('').required()
};

const schemaRestricoes = {
  ... commonSchema,
  'Conditions': Joi.string().allow('').required(),
  'Appendices': Joi.string().allow('').required(),
  'Standards': Joi.string().allow('').required(),
  'History': Joi.string().allow('').required(),
  'Q&As': Joi.string().allow('').required(),
  'External remarks': Joi.string().allow('').required()
};

const schemaSvhc = {
  ... commonSchema,
  'Decision': Joi.string().allow(''),
  'IUCLID dataset': Joi.string().allow(''),
  'Support document': Joi.string().allow(''),
  'Response to comments': Joi.string().allow(''),
  'Remarks': Joi.string().allow('')
};

const rootSchema = schema => Joi.array().min(1)
  .items(Joi.object(schema))
  .required();

export const validateEcha = (echa, type) => {
  switch (type) {
    case SVHC:
      return rootSchema(schemaSvhc).validate(echa, { abortEarly: false });
    case AUTORIZACAO:
      return rootSchema(schemaAutorizacao).validate(echa, { abortEarly: false });
    case RESTRICOES:
      return rootSchema(schemaRestricoes).validate(echa, { abortEarly: false });
  }
};

export default validateEcha;
