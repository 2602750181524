import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columns = [
  { title: 'Código', field: 'codigo_fluxo' },
  { title: 'Nome Fluxograma', field: 'nome' },
];

export default props => {
  const { rowData: rows, data, title } = props;

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={rows} title={title}/>
    </div>
  )
};
