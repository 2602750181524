
// type of Raw Material

export const SUBSTANCE = 'substancia';
export const MIXTURE = 'mistura';
export const POLIMER = 'polímero';

// Types of Scenarios
export const SCENARIO_F = 'F' // Flow
export const SCENARIO_P = 'P' // Product
export const SCENARIO_EF = 'EF' // Exposure Flow
export const SCENARIO_EP = 'EP' // Exposure Product
