import BaseTable from 'components/Tables/BaseTable';
import { actions as scenariosDescriptorsConditionsActions } from 'ducks/scenariosDescriptorsConditions';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreData, lookUpIdValueSelector } from 'selectors/generic';
import arrayToObject from 'utils/arrayToObject';
import columns from 'utils/tables/columns/scenarioExposure/scenarioConditionsExposure';
import { TABLE_HEIGHT_SPLIT_4VIEW } from 'utils/ui/constants';

const TABLE_HEIGHT = TABLE_HEIGHT_SPLIT_4VIEW(64);

const Component = props => {
  const { conditionsFilter, descriptorId, descriptorFilter, scenario } = props;
  const {
    conditions, conditionsById, descriptorsById, scenarioDescriptorConditions,
    scenarioDescriptorsData, units, user
  } = props;
  const { create, remove, update } = props;
  const tableRef = React.useRef(null);

  const filteredScenarioDescriptors = scenarioDescriptorsData.filter(sd =>
    sd.id_cenario === scenario.id &&
    descriptorsById[sd.id_descritor].id_tipo === descriptorFilter
  );

  // Only used for ERC and PROC
  const scenarioDescriptor = filteredScenarioDescriptors.find(fsd =>
    (descriptorId && fsd.id_descritor === descriptorId)
  );

  const filteredConditions = conditions.filter(v => v.tipo === conditionsFilter);

  const filteredSDC = scenarioDescriptorConditions.filter(sdc =>
    sdc.id_cenariodescritor === (scenarioDescriptor && scenarioDescriptor.id)
  );

  const [selectedIds, setSelectedIds] = useState(filteredSDC.map(fsdc => fsdc.id_condicao));
  const [loading, setLoading] = useState(false);

  useEffect(
    () => setSelectedIds(filteredSDC.map(fsdc => fsdc.id_condicao)),
    [scenarioDescriptorConditions, descriptorId]
  );

  // TODO Adding Checked info to table, there should be a better way
  const fsdcByConditionId = arrayToObject(filteredSDC, 'id_condicao');

  let rows = [];
  if (descriptorId) {
    rows = filteredConditions.map(element => {
      const baseElement = { ...element, valor: '' }
      // TODO fsdcByConditionId[element.id] condition has this because the state suffers
      // TODO update on useEffect and not with action, should be corrected
      if (selectedIds.includes(element.id) && fsdcByConditionId[element.id]) {
        const valor = fsdcByConditionId[element.id].valor;
        const id_unidade = fsdcByConditionId[element.id].id_unidade;
        const ok_flag = fsdcByConditionId[element.id].ok_flag;
        const notas = fsdcByConditionId[element.id].notas;
        return {
          ...baseElement,
          tableData: { checked: true },
          valor: valor ? valor : '',
          id_unidade: id_unidade ? id_unidade : '',
          ok_flag: ok_flag ? ok_flag : false,
          notas: notas ? notas : ''
        };
      } else if (element.tableData && element.tableData.checked) {
        return { ...baseElement, tableData: { checked: false } }
      } else {
        return baseElement
      }
    });
  }

  const reOrderedRows = sortBy(rows, [o => !selectedIds.includes(o.id)]);

  return (
    <React.Fragment>
      <BaseTable
        ref={tableRef}
        title={'ESCOLHA AS CONDIÇÕES'}
        columns={columns(user, units)}
        data={reOrderedRows}
        onRowClick={() => null}
        paging={false}
        options={{
          // toolbar: false,
          // search: false,
          maxBodyHeight: TABLE_HEIGHT,
          minBodyHeight: TABLE_HEIGHT,
          selection: true,
          showTextRowsSelected: false,
          showSelectAllCheckbox: false,
          selectionProps: rowData => ({
            //checked: prevInterSubstanceIds.includes(rowData.id)
            //onClick: (event , rowData) => this.handleCheckboxClick(event,rowData),
          })
        }}
        isLoading={loading}
        editable={{
          onRowUpdate: (newData, oldData) => {
            if (oldData.tableData.checked) {
              const id_sdc = filteredSDC.find(fsdc => fsdc.id_condicao === newData.id).id;
              return update(
                id_sdc,
                {
                  ...newData,
                  id_unidade: newData.id_unidade === '' ? null : newData.id_unidade
                }
              );
            } else {
              return create({
                id_cenariodescritor: scenarioDescriptor.id,
                id_condicao: newData.id,
                ...newData,
                id_unidade: newData.id_unidade === '' ? null : newData.id_unidade
              })
            }
          }
        }}
        onSelectionChange={(rows, difRow) => {
          setLoading(true);
          if (rows.length > selectedIds.length) {
            create({
              id_cenariodescritor: scenarioDescriptor.id,
              id_condicao: difRow.id,
              id_unidade: conditionsById[difRow.id].id_unidade
            })
              .then(() => setLoading(false))
              .catch(e => alert(e));
          } else {
            const id_sdc = filteredSDC.find(fsdc => fsdc.id_condicao === difRow.id).id;
            remove(id_sdc)
              .then(() => setLoading(false))
              .catch(e => alert(e));
          }
        }}
      />
    </React.Fragment>
  )
};

const makemapStateToProps = () => {
  const conditions = getImmutableStoreData('conditions');
  const units = lookUpIdValueSelector('units', ['nome']);
  const mapStateToProps = (state, props) => ({
    conditions: conditions(state),
    conditionsById: state.conditions.dataById,
    descriptorsById: state.descriptors.dataById,
    scenarioDescriptorsData: state.scenarioDescriptors.data,
    scenarioDescriptorConditions: state.scenarioDescriptorConditions.data,
    units: units(state),
    user: state.profile.entity
  })
  return mapStateToProps
};

const mapDispatchToProps = dispatch => ({
  create: data => dispatch(scenariosDescriptorsConditionsActions.createPromise(data)),
  update: (id, data) => dispatch(scenariosDescriptorsConditionsActions.updatePromise(id, data)),
  remove: id => dispatch(scenariosDescriptorsConditionsActions.removePromise(id))
});

export default connect(makemapStateToProps, mapDispatchToProps)(Component);
