import { MTableEditField } from '@material-table/core';
import React from 'react';
import { DISTRIBUIDOR, FABRICANTE, IMPORTADOR, UJ } from 'utils/constants/papel_reach';

export default user => {
  const columns = [
    {
      title: 'Código',
      field: 'codigo_produto',
      minWidth: 150,
      editable: 'never',
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Nome',
      field: 'produto_nome',
      minWidth: 400,
      editable: 'never',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Papel Reach',
      field: 'papel_reach',
      minWidth: 150,
      editable: 'never',
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      initialEditValue: UJ,
      lookup: {
        '-': '-',
        [UJ]: 'UJ',
        [FABRICANTE]: 'Fabricante',
        [IMPORTADOR]: 'Importador',
        [DISTRIBUIDOR]: 'Distribuidor'
      }
    },
    {
      title: 'Notas',
      field: 'notas',
      minWidth: 200,
      initialEditValue: '',
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>
    },
    {
      title: 'Perigoso',
      field: 'perigoso',
      type: 'boolean',
      editable: 'never',
      minWidth: 90,
      initialEditValue: 'true',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      },
      lookup: {
        true: 'Sim',
        false: 'Não'
      },
      render: rowData =>
        <span style={{fontWeight: rowData.perigoso ? 'bold' : null}}>
        {rowData.perigoso ? 'Sim' : 'Não' }
      </span>
    }
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};
