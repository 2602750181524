import cloneDeep from 'lodash/cloneDeep'
import findDescriptorsForSubstanceShipment from 'pages/Evaluation/Product/ScenariosManagement/findDescriptorsConditionsForSubstanceShipment';
import { newLogoReachOk64 } from 'reports/base64Images';
import store from 'source/store';
import theme from 'source/theme';
import { ERC, PC, PROC, SU } from 'utils/constants/descriptorTypes';

const lightGrey = '#EEEEEE';

const rowGenerator_SU_PC_ERC_PROC = (lcs, productId, scenario) => {
  const state = cloneDeep(store.getState());

  const conditionsById = state.conditions.dataById;
  const descriptorsById = state.descriptors.dataById;
  const productsById = state.products.dataById;
  const rawMaterialsById = state.rawMaterials.dataById;
  const scenarioDescriptors = state.scenarioDescriptors.data;
  const scenarioDescriptorConditions = state.scenarioDescriptorConditions.data;
  const scenariosData = state.scenarios.data;
  const shipmentsById = state.rawMaterialSuppliers.dataById;
  const suppliersById = state.suppliers.dataById;
  const unitsById = state.units.dataById;

  const { augmentedDescriptors: augmentedDescriptorsPC, augmentedConditionsByDescriptorId: PCNotRelevant } =
    findDescriptorsForSubstanceShipment(
      conditionsById, descriptorsById, PC, scenario, lcs, productId, productsById,
      rawMaterialsById, scenarioDescriptors, scenarioDescriptorConditions, scenariosData,
      shipmentsById, unitsById);

  const { augmentedDescriptors: augmentedDescriptorsSU, augmentedConditionsByDescriptorId: SUNotRelevant } =
    findDescriptorsForSubstanceShipment(
      conditionsById, descriptorsById, SU, scenario, lcs, productId, productsById,
      rawMaterialsById, scenarioDescriptors, scenarioDescriptorConditions, scenariosData,
      shipmentsById, unitsById);

  const { augmentedDescriptors: augmentedDescriptorsERC, augmentedConditionsByDescriptorId: augmentedConditionsByDescriptorIdERC } =
    findDescriptorsForSubstanceShipment(
      conditionsById, descriptorsById, ERC, scenario, lcs, productId, productsById,
      rawMaterialsById, scenarioDescriptors, scenarioDescriptorConditions, scenariosData,
      shipmentsById, unitsById);

  const { augmentedDescriptors: augmentedDescriptorsPROC, augmentedConditionsByDescriptorId: augmentedConditionsByDescriptorIdPROC } =
    findDescriptorsForSubstanceShipment(
      conditionsById, descriptorsById, PROC, scenario, lcs, productId, productsById,
      rawMaterialsById, scenarioDescriptors, scenarioDescriptorConditions, scenariosData,
      shipmentsById, unitsById);

  const rowGenerateDescriptorsWithConditions = (augmentedDescriptors, augmentedConditionsByDescriptorId) => {
    const rows = [];
    augmentedDescriptors.forEach(augDescriptor => {
      rows.push([
        { text: augDescriptor.codigo, fillColor: theme.palette.primary.light },
        { text: augDescriptor.descricao, colSpan: 7, fillColor: theme.palette.primary.light },
        {},
        {},
        {},
        {},
        {},
        {}
      ]);

      augDescriptor.substances.forEach(substance => {
        // rows.push([
        //   { text: '', border: [false, false, false, false] },
        //   { text: '', border: [false, false, false, false] },
        //   { text: substance.mp_nome, colSpan: 6 },
        //   {},
        //   {},
        //   {},
        //   {},
        //   {}
        // ]);

        augDescriptor.shipmentsBySubstanceId[substance.id].forEach(shipment => {
          rows.push([
            { text: '', border: [false, false, false, false], color: theme.palette.secondary.dark },
            { text: '', border: [false, false, false, false], color: theme.palette.secondary.dark },
            { text: '', border: [false, false, false, false], color: theme.palette.secondary.dark },
            { text: shipment.codigo, border: [false, false, false, false], color: theme.palette.secondary.dark },
            { text: shipment.designacao_comercial, colSpan: 2, border: [false, false, false, false], color: theme.palette.secondary.dark },
            {},
            { text: suppliersById[shipment.id_fornecedor].fornecedor, colSpan: 2, border: [false, false, false, false], color: theme.palette.secondary.dark },
            {}
          ]);
        });
      });

      if (augmentedConditionsByDescriptorId && augmentedConditionsByDescriptorId[augDescriptor.id]) {
        Object.values(augmentedConditionsByDescriptorId[augDescriptor.id]).forEach(conditionInfo => {
          rows.push([
            { text: '', border: [false, false, false, false] },
            { text: conditionInfo[0].nome, colSpan: 7, fillColor: lightGrey },
            {},
            {},
            {},
            {},
            {},
            {}
          ]);

          conditionInfo[0].substances.forEach(substance => {
            // rows.push([
            //   { text: '', border: [false, false, false, false] },
            //   { text: '', border: [false, false, false, false] },
            //   { text: '', border: [false, false, false, false] },
            //   { text: substance.mp_nome, colSpan: 5 },
            //   {},
            //   {},
            //   {},
            //   {}
            // ]);

            conditionInfo[0].shipmentsBySubstanceId[substance.id].forEach(shipment => {
              rows.push([
                { text: '', border: [false, false, false, false], color: theme.palette.secondary.dark },
                { text: '', border: [false, false, false, false], color: theme.palette.secondary.dark },
                { text: `${shipment.valor ? shipment.valor : ''} ${shipment.unidade ? shipment.unidade : ''}`, border: [false, false, false, false], color: theme.palette.secondary.dark },
                { text: shipment.codigo, border: [false, false, false, false], color: theme.palette.secondary.dark },
                { text: shipment.designacao_comercial, colSpan: 2, border: [false, false, false, false], color: theme.palette.secondary.dark },
                {},
                { text: suppliersById[shipment.id_fornecedor].fornecedor, colSpan: 2, border: [false, false, false, false], color: theme.palette.secondary.dark },
                {}
              ]);
            });
          });
        });
      }
    });

    return rows;
  }

  return [
    ...rowGenerateDescriptorsWithConditions(augmentedDescriptorsPC),
    ...rowGenerateDescriptorsWithConditions(augmentedDescriptorsSU),
    ...rowGenerateDescriptorsWithConditions(augmentedDescriptorsERC, augmentedConditionsByDescriptorIdERC),
    ...rowGenerateDescriptorsWithConditions(augmentedDescriptorsPROC, augmentedConditionsByDescriptorIdPROC)
  ];
}

const dd = (lcs, product, scenario) => {

  return {
    pageSize: 'A4',
    pageMargins: [30, 60, 30, 40],

    footer: function (currentPage, pageCount) {
      return {
        text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
        alignment: 'right',
        relativePosition: { x: -15, y: 20 },
        fontSize: 7
      }
    },
    header: function (currentPage, pageCount, pageSize) {
      return [
        {
          image: newLogoReachOk64,
          width: 50,
          relativePosition: { x: 20, y: 10 }
        }
      ]
    },
    content: [
      {
        style: 'header',
        text: `Cenário Avaliação Análise`
      },
      {
        style: 'header',
        text: `${product.codigo_produto} - ${product.produto_nome}`
      },
      {
        style: 'tableExample',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 0,
          widths: [30, 40, 60, 60, 60, 60, 60, 50],
          body: [
            [
              { text: scenario.codigo, colSpan: 2, fillColor: theme.palette.primary.main },
              {},
              { text: lcs.descricao, colSpan: 2, fillColor: theme.palette.primary.main },
              {},
              { text: scenario.designacao, colSpan: 4, fillColor: theme.palette.primary.main },
              {},
              {},
              {}
            ],
            ...rowGenerator_SU_PC_ERC_PROC(lcs, product.id, scenario)
          ]
        }
      }
    ],
    styles: {
      header: {
        color: theme.palette.primary.main,
        fontSize: 16,
        bold: true,
        alignment: 'center'
      },
      tableExample: {
        margin: [0, 15, 0, 0],
        fontSize: 8
      }
    }
  }
};

export default dd;
