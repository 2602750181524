import Grid from '@material-ui/core/Grid';
import BaseTable from 'components/Tables/BaseTable';
import { alertError } from 'ducks/alerts';
import { actions } from 'ducks/conditions';
import React from 'react';
import { connect } from 'react-redux';
import { getImmutableStoreData, lookUpIdValueSelector } from 'selectors/generic';
import columns from 'utils/tables/columns/administration/conditions';

const Component = props => {
  const { conditions, units } = props;
  const { create, remove, update } = props;
  const tableRef = React.useRef(null);

  return (
    <Grid item xs={12}>
      <BaseTable
        ref={tableRef}
        columns={columns(units)}
        data={conditions}
        onRowClick={() => null}
        editable={{
          onRowAdd: newData => create(newData),
          onRowUpdate: newData => update(
            newData.id,
            {
              ...newData,
              id_unidade: newData.id_unidade === '' ? null : newData.id_unidade
            },
          ),
          onRowDelete: oldData => remove(oldData.id)
        }}
      />
    </Grid>
  )
};

const makeMapStateToProps = () => {
  const immutableConditions = getImmutableStoreData('conditions');
  const units = lookUpIdValueSelector('units', ['id', 'nome']);
  const mapStateToProps = (state, props) => ({
    conditions: immutableConditions(state),
    units: units(state)
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  alertError: error => dispatch(alertError(error)),
  create: data => dispatch(actions.createPromise(data)),
  remove: id => dispatch(actions.removePromise(id)),
  update: (id, data) => dispatch(actions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
