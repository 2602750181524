import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from 'source/theme';

const useStyles = makeStyles({
  dialogTitle: {
    backgroundColor: theme.palette.background.veryLight,
    color: theme.palette.secondary.dark
  },
  dialogActions: {
    backgroundColor: theme.palette.background.veryLight
  },
  dialogContent: {
    padding: 0
  },
  paper: {
    width: '80%',
    maxHeight: '60%'
  }
});

const Component = props => {
  const { header, maxWidth, noContentPadding, onClose, onConfirm, open } = props;
  const classes = useStyles();

  return (
    <Dialog
      maxWidth={maxWidth}
      // onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      classes={{ paper: classes.paper }}
      //keepMounted
    >
      <DialogTitle className={classes.dialogTitle}>{header}</DialogTitle>
      <DialogContent dividers className={noContentPadding ? classes.dialogContent : null}>
        {props.children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button autoFocus onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="secondary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Component.propTypes = {
  header: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  maxWidth:  PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs', false]),
  noContentPadding: PropTypes.bool
};

export default Component;
