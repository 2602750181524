import React from 'react';

export default user => {
  const columns = [
    {
      title: 'Etapa',
      field: 'nome_etapa',
      editable: 'never',
      width: 200,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Descrição Etapa',
      field: 'descricao_etapa',
      editable: 'never',
      width: 600,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Sub-etapa',
      field: 'nome_subetapa',
      editable: 'never',
      width: 200,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Descrição Etapa',
      field: 'descricao_subetapa',
      editable: 'never',
      width: 600,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};
