import { TextField } from "@material-ui/core";
import React from 'react';
import { MIXTURE, POLIMER, SUBSTANCE } from 'utils/constants/coreConstants';

const editComponent = (props) => (
  <TextField
    fullWidth
    multiline
    size="small"
    //variant="outlined"
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  />
);

export default user => {
  const columns = [
    {
      title: 'Código MP',
      field: 'cod_mp',
      minWidth: 100,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      editComponent: editComponent
    },
    {
      title: 'Nome',
      field: 'mp_nome',
      minWidth: 300,
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'left'
      },
      //editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
      editComponent: editComponent
    },
    {
      title: 'Tipo',
      field: 'tipo',
      minWidth: 150,
      initialEditValue: SUBSTANCE,
      editable: 'onAdd',
      lookup: {
        [SUBSTANCE]: 'Substância',
        [MIXTURE]: 'Mistura',
        [POLIMER]: 'Polímero',
      }
    },
    {
      title: 'CAS',
      field: 'cas',
      minWidth: 150,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      render: rowData => rowData.cas && rowData.cas.length !== 0 ? rowData.cas.join(', ') : '',
      editComponent: editComponent
    },
    {
      title: 'EC',
      field: 'ec',
      minWidth: 150,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      render: rowData => rowData.ec && rowData.ec.length !== 0 ? rowData.ec.join(', ') : '',
      editComponent: editComponent
    },
    {
      title: 'Observações',
      field: 'observacoes',
      minWidth: 300,
      headerStyle: {
        textAlign: 'left'
      },
      cellStyle: {
        textAlign: 'left'
      },
      editComponent: editComponent
    },
    {
      title: 'Perigosa',
      field: 'perigosa',
      type: 'boolean',
      minWidth: 90,
      initialEditValue: 'true',
      headerStyle: {
        textAlign: 'center'
      },
      cellStyle: {
        textAlign: 'center'
      },
      lookup: {
        true: 'Sim',
        false: 'Não'
      },
      render: rowData =>
        <span style={{fontWeight: rowData.perigosa ? 'bold' : null}}>
        {rowData.perigosa ? 'Sim' : 'Não' }
      </span>
    }
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 50
    },
    ...columns
  ];

  return user.is_staff ? columnsAdmin : columns;
}
