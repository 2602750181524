import { MIXTURE, SUBSTANCE } from 'utils/constants/coreConstants';

export default {
  orderBy: {
    what: ['mp_nome'],
    how: ['asc']
  },
  sanitizers: {
    tipo: value => {
      switch (value) {
        case 'substância':
        case 'substancia':
        case 'Substancia':
        case 'Substância':
          return SUBSTANCE;
        case 'Mistura':
        case 'mistura':
          return MIXTURE;
        default:
          return value;
      }
    },
    perigosa: value => {
      switch (value) {
        case 'sim':
        case 'Sim':
        case true:
        case 'True':
        case 'true':
          return true;
        case 'Não':
        case 'não':
        case 'nao':
        case 'Nao':
        case  null:
        case  false:
        case  'False':
        case  'false':
          return false;
        case 'true':
        case 'false':
          return JSON.parse(value)
        default:
          return false;
      }
    },
    cas: value => {
      if (!value) {
        return [];
      }
      if (value[0] === '[') {
        return JSON.parse(value)
      } else {
        let n = 0;
        const trimValue = value.replace(/ /g, '');
        if (trimValue.indexOf(',') === -1) {
          if (trimValue === '-') {
            return [];
          } else {
            return [trimValue];
          }
        } else {
          const valueArray = [];
          let nextn = 0;
          while (nextn !== -1) {
            nextn = trimValue.indexOf(',', n);
            nextn === -1 ?
              valueArray.push(trimValue.slice(n)) :
              valueArray.push(trimValue.slice(n, nextn));
            n = nextn + 1;
          }
          return valueArray;
        }
      }
    },

    ec: value => {
      if (!value) {
        return [];
      }
      if (value[0] === '[') {
        return JSON.parse(value)
      } else {
        let n = 0;
        const trimValue = value.replace(/ /g, '');
        if (trimValue.indexOf(',') === -1) {
          if (trimValue === '-') {
            return [];
          } else {
            return [trimValue];
          }
        } else {
          const valueArray = [];
          let nextn = 0;
          while (nextn !== -1) {
            nextn = trimValue.indexOf(',', n);
            nextn === -1 ?
              valueArray.push(trimValue.slice(n)) :
              valueArray.push(trimValue.slice(n, nextn));
            n = nextn + 1;
          }
          return valueArray;
        }
      }
    },
    inter_substancias: value => {
      if (value && value.length > 0) {
        return value.map( item => {
          item.percentagem = (item.percentagem * 100).toFixed(5);
          return item;
        });
      } else {
        return value;
      }
    },
    inter_misturas: value => {
      if (value && value.length > 0) {
        return value.map( item => {
          item.percentagem = (item.percentagem * 100).toFixed(5);
          return item;
        });
      } else {
        return value;
      }
    }
  }
};
