import ScenarioSelector from 'components/Buttons/ButtonRadioDialog'
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { filterByFunctionSelector } from 'selectors/generic';

const Component = props => {
  const { currentOption, disabled } = props;
  const { onSelection } = props;

  return (
    <ScenarioSelector
      buttonText='SCENARIO: '
      currentOption={currentOption}
      disabled={disabled}
      header='Seleccione um Scenario'
      optionKey='designacao'
      options={[]}
      optionsById={{}}
      onSelection={onSelection}
    />
  )
};

export default Component;

Component.propTypes = {
  currentOption: PropTypes.object.isRequired,
  onSelection: PropTypes.func.isRequired
};
