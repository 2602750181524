import MixturesDetailPanel from 'components/DetailPanels/MixturesDetailPanel';
import ShipmentSupplierSubstMixDetailPanel from 'components/DetailPanels/ScenarioEFShipmentSupplierSubstMixDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { setContextSearchRM } from 'ducks/ui';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/scenariosExposure/scenarioExposureSubstancesScenarios';
import { filterSubstanceWithDirectAndIndirectShipment } from 'selectors/generic';
import { SCENARIO_EF } from 'utils/constants/coreConstants';
import { lcsInternalExposure } from 'utils/constants/lcsTypes';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/substancePolimer';
import noIcon from 'utils/tables/noIcon';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  const { contextSearch, lcsById, rawMaterials, rawMaterialsById, user, shipmentsById, scenarios } = props;
  const {
    setSubstanceId, setScenariosManagementOpen, setScenariosOpen, setSearch,


    setShipmentInitializer
  } = props;
  const tableRef = React.useRef(null);

  const [filterCenario, setFilterCenarios] = useState(false);

  /**
   * IMPORTANT REFACTOR ON INDEX AND SCENARIOA MANAGEMENT INDEX
   */
    // TODO REFACTOR CODE BECAUSE OF THIS
    // TODO ATENCAO AO setShipmentInitializer(shipment); a remover e mudar coisas noutros componentes
    // TODO is not needed anymore because of UI refactor, detail panel doesn't need to send to another environment
    // TODO TAKE A LOOK AT TO_DELETE_ShipmentSelectableTableDetailPanel TOO

  const selectSubstanceWithShipment = id => shipment => {
      setSubstanceId(id);
      setShipmentInitializer(shipment);
      setScenariosManagementOpen(true);
    };
  /**
   */

  const openSubstanceScenarios = id => {
    setSubstanceId(id);
    setScenariosOpen(true);
  };

  const exportPDF = () => {
    const filteredTableData = tableRef.current.state.data;
    pdfMake.createPdf(
      docDefinition(
        filteredTableData,
        lcsById,
        scenarios,
        rawMaterialsById,
        shipmentsById,
        'R - Listagem de Cenários Na Produção por Substância'
      ))
      .open();
  };

  let filteredRawMaterials = rawMaterials.filter(rawMaterial => rawMaterial.perigosa);
  if (filterCenario) {
    filteredRawMaterials = rawMaterials
      .filter(rawMaterial => rawMaterial.perigosa)
      .filter(rawMaterial =>
        scenarios.find(s =>
          s.tipo === SCENARIO_EF &&
          // TODO remove the below line should be "s.id_substancia === rawMaterial.id" ACCESS scenarios lack id_substancia
          (s.id_substancia === rawMaterial.id || rawMaterial.id === shipmentsById[s.id_substancia_fornecedor].id_mp) &&
          s.id_substancia_fornecedor &&
          lcsInternalExposure.includes(parseInt(s.id_lcs)) &&
          (
            rawMaterial.id === shipmentsById[s.id_substancia_fornecedor].id_mp ||
            rawMaterialsById[rawMaterial.id].inter_misturas
              .map(mix => mix.id_mistura)
              .includes(shipmentsById[s.id_substancia_fornecedor].id_mp)
        )
      )
    );
  }

  return (
    <BaseTable
      ref={tableRef}
      columns={columns(user)}
      data={filteredRawMaterials}
      onRowClick={() => null}
      onRowDoubleClick={rowData => openSubstanceScenarios(rowData.id)}
      onSearchChange={text => setSearch(text)}
      options={{
        searchText: contextSearch
      }}
      actions={[
        {
          isFreeAction: true,
          icon: tableIcons.Pdf,
          tooltip: 'Exportar PDF',
          onClick: exportPDF
        },
        rowData => {
          const substanceId = rowData.id;
          const filteredScenarios = scenarios.find(s =>
            s.tipo === SCENARIO_EF &&
            // TODO remove the below line should be "s.id_substancia === substanceId" ACCESS scenarios lack id_substancia
            ( s.id_substancia === substanceId || substanceId === shipmentsById[s.id_substancia_fornecedor].id_mp)  &&
            s.id_substancia_fornecedor &&
            lcsInternalExposure.includes(parseInt(s.id_lcs)) &&
            (
              substanceId === shipmentsById[s.id_substancia_fornecedor].id_mp ||
              rawMaterialsById[substanceId].inter_misturas
                .map(mix => mix.id_mistura)
                .includes(shipmentsById[s.id_substancia_fornecedor].id_mp)
            )
          );
          return {
              icon: filteredScenarios ?
                tableIcons.Scenarios() :
                tableIcons.ScenariosEmpty(),
              tooltip: 'Gerir cenários',
              onClick: (event, rowData) => openSubstanceScenarios(rowData.id)
            }
        },
        {
          isFreeAction: true,
          icon: filterCenario ? tableIcons.FilterWrongStyled : tableIcons.FilterWrong,
          tooltip: 'Filtrar cenários',
          onClick: () => setFilterCenarios(!filterCenario)
        }
      ]}
      detailPanel={[
        rowData => rowData.inter_misturas.length === 0 ?
          noIcon :
          {
            icon: tableIcons.Mixtures,
            tooltip: 'Misturas',
            render: () => <MixturesDetailPanel rowData={rowData.inter_misturas} data={rawMaterialsById} title={'Misturas'}/>
          },
        rowData =>
          ({
            icon: tableIcons.Shipments,
            tooltip: 'Fornecimentos',
            render: () => <ShipmentSupplierSubstMixDetailPanel
              rowData={rowData}
              dataById={rawMaterialsById}
              title={'Fornecimentos da Substância e das Misturas em que participa'}
              extra={{ scenarios, rawMaterialsById, shipmentsById }}
            />
          })
      ]}
    />
  )
};


const makeMapStateToProps = () => {
  const filteredRawMaterials = filterSubstanceWithDirectAndIndirectShipment();
  const mapStateToProps = (state, props) => ({
    contextSearch: state.ui.context.searchRM,
    lcsById: state.descriptors.dataById,
    products: state.products,
    rawMaterials: filteredRawMaterials(state),
    rawMaterialsById: state.rawMaterials.dataById,
    suppliersById: state.suppliers.dataById,
    user: state.profile.entity,
    shipmentsById: state.rawMaterialSuppliers.dataById,
    scenarios: state.scenarios.data,
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  setSearch: search => dispatch(setContextSearchRM(search))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
