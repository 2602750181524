import TabsBarsDescriptors from 'components/TabsBars/TabsBarsDescriptors';
import ERCAmbiente from 'pages/Evaluation/Product/ScenariosManagement/ERCAmbiente';
import ExpandableScenarioConfig from 'pages/Evaluation/Product/ScenariosManagement/ExpansionBar';
import findDescriptorsForSubstanceShipment from 'pages/Evaluation/Product/ScenariosManagement/findDescriptorsConditionsForSubstanceShipment';
import PCProduto from 'pages/Evaluation/Product/ScenariosManagement/PCProduto';
import PROCTrabalhadores from 'pages/Evaluation/Product/ScenariosManagement/PROCTrabalhadores';
import SUSectores from 'pages/Evaluation/Product/ScenariosManagement/SUSectores';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ERC, ERC_TAB, PC, PC_TAB, PROC, PROC_TAB, SU, SU_TAB } from 'utils/constants/descriptorTypes';

const Component = props => {
  const {
    conditionsById, descriptorsById, lcsId, lcssById, productId, productsById, rawMaterialsById,
    scenariosById, scenariosData, scenarioDescriptors, scenarioDescriptorConditions,
    scenarioId, shipmentsById, unitsById
  } = props;
  const { closeScenarioManagement, setLcsId, setScenarioId } = props;

  const product = productsById[productId];
  const lcs = lcssById[lcsId];
  const scenario = scenariosById[scenarioId];
  const productScenarios = scenariosData.filter(s => s.id_produto === productId && parseInt(s.id_lcs) === lcs.id);

  const [descriptorTab, setDescriptorTab] = useState(PC_TAB);
  const [augmentedDescriptors, setAugmentedDescriptors] = useState([]);
  const [augmentedConditionsByDescriptorId, setAugmentedConditionsByDescriptorId] = useState({});

  useEffect(() => {
    const scenarios = scenariosData.filter(s => s.id_produto === productId && parseInt(s.id_lcs) === lcs.id);
    setScenarioId(scenarios.length > 0 ? scenarios[0].id : null)
  }, [lcsId]);

  useEffect(() => {
    // TODO can be more eficient we can compute on first render augmented descriptors for all descriptorFilters
    const descriptorFilter = [PC, SU, ERC, PROC][descriptorTab ? descriptorTab : PC_TAB];
    const { augmentedDescriptors, augmentedConditionsByDescriptorId } =
      // TODO where we have (lcs, product, scenario) lcs and product are redundant should be removed
      findDescriptorsForSubstanceShipment(
        conditionsById, descriptorsById, descriptorFilter, scenario, lcs, productId, productsById,
        rawMaterialsById, scenarioDescriptors, scenarioDescriptorConditions, scenariosData,
        shipmentsById, unitsById);
    setAugmentedDescriptors(augmentedDescriptors);
    setAugmentedConditionsByDescriptorId(augmentedConditionsByDescriptorId);
  }, [lcsId, productId, descriptorTab])


  const WorkingArea = descriptorTab => {
    switch (descriptorTab) {
      case PC_TAB:
        return (
          <PCProduto
            augmentedDescriptors={augmentedDescriptors}
            lcs={lcs}
            scenario={scenario}
            productId={productId}
          />
        );

      case SU_TAB:
        return (
          <SUSectores
            augmentedDescriptors={augmentedDescriptors}
            lcs={lcs}
            scenario={scenario}
            productId={productId}
          />
        )

      case ERC_TAB:
        return (
          <ERCAmbiente
            augmentedDescriptors={augmentedDescriptors}
            augmentedConditionsByDescriptorId={augmentedConditionsByDescriptorId}
            lcs={lcs}
            scenario={scenario}
            productId={productId}
          />
        )

      case PROC_TAB:
        return (
          <PROCTrabalhadores
            augmentedDescriptors={augmentedDescriptors}
            augmentedConditionsByDescriptorId={augmentedConditionsByDescriptorId}
            lcs={lcs}
            scenario={scenario}
            productId={productId}
          />
        )
    }
  }

  return (
    <React.Fragment>
      <ExpandableScenarioConfig
        currentOptionProduct={product}
        currentOptionLcs={lcs}
        onSelectionLcs={setLcsId}
        scenarios={productScenarios}
        currentOptionScenario={scenario}
        onSelectionScenario={setScenarioId}
        closeScenarioManagement={closeScenarioManagement}
      />
      <TabsBarsDescriptors value={descriptorTab} setValue={setDescriptorTab}/>

      {
        scenario &&
        WorkingArea(descriptorTab)
      }
    </React.Fragment>
  )
};

const makeMapStateToProps = state => ({
  lcssById: state.descriptors.dataById,
  productsById: state.products.dataById,
  scenariosData: state.scenarios.data,
  scenariosById: state.scenarios.dataById,
  conditionsById: state.conditions.dataById,
  descriptorsById: state.descriptors.dataById,
  rawMaterialsById: state.rawMaterials.dataById,
  scenarioDescriptors: state.scenarioDescriptors.data,
  scenarioDescriptorConditions: state.scenarioDescriptorConditions.data,
  shipmentsById: state.rawMaterialSuppliers.dataById,
  unitsById: state.units.dataById
});

export default connect(makeMapStateToProps)(Component);
