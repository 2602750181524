import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.secondary.light,
  },
}));

const StyledTabs = withStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.background.veryLight,
  },
}))(Tabs);

const StyledTab = withStyles(theme => ({
  root: {
    '&:hover': {
      color: theme.palette.background.veryLight,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.background.veryLight,
      //fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {}, //not to override root behaviour above
}))(Tab);

export default props => {
  const { value } = props;
  const { setValue } = props;
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  return (
    <Paper className={classes.root}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <StyledTab label="PC - PRODUTO" />
        <StyledTab label="SU - SECTORES" />
        <StyledTab label="ERC - AMBIENTAIS" />
        <StyledTab label="PROC - TRABALHADORES" />
      </StyledTabs>
    </Paper>
  );
}
