export default theme => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.veryLight,
    zIndex: theme.zIndex.tooltip + 1
  },
  loader: {
    left: '45%',
    position: 'fixed',
    top: '45%',
    width: '10%'
  }
});
