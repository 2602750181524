import FluxogramSelector from 'components/Buttons/ButtonRadioDialog'
import RmFlowsDetailPanel from 'components/DetailPanels/RmFlowsDetailPanel';
import RMProductsDetailPanel from 'components/DetailPanels/RMProductsDetailPanel';
import ShipmentsDetailPanel from 'components/DetailPanels/ShipmentsDetailPanel';
import SubstancesDetailPanel from 'components/DetailPanels/SubstanceDetailPanel';
import BaseTable from 'components/Tables/BaseTable';
import { actions as mixtureActions } from 'ducks/mixtures'
import { actions as rawMaterialActions } from 'ducks/rawMaterials';
import { sortBy } from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import docDefinition from 'reports/flows/rawMaterialsSteps';
import { filterByFunctionSelector } from 'selectors/generic';
import theme from 'source/theme';
import { DISTRIBUIDOR } from 'utils/constants/papel_reach';
import tableIcons from 'utils/icons/materialTableIcons';
import columns from 'utils/tables/columns/inventory/rawMaterials';
import noIcon from 'utils/tables/noIcon';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Component = props => {
  let {
    filteredRawMaterials, flowId, flows, productsById, rawMaterialsById,
    stepRawMaterials, suppliersById, user
  } = props;
  const { setFlowId, setRawMaterial } = props;
  const tableRef = React.useRef(null);

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setSelectedIds(flows.dataById[flowId].mps);
  }, [flowId, flows]);

  // TODO Adding Checked info to table, there should be a better way
  const rows = filteredRawMaterials
    .filter(substance => substance.perigosa && substance.inter_fornecedores.find( shipment => shipment.papel_reach !== DISTRIBUIDOR))
    .map(subst =>
    selectedIds.includes(subst.id) ?
      { ...subst, tableData: { checked: true } } :
      subst.tableData && subst.tableData.checked ?
        { ...subst, tableData: { checked: false } } : subst
  );
  const reOrderedRows = sortBy(rows, [o => !selectedIds.includes(o.id)]);

  return (
    <BaseTable
      ref={tableRef}
      title={
        <FluxogramSelector
          buttonText='Fluxograma: '
          currentOption={flows.dataById[flowId]}
          header='Seleccione um fluxograma'
          optionKey={['codigo_fluxo','nome']}
          options={flows.data}
          optionsById={flows.dataById}
          onSelection={id => setFlowId(id)}
        />
      }
      columns={columns(user)}
      data={reOrderedRows}
      onRowClick={() => null}
      onRowDoubleClick={rowData => setRawMaterial(rowData)}
      options={{
        selection: true,
        showTextRowsSelected: false,
        showSelectAllCheckbox: false,
        selectionProps: rowData => ({ disabled: true })
      }}
      actions={[
        {
          icon: tableIcons.Steps,
          tooltip: 'Gerir condições',
          onClick: (event, rowData) => setRawMaterial(rowData),
          position: 'row'
        },
        {
          //isFreeAction: true,
          //position: 'toolbar',
          icon: tableIcons.Pdf,
          tooltip: 'Relatório PDF',
          onClick: (event, rowData) => {
            const onlySelectedRMwithLCS = tableRef.current.state.data.filter( rm => rm.tableData.checked );
            pdfMake.createPdf(docDefinition(
              onlySelectedRMwithLCS,
              flows.dataById[flowId],
              stepRawMaterials
            ))
              .open();
          }
        }
      ]}
      onSelectionChange={(rows) => {
        const nextRowIds = rows.map(row => row.id);
        setSelectedIds(nextRowIds);
      }}
      detailPanel={[
        rowData => rowData.inter_substancias.length === 0 ?
          noIcon :
          {
            icon: tableIcons.Substance,
            tooltip: 'Composição',
            render: () => <SubstancesDetailPanel rowData={rowData.inter_substancias} data={rawMaterialsById} title={'Substâncias'}/>
          },
        rowData => {
          const rmFlows = flows.data.filter(flow => flow.mps.includes(rowData.id));

          if (rmFlows.length === 0) {
            return {
              disabled: true,
              icon: tableIcons.FlowsBase(theme.palette.error.main),
              tooltip: 'Fluxogramas',
              render: () => {
              }
            };
          }

          return {
            icon: tableIcons.FlowsBase(),
            tooltip: 'Fluxogramas',
            render: () => <RmFlowsDetailPanel rowData={rmFlows} title={'Fluxogramas'}/>
          };
        },
        rowData =>
          ({
            icon: rowData.inter_fornecedores.length > 0 ?
              tableIcons.Shipments :
              tableIcons.ShipmentsStyled({ htmlColor: theme.palette.error.main }),
            tooltip: 'Fornecimentos',
            render: () => <ShipmentsDetailPanel rowData={rowData} suppliersById={suppliersById} rawMaterialsById={rawMaterialsById} title={'Fornecimentos'}/>
          }),
        rowData =>
          ({
            icon: rowData.produtos.length > 0 ?
              tableIcons.Products :
              tableIcons.ProductsStyled({htmlColor: theme.palette.error.main}),
            tooltip: 'Produtos',
            render: () =>
              <RMProductsDetailPanel
                rowData={rowData}
                productsById={productsById}
                rawMaterialsById={rawMaterialsById}
                title={'Produtos'}
              />
          }),
      ]}
    />
  )
};

const makeMapStateToProps = () => {
  const filteredRawMaterials = filterByFunctionSelector('rawMaterials', rm => rm.cod_mp !== null && rm.cod_mp !== '');
  const mapStateToProps = (state, props) => ({
    filteredRawMaterials: filteredRawMaterials(state),
    flows: state.flows,
    stepRawMaterials: state.stepRawMaterials.data,
    user: state.profile.entity,
    rawMaterialsById: state.rawMaterials.dataById,
    suppliersById: state.suppliers.dataById,
    productsById: state.products.dataById,
  })
  return mapStateToProps;
}

const mapDispatchToProps = dispatch => ({
  fetchRawMaterial: id => dispatch(rawMaterialActions.fetchOne(id)),
  create: data => dispatch(mixtureActions.createPromise(data)),
  remove: id => dispatch(mixtureActions.removePromise(id)),
  update: (id, data) => dispatch(mixtureActions.updatePromise(id, data))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
