import { sortBy } from 'lodash';
import { newLogoReachOk64 } from 'reports/base64Images';
import theme from 'source/theme';
import { ERC, PC, PROC, SU } from 'utils/constants/descriptorTypes';

const rowGenerator_SU_PC_ERC = (flow, scenario, descriptors, conditions, scenarioDescriptors, scenarioDescriptorConditions, units) => {

  const filteredSD = scenarioDescriptors.filter(fsd => fsd.id_cenario === scenario.id);
  const filteredSDIds = filteredSD.map(sd => sd.id);

  const filteredSDC = scenarioDescriptorConditions.filter(sdc =>
    filteredSDIds.includes(sdc.id_cenariodescritor)
  );

  const PCDescriptorIds = descriptors.data.filter(v => v.id_tipo === PC).map(obj => obj.id);
  const SUDescriptorIds = descriptors.data.filter(v => v.id_tipo === SU).map(obj => obj.id);
  const ERCdescriptorIds = descriptors.data.filter(v => v.id_tipo === ERC).map(obj => obj.id);

  const PC_SD = filteredSD.filter(sd => PCDescriptorIds.includes(sd.id_descritor));
  const SU_SD = filteredSD.filter(sd => SUDescriptorIds.includes(sd.id_descritor));
  const ERC_SD = filteredSD.filter(sd => ERCdescriptorIds.includes(sd.id_descritor));

  const PC_D_ToRender = PC_SD.map(sd => descriptors.dataById[sd.id_descritor]);
  const SU_D_ToRender = SU_SD.map(sd => descriptors.dataById[sd.id_descritor]);
  const ERC_D_ToRender = ERC_SD.map(sd => descriptors.dataById[sd.id_descritor]);

  const ERC_SDC = ERC_SD.map(sd => filteredSDC.filter(sdc => sdc.id_cenariodescritor === sd.id));
  const ERC_C_ToRender = ERC_SDC.map(sdcArray => sdcArray.map(sdc =>
      ({
        ...conditions.dataById[sdc.id_condicao],
        valor: sdc.valor
      })
    )
  )

  const renderDescriptorsWithoutConditions = descriptors =>
    descriptors.map(d =>
      [
        d.codigo,
        { text: d.descricao, colSpan: 3 },
        '',
        ''
      ]
    );

  const renderDescriptorsWithConditions = (descriptors, conditions, units) => {
    const dataToRender = [];
    descriptors.forEach((d, index) => {
      dataToRender.push([
        { text: d.codigo, rowSpan: conditions[index].length + 1 },
        { text: d.descricao, colSpan: 3 },
        '',
        ''
      ]);
      conditions[index].forEach(c =>
        dataToRender.push([
          '',
          { text: c.valor ? c.valor : '', border: [false, false, false, false] },
          { text: c.id_unidade ? units[c.id_unidade].nome : '', border: [false, false, false, false] },
          { text: c.nome, border: [false, false, false, false] }
        ])
      );
    });
    return dataToRender;
  };

  return [
    ...renderDescriptorsWithoutConditions(PC_D_ToRender),
    ...renderDescriptorsWithoutConditions(SU_D_ToRender),
    ...renderDescriptorsWithConditions(ERC_D_ToRender, ERC_C_ToRender, units)
  ]
}

const rowGenerator_PROC = (flow, scenario, descriptors, conditions, scenarioDescriptors, scenarioDescriptorConditions, units) => {

  const filteredSD = scenarioDescriptors.filter(fsd => fsd.id_cenario === scenario.id);
  const filteredSDIds = filteredSD.map(sd => sd.id);

  const filteredSDC = scenarioDescriptorConditions.filter(sdc =>
    filteredSDIds.includes(sdc.id_cenariodescritor)
  );

  const PROCdescriptorIds = descriptors.data.filter(v => v.id_tipo === PROC).map(obj => obj.id);

  const PROC_SD = filteredSD.filter(sd => PROCdescriptorIds.includes(sd.id_descritor));
  const PROC_D_ToRender = PROC_SD.map(sd => descriptors.dataById[sd.id_descritor]);

  const PROC_SDC = PROC_SD.map(sd => filteredSDC.filter(sdc => sdc.id_cenariodescritor === sd.id));
  const PROC_C_ToRender = PROC_SDC.map(sdcArray => sdcArray.map(sdc =>
      ({
        ...conditions.dataById[sdc.id_condicao],
        valor: sdc.valor
      })
    )
  )

  const masterSteps = sortBy(flow.etapas.filter(s => !s.id_master_etapa), [o => o.nome]);

  const renderDescriptorsWithConditionsInsideSteps = (scenarioDescriptors, descriptors, conditions, units) => {
    const dataToRender = [];

    masterSteps.forEach(ms => {
      dataToRender.push([
        { text: ms.nome, fillColor: theme.palette.primary.light },
        { text: ms.descricao, colSpan: 3, fillColor: theme.palette.primary.light },
        '',
        ''
      ]);

      if (ms.subetapas.length === 0) {
        descriptors.forEach((d, index) => {
          if (scenarioDescriptors[index].id_etapa === ms.id) {
            dataToRender.push([
              { text: d.codigo, rowSpan: conditions[index].length + 1 },
              { text: d.descricao, colSpan: 3 },
              '',
              ''
            ]);
            conditions[index].forEach(c =>
              dataToRender.push([
                '',
                { text: c.valor ? c.valor : '', border: [false, false, false, false] },
                { text: c.id_unidade ? units[c.id_unidade].nome : '', border: [false, false, false, false] },
                { text: c.nome, border: [false, false, false, false] }
              ])
            );
          }
        });
      } else {
        ms.subetapas.forEach(ssub => {
          dataToRender.push([
            { text: ssub.nome, fillColor: '#EEEEEE' },
            { text: ssub.descricao, colSpan: 3, fillColor: '#EEEEEE' },
            '',
            ''
          ]);

          descriptors.forEach((d, index) => {
            if (scenarioDescriptors[index].id_etapa === ssub.id) {
              dataToRender.push([
                { text: d.codigo, rowSpan: conditions[index].length + 1 },
                { text: d.descricao, colSpan: 3 },
                '',
                ''
              ]);
              conditions[index].forEach(c =>
                dataToRender.push([
                  '',
                  { text: c.valor ? c.valor : '', border: [false, false, false, false] },
                  { text: c.id_unidade ? units[c.id_unidade].nome : '', border: [false, false, false, false] },
                  { text: c.nome, border: [false, false, false, false] }
                ])
              );
            }
          });
        });
      }
    });
    return dataToRender;
  };

  return [
    ...renderDescriptorsWithConditionsInsideSteps(PROC_SD, PROC_D_ToRender, PROC_C_ToRender, units)
  ]
}


const dd = (
  lcs, flow, descriptors, conditions, scenario, scenarioDescriptors,
  scenarioDescriptorConditions, units
) => {

  return {
    pageSize: 'A4',
    pageMargins: [40, 60, 40, 40],

    footer: function (currentPage, pageCount) {
      return {
        text: `${new Date().toLocaleDateString()} - ${currentPage.toString()} de ${pageCount}`,
        alignment: 'right',
        relativePosition: { x: -15, y: 20 },
        fontSize: 7
      }
    },
    header: function (currentPage, pageCount, pageSize) {
      return [
        {
          image: newLogoReachOk64,
          width: 50,
          relativePosition: { x: 20, y: 10 }
        }
      ]
    },
    content: [
      {
        style: 'header',
        text: `R - Condições ${lcs.descricao}`
      },
      {
        style: 'tableExample',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: [50, 50, 75, '*'],
          body: [
            [
              { text: flow.codigo_fluxo, fillColor: theme.palette.primary.main },
              { text: flow.nome, colSpan: 3, fillColor: theme.palette.primary.main },
              '',
              ''
            ],
            ...rowGenerator_SU_PC_ERC(
              flow,
              scenario,
              descriptors,
              conditions,
              scenarioDescriptors,
              scenarioDescriptorConditions,
              units
            ),
            ...rowGenerator_PROC(
              flow,
              scenario,
              descriptors,
              conditions,
              scenarioDescriptors,
              scenarioDescriptorConditions,
              units
            )
          ]
        }
      }
    ],
    styles: {
      header: {
        color: theme.palette.primary.main,
        fontSize: 16,
        bold: true,
        alignment: 'center'
      },
      tableExample: {
        margin: [0, 15, 0, 0],
        fontSize: 8
      }
    }
  }
};

export default dd;
