import SimpleTable from 'components/Tables/SimpleTable';
import React from 'react';
import theme from 'source/theme';

const columns = [
  { title: 'Nome', field: 'mp_nome' },
  { title: 'Codigo MP', field: 'cod_mp' },
  { title: 'Tipo', field: 'tipo' },
  {
    title: '% na mistura',
    field: 'percentagem',
    render: value => value ?
      parseFloat(value) :
      value
  },
  {
    title: 'Perigosa',
    field: 'perigosa',
    render: value =>
      <span style={{ fontWeight: value ? 'bold' : null }}>
        {value ? 'Sim' : 'Não'}
      </span>
  }
];

export default props => {
  const { rowData, data, title } = props;

  const rows = rowData.map(obj => ({
      ...data[obj.id_mistura],
      percentagem: obj.percentagem
    })
  );

  return (
    <div style={{ padding: 10, backgroundColor: theme.palette.background.light }}>
      <SimpleTable columns={columns} rows={rows} title={title}/>
    </div>
  )
};
