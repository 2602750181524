import { MTableEditField } from '@material-table/core';
import { lcsNames, M_ID } from 'utils/constants/lcsTypes';
import React from 'react';

export default user => {
  const columns = [
    {
      title: 'Código',
      field: 'codigo',
      minWidth: 150,
      initialEditValue: '',
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
    },
    {
      title: 'LCS',
      field: 'id_lcs',
      minWidth: 200,
      initialEditValue: M_ID,
      lookup: lcsNames,
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      editable: 'onAdd',
      render: rowData => rowData.id_lcs !== '' ? lcsNames[rowData.id_lcs] : '',
    },
    {
      title: 'Designação',
      field: 'designacao',
      minWidth: 200,
      initialEditValue: '',
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
    },
    {
      title: 'Notas',
      field: 'notas',
      minWidth: 200,
      initialEditValue: '',
      headerStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
      editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
    },
  ];

  const columnsAdmin = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      width: 100,
    },
    ...columns
  ]

  return user.is_staff ? columnsAdmin : columns;
};
