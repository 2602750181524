import { MTableEditField } from '@material-table/core';
import React from 'react';

export default [
  {
    title: 'ID',
    field: 'id',
    editable: 'never',
    width: 200,
  },
  {
    title: 'Nome',
    field: 'nome',
    width: 800,
    editComponent: (props) => <MTableEditField fullWidth {...props} multiline/>,
  }
];
