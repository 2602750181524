// actions
import { ALERT_ERROR, ALERT_SUCCESS, CLEAN_ALERT, CLEAN_PERSISTENT_ALERT, PERSISTENT_ALERT } from 'actionTypes';

export const alertError = error => ({
  type: ALERT_ERROR,
  payload: error
})

export const cleanAlert = () => ({
  type: CLEAN_ALERT
});

export const persistentAlert = message => ({
  type: PERSISTENT_ALERT,
  payload: message
});

export const cleanPersistentAlert = message => ({
  type: CLEAN_PERSISTENT_ALERT
});

const INITIAL_STATE = {
  persistentMessage: '',
  message: '',
  status: 'error' // 'success', 'error', etc
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case PERSISTENT_ALERT:
      return {
        ...state,
        persistentMessage: payload,
        status: 'error'
      };

    case ALERT_SUCCESS:
      return {
        ...state,
        message: payload,
        status: 'success'
      };

    case ALERT_ERROR:
      return {
        ...state,
        message: payload,
        status: 'error'
      };

    case CLEAN_ALERT:
      return {
        ...state,
        message: '',
        status: 'error'
      };

    case CLEAN_PERSISTENT_ALERT:
      return {
        ...state,
        persistentMessage: '',
        status: 'error'
      };

    default:
      return state;
  }
};
