import TextField from '@material-ui/core/TextField';
import Dialog from 'components/Dialog';
import React from 'react';

const Component = props => {
  const { code, description, open } = props;
  const { handleClose, handleSubmit } = props;

  const [codigo, setCodigo] = React.useState(code);
  const [designacao, setDesignacao] = React.useState(description);
  const [notas, setNotas] = React.useState('');

  const handleChangeCodigo = e => setCodigo(e.target.value);
  const handleChangeDesignacao = e => setDesignacao(e.target.value);
  const handleChangeNotas = e => setNotas(e.target.value);

  const createScenario = () => {
    handleSubmit(codigo, designacao, notas);
  }

  return (
    <Dialog
      maxWidth={'md'}
      header='Criar Cenário'
      open={open}
      onClose={handleClose}
      onConfirm={createScenario}
    >
      <TextField
        autoFocus
        id="codigo"
        label="Codigo cenário"
        type="codigo"
        fullWidth
        multiline
        value={codigo}
        style={{marginBottom: 10}}
        onChange={handleChangeCodigo}
      />
      <TextField
        id="designacao"
        label="Designação cenário"
        type="designacao"
        fullWidth
        multiline
        value={designacao}
        style={{marginBottom: 10}}
        onChange={handleChangeDesignacao}
      />
      <TextField
        id="notas"
        label="Notas cenário"
        type="notas"
        fullWidth
        multiline
        value={notas}
        style={{marginBottom: 10}}
        onChange={handleChangeNotas}
      />
    </Dialog>
  );
}

export default Component;
