import PrivateRoute from 'components/PrivateRoute/index';
import Conditions from 'pages/CoreEntities/Conditions';
import Descriptors from 'pages/CoreEntities/Descriptors';
import Scenarios from 'pages/CoreEntities/Scenarios';
import TypeDescriptors from 'pages/CoreEntities/TypeDescriptors';
import Units from 'pages/CoreEntities/Units';
import Echas from 'pages/Echas';
import EchaStatus from 'pages/EchaStatus';
import Flows from 'pages/Flows/Flows';
import FlowsScenarios from 'pages/Flows/FlowScenarios';
import FlowsSteps from 'pages/Flows/FlowsSteps';
import ImportData from 'pages/import/ImportData';
import ImportEchas from 'pages/import/ImportEchas';
import RawMaterialConsumptions from 'pages/Inventory/Consumptions';
import Mixtures from 'pages/Inventory/Mixtures';
import RawMaterials from 'pages/Inventory/RawMaterials';
import RawMaterialsSuppliers from 'pages/Inventory/Shipments';
import SubstancesPolimers from 'pages/Inventory/substancesPolimers';
import Suppliers from 'pages/Inventory/Suppliers';
import FlowProducts from 'pages/Products/FlowProducts'
import ProductRM from 'pages/Products/ProductRM';
import Products from 'pages/Products/Products';
import ProductScenarios from 'pages/Products/ProductScenarios';
import AccountManagement from 'pages/AppBarMenu/AccountManagement';
import Manuals from 'pages/AppBarMenu/Manuals';
import ReachOKAccountManagement from 'pages/AppBarMenu/ReachOKAccountManagement';
import UserData from 'pages/AppBarMenu/UserData';
import UsersManagement from 'pages/AppBarMenu/UsersManagement';
import ExposureInternal from 'pages/ScenarioExposure/Flow';
import ExposureExternal from 'pages/ScenarioExposure/Product';
import EvaluationProduct from 'pages/Evaluation/Product';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

export default () => (
  <Switch>
    <PrivateRoute exact path='/suppliers' component={Suppliers} title={'FORNECEDORES'}/>
    <PrivateRoute exact path='/raw-materials' component={RawMaterials} title={'MATÉRIAS PRIMAS'}/>
    <PrivateRoute exact path='/raw-materials-suppliers' component={RawMaterialsSuppliers} title={'FORNECIMENTOS'}/>
    <PrivateRoute exact path='/raw-material-consumptions' component={RawMaterialConsumptions} title={'CONSUMOS'}/>
    <PrivateRoute exact path='/mixtures' component={Mixtures} title={'MISTURAS'}/>
    <PrivateRoute exact path='/substance-polimers' component={SubstancesPolimers} title={'SUBSTÂNCIAS E POLÍMEROS'}/>


    <PrivateRoute exact path='/clients' component={ReachOKAccountManagement}/>
    <PrivateRoute exact path='/manuals' component={Manuals}/>
    <PrivateRoute exact path='/userdata' component={UserData}/>
    <PrivateRoute exact path='/usersmanagement' component={UsersManagement}/>
    <PrivateRoute exact path='/accountmanagement' component={AccountManagement}/>


    <PrivateRoute exact path='/flow-steps' component={Flows} title={'FLUXOGRAMAS'}/>
    <PrivateRoute exact path='/flow-steps-rm' component={FlowsSteps} title={'ETAPAS - MATÉRIAS PRIMAS'}/>
    <PrivateRoute exact path='/flow-scenarios' component={FlowsScenarios} title={'CENÁRIOS PRODUÇÃO'}/>

    <PrivateRoute exact path='/products' component={Products}  title={'PRODUTOS'}/>
    <PrivateRoute exact path='/product-flows' component={FlowProducts}  title={'PRODUTOS - FLUXOGRAMA'}/>
    <PrivateRoute exact path='/product-rms' component={ProductRM}  title={'COMPOSIÇÃO PRODUTO'}/>
    <PrivateRoute exact path='/product-scenarios' component={ProductScenarios}  title={'CENÁRIOS PRODUTO'}/>

    <PrivateRoute exact path='/exposure-internal' component={ExposureInternal}  title={'CE DE MP - NA PRODUÇÃO'}/>
    <PrivateRoute exact path='/exposure-external' component={ExposureExternal}  title={'CE DE MP - NO PRODUTO'}/>

    <PrivateRoute exact path='/evaluation-production' component={EvaluationProduct}  title={'AVALIAÇÃO PRODUÇÃO'}/>
    <PrivateRoute exact path='/evaluation-product' component={EvaluationProduct}  title={'AVALIAÇÃO PRODUTO'}/>

    <PrivateRoute exact path='/echa-status' component={EchaStatus} title={'LIGAÇÃO MATÉRIAS PRIMAS - ECHA'}/>

    <PrivateRoute exact path='/units' component={Units} title={'UNIDADES'}/>
    <PrivateRoute exact path='/descriptors' component={Descriptors} title={'DESCRITORES'}/>
    <PrivateRoute exact path='/type-descriptors' component={TypeDescriptors} title={'TIPOS DE DESCRITORES'}/>
    <PrivateRoute exact path='/conditions' component={Conditions} title={'CONDIÇÕES'}/>
    <PrivateRoute exact path='/echas' component={Echas}  title={'LISTA DE ECHAs'}/>
    <PrivateRoute exact path='/scenarios' component={Scenarios}  title={'LISTA TOTAL DE CENARIOS'}/>

    <PrivateRoute exact path='/import-echas' component={ImportEchas} title={'IMPORTAÇÃO DE ECHAs'}/>
    <PrivateRoute exact path='/import-data' component={ImportData} title={'IMPORTAÇÃO DE DADOS'}/>

    <Redirect to='/raw-materials'/>
  </Switch>
);
